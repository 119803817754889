import React from 'react';
import {Form, Button, DatePicker, Input, Radio, Drawer, Table, Space, Popover, message, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import './backwardness.scss';
import AddBackwardness from '../addBackwardness/addBackwardness';
import Http from '../../../../utils/http';
import moment from 'moment';
import { adminEditAndRead } from '../../../../utils/utils';

export class Backwardness extends React.Component<any,any>{
  editData:any = [];
  formRef: any;
  constructor(props:any){
    super(props);
    this.state = {
      newDrawer: false,
      eesData:[],
      epsData:[],
      eesTableShow:false,
      epsTableShow:false,
      index:0,
      tableIndex:0,
      modalVisible: false,
      readAndEdit:true
    };
    this.getFormRef = this.getFormRef.bind(this);
    this.clickRadio = this.clickRadio.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.getData = this.getData.bind(this);
    this.recyclingData = this.recyclingData.bind(this);
    this.showAddTable = this.showAddTable.bind(this);
    this.delete = this.delete.bind(this);
    this.getInfo = this.getInfo.bind(this);
    this.modalHandleCancel = this.modalHandleCancel.bind(this);
    this.modalShow = this.modalShow.bind(this);
  }
  setFormRef = (el: any) => this.formRef = el;
  createForm = React.createRef<FormInstance>();

  getFormRef(){
    this.formRef.vailForm();
  }

  getData(values:any){
    const {eesData,epsData} = this.state;
    values.date = Number(new Date(values.date).getTime());
    values.ees = eesData;
    values.eps = epsData;
    Http.put('/diagnosis/ebt/ei/'+this.props.id,values).then((res:any)=>{
      if(res&&res.code === 200){
        message.success('保存成功!');
        this.getInfo();
      }
    })
  }

  modalHandleCancel(){
    this.setState({
      modalVisible: false
    })
  }

  modalShow(){
    this.setState({
      modalVisible: true
    })
  }

  clickRadio(e:any,index:number){
    if(index===0){
      this.setState({
        epsTableShow:e.target.value === 'T'?true:false
      })
    } else {
      this.setState({
        eesTableShow:e.target.value === 'T'?true:false
      })
    }
  }

  showAddTable(index:number){
    this.editData = [];
    this.setState({
      index:index,
      newDrawer: true
    });
  }

  edit(data:any,tableIndex:number,index:number){
    this.editData = data;
    this.setState({
      index:index,
      tableIndex:tableIndex,
      newDrawer: true
    })
  }

  closeDrawer(){
    this.setState({
      newDrawer: false
    })
  }

  recyclingData(isEdit: boolean, eesEPS: number, values: any){
    let {eesData,epsData,tableIndex} = this.state;
    if(isEdit){
      if(eesEPS===0){
        eesData = Array.from(eesData,(v,i)=> i===tableIndex?v=values:v)
      } else {
        epsData = Array.from(epsData,(v,i)=> i===tableIndex?v=values:v)
      }
    } else {
      if(eesEPS===0){
        eesData = [...eesData,values];
      } else {
        epsData = [...epsData,values];
      }
    }
    eesData.forEach((i:any,index:number)=>{
      i.key = index + 1;
    })
    epsData.forEach((i:any,index:number)=>{
      i.key = index + 1;
    })
    this.setState({
      eesData,
      epsData,
      tableIndex:-1,
      newDrawer:false
    });
  }

  delete(index:number,eesEPS:number){
    let {eesData,epsData} = this.state;
    if(eesEPS === 0){
      eesData.splice(index,1);
    } else {
      epsData.splice(index,1);
    }
    this.setState({
      eesData:[...eesData],
      epsData:[...epsData]
    })
  }
  getInfo(){
    Http.get('/diagnosis/ebt/ei/'+this.props.id).then((res:any)=>{
      if(res&&res.code===200&&res.data){
        this.createForm.current?.setFieldsValue({
          date:moment(res.data.date),
          comment:res.data.comment,
          ee:res.data.ee,
          ep:res.data.ep,
          tee:res.data.tee,
        });
        res.data.ees.forEach((i:any,index:number)=>{
          i.key = index + 1;
        })
        res.data.eps.forEach((i:any,index:number)=>{
          i.key = index + 1;
        })
        this.setState({
          eesData:res.data.ees,
          epsData:res.data.eps,
          eesTableShow:res.data.ee==='T'?true:false,
          epsTableShow:res.data.ep==='T'?true:false,
        })
      }
    })
  }

  componentDidMount(){
    this.getInfo();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }
  render(){
    const { TextArea } = Input;
    let {eesTableShow,epsTableShow,eesData,epsData,readAndEdit} = this.state;
    const epsColumns = [
      {
        title:'序号',
        dataIndex:'key',
        key:'key',
        width:70,
        align:"center" as any
      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
      },{
        title: '规模/容量（注明单位）',
        width:200,
        dataIndex: 'scale',
        key: 'scale'
      },{
        title:'数量',
        dataIndex:'num',
        key:'num'
      },{
        title:'适用目录',
        dataIndex:'fit',
        key:'fit'
      },{
        title:'备注',
        dataIndex:'remark',
        key:'remark',
        width:200,
        ellipsis:true,
        render:(text:string)=>(
          <Popover content={text} title="备注" trigger="hover">
            <span>{text}</span>
          </Popover>
        )
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any,index:number) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record,index,1)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.delete(index,1)}>删除</span>:''}
            
          </Space>
        ),
      }
    ];
    const eesColumns = [
      {
        title:'序号',
        dataIndex:'key',
        key:'key',
        width:70,
        align:"center" as any
      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
      },{
        title: '规格型号',
        dataIndex: 'spec',
        key: 'spec'
      },{
        title:'数量',
        dataIndex:'num',
        key:'num'
      },{
        title:'适用目录',
        dataIndex:'fit',
        key:'fit'
      },{
        title:'备注',
        dataIndex:'remark',
        key:'remark',
        width:200,
        ellipsis:true,
        render:(text:string)=>(
          <Popover content={text} title="备注" trigger="hover">
            <span>{text}</span>
          </Popover>
        )
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any, index:number) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record,index,0)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.delete(index,0)}>删除</span>:''}
            
          </Space>
        ),
      }
    ];
    const eesTable = eesTableShow?
    <div>
      <div style={{width:'100%',display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
        <div>总共{eesData.length}条信息</div>
        {readAndEdit?<Button className="green-button" onClick={e=>this.showAddTable(0)}>新增</Button>:''}
        
      </div>
      <Table
        columns={eesColumns}
        dataSource={eesData}
        size="small"
      />
    </div>:'';
    const epsTable = epsTableShow?
    <>
    <div style={{width:'100%',display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
      <div>总共{epsData.length}条信息</div>
      {readAndEdit?<Button className="green-button" onClick={e=>this.showAddTable(1)}>新增</Button>:''}
      
    </div>
    <Table
      columns={epsColumns}
      dataSource={epsData}
      size="small"
    /></>:'';
    return(
      <div className="backwardness-container">
        <div className="alert-info" onClick={this.modalShow}>按《产业结构调整指导目录（2019年本）》填写（点击查看详情）</div>
        <Form layout="vertical" ref={this.createForm} name="backwardness" onFinish={this.getData}>
          <Form.Item label="1.企业投产年代" name="date" rules={[{required: true}]}>
            <DatePicker picker="month" placeholder="年/月" disabled={!readAndEdit} />
          </Form.Item>
          <Form.Item label="2. 是否存在淘汰落后工艺、装备、设备等" name="tee" rules={[{required: true}]}>
            <Radio.Group disabled={!readAndEdit}>
              <Radio value='T'>是</Radio>
              <Radio value='F'>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="3. 是否存在落后工艺或产能" name="ep" rules={[{required: true}]}>
            <Radio.Group onChange={e=>this.clickRadio(e,0)} disabled={!readAndEdit}>
              <Radio value='T'>是</Radio>
              <Radio value='F'>否</Radio>
            </Radio.Group>
          </Form.Item>
          {epsTable}
          <Form.Item label="4.是否存在落后装置或设备" name="ee" rules={[{required: true}]}>
            <Radio.Group onChange={e=>this.clickRadio(e,1)} disabled={!readAndEdit}>
              <Radio value='T'>是</Radio>
              <Radio value='F'>否</Radio>
            </Radio.Group>
          </Form.Item>
          {eesTable}
          <Form.Item label="5.其他信息说明" name="comment">
            <TextArea rows={3} disabled={!readAndEdit} />
          </Form.Item>
          {readAndEdit?<Form.Item>
            <Button type="primary" className="green-button" htmlType="submit">保存</Button>
          </Form.Item>:''}
          
        </Form>
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.getFormRef} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddBackwardness 
            recyclingData={this.recyclingData} 
            editData={this.editData} 
            id={this.props.id}
            index={this.state.index}
            bindRef={this.setFormRef}
          ></AddBackwardness>
        </Drawer>
        <Modal
          visible={this.state.modalVisible}
          title="《产业结构调整指导目录（2019年本）》纺织行业淘汰类工艺及设备"
          onCancel={this.modalHandleCancel}
          width={900}
          footer={[
            <Button key="back" onClick={this.modalHandleCancel}>
              关闭
            </Button>
          ]}
        >
          <div className="modal-container">
            <p>1、使用时间达到30年的棉纺、毛纺、麻纺设备、机织设备</p>
            <p>2、辊长1000毫米以下的皮辊轧花机，锯片片数在80以下的锯齿轧花机，压力吨位在400吨以下的皮棉打包机（不含160吨、200吨短绒棉花打包机）</p>
            <p>3、ZD647、ZD721型自动缫丝机，D101A型自动缫丝机，ZD681型立缫机，DJ561型绢精纺机，K251、K251A型丝织机等丝绸加工设备</p>
            <p>4、Z114型小提花机</p>
            <p>5、GE186型提花毛圈机</p>
            <p>6、Z261型人造毛皮机</p>
            <p>7、未经改造的74型染整设备</p>
            <p>8、蒸汽加热敞开无密闭的印染平洗槽</p>
            <p>9、R531型酸性粘胶纺丝机</p>
            <p>年及以下粘胶常规短纤维生产线</p>
            <p>11、湿法氨纶生产工艺</p>
            <p>12、二甲基甲酰胺（DMF）溶剂法氨纶及腈纶生产工艺</p>
            <p>14、常规聚酯（PET）间歇法聚合生产工艺及设备</p>
            <p>15、常规涤纶长丝锭轴长900毫米及以下的半自动卷绕设备</p>
            <p>16、使用年限超过15年的国产和使用年限超过20年的进口印染前处理设备、拉幅和定形设备、圆网和平网印花机、连续染色机</p>
            <p>17、使用年限超过15年的浴比大于1：10的棉及化纤间歇式染色设备</p>
            <p>18、使用直流电机驱动的印染生产线</p>
            <p>19、印染用铸铁结构的蒸箱和水洗设备，铸铁墙板无底蒸化机，汽蒸预热区短的L型退煮漂履带汽蒸箱</p>
            <p>20、螺杆挤出机直径小于或等于90mm，2000吨</p>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Backwardness;