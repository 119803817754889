import React from 'react';
import {Form, message, Input} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Http from '../../../../utils/http';
import { adminEditAndRead } from '../../../../utils/utils';

export class AddEnterWork extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    props.bindRef(this);
    this.state={
      list:[],
      isNull:false,
      readAndEdit:true
    };
    this.getData = this.getData.bind(this);
    this.ecList = this.ecList.bind(this);
    this.changeProduct = this.changeProduct.bind(this);
  };
  
  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(values:any){
    values.processType='PRODUCE';
    if(this.props.editData.id){
      Http.put('/diagnosis/equip/'+this.props.editData.id,values).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功');
          this.props.closeDrawer(true);
        }
      })
    } else {
      let requestData = {...values,diagnosisId:this.props.id}
      Http.post('/diagnosis/equip',requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新增成功');
          this.props.closeDrawer(true);
        }
      })
    }
  }

  ecList(){
    Http.get('/diagnosis/equip/'+this.props.id+'/process/type').then((res:any)=>{
      if(res&&res.code===200){
        this.setState({
          list:[...res.data],
          isNull:res.data.length===0?true:false
        });
      }
    })
  }
  changeProduct(e:any){
    let {list} = this.state;
    let selectList = list.filter((i:any)=> i.processName === e)[0];
    this.createForm.current?.setFieldsValue({
      num:selectList.num,
      unit:selectList.unit,
      productName:selectList.productName
    });
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.editData.id){
      this.createForm.current?.setFieldsValue(this.props.editData);
    }
    this.ecList();
  }
  
  render(){
    // const { Option } = Select;
    const {readAndEdit} = this.state;
    // const product = list.map((i:any)=>{
    //   return <Option disabled={i.used==='T'?true:false} key={i.processName} value={i.processName}>{i.processName}</Option>;
    // });
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        {/* {isNull?<Alert style={{marginBottom:'15px'}} message="请先到“基本信息-系统边界”页面补充“工序边界”信息" type="warning" showIcon />:''} */}
        <Form.Item label="工序名称" name="processName" rules={[{required: true}]}>
          {/* <Select style={{ width: '100%' }} placeholder="请选择" onChange={this.changeProduct} >
            {product}
          </Select> */}
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
      </Form>
    )
  }
}

export default AddEnterWork;
