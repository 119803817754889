import React from 'react';
import Http from '../../../../utils/http';
import {Row, Col, Form, message, InputNumber} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { adminEditAndRead } from '../../../../utils/utils';
// import moment from 'moment';

export class AddRank extends React.Component<any,any>{
  state = {
    energyData:[],
    fileList:[],
    readAndEdit:true
  };
  constructor(props: any){
    super(props);
    props.bindRef(this);
    this.getData = this.getData.bind(this);
  }

  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(values:any){
    Http.put('/diagnosis/equipment/rank/'+this.props.editData.id,values).then((res:any)=>{
      if(res&&res.code===200){
        message.success('修改成功!');
        this.props.closeDrawer(true);
      }
    })
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.editData){
      this.createForm.current?.setFieldsValue(this.props.editData);
    }
  }

  render(){
    let {readAndEdit} = this.state;
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="机电设备总数" name="sum" >
              <InputNumber style={{width:'100%'}} min={0} placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="一级能效设备数量" name="oneNum">
              <InputNumber style={{width:'100%'}} min={0} placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="一级能效设备占比(%)" name="onePercent">
              <InputNumber style={{width:'100%'}} min={0} placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="二级能效设备数量" name="twoNum">
              <InputNumber style={{width:'100%'}} min={0} placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="二级能效设备占比(%)" name="twoPercent">
              <InputNumber style={{width:'100%'}} min={0} placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="三级能效设备数量" name="threeNum">
              <InputNumber style={{width:'100%'}} min={0} placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="三级能效设备占比(%)" name="threePercent">
              <InputNumber style={{width:'100%'}} min={0} placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default AddRank;