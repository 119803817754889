import React, { ReactNode } from 'react';
import './enterprise-baisc-info.scss';
import {Row, Col, Form, Input, Button, Radio ,Divider, Upload, Cascader, Select, message,InputNumber, Space, Popover, TreeSelect} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Province from '../../../../utils/ssq.json';
import Http from '../../../../utils/http';
import { FormInstance } from 'antd/lib/form';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';
import { waterEditAndRead, verificationType } from '../../../../utils/utils';
import { render } from 'react-dom';
import { TreeNode } from 'antd/lib/tree-select';
import * as uniqid from 'uniqid';

export class EnterpriseBaiscInfo extends React.Component<any,any>{
  upLoad: any;
  fileupLoad: any;
  province:any;
  selectCode: any[] = [];
  industryList: any;
  constructor(props:any){
    super(props);
    let arr: any = [];
    Province.forEach(i=>{
      let option = {
        value: i.name,
        label: i.name,
        children:this.provicePush(i.children)
      }
      arr.push(option);
    })
    this.province = arr;

    this.state={
      fileList:[],
      readAndEdit:true,
      industryList:[],
      selectCode:[]
    };
    this.upLoad = {
      beforeUpload: (file: any) => {
        if(verificationType(file.type)){
          this.fileUpload(file);
        } else {
          message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
        }
        return false;
      },
      onRemove: (file: { uid: any; })=>{
        let {fileList} = this.state;
        fileList = fileList.filter((i: { [x: string]: any; })=> i['uid'] !== file.uid);
        this.setState({fileList});
        return false;
      },
      onPreview:(file:any)=>{
        store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name})
        return false;
      }
    };

    this.getInfo = this.getInfo.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.getForm = this.getForm.bind(this);
    this.getIndustryList = this.getIndustryList.bind(this);
  }
  
  createForm = React.createRef<FormInstance>();
  
  fileUpload(file:any){
    const formData = new FormData();
    formData.append('file', file);
    Http.post('/system/file/upload/auth',formData).then((res:any)=>{
      if(res&&res.code===200){
        let img = {
          uid:file.uid,
          name:file.name,
          status:'done',
          id:res.data,
          url:''
        };
        this.setState({
          fileList:[img]
        })
      }
    })
  }

  getForm(values:any){
    let {fileList} = this.state;
    values.licenseFile = [];
    values.licenseFile = {
      fileId:fileList[0]?.id,
      showName: fileList[0]?.name
    } 
    let respose = {
      companyTypeEnum:values.companyTypeEnum,
      companyName:values.companyName,
      license:values.license,
      area:values.area,
      members:values.members,
      baseInfo:values.baseInfo,
      wmlEnum:values.wmlEnum,
      writer:values.writer,
      province:values.district[0],
      city:values.district[1],
      district:values.district[2],
      industryCodes:values.industryCodes,
      address:values.address,
      tel:values.tel,
      contacts:[
        {
          flag:1,
          name:values.legal.name,
          tel:values.legal.tel
        },{
          flag:2,
          name:values.contactDepartment.name,
          tel:values.contactDepartment.tel
        }
      ],
      licenseFile:values.licenseFile,
      zipCode:values.zipCode
    };
    Http.put('/apiWaterUrl/assess/base/info/'+this.props.id,respose).then((res:any)=>{
      if(res&&res.code===200){
        message.success('保存成功!');
        this.getInfo();
      }
    })
  }

  getInfo(){
    Http.get('/apiWaterUrl/assess/base/info/'+this.props.id).then((res:any)=>{
      if(res&&res.code===200){
        // this.createForm.current?.setFieldsValue(res.data);
        this.createForm.current?.setFieldsValue({
          companyName:res.data.companyName,
          companyTypeEnum:res.data.companyTypeEnum,
          license:res.data.license,
          area:res.data.area,
          members:res.data.members,
          baseInfo:res.data.baseInfo,
          wmlEnum:res.data.wmlEnum,
          writer:res.data.writer,
          tel:res.data.tel,
          address:res.data.address,
          zipCode:res.data.zipCode
        });
        this.createForm.current?.setFieldsValue({
          industryCodes:res.data?.industryCodes?res.data.industryCodes:[]
        });
        this.selectCode = res.data?.industryCodes;
        if(res?.data){
          res.data.contacts?.forEach((i:any)=>{
            switch(i.flag){
              case 1:
                this.createForm.current?.setFieldsValue({
                  legal:{
                    name:i.name,
                    tel:i.tel
                  }
                }); 
                break;
              case 2:
                this.createForm.current?.setFieldsValue({
                  contactDepartment:{
                    name:i.name,
                    tel:i.tel
                  }
                }); 
                break;
            }
          })
          this.createForm.current?.setFieldsValue({
            district:[res.data?.province,res.data?.city,res.data?.district]
          });
          let fileList:any[] = [];
          if(res.data.licenseFile){
            fileList = [{
              uid:'1',
              name:res.data.licenseFile.showName,
              status:'done',
              id:res.data.licenseFile.fileId,
              url:''
            }];
          }
          this.setState({
            fileList:fileList,
          });
        }
      }
    })
  }

  getIndustryList(){
    Http.get('/apiWaterUrl/assess/industry/list').then((res:any)=>{
      if(res&&res.code===200){
        res.data.forEach((i:any,number:any) => {
          i.key = i.code;
        })
        let arr:any[] = res.data;
        this._forEachIndustryList(arr);
        this.setState({
          industryList:arr
        });
      }
    })
  }

  _forEachIndustryList(item:any){
    item.forEach((i:any,index:any) => {
      i.key = i.code;
      i.label = i.codeName;
      i.value = i.code;
      if(i.children && i.children.length>0){
        this._forEachIndustryList(i.children);
        i.isLeaf = false;
      }else{
        i.label = this.initLabel(i);
        i.isLeaf = true;
      }
    })
  }

  initLabel(i:any){
    return (
        <Popover overlayClassName="space-container" style={{zIndex: 2000}} content={
          <div className="popover-container">
            <div className="popover-item">
              <div className="popover-item-title">
                <img src="/assets/images/icon_description_normal.png" alt=""/>
                <span style={{paddingLeft: '8px'}}>说明</span>
              </div>
              <div className="popover-item-content">
                <span>{i.explain}</span>
              </div>
            </div>
            <div className="popover-item">
              <div className="popover-item-title">
                <img src="/assets/images/icon_productType_normal.png" alt=""/>
                <span style={{paddingLeft: '8px'}}>产品种类</span>
              </div>
              <div className="popover-item-content">
                <span>{i.product}</span>
              </div>
            </div>
          </div>
        } title={null} trigger="hover" placement="rightTop">
          <span className="popover-content-span">{i.label}</span>
        </Popover>
      )
  }

  componentDidMount(){
    this.getInfo();
    this.getIndustryList();
    this.setState({
      readAndEdit:waterEditAndRead()
    })
  }

  provicePush(arr:any) {
    let returnArr:any = [];
    let option:any;
    arr.forEach((element:any) => {
      if(element.children){
        option = {
          value:element.name,
          label:element.name,
          children:this.provicePush(element.children)
        };
        returnArr.push(option);
      } else {
        option = {
          value:element.name,
          label: element.name
        };
        returnArr.push(option);
      }
    });
    return returnArr;
  }

  onChange(value:any){
  }

  render(){
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    const { TreeNode } = TreeSelect;
    const { Option } = Select;
    const { TextArea } = Input;
    const {fileList,readAndEdit,industryList} = this.state;
    let children = [
      <Option key={1} value="棉纺织及印染精加工">棉纺织及印染精加工</Option>,
      <Option key={2} value="毛纺织及染整精加工">毛纺织及染整精加工</Option>,
      <Option key={3} value="麻纺织及染整精加工">麻纺织及染整精加工</Option>,
      <Option key={4} value="丝绢纺织及印染精加工">丝绢纺织及印染精加工</Option>,
      <Option key={5} value="化纤织造及印染精加工">化纤织造及印染精加工</Option>,
      <Option key={6} value="针织或钩针编织物及其制品制造">针织或钩针编织物及其制品制造</Option>,
      <Option key={7} value="家用纺织制成品制造">家用纺织制成品制造</Option>,
      <Option key={8} value="产业用纺织制成品制造">产业用纺织制成品制造</Option>,
    ];
    return(
        <div className="enterprise-information">
          <div className="enterprise-information-item">
          <Divider type="vertical" className="item-title-divider"/><p className="item-title-p">企业基本信息</p>
            <Divider />
            <Form  
              {...formItemLayout}
              ref={this.createForm}
              name="enterprise"
              onFinish={this.getForm}
              labelCol={{span:8,offset:0}}
              wrapperCol={{span:16,offset:0}}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Col span={24}>
                    <Form.Item
                      label="企业名称"
                      name="companyName"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="请输入" disabled={!readAndEdit} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="所属行业"
                      name="industryCodes"
                      rules={[{ required: true, message: '请选择所属行业' }]}
                    >
                      {/* <Cascader
                          options={industryList}
                          expandTrigger="hover"
                          displayRender={this.displayRender}
                      />                     */}
                      <TreeSelect
                        showSearch
                        style={{ width: '100%' }}
                        value={this.selectCode}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto',zIndex: 1000 }}
                        placeholder="请选择"
                        allowClear
                        multiple
                        treeDefaultExpandAll
                        filterTreeNode
                        onChange={this.onChange}
                        disabled={!readAndEdit}
                      >
                        {
                          industryList.map((nodes:any) => 
                            <TreeNode value={nodes.code} title={nodes.codeName} key={nodes.code} selectable={nodes.isLeaf}>
                              {nodes.children && nodes.children.length>0 ? 
                                nodes.children.map((node:any) => 
                                  <TreeNode value={node.code} title={node.codeName} key={node.code} selectable={node.isLeaf}>
                                    {node.children && node.children.length>0 ? 
                                      node.children.map((i:any) => 
                                        <TreeNode value={i.code} title={ 
                                          <Popover style={{marginLeft: '20px'}} content={
                                            <div className="popover-container">
                                              <div className="popover-item">
                                                <div className="popover-item-title">
                                                  <img src="/assets/images/icon_description_normal.png" alt=""/>
                                                  <span style={{paddingLeft: '8px'}}>说明</span>
                                                </div>
                                                <div className="popover-item-content">
                                                  <span>{i.explain}</span>
                                                </div>
                                              </div>
                                              <div className="popover-item">
                                                <div className="popover-item-title">
                                                  <img src="/assets/images/icon_productType_normal.png" alt=""/>
                                                  <span style={{paddingLeft: '8px'}}>产品种类</span>
                                                </div>
                                                <div className="popover-item-content">
                                                  <span>{i.product}</span>
                                                </div>
                                              </div>
                                            </div>
                                          } title={null} trigger="hover" placement="rightTop">
                                            <span className="popover-content-span">{i.code}<span style={{paddingLeft: '8px'}}>{i.codeName}</span></span>
                                          </Popover>
                                  
                                          } key={i.code} selectable={i.isLeaf}/>
                                      ) : ''
                                    }
                                  </TreeNode>
                                ) : ''
                              }
                            </TreeNode>
                          )
                        }
                      </TreeSelect>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="所在地区"
                      name="district"
                      rules={[{ required: true, message: '请选择所在地区' }]}
                    >
                      <Cascader options={this.province} placeholder="请选择区域" disabled={!readAndEdit} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="企业类型"
                      name="companyTypeEnum"
                      rules={[{ required: true, message: '请选择企业类型' }]}
                    >
                      <Select
                        showSearch
                        placeholder="选择企业类型"
                        disabled={!readAndEdit}
                      >
                        <Option value="NZ_STATE_OWNED">内资-国有</Option>
                        <Option value="NZ_COLLECTIVE">内资-集体</Option>
                        <Option value="NZ_PRIVATE">内资-民营</Option>
                        <Option value="JOINT_VENTURE">中外合资</Option>
                        <Option value="HONG_KONG_AND_MACAO_AND_TAIWAN">港澳台资</Option>
                        <Option value="FOREIGN_OWNED">外商独资</Option>
                        <Option value="OTHER">其他</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={12}>
                  <Col span={24}>
                    <Form.Item
                      label="营业执照代码(18位)"
                      name="license"
                      rules={[
                        {pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
                        message: '营业执照代码格式不正确'},
                        {required: true, message: '请输入18位营业执照代码' }]}
                      >
                      <Input placeholder="请输入" disabled={!readAndEdit} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="营业执照"
                      name="licenseFile"
                    >
                      <Upload {...this.upLoad} fileList={fileList} disabled={!readAndEdit}>
                        <Button style={{width:'100%'}}>
                          <UploadOutlined />点击上传
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="工厂土地面积(m2)"
                      name="area"
                      rules={[{ required: true, message: '请输入土地面积' }]}
                    >
                      <InputNumber placeholder="请输入" style={{width:'100%'}} disabled={!readAndEdit} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="职工数量(人)"
                      name="members"
                      rules={[{ required: true, message: '请输入职工数量' }]}
                    >
                      <InputNumber placeholder="请输入" style={{width:'100%'}} disabled={!readAndEdit} />
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="企业基本情况"
                    name="baseInfo"
                    labelCol={{span:4,offset:0}}
                    wrapperCol={{span:20,offset:0}}
                  >
                    <TextArea placeholder="包括组织结构、主要产品、生产能力、行业地位等" rows={2} disabled={!readAndEdit} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="用水计量级别"
                    name="wmlEnum"
                    rules={[{ required: true}]}
                    labelCol={{span:4,offset:0}}
                  >
                    <Radio.Group disabled={!readAndEdit}>
                      <Radio value='WU'>用水单位</Radio>
                      <Radio value='SWU'>次级用水单位</Radio>
                      <Radio value='WE'>用水设备(用水系统)</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Divider type="vertical" className="item-title-divider"/><p className="item-title-p">联系信息</p>
              <Divider />
              
              <Row gutter={16}>
                <Col span={12}>
                  <Col span={24}>
                    <Form.Item
                      label="填表人-联系电话"
                      required
                    >
                      <Input.Group>
                        <Row style={{display:'flex',alignItems:'center'}}>
                          <Col span={8}>
                            <Form.Item
                              name="writer"
                              rules={[{ required: true,message:"请输入姓名"}]}
                              style={{marginBottom:0}}
                            >
                              <Input placeholder="姓名" disabled={!readAndEdit} />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{textAlign:'center',color:'#d9d9d9'}}> — </Col>
                          <Col span={14}>
                            <Form.Item
                              name="tel"
                              rules={[{ required: true,message:"请输入电话"}]}
                              style={{marginBottom:0}}
                            >
                              <Input placeholder="联系电话" disabled={!readAndEdit} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="法定代表人-联系电话"
                    >
                      <Input.Group>
                        <Row style={{display:'flex',alignItems:'center'}}>
                          <Col span={8}>
                            <Form.Item
                              name={['legal','name']}
                              style={{marginBottom:0}}
                            >
                              <Input placeholder="姓名" disabled={!readAndEdit} />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{textAlign:'center',color:'#d9d9d9'}}> — </Col>
                          <Col span={14}>
                            <Form.Item
                                name={['legal','tel']}
                                style={{marginBottom:0}}
                              >
                              <Input placeholder="联系电话" disabled={!readAndEdit} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="企业联系部门-联系电话"
                    >
                      <Input.Group>
                        <Row style={{display:'flex',alignItems:'center'}}>
                          <Col span={8}>
                            <Form.Item
                              name={['contactDepartment','name']}
                              style={{marginBottom:0}}
                            >
                              <Input placeholder="姓名" disabled={!readAndEdit} />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{textAlign:'center',color:'#d9d9d9'}}> — </Col>
                          <Col span={14}>
                            <Form.Item
                              name={['contactDepartment','tel']}
                              style={{marginBottom:0}}
                            >
                              <Input placeholder="联系电话" disabled={!readAndEdit} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={12}>
                  <Col span={24}>
                    <Form.Item
                      label="详细地址"
                      name="address"
                    >
                      <Input placeholder="请输入" disabled={!readAndEdit} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="邮编"
                      name="zipCode"
                    >
                      <Input placeholder="请输入" disabled={!readAndEdit} />
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
              {readAndEdit?
              // <div className="ant-row ant-form-item">
              //   <div className="ant-col ant-col-6 ant-form-item-label"></div>
              //   <div className="ant-col ant-col-14 ant-form-item-control">
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item wrapperCol={{span:24,offset:0}} style={{textAlign:'right'}}>
                      <Button className="green-button" htmlType="submit" type="primary" style={{marginRight:'10px'}}>保存</Button>
                      <Button type="default" onClick={this.props.nextCurrent} style={{marginRight:'10px'}}>下一步</Button>
                    </Form.Item>
                  </Col>
                </Row>
              //   </div>
              // </div>
              :''}
              
            </Form>
          </div>
        </div>
    )
  }
}

export default EnterpriseBaiscInfo;

// import React, { useState, useEffect } from 'react';
// import './enterprise-baisc-info.scss';
// import { Form, Input, Cascader, Select, Upload, Button, InputNumber, message, Radio, Row, Col } from 'antd';
// import { waterEditAndRead, verificationType } from '../../../../utils/utils';
// import Province from '../../../../utils/ssq.json';
// import { UploadOutlined } from '@ant-design/icons';
// import store from '../../../../utils/store';
// import { OPENIMG } from '../../../../utils/actions';
// import http from '../../../../utils/http';

// const { Option,OptGroup } = Select;
// const { TextArea } = Input;

// const EnterpriseBaiscInfo = (props:any) => {
//   const [readAndEdit,setReadAndEdit] = useState<boolean>(true);
//   const [form] = Form.useForm();
//   const [province,setProvince] = useState<Array<any>>([]);
//   const [fileList,setFileList] = useState<Array<any>>([]);

//   let upLoad:any;
//   // const upLoad = useState<any>();

//   const provicePush = (arr:any) => {
//     let returnArr:any = [];
//     let option:any;
//     arr.forEach((element:any) => {
//       if(element.children){
//         option = {
//           value:element.name,
//           label:element.name,
//           children:provicePush(element.children)
//         };
//         returnArr.push(option);
//       } else {
//         option = {
//           value:element.name,
//           label: element.name
//         };
//         returnArr.push(option);
//       }
//     });
//     return returnArr;
//   }

//   const fileUpload = (file:any) =>{
//     const formData = new FormData();
//     formData.append('file', file);
//     http.post('/system/file/upload/auth',formData).then((res:any)=>{
//       if(res&&res.code===200){
//         let img = {
//           uid:file.uid,
//           name:file.name,
//           status:'done',
//           id:res.data,
//           url:''
//         };
//         setFileList([img]);
//       }
//     })
//   }

//   useEffect(() => {
//     setReadAndEdit(waterEditAndRead());
//     let arr:Array<any> = [];
//     Province.forEach(i=>{
//       let option = {
//         value: i.name,
//         label: i.name,
//         children:provicePush(i.children)
//       }
//       arr.push(option);
//     })
//     setProvince(arr);

//     upLoad = {
//       beforeUpload: (file: any) => {
//         if(verificationType(file.type)){
//           fileUpload(file);
//         } else {
//           message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
//         }
//         return false;
//       },
//       onRemove: (file: { uid: any; })=>{
//         let list = fileList.filter((i: { [x: string]: any; })=> i['uid'] !== file.uid);
//         setFileList(list);
//         return false;
//       },
//       onPreview:(file:any)=>{
//         store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name})
//         return false;
//       }
//     };


//   })

//   const save = (value:any) => {

//   }

//   return (
//     <>
//         <div className="enterprise-information">
//           <div className="enterprise-information-item">
//             <p><b>基本信息</b></p>
//             <Form form={form} layout="vertical" onFinish={save}>
//               <Form.Item
//                 label="企业名称"
//                 name="company"
//                 rules={[{ required: true }]}
//               >
//                 <Input placeholder="请输入" disabled={!readAndEdit}  />
//               </Form.Item>

//               <Form.Item
//                 label="所属行业"
//                 name="industry"
//                 rules={[{ required: true, message: '请选择所属行业' }]}
//               >
//                 {/* <Select
//                   mode="multiple"
//                   style={{ width: '100%' }}
//                   placeholder="选择所属行业"
//                   disabled={!readAndEdit}
//                 >
//                   {children}
//                 </Select> */}
//                 <Input placeholder="请输入" disabled={!readAndEdit} />
//               </Form.Item>

//               <Form.Item
//                 label="所在地区"
//                 name="region"
//                 rules={[{ required: true, message: '请选择所在地区' }]}
//               >
//                 <Cascader options={province} placeholder="请选择区域" disabled={!readAndEdit} />
//               </Form.Item>

//               <Form.Item
//                 label="企业类型"
//                 name="companyType"
//                 rules={[{ required: true, message: '请选择企业类型' }]}
//               >
//                 <Select
//                   showSearch
//                   placeholder="选择企业类型"
//                   disabled={!readAndEdit}
//                 >
//                   <Option value="内资-国有">内资-国有</Option>
//                   <Option value="内资-民营">内资-民营</Option>
//                   <Option value="中外合资">中外合资</Option>
//                   <Option value="港澳台资">港澳台资</Option>
//                   <Option value="外商独资">外商独资</Option>
//                   <Option value="其他">其他</Option>
//                 </Select>
//               </Form.Item>

//               <Form.Item
//                 label="营业执照代码"
//                 name="tax"
//                 rules={[{ required: true}]}
//               >
//                 <Input placeholder="请输入" disabled={!readAndEdit} />
//               </Form.Item>

//               <Form.Item
//                 label="营业执照"
//               >
//                 <Upload {...upLoad} fileList={fileList} disabled={!readAndEdit}>
//                   <Button style={{width:'100%'}}>
//                     <UploadOutlined />点击上传
//                   </Button>
//                 </Upload>
//               </Form.Item>

//               <Form.Item
//                 label="工厂土地面积(m2)"
//                 name="area"
//                 rules={[{ required: true, message: '请输入土地面积' }]}
//               >
//                 <InputNumber placeholder="请输入" style={{width:'100%'}} disabled={!readAndEdit} />
//               </Form.Item>

//               <Form.Item
//                 label="职工数量(人)"
//                 name="members"
//                 rules={[{ required: true, message: '请输入职工数量' }]}
//               >
//                 <InputNumber placeholder="请输入" style={{width:'100%'}} disabled={!readAndEdit} />
//               </Form.Item>

//               <Form.Item
//                 label="总年产值(万元)"
//                 name="output"
//                 rules={[{ required: true, message: '总年产值(万元)' }]}
//               >
//                 <InputNumber placeholder="请输入" style={{width:'100%'}} disabled={!readAndEdit} />
//               </Form.Item>

//               {/* <Form.Item
//                 label="生产工艺流程"
//                 name="tecProcess"
//               >
//                 <TextArea value='请输入' rows={4} />
//               </Form.Item>

//               <Form.Item
//                 label="企业工艺流程图"
//               >
//                 <Upload {...this.fileupLoad} fileList={flowChartList}>
//                   <Button style={{width:'100%'}}>
//                     <UploadOutlined />点击上传
//                   </Button>支持.xls .xlsx .doc .docx .pdf .jpg格式，单个文件大小不超过20M
//                 </Upload>
//               </Form.Item> */}

//               <Form.Item
//                 label="企业基本情况"
//                 name="baseInfo"
//               >
//                 <TextArea value='请输入' rows={4} disabled={!readAndEdit} />
//               </Form.Item>

//               <Form.Item
//                 label="能源计量级别"
//                 name="eml"
//                 rules={[{ required: true}]}
//               >
//                 <Radio.Group disabled={!readAndEdit}>
//                   <Radio value='ECU'>用能单位</Radio>
//                   <Radio value='SECU'>次级用能单位</Radio>
//                   <Radio value='ECE'>用能设备</Radio>
//                 </Radio.Group>
//               </Form.Item>

//               <p><b>联系信息</b></p>

//               <Form.Item
//                 label="详细地址"
//                 name="address"
//               >
//                 <Input placeholder="请输入" disabled={!readAndEdit} />
//               </Form.Item>

//               <Form.Item
//                 label="邮编"
//                 name="zipCode"
//               >
//                 <Input placeholder="请输入" disabled={!readAndEdit} />
//               </Form.Item>

//               <Form.Item
//                 label="法定代表人-联系电话"
//               >
//                 <Input.Group>
//                   <Row style={{display:'flex',alignItems:'center'}}>
//                     <Col span={8}>
//                       <Form.Item
//                         name={['legal','name']}
//                         style={{marginBottom:0}}
//                       >
//                         <Input disabled={!readAndEdit} />
//                       </Form.Item>
//                     </Col>
//                     <Col span={2} style={{textAlign:'center',color:'#d9d9d9'}}> — </Col>
//                     <Col span={14}>
//                       <Form.Item
//                           name={['legal','tel']}
//                           style={{marginBottom:0}}
//                         >
//                         <Input disabled={!readAndEdit} />
//                       </Form.Item>
//                     </Col>
//                   </Row>
//                 </Input.Group>
//               </Form.Item>

//               <Form.Item
//                 label="企业联系部门-联系电话"
//               >
//                 <Input.Group>
//                   <Row style={{display:'flex',alignItems:'center'}}>
//                     <Col span={8}>
//                       <Form.Item
//                         name={['contactDepartment','name']}
//                         style={{marginBottom:0}}
//                       >
//                         <Input disabled={!readAndEdit} />
//                       </Form.Item>
//                     </Col>
//                     <Col span={2} style={{textAlign:'center',color:'#d9d9d9'}}> — </Col>
//                     <Col span={14}>
//                       <Form.Item
//                         name={['contactDepartment','tel']}
//                         style={{marginBottom:0}}
//                       >
//                         <Input disabled={!readAndEdit} />
//                       </Form.Item>
//                     </Col>
//                   </Row>
//                 </Input.Group>
//               </Form.Item>

//               <Form.Item
//                 label="填表人-联系电话"
//               >
//                 <Input.Group>
//                   <Row style={{display:'flex',alignItems:'center'}}>
//                     <Col span={8}>
//                       <Form.Item
//                         name={['admin','name']}
//                         style={{marginBottom:0}}
//                       >
//                         <Input disabled={!readAndEdit} />
//                       </Form.Item>
//                     </Col>
//                     <Col span={2} style={{textAlign:'center',color:'#d9d9d9'}}> — </Col>
//                     <Col span={14}>
//                       <Form.Item
//                         name={['admin','tel']}
//                         style={{marginBottom:0}}
//                       >
//                         <Input disabled={!readAndEdit} />
//                       </Form.Item>
//                     </Col>
//                   </Row>
//                 </Input.Group>
//               </Form.Item>

//               {readAndEdit?<div className="ant-row ant-form-item">
//                 <div className="ant-col ant-col-6 ant-form-item-label"></div>
//                 <div className="ant-col ant-col-14 ant-form-item-control">
//                 <Row>
//                   <Col span={4}>
//                     <Form.Item>
//                       <Button className="green-button" htmlType="submit" type="primary" style={{marginRight:'10px'}}>保存</Button>
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 </div>
//               </div>:''}
              
//             </Form>
//           </div>
//         </div>
//     </>
//   )
// }

// export default EnterpriseBaiscInfo;