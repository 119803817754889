import React from 'react';
import './highConsumptionBackwardness.scss';
import {Table, Button, Popover, Space, Drawer, Pagination, message, Alert} from 'antd';
import Http from '../../../../utils/http';
import {adminEditAndRead, dateFormat} from '../../../../utils/utils';
import AddHighConsumptionBackwardness from '../addHighConsumptionBackwardness/addHighConsumptionBackwardness';
import AddRank from '../addRank/addRank';

export class HighConsumptionBackwardness extends React.Component<any,any>{
  dataList = [];
  editData:any = [];
  formRef: any;
  rankFormRef: any;
  constructor(props:any){
    super(props);
    this.state = {
      newDrawer: false,
      tabData:[],
      rankData:[],
      total:10,
      currentPage:1,
      rankDrawer: false
    };
    this.ecPage = this.ecPage.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.changePage = this.changePage.bind(this);
    this.delete = this.delete.bind(this);
    this.rank = this.rank.bind(this);
    this.closeRankDrawer = this.closeRankDrawer.bind(this);
    this.getRankFormRef = this.getRankFormRef.bind(this);
    this.onSubmitRankDrawer = this.onSubmitRankDrawer.bind(this);
  }
  setFormRef = (el: any) => this.formRef = el;
  setRankFormRef = (el: any) => this.rankFormRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }
  getRankFormRef(){
    this.rankFormRef.vailForm();
  }

  onSubmitDrawer(){
    this.getFormRef();
  }
  onSubmitRankDrawer(){
    this.getRankFormRef();
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  rankEdit(data:any){
    this.editData = data;
    this.setState({
      rankDrawer: true
    })
  }

  changePage(page:any) {
    this.setState({
      currentPage:page
    });
    this.ecPage();
  }

  rank(){
    Http.get('/diagnosis/equipment/rank/'+this.props.id+'/list').then((res:any)=>{
      if(res&&res.code===200){
        res.data.forEach((i:any,index:number)=>{
          i.key=index + 1;
        });
        this.setState({
          rankData:res.data
        })
      }
    })
  }

  ecPage(){
    let {currentPage} = this.state;
    Http.get('/diagnosis/hec/be/ei/page',{
      diagnosisId:this.props.id,
      pageNum:currentPage,
      pageSize:10,
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key=index + 1;
        });
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count
        })
      }
      
    })
  }

  delete(value:any){
    Http.DELETE('/diagnosis/hec/be/ei/'+value.id).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功!');
        this.ecPage();
      }
    });
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.ecPage();
    };
    this.setState({
      newDrawer: false
    })
  }
  closeRankDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.rank();
    };
    this.setState({
      rankDrawer: false
    })
  }

  

  componentDidMount(){
    this.ecPage();
    this.rank();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }
  render(){
    const columns = [
      {
        title:'序号',
        dataIndex:'key',
        key:'key',
        width:70,
        fixed: true,
      },
      {
        title: '机电设备（产品）情况',
        width:1100,
        children:[
          {
            title:'设备名称',
            dataIndex:'name',
            key:'name'
          },
          {
            title:'型号',
            dataIndex:'model',
            key:'model'
          },
          // {
          //   title:'设备类型',
          //   dataIndex:'deviceType',
          //   key:'deviceType'
          // },
          {
            title:'设备生产日期/投运日期',
            width:250,
            render:(text:string,record:any)=>(
              <span>{dateFormat(new Date(record.productTs),'YYYY-mm')} - {dateFormat(new Date(record.runTs),'YYYY-mm')}</span>
            )
          },
          {
            title:'数量（台）',
            dataIndex:'num',
            key:'num'
          },
          {
            title:'单台容量',
            children:[
              {
                title:'数值',
                dataIndex:'capacity',
                key:'capacity'
              },{
                title:'单位',
                dataIndex:'capacityUnit',
                key:'capacityUnit'
              }
            ]
          },
          {
            title:'年均使用时间（h）',
            dataIndex:'annualAverage',
            key:'annualAverage'
          },
          {
            title:'使用、存放地点',
            dataIndex:'placeOus',
            key:'placeOus'
          },
        ]
      },{
        title: '淘汰计划',
        width:700,
        children:[
          {
            title:'计划淘汰时间',
            dataIndex:'planEliTs',
            key:'planEliTs',
            render:(text:string)=>(
              <span>{dateFormat(new Date(text),'YYYY-mm')}</span>
            )
          },
          {
            title:'淘汰方式',
            dataIndex:'eliWay',
            key:'eliWay'
          },
          {
            title:'适用目录编号',
            dataIndex:'fitNo',
            key:'fitNo'
          },
          {
            title:'责任部门',
            dataIndex:'department',
            key:'department'
          },
          {
            title:'责任人',
            dataIndex:'person',
            key:'person'
          },
        ]
      },{
        title: '备注',
        dataIndex:'comment',
        key:'comment',
        width:200,
        ellipsis:true,
        render:(text:string)=>(
          <Popover content={text} title="备注" trigger="hover">
            <span>{text}</span>
          </Popover>
        )
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.delete(record)}>删除</span>:''}
            
          </Space>
        ),
        width:100,
        fixed:'right' as any
      }
    ];
    const rankCol = [
      {
        title:'',
        dataIndex:'type',
        key:'type',
        width:100,
        fixed:'left' as any
      },{
        title:'机电设备总数',
        dataIndex:'sum',
        key:'sum'
      },{
        title:'一级能效设备数量',
        dataIndex:'oneNum',
        key:'oneNum'
      },{
        title:'一级能效设备占比(%)',
        dataIndex:'onePercent',
        key:'onePercent'
      },{
        title:'二级能效设备数量',
        dataIndex:'twoNum',
        key:'twoNum'
      },{
        title:'二级能效设备占比(%)',
        dataIndex:'twoPercent',
        key:'twoPercent'
      },{
        title:'三级能效设备数量',
        dataIndex:'threeNum',
        key:'threeNum'
      },{
        title:'三级能效设备占比(%)',
        dataIndex:'threePercent',
        key:'treePercent'
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.rankEdit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
          </Space>
        ),
        width:100,
        fixed:'right' as any
      }
    ]
    const data = this.state.tabData;
    const {total,currentPage,rankData,readAndEdit} = this.state;
    return(
      <div className="application-energy">
        <Alert style={{marginBottom:'15px'}} message="请填写染色工序、定形工序、空调系统等几个重点耗能工序中的机电设备信息" type="info" />
        <div className="application-energy-head">
          <div className="modular-title">机电设备（产品）情况</div>
          {readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''}
          
        </div>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          size="small"
          scroll={{ x: 2300 }} 
          pagination={false}
        />
        <div className="pagination" style={{marginBottom:'30px'}}>
          <div className="pagination-total">总共{data.length}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
          
        </div>
        <Table
          columns={rankCol}
          dataSource={rankData}
          bordered
          size="small"
          pagination={false}
          scroll={{ x: 1500 }} 
        />
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddHighConsumptionBackwardness 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id} 
            bindRef={this.setFormRef}
          ></AddHighConsumptionBackwardness>
        </Drawer>

        <Drawer 
          title='编辑'
          width={550}
          onClose={e=>this.closeRankDrawer()}
          destroyOnClose={true}
          visible={this.state.rankDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeRankDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitRankDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddRank
            closeDrawer={this.closeRankDrawer} 
            editData={this.editData} 
            id={this.props.id} 
            bindRef={this.setRankFormRef}
          ></AddRank>
        </Drawer>
      </div>
    )
  }
}

export default HighConsumptionBackwardness;