import React from 'react';
import { Form, Input, Row, Col, Select, Cascader, message,InputNumber, Upload, Button} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Http from '../../../../utils/http';
import unitList from '../../../../utils/unit.json';
import _ from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import { OPENIMG } from '../../../../utils/actions';
import { adminEditAndRead, verificationType } from '../../../../utils/utils';
import store from '../../../../utils/store';

export class AddProduct extends React.Component<any,any>{
  unitList: Array<any>;
  fileupLoad: any;
  constructor(props: any){
    super(props);
    props.bindRef(this);
    this.unitList = unitList;
    this.state = {
      readAndEdit:true,
      productList:[],
      productType:[],
      options:[],
      optionsTp:[],
      finishingTp:[],
      finishingList:[{
        value:'YH',
        label:'印花',
        child:[{
          value:'印花',
          label:'印花'
        },{
          value:'固色',
          label:'固色'
        },{
          value:'水洗',
          label:'水洗'
        }]
      },{
        value:'SG',
        label:'丝光',
        child:[{
          value:'丝光',
          label:'丝光'
        }]
      },{
        value:'QY_YLR',
        label:'起绒-摇粒绒',
        child:[{
          value:'刷毛',
          label:'刷毛'
        },{
          value:'梳剪摇',
          label:'梳剪摇',
        },{
          value:'定型',
          label:'定型',
        }]
      },{
        value:'QY_SHR',
        label:'起绒-珊瑚绒',
        child:[{
          value:'剖幅',
          label:'剖幅'
        },{
          value:'梳绒',
          label:'梳绒',
        },{
          value:'剪绒',
          label:'剪绒',
        },{
          value:'烫光',
          label:'烫光',
        }]
      },{
        value:'QY_JXRR',
        label:'起绒-机械柔软',
        child:[{
          value:'机械柔软机整理',
          label:'机械柔软机整理'
        }]
      }],
      translateShow:false,
      flowChartList:[],
    }
    this.getList = this.getList.bind(this);
    this.changeProduct = this.changeProduct.bind(this);
    this.casaderData = this.casaderData.bind(this);
    this.changeFinishing = this.changeFinishing.bind(this);
    this.getData = this.getData.bind(this);
    this.formAssignment = this.formAssignment.bind(this);
    this.flowChartListUpload = this.flowChartListUpload.bind(this);

    this.fileupLoad = {
      beforeUpload: (file: any) => {
        if(verificationType(file.type)){
          this.flowChartListUpload(file);
        } else {
          message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
        }
        return false;
      },
      onRemove: (file:any)=>{
        let {flowChartList} = this.state;
        flowChartList = flowChartList.filter((i:any)=> i['uid'] !== file.uid);
        this.setState({flowChartList});
        return false;
      },
      onPreview:(file:any)=>{
        store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name})
        return false;
      }
    };
  }
  formRef:any;
  fnRef = (el:any) => this.formRef = el;

  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  flowChartListUpload(file:any){
    const formData = new FormData();
    formData.append('file', file);
    Http.post('/system/file/upload/auth',formData).then((res:any)=>{
      if(res&&res.code===200){
        let img = {
          uid:file.uid,
          name:file.name,
          status:'done',
          id:res.data,
          url:''
        };
        this.setState({
          flowChartList:[...this.state.flowChartList,img]
        })
      }
    })
  }

  changeProduct(e:any){
    let optionArr:any;
    let tpArr:any;
    let {productList} = this.state;
    if(e.length===0){
      this.setState({
        optionsTp:[],
        finishingTp:[]
      });
      return false;
    }
    if(e[0]==='其他'){
      this.setState({
        translateShow:true,
        finishingTp:[],
        optionsTp:[],
      });
      this.createForm.current?.setFieldsValue({
        process:['OT'],
        processList:[]
      })
      return false;
    }
    for(let i in productList){
      if(i===e[0]){
        optionArr = productList[i];
      }
    }
    if(typeof e[1] !== 'number'){
      for(let j in optionArr){
        if(j===e[1]){
          tpArr = [...optionArr[j].filter((n:any)=> n.id===e[2])[0].tp];
          continue;
        }
      }
      tpArr = _.flattenDeep(tpArr);
    } else {
      for(let j in optionArr){
        let tparr;
        optionArr[j].forEach((element:any)=>{
          if(element.id===e[1]){
            tparr = element.tp.reduce((prev:any,curr:any)=>{return prev.concat(curr)})
          }
        })
        tpArr = tparr;
      };
    }
    this.setState({
      translateShow:false,
      optionsTp:tpArr,
      finishingTp:[],
    });
    this.createForm.current?.setFieldsValue({
      process:[],
      processList:[]
    })
  }
  changeFinishing(e:any){
    let {finishingList} = this.state;
    let arr: any[] = [];
    e.forEach((k:any)=>{
      finishingList.forEach((element:any) => {
        if(element.value=== k){
          element.child.forEach((i:any)=>{
            arr.push(i.value);
          })
        }
      });
    })
    
    this.setState({
      finishingTp:arr
    })
  }

  getData(values:any){
    let {flowChartList} = this.state;
    let uploadList: Array<any> = [];
    flowChartList.forEach((i:any) => {
      uploadList.push({
        fileId:i.id,
        showName:i.name
      });
    });
    values.files = uploadList;
    if(values.categoryId.length!==1){
      values.categoryId = values.categoryId[values.categoryId.length - 1];
    } else {
      values.categoryId = '';
    };

    if(values.processList === '其他'){
      values.processList = ['其他'];
    }
    if(this.props.editData.id){
      Http.put('/diagnosis/product/'+this.props.editData.id,{...values}).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功!');
          this.props.closeDrawer(true);
        }
        
      })
    } else {
      Http.post('/diagnosis/product',{...values,diagnosisId:this.props.id}).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新建成功!');
          this.props.closeDrawer(true);
        }
      })
    }
  }

  getList(){
    Http.get('/diagnosis/product/category/list').then((res:any)=>{
      let cascader = [];
      if(res&&res.code===200){
        for(let i in res.data){
          let children = this.casaderData(res.data[i]);
          cascader.push({
            value:i,
            label:i,
            children:children
          });
        }
        cascader.push({
          value:'其他',
          label:'其他'
        });
        this.setState({
          productList:res.data,
          options:cascader
        });
      }
      this.formAssignment();
    })
  }

  casaderData(data:any):Array<any>{
    let datas = _.cloneDeep(data);
    let cascader = [];
    if(datas[0]?.tp){
      datas.forEach((k:any)=>{
        cascader.push({
          value:k.id,
          label:k.attr3
        })
      })
    } else {
      for(let i in datas){
        let children = this.casaderData(datas[i]);
        if(datas[i].length===1){
          cascader.push({
            value:datas[i][0].id,
            label:i,
          })
        } else {
          cascader.push({
            value:i,
            label:i,
            children:children
          })
        }
      };
    }
    
    return cascader;
  }

  formAssignment(){
    if(this.props.editData.id){
      if(!this.props.editData.categoryId){
        this.createForm.current?.setFieldsValue({
          categoryId:['其他']
        })
      } else {
        if(this.props.editData.attr3){
          this.createForm.current?.setFieldsValue({
            categoryId:[this.props.editData.attr1,this.props.editData.attr2,this.props.editData.categoryId]
          })
        } else {
          this.createForm.current?.setFieldsValue({
            categoryId:[this.props.editData.attr1,this.props.editData.categoryId]
          })
        }
      }
      this.changeProduct(this.createForm.current?.getFieldValue('categoryId'));
      if(this.props.editData.process){
        this.createForm.current?.setFieldsValue({
          process:this.props.editData.process
        })
        this.changeFinishing(this.createForm.current?.getFieldValue('process'));
      } else {
        this.createForm.current?.setFieldsValue({
          process:[this.props.editData.process]
        })
      }
      let tecProcessUrl:any[] = [];
      if(this.props.editData.files){
        this.props.editData.files.forEach((i:any,index:number)=>{
          let img = {
            uid:index,
            name:i.showName,
            status:'done',
            id:i.fileId,
            url:''
          };
          tecProcessUrl.push(img);
        });
      }
      this.createForm.current?.setFieldsValue({
        markYield:this.props.editData.markYield,
        name:this.props.editData.name,
        // output:this.props.editData.output,
        remark:this.props.editData.remark,
        unit:this.props.editData.unit,
        yield:this.props.editData.yield,
        processList:this.props.editData.processList,
        files:this.props.editData.files,
        tecProcess: this.props.editData.tecProcess
      })
      this.setState({
        translateShow:this.props.editData.processList[0]==='其他'?true:false,
        flowChartList: tecProcessUrl
      });
    }
  }

  componentDidMount(){
    this.getList();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }
  
  render(){
    const { TextArea } = Input;
    const { Option } = Select;
    const { options,finishingList,optionsTp,finishingTp,translateShow,flowChartList,readAndEdit} = this.state;
    const unit = this.unitList.map(i=>{
      return <Option disabled={i.key === 't' ||i.key === 'kg' || i.key === 'g'?false:true} key={i.key} value={i.key}>{i.name}</Option>;
    });
    let processOption = [];
    processOption = finishingList.map((i:any)=>{
      return <Option key={i.value} value={i.value}>{i.label}</Option>
    })
    let productOption = [];
    const tpArr = _.uniq([...optionsTp,...finishingTp]);
    productOption = tpArr.map(i=>{
      return <Option key={i} value={i}>{i}</Option>
    })
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Form.Item label="产品种类" name="categoryId" rules={[{required: true}]}>
          <Cascader disabled={!readAndEdit} options={options} onChange={e => this.changeProduct(e)} />
        </Form.Item>
        <Form.Item label="产品名称" name="name" rules={[{required: true}]}>
          <Input disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="后整理工序" name="process">
          {
            translateShow?<Select disabled={!readAndEdit} mode="multiple" style={{ width: '100%' }} >
              <Option value="OT">其他</Option>
            </Select>:<Select disabled={!readAndEdit} mode="multiple" style={{ width: '100%' }} onChange={e => this.changeFinishing(e)} >
              {processOption}
            </Select>
          }
        </Form.Item>
        <Form.Item label="主要工艺工序" name="processList" rules={[{required: true}]}>
          {
            translateShow?<Select disabled={!readAndEdit} style={{ width: '100%' }} >
              <Option value="其他">其他</Option>
            </Select>:<Select disabled={!readAndEdit} mode="multiple" style={{ width: '100%' }} >
              {productOption}
            </Select>
          }
        </Form.Item>
        {
          translateShow?<Form.Item label="描述补充" name="remark" rules={[{required: true}]}>
          <Input disabled={!readAndEdit} />
        </Form.Item>:''
        }
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="年产量" name="yield" rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="单位" name="unit" rules={[{required: true}]}>
              <Select style={{ width: '100%' }} disabled={!readAndEdit} >
                {unit}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row" span={12}>
            <Form.Item label="年产值(万元)" name="output" rules={[{required: true}]}>
            <InputNumber style={{width:'100%'}} />
            </Form.Item>
          </Col> */}
        </Row>
        {
          translateShow?<><Form.Item label="标准品折算公式" name="expression">
          <TextArea rows={2} disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="折标产量" rules={[{required: true}]} name="markYield">
          <Input disabled={!readAndEdit} />
        </Form.Item></>:''
        }
        <Form.Item
          label="生产工艺流程"
          name="tecProcess"
        >
          <TextArea value='请输入' rows={4} disabled={!readAndEdit} />
        </Form.Item>

        <Form.Item
          label="工艺流程图"
        >
          <Upload {...this.fileupLoad} fileList={flowChartList} disabled={!readAndEdit}>
            <Button style={{width:'100%'}}>
              <UploadOutlined />点击上传
            </Button>支持.xls .xlsx .doc .docx .pdf .jpg格式，单个文件大小不超过20M
          </Upload>
        </Form.Item>
      </Form>
    )
  }
}

export default AddProduct;