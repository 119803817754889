import React from 'react';
import {Table, Button, Space,Drawer,Pagination,message, Popover} from 'antd';
import './standard.scss';
import Http from './../../../../utils/http';
import AddStandard from '../addStandard/addStandard';
import { adminEditAndRead } from '../../../../utils/utils';
import { OPENIMG } from '../../../../utils/actions';
import store from '../../../../utils/store';

export class Standard extends React.Component<any,any>{
  dataList = [];
  editData:any = [];
  formRef: any;
  constructor(props:any){
    super(props);
    this.state = {
      newDrawer: false,
      tabData:[],
      total:10,
      currentPage:1,
      readAndEdit:true
    };
    this.ecPage = this.ecPage.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.changePage = this.changePage.bind(this);
    this.imgClick = this.imgClick.bind(this);
  }
  setFormRef = (el: any) => this.formRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }

  onSubmitDrawer(){
    this.getFormRef();
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  changePage(page:any) {
    this.setState({
      currentPage:page
    });
    this.ecPage();
  }

  ecPage(){
    let {currentPage} = this.state;
    Http.get('/diagnosis/standard/page',{
      diagnosisId:this.props.id,
      pageNum:currentPage,
      pageSize:10,
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key=index + 1;
        });
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count
        })
      }
    })
  }

  delete(value:any){
    Http.DELETE('/diagnosis/standard/'+value.id,).then((res:any)=>{
      message.success('删除成功!');
      this.ecPage();
    });
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.ecPage();
    };
    this.setState({
      newDrawer: false
    })
  }

  imgClick(i:any){
    store.dispatch({type: OPENIMG,imgId:i.fileId,imgName:i.showName});
  }

  componentDidMount(){
    this.ecPage();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }
  render(){
    const columns = [
      {
        title:'序号',
        dataIndex:'key',
        key:'key',
        width:70,
        fixed: true,
      },
      {
        title: '标准名称',
        dataIndex: 'name',
        key: 'name',
      },{
        title: '国家/行业标准',
        dataIndex: 'industryStandard',
        key: 'industryStandard'
      },{
        title:'标准号',
        dataIndex:'no',
        key:'no'
      },{
        title:'企业排名',
        dataIndex:'rank',
        key:'rank'
      },{
        title:'已上传附件',
        render:(text:any,record:any)=>(
          <Popover content={
            record.files?.map((i:any,index:number)=> <div key={index} onClick={e=>this.imgClick(i)} className="div-a">{i.showName}</div>)
          } title="已上传附件" trigger="hover">
            <span style={{color:'#0F9940'}}>{record.files?.length | 0}</span>
          </Popover>
        )
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.delete(record)}>删除</span>:''}
            
          </Space>
        ),
        width:150
      }
    ];
    const data = this.state.tabData;
    const {total,currentPage,readAndEdit} = this.state;
    return(
      <div className="application-energy">
        <div className="application-energy-head">
          <div className="modular-title">标准参编列表</div>
          {
            readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''
          }
          
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
        />
        <div className="pagination">
          <div className="pagination-total">总共{data.length}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
          
        </div>
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddStandard 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id} 
            bindRef={this.setFormRef}
          ></AddStandard>
        </Drawer>
      </div>
    )
  }
}

export default Standard;