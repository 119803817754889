import React from 'react';
import Http from '../../../../utils/http';
import {Row, Col, Form, Input, message, DatePicker, InputNumber} from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { adminEditAndRead } from '../../../../utils/utils';

export class AddHighConsumptionBackwardness extends React.Component<any,any>{
  state = {
    energyData:[],
    fileList:[],
    readAndEdit:true
  };
  constructor(props: any){
    super(props);
    props.bindRef(this);
    this.getData = this.getData.bind(this);
  }

  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(values:any){
    if(values.planEliTs){
      values.planEliTs = new Date(values.planEliTs).getTime();
    }
    if(values.productTs){
      values.productTs = new Date(values.productTs).getTime();
    }
    if(values.runTs){
      values.runTs = new Date(values.runTs).getTime();
    }

    if(this.props.editData.id){
      Http.put('/diagnosis/hec/be/ei/'+this.props.editData.id,values).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功!');
          this.props.closeDrawer(true);
        }
      })
    } else {
      let requestData = {...values,diagnosisId:this.props.id}
      Http.post('/diagnosis/hec/be/ei',requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新建成功!');
          this.props.closeDrawer(true);
        }
      })
    }
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.editData){
      this.createForm.current?.setFieldsValue(this.props.editData);
      this.createForm.current?.setFieldsValue({
        planEliTs:moment(this.props.editData.planEliTs),
        productTs:moment(this.props.editData.productTs),
        runTs:moment(this.props.editData.runTs)
      })
    }
  }

  render(){
    // const { Option } = Select;
    let {readAndEdit} = this.state;
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <h3 style={{fontWeight:'bold'}}>机电设备（产品）情况</h3>
        <Form.Item label="设备名称" name="name" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="型号" name="model">
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        {/* <Form.Item label="设备类型" name="deviceType" rules={[{required: true}]}>
          <Select style={{ width: '100%' }} placeholder="请选择" >
            <Option value="JDO2、JDO3系列电动机">JDO2、JDO3系列电动机</Option>
            <Option value="傍磁制动电动机">傍磁制动电动机</Option>
            <Option value="电制动电动机">电制动电动机</Option>
            <Option value="高滑差电动机">高滑差电动机</Option>
            <Option value="三相异步电动机">三相异步电动机</Option>
            <Option value="小型异步电动机">小型异步电动机</Option>
            <Option value="单相异步电动机">单相异步电动机</Option>
            <Option value="其他">其他</Option>
          </Select>
        </Form.Item> */}
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="设备生产日期" name="productTs" rules={[{required: true}]}>
              <DatePicker style={{ width: '100%' }} picker="month" placeholder="请选择月份" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="设备投运时间" name="runTs" rules={[{required: true}]}>
              <DatePicker style={{ width: '100%' }} picker="month" placeholder="请选择月份" disabled={!readAndEdit}/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="数量（台）" name="num" rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} placeholder="数值" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="单台容量" rules={[{required: true}]}>
              <Input.Group>
                <Row style={{display:'flex',alignItems:'center'}}>
                  <Col span={8}>
                    <Form.Item name="capacity" style={{marginBottom:0}} rules={[{required: true,message:'请填写单台容量'}]}>
                      <InputNumber style={{width:'100%'}} placeholder="数值" disabled={!readAndEdit} />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{textAlign:'center',color:'#d9d9d9'}}> — </Col>
                  <Col span={14}>
                  <Form.Item name="capacityUnit" style={{marginBottom:0}} rules={[{required: true,message:'请填写单台容量单位'}]}>
                    <Input placeholder="单位" disabled={!readAndEdit} />
                  </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="年均使用时间(h)" name="annualAverage">
              <Input placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="使用、存放地点" name="placeOus">
              <Input placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
        </Row>
        <h3 style={{fontWeight:'bold'}}>淘汰计划</h3>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="计划淘汰时间" name="planEliTs" >
              <DatePicker picker="month" style={{ width: '100%' }} placeholder="请选择月份" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="淘汰方式" name="eliWay">
              <Input placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="适用目录编号" name="fitNo">
              <Input placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="责任部门" name="department">
              <Input placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="责任人" name="person">
              <Input placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="备注" name="comment">
              <Input placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default AddHighConsumptionBackwardness;