import React from "react";
import {Input, message} from "antd";
import http from "../../../../utils/http";
import './expert-score.scss'

export class ExpertScore extends React.Component<any,any>{
    constructor(props:any) {
        super(props);
        this.state = {
            item:this.props.item,
            score:this.props.item.expertScore,
        }
        this.onBlur = this.onBlur.bind(this)
    }

    onBlur(){
        const data = {
            score: Number(this.state.score),
            assessId:this.props.id,
            itemId:this.state.item.itemId || null,
            additional:this.state.item.additional || null,
        }

        if( data.score > this.state.item.maxScore || data.score < 0) {
            message.info('请输入正确的评分')
            this.setState({score:null})
            return
        }

        http.put(`/apiWaterUrl/assess/rules/score`,data).then((res:any)=>{
            if(res.code === 200){
                message.success('保存成功！');
            }
        })
    }

    render(){
        const { item , score} = this.state;
        return(
            <div className="expert-score">
                <Input  placeholder="请输入您的评分"
                    type={"number"}
                    max={item.maxScore}
                    min={0}
                    value={score}
                    addonBefore={'共'+item.maxScore + '分'}
                    addonAfter={'分'}
                    onChange={(e)=>{this.setState({ score: e.target.value })}}
                    onBlur={this.onBlur}
                        >
                </Input>
            </div>
        )
    }
}
