import React, {ReactNode} from 'react';
import './TechnicalIndicators.scss';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Radio,
    Divider,
    Upload,
    Cascader,
    Select,
    message,
    InputNumber,
    Space,
    Popover,
    TreeSelect
} from 'antd';
import Province from '../../../utils/ssq.json';
import Http from '../../../utils/http';
import store from './../../../utils/store';
import {FormInstance} from 'antd/lib/form';
import {OPENIMG} from './../../../utils/actions';
import {waterEditAndRead, verificationType} from './../../../utils/utils';
import {UploadOutlined} from '@ant-design/icons';

export class WaterParkOne2 extends React.Component<any, any> {
    province: any;
    selectCode: any[] = [];
    industryList: any;
    createForm = React.createRef<FormInstance>();

    constructor(props: any) {
        super(props);
        let arr: any = [];
        Province.forEach(i => {
            let option = {
                value: i.name,
                label: i.name,
                children: this.provicePush(i.children)
            }
            arr.push(option);
        })
        this.province = arr;

        this.state = {
            readAndEdit: true,
            getData: {},
        };


        this.getInfo = this.getInfo.bind(this);
        this.getForm = this.getForm.bind(this);
        this.getChildMsg = this.getChildMsg.bind(this);
    }

    getForm(values: any) {
        console.log(values);

        if (values.qsFiles) {
            let qsFiles: any = [];
            values.qsFiles.forEach((k: any, index: any) => {
                let img = {
                    showName: k.name,
                    fileId: k.id

                }
                qsFiles.push(img);


            });
            values.qsFiles = qsFiles
        }
        if (values.cfFiles) {
            let cfFiles: any = [];
            values.cfFiles.forEach((k: any, index: any) => {
                let img = {
                    showName: k.name,
                    fileId: k.id
                }
                cfFiles.push(img);


            });
            values.cfFiles = cfFiles
        }
        if (values.fgFiles) {
            let fgFiles: any = [];
            values.fgFiles.forEach((k: any, index: any) => {
                let img = {
                    showName: k.name,
                    fileId: k.id
                }
                fgFiles.push(img);


            });
            values.fgFiles = fgFiles
        }

        if (values.fsFiles) {
            let fsFiles: any = [];
            values.fsFiles.forEach((k: any, index: any) => {
                let img = {
                    showName: k.name,
                    fileId: k.id
                }
                fsFiles.push(img);


            });
            values.fsFiles = fsFiles
        }
        if (values.lsFiles) {
            let lsFiles: any = [];
            values.lsFiles.forEach((k: any, index: any) => {
                let img = {
                    showName: k.name,
                    fileId: k.id
                }
                lsFiles.push(img);


            });
            values.lsFiles = lsFiles
        }
        if (values.psFiles) {
            let psFiles: any = [];
            values.psFiles.forEach((k: any, index: any) => {
                let img = {
                    showName: k.name,
                    fileId: k.id
                }
                psFiles.push(img);


            });
            values.psFiles = psFiles
        }
        if (values.tdFiles) {
            let tdFiles: any = [];
            values.tdFiles.forEach((k: any, index: any) => {
                let img = {
                    showName: k.name,
                    fileId: k.id
                }
                tdFiles.push(img);


            });
            values.tdFiles = tdFiles
        }

        Http.put('/apiWaterParkUrl/assess/index/' + this.props.id, values).then((res: any) => {
            if (res && res.code === 200) {
                message.success('保存成功!');
                this.getInfo();
            }
        })
    }

    getInfo() {
        Http.get('/apiWaterParkUrl/assess/index/' + this.props.id).then((res: any) => {

            if (res && res.data && res.code === 200) {

                if (res.data.qsFiles) {
                    let qsFiles: any = [];
                    res.data.qsFiles.forEach((k: any, index: any) => {
                        let img = {
                            uid: index,
                            name: k.showName,
                            status: 'done',
                            id: k.fileId,
                            url: ''
                        }
                        qsFiles.push(img);


                    });
                    res.data.qsFiles = qsFiles
                }
                if (res.data.cfFiles) {
                    let cfFiles: any = [];
                    res.data.cfFiles.forEach((k: any, index: any) => {
                        let img = {
                            uid: index,
                            name: k.showName,
                            status: 'done',
                            id: k.fileId,
                            url: ''
                        }
                        cfFiles.push(img);


                    });
                    res.data.cfFiles = cfFiles
                }
                if (res.data.fgFiles) {
                    let fgFiles: any = [];
                    res.data.fgFiles.forEach((k: any, index: any) => {
                        let img = {
                            uid: index,
                            name: k.showName,
                            status: 'done',
                            id: k.fileId,
                            url: ''
                        }
                        fgFiles.push(img);


                    });
                    res.data.fgFiles = fgFiles
                }

                if (res.data.fsFiles) {
                    let fsFiles: any = [];
                    res.data.fsFiles.forEach((k: any, index: any) => {
                        let img = {
                            uid: index,
                            name: k.showName,
                            status: 'done',
                            id: k.fileId,
                            url: ''
                        }
                        fsFiles.push(img);


                    });
                    res.data.fsFiles = fsFiles
                }
                if (res.data.lsFiles) {
                    let lsFiles: any = [];
                    res.data.lsFiles.forEach((k: any, index: any) => {
                        let img = {
                            uid: index,
                            name: k.showName,
                            status: 'done',
                            id: k.fileId,
                            url: ''
                        }
                        lsFiles.push(img);


                    });
                    res.data.lsFiles = lsFiles
                }
                if (res.data.psFiles) {
                    let psFiles: any = [];
                    res.data.psFiles.forEach((k: any, index: any) => {
                        let img = {
                            uid: index,
                            name: k.showName,
                            status: 'done',
                            id: k.fileId,
                            url: ''
                        }
                        psFiles.push(img);


                    });
                    res.data.psFiles = psFiles
                }
                if (res.data.tdFiles) {
                    let tdFiles: any = [];
                    res.data.tdFiles.forEach((k: any, index: any) => {
                        let img = {
                            uid: index,
                            name: k.showName,
                            status: 'done',
                            id: k.fileId,
                            url: ''
                        }
                        tdFiles.push(img);


                    });
                    res.data.tdFiles = tdFiles
                }


                this.createForm.current?.setFieldsValue(res.data);
                this.setState({
                    getData: res.data
                })

            }
        })
    }


    componentDidMount() {
        this.getInfo();
        this.setState({
            readAndEdit: waterEditAndRead()
        })
    }

    provicePush(arr: any) {
        let returnArr: any = [];
        let option: any;
        arr.forEach((element: any) => {
            if (element.children) {
                option = {
                    value: element.name,
                    label: element.name,
                    children: this.provicePush(element.children)
                };
                returnArr.push(option);
            } else {
                option = {
                    value: element.name,
                    label: element.name
                };
                returnArr.push(option);
            }
        });
        return returnArr;
    }


    getChildMsg(msg: any) {
        console.log(msg);


    }

    render() {

        const {readAndEdit, getData} = this.state;
        console.log('this.createForm.current', getData);
        const content = (
            <div>
                <span>非常规水资源包括雨水、海水淡化水和海水、人工增雨、矿井水、苦咸水等水资源，企业外部采购的中水（再生水）也属于非常规水。</span><br/>
                <span>非常规水源替代率等于非常规水源所替代的取水量除以园区取水总量。</span><br/>
                <span>查阅园区用水记录文件。</span>
            </div>
        )
        return (
            <div className="basic-requirement-container">
                <div className="basic-requirement-container-left" id="center">

                    <Form
                        ref={this.createForm}
                        name="enterprise"
                        onFinish={this.getForm}
                    >
                        <div className="basic-requirement-container-left-item">
                            <p className="basic-requirement-container-left-item-title">1. 万元工业增加值取水量 (m3)
                                <Popover content={'万元工业增加值取水量等于园区用新鲜水量除以园区工业总产值'}
                                         trigger="hover" placement='bottomLeft'>
                                    <img style={{marginTop: '-3px', marginLeft: '5px'}}
                                         src="/assets/images/icon_description_normal.png" alt=""/>
                                </Popover>
                            </p>
                            <div className="basic-requirement-container-left-small ffims" style={{margin: '8px 0px'}}>
                                <div className='ffims-one'>
                                    <Form.Item name='qs'>
                                        <InputNumber disabled={!readAndEdit}/>
                                    </Form.Item>
                                    <Form.Item name='qsFiles'>
                                        <UploadComponentMax readAndEdit={readAndEdit} key={getData?.qsFiles}
                                                            getData={getData?.qsFiles} onChange={this.getChildMsg}/>
                                    </Form.Item>
                                </div>

                            </div>

                            <p className="basic-requirement-container-left-item-title">2. 水重复利用率 (%)
                                <Popover content={'水重复利用率等于园区重复用水量除以园区用水总量'} trigger="hover"
                                         placement='bottomLeft'>
                                    <img style={{marginTop: '-3px', marginLeft: '5px'}}
                                         src="/assets/images/icon_description_normal.png" alt=""/>
                                </Popover>
                            </p>
                            <div className="basic-requirement-container-left-small ffims" style={{margin: '8px 0px'}}>
                                <div className='ffims-one'>
                                    <Form.Item name='cf'>
                                        <InputNumber disabled={!readAndEdit}/>
                                    </Form.Item>
                                    <Form.Item name='cfFiles'>
                                        <UploadComponentMax readAndEdit={readAndEdit} key={getData?.cfFiles}
                                                            getData={getData?.cfFiles} onChange={this.getChildMsg}/>
                                    </Form.Item>
                                </div>

                            </div>
                            <p className="basic-requirement-container-left-item-title">3. 万元工业增加值排水量 (m3)

                                <Popover content={'万元工业增加值排水量等于园区总排水量除以园区工业总产值'}
                                         trigger="hover" placement='bottomLeft'>
                                    <img style={{marginTop: '-3px', marginLeft: '5px'}}
                                         src="/assets/images/icon_description_normal.png" alt=""/>
                                </Popover>
                            </p>
                            <div className="basic-requirement-container-left-small ffims" style={{margin: '8px 0px'}}>
                                <div className='ffims-one'>
                                    <Form.Item name='ps'>
                                        <InputNumber disabled={!readAndEdit}/>
                                    </Form.Item>
                                    <Form.Item name='psFiles'>
                                        <UploadComponentMax readAndEdit={readAndEdit} key={getData?.psFiles}
                                                            getData={getData?.psFiles} onChange={this.getChildMsg}/>
                                    </Form.Item>
                                </div>

                            </div>
                            <p className="basic-requirement-container-left-item-title">4. 废水回用率 (%)
                                <Popover content={'废水回用率等于废水处理再利用量除以园区排水总量'} trigger="hover"
                                         placement='bottomLeft'>
                                    <img style={{marginTop: '-3px', marginLeft: '5px'}}
                                         src="/assets/images/icon_description_normal.png" alt=""/>
                                </Popover>
                            </p>
                            <div className="basic-requirement-container-left-small ffims" style={{margin: '8px 0px'}}>
                                <div className='ffims-one'>
                                    <Form.Item name='fs'>
                                        <InputNumber disabled={!readAndEdit}/>
                                    </Form.Item>
                                    <Form.Item name='fsFiles'>
                                        <UploadComponentMax readAndEdit={readAndEdit} key={getData?.fsFiles}
                                                            getData={getData?.fsFiles} onChange={this.getChildMsg}/>
                                    </Form.Item>
                                </div>

                            </div>
                            <p className="basic-requirement-container-left-item-title">5. 非常规水源替代率 (%)
                                <Popover content={content} trigger="hover" placement='bottomLeft'>
                                    <img style={{marginTop: '-3px', marginLeft: '5px'}}
                                         src="/assets/images/icon_description_normal.png" alt=""/>
                                </Popover>
                            </p>
                            <div className="basic-requirement-container-left-small ffims" style={{margin: '8px 0px'}}>
                                <div className='ffims-one'>
                                    <Form.Item name='td'>
                                        <InputNumber disabled={!readAndEdit}/>
                                    </Form.Item>
                                    <Form.Item name='tdFiles'>
                                        <UploadComponentMax readAndEdit={readAndEdit} key={getData?.tdFiles}
                                                            getData={getData?.tdFiles} onChange={this.getChildMsg}/>
                                    </Form.Item>
                                </div>

                            </div>
                            <p className="basic-requirement-container-left-item-title">6. 节水型企业覆盖率 (%)
                                <Popover content={'节水型企业覆盖率等于节水型企业数量除以园区企业总数'} trigger="hover"
                                         placement='bottomLeft'>
                                    <img style={{marginTop: '-3px', marginLeft: '5px'}}
                                         src="/assets/images/icon_description_normal.png" alt=""/>
                                </Popover>
                            </p>
                            <div className="basic-requirement-container-left-small ffims" style={{margin: '8px 0px'}}>
                                <div className='ffims-one'>
                                    <Form.Item name='fg'>
                                        <InputNumber disabled={!readAndEdit}/>
                                    </Form.Item>
                                    <Form.Item name='fgFiles'>
                                        <UploadComponentMax readAndEdit={readAndEdit} key={getData?.fgFiles}
                                                            getData={getData?.fgFiles} onChange={this.getChildMsg}/>
                                    </Form.Item>
                                </div>

                            </div>
                            <p className="basic-requirement-container-left-item-title">7. 管网漏失率 (%)
                                <Popover
                                    content={'管网漏失率等于园区公共供水量减去园区有效供水量的值再除以园区供水总量值'}
                                    trigger="hover" placement='bottomLeft'>
                                    <img style={{marginTop: '-3px', marginLeft: '5px'}}
                                         src="/assets/images/icon_description_normal.png" alt=""/>
                                </Popover>
                            </p>
                            <div className="basic-requirement-container-left-small ffims" style={{margin: '8px 0px'}}>
                                <div className='ffims-one'>
                                    <Form.Item name='ls'>
                                        <InputNumber disabled={!readAndEdit}/>
                                    </Form.Item>
                                    <Form.Item name='lsFiles'>
                                        <UploadComponentMax readAndEdit={readAndEdit} key={getData?.lsFiles}
                                                            getData={getData?.lsFiles} onChange={this.getChildMsg}/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                        {readAndEdit ?
                            // <div className="ant-row ant-form-item">
                            //   <div className="ant-col ant-col-6 ant-form-item-label"></div>
                            //   <div className="ant-col ant-col-14 ant-form-item-control">
                            <div className="basic-requirement-foot">

                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item wrapperCol={{span: 24, offset: 0}} style={{textAlign: 'right'}}>
                                            <Button className="green-button" htmlType="submit" type="primary"
                                                    style={{marginRight: '10px'}}>保存</Button>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>

                            //   </div>
                            // </div>
                            : ''}

                    </Form>
                </div>
            </div>
        )
    }
}


export class UploadComponentMax extends React.Component<any, any> {
    reduceUpLoad: any;

    constructor(props: any) {
        super(props)
        this.state = {
            readAndEdit: this.props.readAndEdit,
            fileList: this.props.getData || [],
            files: [],
        }

        this.removeFile = this.removeFile.bind(this)
        this.reduceUpLoad = {
            beforeUpload: (file: any) => {
                if (verificationType(file.type)) {
                    console.log(file);

                    this.customRequest(file);
                } else {
                    message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
                }
                return false;
            },
            onPreview: (file: any) => {
                // store.dispatch({type: OPENIMG,url:file.url})
                store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name});
                return false;
            }

        };
    }

    removeFile(file: any) {

        this.state.fileList.map((v: any, i: any) => {
            if (file.uid === v.uid) {
                this.state.fileList.splice(i, 1)

                this.props.onChange(this.state.fileList)
            }
        })
    }

    customRequest = (option: any) => {
        const formData = new FormData();
        formData.append('file', option);
        Http.post('/system/file/upload/auth', formData).then((res: any) => {

            this.state.fileList.push({
                uid: option.uid,
                name: option.name,
                status: 'done',
                id: res.data,
                url: ''
            })
            this.state.fileList.map((v: any, i: any) => {
                v.uid = i
            })
            this.setState({
                fileList: this.state.fileList
            })

            this.props.onChange(this.state.fileList)
        })
    }

    render() {
        const fileList = this.state.fileList
        const readAndEdit = this.state.readAndEdit

        return (
            <Upload
                // accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                // customRequest={this.customRequest}
                // beforeUpload={this.beforeUpload}
                onRemove={this.removeFile}
                fileList={fileList}
                {...this.reduceUpLoad}
                disabled={!readAndEdit}
            >
                <Button style={{marginLeft: '20px'}} size="small">
                    <UploadOutlined/>上传文件
                </Button>（单个文件大小不超过20M）
            </Upload>
        )
    }

}


export default WaterParkOne2;
