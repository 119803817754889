import './resultsSummary.scss';
import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Row, Col, Progress, Divider, Table } from 'antd';
import http from '../../../../utils/http';
import { ScoreObj, ScoreProductItem } from '../../../../utils/entity';
import * as uniqid from 'uniqid';
import _ from 'lodash';
import { spawn } from 'child_process';
interface SummarizedResObjName {
    key?: string
    full: number
    qualified: number
    qualifiedScore: number
    score: number
}
interface SummarizedResObj {
    key?: string
    en: SummarizedResObjName
    sum: SummarizedResObjName
    sx: SummarizedResObjName
    te: SummarizedResObjName
    tips: Array<0>
}
interface WaterSummarizedResult {
    key?: string
    companyName: string
    period: string
    results: SummarizedResObj
}
const ResultsSummary = forwardRef((props: any, ref: any) => {
    const [satisfy, setSatisfy] = useState<boolean>(true);
    const [resultObj, setResultObj] = useState<WaterSummarizedResult>();

    useImperativeHandle(ref, () => {
        return {
            func: getTableData,
        };
    })

    const getTableData = useCallback(() => {
        http.get('/apiWaterParkUrl/assess/result/' + props.id).then((res: any) => {
            if (res && res.data) {
                res.data.results?.rationScore?.forEach((i: ScoreProductItem) => {
                    i.key = uniqid.default();
                })
                setResultObj(res.data);
                setSatisfy(res.data.results?.basicCheck);
            }
        })
    }, [props.id])

    useEffect(() => {
        getTableData();
    }, [getTableData])


    const efficiencyNum = resultObj?.results?.sx?.score && (resultObj?.results?.sx?.score / resultObj?.results?.sx?.full * 100)
    const technology = resultObj?.results?.te?.score && (resultObj?.results?.te?.score / resultObj?.results?.te?.full * 100)
    const encourage = resultObj?.results?.en?.score && (resultObj?.results?.en?.score / resultObj?.results?.en?.full * 100)

    return (
        <>
            <div className="summarized-result-container">
                <p className="header-title">{resultObj?.companyName}<span style={{ padding: '0px 16px' }}>{resultObj?.period}</span>评估结果汇总</p>
                <div className='resuits'>
                    <div className='resuits1'>
                        <img src="/assets/images/img_shuixiao_big.png" alt='' ></img>
                        <div className='resuits12'>
                            <div>总得分</div>
                            <span style={resultObj?.results?.sum?.qualified ? { color: '#00A968' } : { color: '#D15757' }}>{resultObj?.results?.sum?.score}</span>
                        </div>

                    </div>
                    <div className='resuits2'>
                        <div className="resuits21">
                            <div className='resuits223'>
                                <div className='resuits221'>
                                    <img className='m-r10' src="/assets/images/icon_jiben.png" alt="" />
                                    <span className='m-r10 fontSzi'>基本要求</span>
                                    {!satisfy ? <img className='m-r10' src="/assets/images/iam/popup_icon_no_normal.png" alt="" /> : <img className='m-r10' src="/assets/images/iam/popup_icon_yes_normal.png" alt="" />}
                                    {!satisfy ? <span className='fontSzimin'>不满足</span> : <span className='fontSzimin'>满足</span>}

                                </div>
                                {!satisfy ? <div className='fontfais'>
                                    {
                                        resultObj?.results?.tips?.map((item, index) => {
                                            return <div className='fonttext' key={index}>
                                                {item}
                                            </div>
                                        })
                                    }
                                </div> : " "}
                            </div>
                        </div>

                        <div className='resuits223 resuis'>
                            <img className='m-r10' src="/assets/images/icon_shuixiao.png" alt="" />
                            <div className='resioss'>
                                <span className='fontSzi'>水效管理指标</span><span className='fontSzimin'>（满分{resultObj?.results?.sx?.full}分）</span>
                                <Progress percent={efficiencyNum}
                                    strokeColor={resultObj?.results?.sx?.qualified ? '#00A968' : '#D15757'}
                                    format={(percent =>
                                        <span className={resultObj?.results?.sx?.qualified ? 'font-Green' : 'font-Red'}>{resultObj?.results?.sx?.score?.toFixed(2)}</span>
                                    )} />

                            </div>

                        </div>

                        <div className='resuits223 resuis'>
                            <img className='m-r10' src="/assets/images/icon_jishu.png" alt="" />
                            <div className='resioss'>
                                <span className='fontSzi'>技术性指标</span><span className='fontSzimin'>（满分{resultObj?.results?.te?.full}分）</span>

                                <Progress percent={technology}
                                    strokeColor={resultObj?.results?.te?.qualified ? '#00A968' : '#D15757'}
                                    format={(percent =>
                                        <span className={resultObj?.results?.te?.qualified ? 'font-Green' : 'font-Red'}>{resultObj?.results?.te?.score?.toFixed(2)}</span>)} />

                            </div>

                        </div>
                        <div className='resuits223 resuis'>
                            <img className='m-r10' src="/assets/images/icon_guli.png" alt="" />
                            <div className='resioss'>
                                <span className='fontSzi'>鼓励性指标</span><span className='fontSzimin'>（满分{resultObj?.results?.en?.full}分）</span>


                                <Progress percent={encourage}
                                    strokeColor={resultObj?.results?.en?.qualified ? '#00A968' : '#D15757'}
                                    format={(percent =>
                                        <span className={resultObj?.results?.en?.qualified ? 'font-Green' : 'font-Red'}>{resultObj?.results?.en?.score?.toFixed(2)}</span>)} />
                            </div>

                        </div>

                    </div>


                </div>
            </div>
        </>
    )
})

export default ResultsSummary;