import React from 'react';
import { Divider, Button, message, Alert, Form, Row, Col, InputNumber} from 'antd';
import './fixed.scss';
import Http from '../../../../utils/http';
import DyeingProduct from '../dyeingProduct/dyeingProduct';
import DyeingEnergy from '../dyeingEnergy/dyeingEnergy';
import DyeingEquipment from '../dyeingEquipment/dyeingEquipment';
import { PlusOutlined, DeleteOutlined, DownCircleOutlined, UpCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { adminEditAndRead } from '../../../../utils/utils';

export class Fixed extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    this.state = {
      batchList:[],
      modifyId:'',
      isOpen:true,
      readAndEdit:true
    };
    this.dyeingFixed = this.dyeingFixed.bind(this);
    this.refresh = this.refresh.bind(this);
    this.addBatch = this.addBatch.bind(this);
    this.expendInfo = this.expendInfo.bind(this);
    this.shrinkInfo = this.shrinkInfo.bind(this);
    this.deleteBatch = this.deleteBatch.bind(this);
    this.getData = this.getData.bind(this);
    this.getDiagnosis = this.getDiagnosis.bind(this);
    this.changeOpen = this.changeOpen.bind(this);
  }
  createForm =  React.createRef<FormInstance>();
  getData(values:any){
    Http.put('/diagnosis/'+this.props.id,values).then((res:any)=>{
      if(res&&res.code===200){
        message.success('修改成功');
        this.getDiagnosis();
      }
    })
  }

  getDiagnosis(){
    Http.get('/diagnosis/'+this.props.id).then((res:any)=>{
      if(res&&res.code===200){
        this.createForm.current?.setFieldsValue({
          fixedEcRate:res.data?.fixedEcRate
        })
      }
    })
  }
  dyeingFixed(){
    Http.get('/diagnosis/dyeing/fixed/list',{
      diagnosisId:this.props.id,
      type:'FIXED'
    }).then((res:any)=>{
      if(res?.data){
        res.data.forEach((i:any,index:number)=>{
          i.key = Math.random();
          if(!this.state.modifyId){
            if(index===0){
              res.data[index].show = true;
            }
          } else {
            if(i.id===this.state.modifyId){
              i.show = true;
            } else {
              i.show = false;
            }
          }
        })
        this.setState({
          batchList: [...res.data]
        });
      }
      
    })
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    this.dyeingFixed();
    this.getDiagnosis();
  }
  deleteBatch(id:any){
    Http.DELETE('/diagnosis/dyeing/fixed/'+id).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功');
        this.dyeingFixed();
      }
    })
  }

  refresh(id:any){
    this.setState({
      modifyId:id
    })
    this.dyeingFixed();
  }
  addBatch(){
    Http.post('/diagnosis/dyeing/fixed',{
      diagnosisId:this.props.id,
      type:'FIXED'
    }).then((res:any)=>{
      if(res&&res.code===200){
        message.success('新增成功!');
        this.dyeingFixed();
      }
    })
  }
  expendInfo(id:any){
    let {batchList} = this.state;
    batchList.forEach((i:any)=>{
      if(i.id===id){
        i.show = true;
      }
    });
    this.setState({
      batchList:batchList
    });
  }
  shrinkInfo(id:any){
    let {batchList} = this.state;
    batchList.forEach((i:any)=>{
      if(i.id===id){
        i.show = false;
      }
    });
    this.setState({
      batchList:batchList
    });
  }
  changeOpen(){
    let status = this.state.isOpen;
    this.setState({
      isOpen: !status
    })
  };
  render(){
    let {batchList,isOpen,readAndEdit} = this.state;
    let itemList = batchList.map((i:any,index:number)=>{
      return <div className="dyeing-container-item" key={i.key}>
        <div className="dyeing-container-item-title">
          <div>批次{index+1}  {batchList.length===1?'':<DeleteOutlined style={{marginLeft:'20px'}} onClick={e=>this.deleteBatch(i.id)} />}</div>
          {i.show?<div><UpCircleOutlined onClick={e=>this.shrinkInfo(i.id)} /></div>:<div><DownCircleOutlined onClick={e=>this.expendInfo(i.id)} /></div>}
        </div>
        <Divider />
        {i.show?<div className="dyeing-container-item-body">
          <DyeingProduct product={i} id={i.id} refresh={this.refresh} type={1}></DyeingProduct>
          <DyeingEnergy data={i} id={i.id} diagnosisId={this.props.id} refresh={this.refresh}></DyeingEnergy>
          <div className="item-title-container" style={{marginTop:'20px'}}>
            <div className="item-title">
              <img src="/assets/images/icon1.png" alt='' ></img>
              <span>计算结果</span>
            </div>
            <div className="change-open" onClick={this.changeOpen}>
              {isOpen?<><span>收起</span><UpOutlined /></>:<><span>展开</span><DownOutlined /></>}
            </div>
          </div>
          {isOpen?<>
          <div className="result">
            <div className="result-item">
              <div>标准品产量</div>
              <div>{i.calculationStandardYield}</div>
              <div>t</div>
            </div>
            <div className="result-item">
              <div>单位产品综合能耗</div>
              <div>{i.uec}</div>
              <div>kgce/t</div>
            </div>
            <div className="result-foot">
              对标结果:<span>{i.result}</span>
            </div>
          </div></>:''}
          <Divider />
          <DyeingEquipment equipment={i} id={i.id} diagnosisId={this.props.id} type={1} refresh={this.refresh}></DyeingEquipment>
        </div>:''}
        
      </div>;
    })
    return(
      <div className="dyeing-container">
        <Form
          ref={this.createForm} onFinish={this.getData}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="定形工序能耗占全厂总能耗的比例(%):" name="fixedEcRate">
                <InputNumber disabled={!readAndEdit} min={0} max={100} placeholder="请输入" style={{width:'80%',marginLeft:'20px'}} />
              </Form.Item>
            </Col>
            <Col span={2} offset={10}>
              {readAndEdit?<Button className="green-button" htmlType="submit" type="primary">保存</Button>:''}
              
            </Col>
          </Row>
        </Form>
        <Alert message="请填写至少2个批次的数据" type="warning" style={{marginBottom:'22px'}} />
        {itemList}
        {readAndEdit?<Button size='small' onClick={this.addBatch} className="add-button" icon={<PlusOutlined />}>添加批次</Button>:''}
        
      </div>
    )
  }
}

export default Fixed;