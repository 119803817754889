import React from 'react';
import {Input, Form} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { adminEditAndRead } from '../../../../utils/utils';

interface addBackwardnessProps{
  id:string
  recyclingData: (isEdit: boolean, eesEPS: number, values: any) => void
  editData: any[]
  bindRef: (el: any) => any
  index:number
}
export class AddBackwardness extends React.Component<any,any>{
  constructor(props:addBackwardnessProps){
    super(props);
    this.state={
      readAndEdit:true
    }
    props.bindRef(this);
    this.getData = this.getData.bind(this);
  }
  vailForm(){
    this.createForm.current?.submit();
  }
  getData(values:any){
    let isEdit = this.props.editData.name?true:false;
    this.props.recyclingData(isEdit, this.props.index, values);
  }
  createForm = React.createRef<FormInstance>();
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.editData.name){
      this.createForm.current?.setFieldsValue(this.props.editData);
    }
  }
  render(){
    let docHtml;
    let {readAndEdit} = this.state;
    if(this.props.index === 1){
      docHtml = <>
        <Form.Item label="名称" name="name" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="规格/容量（注明单位）" name="scale" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="数量" name="num" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="适用目录" name="fit" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
      </>;
    } else {
      docHtml = <>
        <Form.Item label="名称" name="name" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="规格型号" name="spec" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="数量" name="num" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="适用目录" name="fit" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit}/>
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
      </>;
    }
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        { docHtml }
      </Form>
    )
  }
}

export default AddBackwardness;