import React, { useState, useEffect } from 'react';
import { Link, Router } from '@reach/router';
import { Col, Row } from 'antd';

import './dataStatisticsTabs.scss';
import UserBasicInfoStatistics from './user-basic-info-statistics/user-basic-info-statistics'
import {broadcaster} from 'web-tool-lib';
import {Subscription} from 'rxjs';
import { LoadingOutlined } from '@ant-design/icons';
import UserEvaluationDataStatistics from "./user-evaluation-data-statistics/user-evaluation-data-statistics";

const NavLink = (props:any) => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            return {
                className:isCurrent?'active':''
            };
        }}
    />
);

const DataStatisticsTabs = (props:any) => {
    const broadcast = broadcaster;
    const unsubscrip = new Subscription();
    const [visible,setVisible] = useState<boolean>(false);

    useEffect(() => {
        let temp1 = broadcast.on('onCloseDowloadMsg').subscribe((data: boolean) => {
            setVisible(data);
        });
        unsubscrip.add(temp1);
        return () => {
            unsubscrip.unsubscribe();
        }
    },[broadcast, unsubscrip, visible])

    return (
        <>
            <div className="white-container">
                <div className="component-nav">
                    <Row>
                        <Col span={visible?8:18}>
                            <ul className="nav-ul">
                                <NavLink to=''><li>用户基本信息统计</li></NavLink>
                                {/*<NavLink to='adminWaterEffectAssess/'><li>用户活动数据统计</li></NavLink>*/}
                                <NavLink to='userEvaluationDataStatistics/'><li>用户评估数据统计</li></NavLink>
                            </ul>
                        </Col>
                        {visible ?
                            <Col span={8} style={{textAlign: 'center'}}>
                                <div className="download-box"><LoadingOutlined /><span style={{paddingLeft: '8px'}}>正在下载中，请稍等 ...</span></div>
                            </Col> : ''}
                    </Row>
                </div>
                <div>
                    <Router>
                        <UserBasicInfoStatistics path="/*" />
                        <UserEvaluationDataStatistics path='userEvaluationDataStatistics/*' />
                    </Router>
                </div>
            </div>
        </>
    )
}

export default DataStatisticsTabs;

