import './water-effect-baisc-info.scss';
import React, { useState, useEffect } from 'react';
import { Row, Col, Checkbox, DatePicker, Button, message } from 'antd';
import moment from 'moment';
import http from '../../../../utils/http';
import { dateFormat, waterEditAndRead } from '../../../../utils/utils';

const WaterEffectBaiscInfo = (props:any) => {
  const [readAndEdit,setReadAndEdit] = useState<boolean>(true);
  const [period,setPeriod] = useState<any>(localStorage.getItem('period'));


  const boundary = [{
    label:'企业层次',
    value:0
  },{
    label:'生产线层次',
    value:1
  },{
    label:'设备层次',
    value:2
  }];

  const depth = [{
    label:'通用基础评估',
    value:0
  },{
    label:'专项评估',
    value:1
  }];

  const changePeriod = (date:any) => {
    setPeriod(date);
  }

  const disabledDate = (current:any) => {
    return current.valueOf() <= new Date('2000') || current.valueOf() >= Date.now();
  }

  const periodSave = () => {
    http.put('/apiWaterUrl/assess/'+props.id,{
      period:dateFormat(new Date(period),'YYYY')
    }).then((res:any) => {
      if(res && res.code === 200){
        message.success('保存成功');
        localStorage.setItem('period',dateFormat(new Date(period),'YYYY'));
      }
    })
  }

  useEffect(() => {
    setReadAndEdit(waterEditAndRead());
  }, [])


  return (
    <>
      <div className="water-effect-saving-information">
        <Row className="row-bottom" justify="center">
          <Col span={2} style={{textAlign:'center'}}>评估边界:</Col>
          <Col span={12}>
            <Checkbox.Group
              options={boundary}
              disabled
              defaultValue={[0]}
            />
          </Col>
        </Row>
        <Row className="row-bottom" justify="center">
          <Col span={2} style={{textAlign:'center'}}>评估深度:</Col>
          <Col span={12}>
            <Checkbox.Group
              options={depth}
              disabled
              defaultValue={[0]}
            />
          </Col>
        </Row>
        <Row className="row-bottom" justify="center" align="middle">
          <Col span={2} style={{textAlign:'center'}}><span style={{color:'red'}}>*</span>统计期:</Col>
          <Col span={12}>
            <DatePicker
              disabled={!readAndEdit}
              picker="year"
              value={moment(period)} 
              onChange={changePeriod} 
              // disabledDate={disabledDate}
            />
          </Col>
        </Row>
        {readAndEdit?<Row justify="center">
          <Col span={12} offset={2}>
          <Button className="green-button" type="primary" onClick={periodSave} style={{marginRight:'10px'}}>保存</Button>
          <Button type="default" onClick={props.nextCurrent} style={{marginRight:'10px'}}>下一步</Button>
          </Col>
        </Row>:''}
      </div>
    </>
  )
}

export default WaterEffectBaiscInfo;