import React,{ useState, useEffect  } from 'react';
import './resource-query.scss';
import { Input, List, Typography, message } from 'antd';
import http from '../../utils/http';
const { Search } = Input;
  
const ResourceQuery = (props:any) => {
  const [keyword,setKeyword] = useState<string>('');
  const [tabStatus,setTabStatus] = useState<string>('PROPAGANDA');
  const [tableList,setTableList] = useState<Array<any>>([]);

  const onSearch = (value:string) => {
    setKeyword(value);
  }

  const onChangeStatus = (v:string) => {
    setTabStatus(v);
  }

  const getTableList = () => {
    http.get('/website/information/list',{keyword:keyword,classify:tabStatus,online:true}).then((res:any)=>{
      if(res&&res.code===200){
        res.data.forEach((item:any,index:number)=>{
          item.key = index+1;
        });
        setTableList(res.data);
      }
    })
  }

  const openUrl = (url:string) => {
    if(!url){
      message.warning("当前内容不可访问");
      return;
    }
    window.open(url);
  }

  useEffect(() => {
    // getTableList();
  })

  useEffect(() => {
    getTableList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tabStatus,keyword])

  return(
    <>
      <div className="resource-query-container">
        <div className="resource-query-head">
          <div className="resource-query-head-left">
            <div className="header-box1" style={tabStatus === 'PROPAGANDA'?{zIndex: 2}:{zIndex: 0}} onClick={() => onChangeStatus('PROPAGANDA')}>
              {
                tabStatus === 'PROPAGANDA' ? 
                  <img src="/assets/images/tab-check.png" alt="check1" /> :
                  <img src="/assets/images/tab-default.png" alt="" />
              }
              <span className="head-left-text">科普宣传</span>
            </div>
            <div className="header-box2" style={tabStatus === 'CULTIVATE'?{zIndex: 2}:{zIndex: 1}} onClick={() => onChangeStatus('CULTIVATE')}>
              {
                tabStatus === 'CULTIVATE' ? 
                  <img src="/assets/images/tab-check.png" alt="check2" /> :
                  <img src="/assets/images/tab-default.png" alt="" />
              }
              <span className="head-left-text">行业技术</span>
            </div>
            <div className="header-box2" style={tabStatus === 'MAJOR'?{zIndex: 2}:{zIndex: 0}} onClick={() => onChangeStatus('MAJOR')}>
              {
                tabStatus === 'MAJOR' ? 
                  <img src="/assets/images/tab-check.png" alt="check3" /> :
                  <img src="/assets/images/tab-default.png" alt="" />
              }
              <span className="head-left-text">专业标准</span>
            </div>
          </div>
          <div className="resource-query-head-right">
            <Search placeholder="搜索资料" onSearch={onSearch} style={{ width: '100%' }} />          
          </div>
        </div>
        <div className="resource-query-content">
            <List
              header={null}
              footer={null}
              bordered
              dataSource={tableList}
              renderItem={item => (
                <List.Item>
                  <Typography.Text mark>
                    {item.ext === 'pdf' ? 
                      <img src="/assets/images/icon_documentweb_normal.png" alt="" onClick={() => openUrl(item.url)} style={{cursor: 'pointer'}}/> :
                      <img src="/assets/images/icon_videoweb_normal.png" alt="" onClick={() => openUrl(item.url)} style={{cursor: 'pointer'}}/>
                    }
                  </Typography.Text> <span style={{paddingLeft: '14px',cursor: 'pointer'}} onClick={() => openUrl(item.url)}>{item.showName}</span>
                </List.Item>
              )}
            />
        </div>
      </div>
    </>
  )
}

export default ResourceQuery;