import './summarized-result.scss';
import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Row, Col, Progress, Divider, Table } from 'antd';
import http from '../../../../utils/http';
import { WaterSummarizedResult, ScoreObj, ScoreProductItem } from '../../../../utils/entity';
import * as uniqid from 'uniqid';
import _ from 'lodash';

const SummarizedResult = forwardRef((props:any,ref:any) => {
  const [satisfy,setSatisfy] = useState<boolean>(true);
  const [resultObj,setResultObj] =useState<WaterSummarizedResult>();

  useImperativeHandle(ref,()=>{
    return{
      func:getTableData,
    };
  })

  const getTableData = useCallback(() => {
    http.get('/apiWaterUrl/assess/result/'+props.id).then((res:any) => {
      if(res&&res.data){
        res.data.results?.rationScore?.forEach((i:ScoreProductItem) => {
          i.key = uniqid.default();
        })
        setResultObj(res.data);
        setSatisfy(res.data.results?.basicCheck);
      }
    })
  },[props.id])

  useEffect(()=>{
    getTableData();
  },[getTableData])

  const columns = [{
    title:<span className="table-title">产品名称</span>,
    dataIndex:'productName',
    key:'productName',
    render:(text:any,record:any) => (
    <span className="table-row" style={{color:"#262626"}}>{text}</span>
    )
  },{
    title:<span className="table-title">基础得分</span>,
    dataIndex:'scoreObject',
    key:'scoreObject',
    render:(text:any,record:any) => (
      <span className={text.basic > 30 ? 'font-Green':'font-Red'}> {text.basic}</span>
    )
  },{
    title:<span className="table-title">附加得分</span>,
    dataIndex:'scoreObject',
    key:'scoreObject',
    render:(text:any,record:any) => (
      <span className={text.additionalQualified?'font-Green':'font-Red'}>{text.additional}</span>
    )
  }]

  const resultDomView = () => {
    resultObj?.results?.result?.map((option:ScoreProductItem) => {
      return (
      <Col span={12} className="card-div-Col" key={uniqid.default()}>
        <div className="card-div-width80">
          <p className="card-div-item-title">{option.productName}</p>
          <div>
            <span className="card-item-14">基础得分</span>
            <span className="card-item-10">（满分100分）</span>
            <Progress percent={option.scoreObject?.basic} 
              strokeColor={option.scoreObject.basicQualified ? '#00A968':'#D15757'}
              format={(percent => 
              <span className={option.scoreObject.basicQualified ? 'font-Green':'font-Red'}>{percent}</span>)}/>
          </div>
          <div>
            <span className="card-item-14">附加得分</span>
            <span className="card-item-10">（满分30分）</span>
            <Progress percent={option.scoreObject.additional} 
              strokeColor={option.scoreObject.additionalQualified ? '#00A968':'#D15757'}
              format={(percent => 
              <span className={option.scoreObject.additionalQualified ? 'font-Green':'font-Red'}>{percent}</span>)}/>
          </div>
        </div>
      </Col>
      )
    })
  }

  return(
    <>
      <div className="summarized-result-container">
        <p className="header-title">{resultObj?.companyName}<span style={{padding:'0px 16px'}}>{resultObj?.period}</span>评估结果汇总</p>
        <Row gutter={16} className="card-div-Row">
          <div style={{width:'100%'}}>
          <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">评估结果</p>
          </div>
          {/* {resultDomView()} */}
          {
            resultObj?.results?.result?.map((option:ScoreProductItem) => {
              return (
              <Col span={12} className="card-div-Col" key={uniqid.default()}>
                <div className="card-div-width80">
                  <p className="card-div-item-title">{option.productName}</p>
                  <div>
                    <span className="card-item-14">基础得分</span>
                    <span className="card-item-10">（满分100分）</span>
                    <Progress percent={option.scoreObject?.basic} 
                      strokeColor={option.scoreObject.basicQualified ? '#00A968':'#D15757'}
                      format={(percent => 
                      <span className={option.scoreObject.basicQualified ? 'font-Green':'font-Red'}>{percent?.toFixed(2)}</span>)}/>
                  </div>
                  <div>
                    <span className="card-item-14">附加得分</span>
                    <span className="card-item-10">（满分30分）</span>
                    <Progress percent={option.scoreObject.additional} 
                      strokeColor={option.scoreObject.additionalQualified ? '#00A968':'#D15757'}
                      format={(percent => 
                      <span className={option.scoreObject.additionalQualified ? 'font-Green':'font-Red'}>{percent?.toFixed(2)}</span>)}/>
                  </div>
                </div>
              </Col>
              )
            })            
          }
        </Row>
        <Row gutter={16}>
          <Col span={12} style={{paddingRight:'20px'}}>
            <div className="card-bais-box">
            <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">基本要求</p>
              {satisfy?
                <div className="card-bais-div">
                  <img src="/assets/images/popup_icon_yes_normal.png" alt=""/><span className="card-bais-img">满足</span>
                </div> : 
                <div >
                  <div style={{textAlign:'center'}}>
                    <img src="/assets/images/popup_icon_no_normal.png" alt=""/><span className="card-bais-img">不满足</span>
                  </div>
                  <div className="card-bais-content">
                    {
                      resultObj?.results?.basicTexts?.map((item:{key:string,value:string}) => {
                        return (
                          <p key={uniqid.default()}>{item.key}.{item.value}</p>
                        )
                      })
                    }
                  </div>
                </div>
              }
            </div>
          </Col>
          <Col span={12} style={{paddingLeft:'20px'}}>
            <div className="card-bais-box">
            <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">定性评估</p>
              <Row gutter={16}>
                <Col span={12} className="card-bais-Col">
                  <span style={{paddingRight:'16px'}}>基础得分</span>
                  <Progress type="circle" percent={resultObj?.results?.qualitativeScore?.basic} width={40} 
                  strokeColor={resultObj?.results?.qualitativeScore?.basicQualified ? '#00A968':'#D15757'}
                  format={(percent => 
                    <span className={resultObj?.results?.qualitativeScore?.basicQualified ? 'font-Green':'font-Red'}>{percent}</span>)}/>
                  </Col>
                <Col span={12} className="card-bais-Col">
                  <span style={{paddingRight:'16px'}}>附加得分</span>
                  <Progress type="circle" percent={resultObj?.results?.qualitativeScore?.additional} width={40} 
                  strokeColor={resultObj?.results?.qualitativeScore?.additionalQualified ? '#00A968':'#D15757'}
                  format={(percent => 
                  <span className={resultObj?.results?.qualitativeScore?.additionalQualified ? 'font-Green':'font-Red'}>{percent}</span>)}/>
                </Col>
              </Row>
              <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">定量评估</p>
              <div>
                <Table
                  columns={columns}
                  dataSource={resultObj?.results?.rationScore}
                  pagination={false}
                  size="small"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
})

export default SummarizedResult;