import React, { useState, useEffect, useCallback } from 'react';
import './admin-water-effect-assess.scss';
import { Row, Col, Select, Input, Button, Table, Pagination, Drawer, Space, Popover, Tag, message, DatePicker, Modal } from 'antd';
import { page, Page, waterStatusEnum, waterStatusType } from '../../../utils/entity';
import http from '../../../utils/http';
import {DeleteOutlined, PhoneFilled,EditOutlined} from '@ant-design/icons';
import { dateFormat } from '../../../utils/utils';
import { navigate } from '@reach/router';
import cookie from 'react-cookies';
import moment from 'moment';
import Http from "../../../utils/http";
let newDateTime:number
const AdminWaterEffectAssessComponent = (props:any) => {
  const { Option } = Select;
  const { Search } = Input;
  const [status,setStatus] = useState<string>('');
  const [searchValue,setSearchValue] = useState<string>('');
  const [dataList,setDataList] = useState<Array<any>>([]);
  const [total,setTotal] = useState<number>(0);
  const [searchPage,setSearchPage] = useState<page>(new Page());
  const [visiable,setVisiable] = useState<boolean>(false);
  const [editId,setEditId] = useState<any>();

  const [drawerSelect,setDrawerSelect] = useState<Array<any>>([]);  
  const [drawerSearchValue,setDrawerValue] = useState<string>('');
  const [drawerSearchPage,setDrawerSearchPage] = useState<page>(new Page());
  const [drawerData,setDrawerData] = useState<Array<any>>([]);
  const [btnLoading,setBtnLoading] = useState<boolean>(false);
  const [statuList,setStatusList] = useState<Array<any>>([]);
  const [endTime,setEndTime] = useState<Date>(new Date());
  const [modalVisible,setModalVisible] = useState<boolean>(false);
  const [dateVisible,setDateVisible] = useState<boolean>(true);
  const [modalList,setModalList] = useState<Array<any>>([]);


  const getStatusList = () => {
    http.get('/apiWaterUrl/assess/status/count/admin').then((res:any) => {
      if(res && res.code === 200){
        let statusArr:Array<{count:number,status:waterStatusType,text:string}> = [];
        res.data.forEach((i:any,index:number) => {
          i.key = index+1;
        })
        res.data.forEach((item:{count:number,status:waterStatusType}) => {
          statusArr.push({
            count:item.count,
            status:item.status,
            text:waterStatusEnum[item.status]
          })
        });
        setStatusList(statusArr);
      }
    })
  }

  const getTableList = useCallback(() => {
    http.get('/apiWaterUrl/assess/page/admin',{
      keyword: searchValue,
      pageNum: searchPage.PageNum,
      pageSize: 10,
      status: status
    }).then((res:any) => {
      if(res && res.code === 200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key = index+1;
        });
        setTotal(res.data.count);
        setDataList(res.data.dataList);
      }
    })
  },[searchPage.PageNum, searchValue, status])

  const onChange = (value:any) => {
    let page = searchPage;
    page.KeyWord = value.target.value;
    setSearchPage(page);
    setSearchValue(value.target.value);
  }

  const onSearch = (value:string) => {
    setSearchPage({
      KeyWord:value,
      SkipCount:0,
      MaxResultCount:10
    });
    pageChange(1);
  }
  const deleteExpert = (obj:any) => {
    Modal.confirm({
      content:'删除专家后，专家已填写的意见将被一并删除,确定要删除? ',
      onOk:()=>{
        const data = {
          assessId:editId,
          expertId:obj.adminId,
        }
        Http.DELETE(`/apiWaterUrl/assess/expert?assessId=${data.assessId}&expertId=${data.expertId}`).then((res:any)=>{
          if(res&&res.code===200){
            message.info('操作成功')
            getDrawerList(editId);
          }
        })
      },
      onCancel:()=>{
        message.info('取消删除')
      }
    })

  }
  const pageChange = (page:number) => {
    let pageData = searchPage;
    pageData.SkipCount = (page - 1) * 10;
    pageData.PageNum = page;
    setSearchPage(pageData);
    getTableList();
  }

  const showViewDrawer = (data:any) => {
    localStorage.clear();
    localStorage.setItem('waterEffectStatus',data.status);
    localStorage.setItem('period',data.period);
    navigate('/home/waterEffectProcess/'+data.id);
  }

  useEffect(() => {
    cookie.remove('waterEffectEdit');
    getTableList();
    getStatusList();
  },[getTableList])

  const getDrawerList = (id?:number) => {
    http.get('/apiWaterUrl/assess/expert/appoint/page',{
      keyword: drawerSearchValue,
      pageNum: drawerSearchPage.PageNum,
      pageSize: 1000,
      assessId: id?id:editId
    }).then((res:any) => {
      if(res && res.code === 200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key = index+1;
        });
        setDrawerData(res.data.dataList);
      }
    })
  }

  const onClose = () => {
    setDrawerValue('');
    setDrawerSelect([]);
    setEndTime(new Date())

    setVisiable(false);
  }

  const onSubmitDrawer = () => {
    if(drawerSelect.length === 0 ){
      message.info('请至少选择一位专家');
      return;
    }
    let ids:any = [];
    drawerSelect.forEach((i:any) => {
      ids.push(i.adminId);
    })
    setBtnLoading(true);
    
    http.put('/apiWaterUrl/assess/expert/appoint',{
      adminIds: ids,
      assessId: editId,
      endTs: endTime ? new Date(new Date(new Date(endTime).toLocaleDateString()).getTime() ) : ''
    }).then((res:any) => {
      if(res && res.code === 200){
        message.success("指定成功");
        onClose();
        getTableList();
      }
      setBtnLoading(false);
    })
  }

  const showDrawer = (data?:any) => {
    setEditId(data.id);
    getDrawerList(data.id);
    setVisiable(true);
    setDateVisible(data.endTs? false : true);
    // console.log( new Date(data.endTs))
    setEndTime( new Date(data.endTs))

    newDateTime = new Date(new Date(data.endTs).toLocaleDateString()).getTime()

    // setEndTime(new Date())
  }

  const drawerOnChange = (value:any) => {
    let page = searchPage;
    page.KeyWord = value.target.value;
    setDrawerSearchPage(page);
    setDrawerValue(value.target.value);
  }

  const drawerOnSearch = (value:string) => {
    setDrawerSearchPage({
      KeyWord:value,
      SkipCount:0,
      MaxResultCount:10
    });
    drawerPageChange(1);
  }

  const drawerPageChange = (page:number) => {
    let pageData = searchPage;
    pageData.SkipCount = (page - 1) * 10;
    pageData.PageNum = page;
    setDrawerSearchPage(pageData);
    getDrawerList();
  }

  const changeStatus = (status:string) => {
    setStatus(status);
  }

  const showModalView = (list:any[]) => {
    setModalList(list);
    setModalVisible(true);
  }

  const handleCancel = () => {
    setModalVisible(false);
  }

  const statusView = (text:waterStatusType,record:any) => {
    if(text === 'ING' || text === 'END'){
      return <span style={{cursor:'pointer'}} onClick={()=>showModalView(record.experts)}>
          {waterStatusEnum[text]}(<span style={{color:'#00A968'}}>{record.end}</span>/{record.all})
        </span>
    }else{
      return <span>{waterStatusEnum[text]}</span>
    }
  }

  const columns = [{
    title:'序号',
    dataIndex:'key',
    width:60
  },{
    title:'企业名称',
    dataIndex:'companyName',
    key:'companyName',
    width:200
  },{
    title:'填表人',
    dataIndex:'writer',
    key:'writer',
    width:150,
    render: (text:string,record:any) => (
      record.writer?
      <span>{text}<Popover 
      placement="topLeft" 
      content={<>电话:{record.tel}</>} 
      title="联系电话" 
      trigger="hover">
        <PhoneFilled style={{marginLeft:'5px',color:'#1C9A6A'}} /></Popover>
      </span>:''
    )
  },{
    title:'统计期',
    dataIndex:'period',
    key:'period',
    width:100
  },{
    title:'填写时间',
    dataIndex:'createTs',
    key:'createTs',
    width:150,
    render: (text:string,record:any) => (
      <span>{text?dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss'):''}</span>
    )
  },{
    title:'评审截止日期',
    dataIndex:'endTs',
    key:'endTs',
    width:150,
    render: (text:string,record:any) => (
      <span>{text?dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss'):''}</span>
    )
  },{
    title:'评估专家',
    dataIndex:'expert',
    key:'expert',
    width:200,
    render:(text:string,record:any) => (
      <Popover content={
          <div style={{maxWidth:300}}>{text}</div>
        } title={null}>
        <span className="ellipsis-text">{text}</span>
      </Popover>
    )
  },{
    title:'状态',
    dataIndex:'status',
    key:'status',
    width:100,
    render:(text:waterStatusType,record:any) => (
      statusView(text,record)
      // <span>{waterStatusEnum[text]}</span>
    )
  },{
    title:'操作',
    key: 'action',
    width: 150,
    fixed: 'right' as any,
    render:(text:any, record:any) => (
      listBtnRender(record)
    )  
  }]

  const listBtnRender = (list:any) => {
    if(list.status === 'SUBMITTED' || list.status === 'END' || list.status === 'ING'){
      return <Space size="middle">
        <span className="green-table-a" onClick={() => showViewDrawer(list)}>查看</span>
        <span className="green-table-a" onClick={() => showDrawer(list)}>指定专家</span>
      </Space>
    } else {
      return <Space size="middle">
        <span className="green-table-a" onClick={() => showViewDrawer(list)}>查看</span>
      </Space>
    }
  }

  const drawerColumns = [
    {
      title: '专家姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '所在机构',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: '状态',
      dataIndex: 'appoint',
      key: 'appoint',
      render:(text:boolean,r:any)=>(
        text===true?<div> <Tag color="blue">已指定</Tag> <DeleteOutlined onClick={ () => deleteExpert(r)} style={{cursor:"pointer"}} /> </div>:''
      )
    }
  ]

  const dateOnChange = (e:any,dateString:string) => {
    setEndTime(e)
    http.put(`/apiWaterUrl/assess/review/endts/${editId}?endTs=${new Date(new Date(e).toLocaleDateString()).getTime()}`).then((res:any) => {
      if(res && res.code === 200){
        message.info('操作成功')
        setDateVisible(false)
      }
    })
  }

  let statusCol = statuList.map((item:{count:string,status:waterStatusType,text:string})=>{
    return <Col  key={item.status}>
      <div onClick={e=>changeStatus(item.status)} className={status===item.status?'effect-assess-list-status-item-active effect-assess-list-status-item':'effect-assess-list-status-item'}>{item.text}({item.count})</div>
    </Col>
  })

  const modalColums = [
    {
      title: '专家姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '所在机构',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '评审完成时间',
      dataIndex: 'reviewTs',
      key: 'reviewTs',
      render: (text:string,record:any) => (
        <span>{text?dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss'):''}</span>
      )  
    }
  ]
  
  return (
    <>
      <div className="water-effect-assess-container">
        <h1>评估列表</h1>
        <Row gutter={16} style={{marginBottom:'12px'}}>
          <Space>
            <Col >
              <div onClick={e=>changeStatus('')} className={status===''?'effect-assess-list-status-item-active effect-assess-list-status-item':'effect-assess-list-status-item'}>全部状态({total})</div>
            </Col>
              {statusCol}
          </Space>
            <Col span={6}>
            <Search value={searchValue} onChange={onChange} 
            onSearch={onSearch} placeholder="搜索企业名称/填表人/评估专家" 
            style={{ width: '100%' }}/>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={dataList}
          pagination={false}
          size="small"
        />
        <Pagination
          defaultCurrent={1}
          total={total}
          hideOnSinglePage
          onChange={(page:number)=>pageChange(page)}
        />
        <Drawer 
          title={'指定专家'}
          width={550}
          onClose={onClose}
          destroyOnClose={true}
          visible={visiable}
          bodyStyle={{ paddingBottom: 10 }}
        >
          <div className="expert-drawer-search">
            <Search placeholder="搜索专家" value={drawerSearchValue} onChange={drawerOnChange} onSearch={drawerOnSearch} style={{ width: 200 }} />
            <div className='expert-drawer-search-new'>
               <span> 评审截止日期：</span>
              {
                dateVisible
                    ? <DatePicker defaultValue={moment(endTime)} format="YYYY-MM-DD" onChange={dateOnChange} style={{width: 180}}/>
                    : <div>
                        {moment(endTime).format('YYYY-MM-DD')}
                        <EditOutlined onClick={() => setDateVisible(true)} style={{cursor:"pointer"}} />
                      </div>
              }
            </div>


          </div>
          <Table
            rowSelection={{
              type:'checkbox',
              getCheckboxProps:record=>({
                disabled:record.appoint===true,
                name:record.name
              }),
              onChange:(selectedRowKeys,selectedRows) =>{
                setDrawerSelect(selectedRows);
              }
            }}
            scroll={{ y: 300 }}
            columns={drawerColumns}
            dataSource={drawerData}
            pagination={false}
            size="small"
          />
          <div style={{textAlign:'right',paddingTop:'16px'}}>
            <Space>
              <Button type="default" onClick={onClose}>取消</Button>
              <Button type="primary" onClick={onSubmitDrawer} loading={btnLoading} className="green-button">指定</Button>
            </Space>
          </div>
        </Drawer>
        <Modal
          title="评审专家"
          visible={modalVisible}
          width="560px"
          cancelText={null}
          okText={null}
          onCancel={handleCancel}
          destroyOnClose
          footer={null}
          bodyStyle={{
            paddingBottom: 50
          }}
        >
          <Table
            columns={modalColums}
            dataSource={modalList}
            pagination={false}
            size="small"
          />
        </Modal>
      </div>
    </>
  )
}

export default AdminWaterEffectAssessComponent;