import React, { useState } from 'react';
import { Link, Router } from '@reach/router';
import './index.scss'
import DataStatistics from './user-data-statistics/dataStatistics'
import WWF from "./wwf/wwf";
import {ExportOutlined,SyncOutlined} from "@ant-design/icons";
import { message } from "antd";

const UserEvaluationDataStatistics = (props:any) => {
    const [status,setStatus] = useState<number>(1)
    let ChildRef:any = React.createRef();
    const  handleOnClick = () => {
        ChildRef.current.download();
    }
    const handleRefresh = () => {
        ChildRef.current.refresh();
        message.info("成功刷新！")
    }

    return (
        <>
            <div className={"f-flex"}>
                <div className={'user-eval'}>
                    <Link to="" onClick={() => setStatus(1)} className={status === 1 ? 'active link' : 'link'}>WWF五步法问卷</Link>
                    {/*<Link to={''}  onClick={() => setStatus(2)} className={status === 2 ? 'active link' : 'link'}>水效评估统计</Link>*/}
                    <Link to={'industrialEnergySavingDiagnosisStatistics'}  onClick={() => setStatus(3)} className={status === 3 ? 'active link' : 'link'}>工业节能诊断统计</Link>
                </div>
                {
                    status === 1 ? <div>
                        <SyncOutlined onClick={handleRefresh} style={{color:'#00A566',cursor:"pointer",fontWeight:"bold",fontSize:"18px",marginRight:'18px'}} />
                        <ExportOutlined onClick={handleOnClick} style={{color:'#00A566',cursor:"pointer",fontWeight:"bold",fontSize:"18px"}} />
                    </div> : <div></div>
                }
            </div>

            <div>
                <Router>
                    <WWF onRef={ChildRef} path={"/*"} ></WWF>
                    <DataStatistics path={"industrialEnergySavingDiagnosisStatistics"}></DataStatistics>
                </Router>
            </div>
        </>
    )
}

export default UserEvaluationDataStatistics