import './add-standard.scss';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Input, Form, message, Upload, Button, DatePicker, Row, Col, Select, InputNumber } from 'antd';
import { verificationType, waterEditAndRead } from '../../../../utils/utils';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';
import http from '../../../../utils/http';
import { UploadOutlined } from '@ant-design/icons';
import * as uniqid from 'uniqid';


const AddStandard = (props:any) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [readAndEdit,setReadAndEdit] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [flowChartList,setFlowChartList] = useState<Array<any>>([]);
  const [btnLoading,setBtnLoading] = useState<boolean>(false);
  const flowListRef = useRef<Array<any>>([]);

  useEffect(() => {
    flowListRef.current = flowChartList;
  },[flowChartList])

  const optionList = [
    {
      value: 'INTERNATIONAL',
      name: '国际标准'
    },{
      value: 'COUNTRY',
      name: '国家标准'
    },{
      value: 'INDUSTRY',
      name: '行业标准'
    },{
      value: 'LOCAL',
      name: '地方标准'
    },{
      value: 'GROUP',
      name: '团体标准'
    }
  ]

  let fileupLoad = {
    beforeUpload: (file: any) => {
      if(verificationType(file.type)){
        flowChartListUpload(file);
      } else {
        message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
      }
      return false;
    },
    onRemove: (file:any)=>{
      let list = flowChartList.filter((i:any)=> i['uid'] !== file.uid);
      setFlowChartList(list);
      return false;
    },
    onPreview:(file:any)=>{
      store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name})
      return false;
    }
  };

  const flowChartListUpload = (file:any) => {
    const formData = new FormData();
    formData.append('file', file);
    http.post('/system/file/upload/auth',formData).then((res:any)=>{
      if(res&&res.code===200){
        let img = {
          uid:file.uid,
          name:file.name,
          status:'done',
          id:res.data,
          url:''
        };
        setFlowChartList([...flowChartList,img]);
      }
    })
  }

  useEffect(() => {
    setReadAndEdit(waterEditAndRead());
  }, [])

  useEffect(() => {
    if(props.editData){
      form.setFieldsValue({
        standardEnum: props.editData.standardEnum,
        name: props.editData.name,
        no: props.editData.no,
        rank: props.editData.rank,
        remark: props.editData.remark,
        files: props.editData.files
      })
      if(props.editData.files&&props.editData.files.length>0){
        let arr:any = [];
        props.editData.files.forEach((i:any,index:number)=>{
          let img = {
            uid:index,
            name:i.showName,
            status:'done',
            id:i.fileId,
            url:''
          };
          arr.push(img);
        });
        setFlowChartList(arr);
      }
    }
  }, [form, props.editData])

  const onFinish = (values:any) => {
    let obj = Object.assign({},values);
    let arr:any = [];
    flowListRef.current.forEach((i:any) => {
      arr.push({fileId:i.id,showName:i.name});
    })
    obj.files = arr;
    setBtnLoading(true);
    if(props.editData.id){
      http.put('/apiWaterUrl/assess/standard/' + props.editData.id,{...obj}).then((res:any) => {
        if(res && res.code === 200){
          message.success("修改成功");
          props.onSubmitDrawer();
        }
        setBtnLoading(false);
      })
    }else{
      http.post('/apiWaterUrl/assess/standard',{...obj,assessId:props.id}).then((res:any) => {
        if(res && res.code === 200){
          message.success("新增成功");
          props.onSubmitDrawer();
        }
        setBtnLoading(false);
      })
    }
  }

  return(
    <>
      <Form layout="vertical" form={form} name="add-water-saving" onFinish={onFinish}>
        <Form.Item label="标准类型" name="standardEnum" rules={[{required: true}]}>
          <Select disabled={!readAndEdit} placeholder="请选择">
            {
              optionList.map((i:any,index:number) => 
                <Option key={uniqid.default()} value={i.value}>{i.name}</Option>
              )
            }
          </Select> 
        </Form.Item>
        <Form.Item label="标准名称" name="name" rules={[{required: true}]}>
          <Input disabled={!readAndEdit} placeholder="请输入"/>
        </Form.Item>
        <Form.Item label="标准号" name="no" rules={[{required: true}]}>
        <Input disabled={!readAndEdit} placeholder="请输入"/>
        </Form.Item>
        <Form.Item label="企业排名" name="rank">
          <InputNumber disabled={!readAndEdit} min={0} placeholder="请输入企业排名" style={{width: '100%'}}></InputNumber>
        </Form.Item>
        <Form.Item label="备注" name="remark" >
          <TextArea placeholder='请输入' rows={2} disabled={!readAndEdit} />
        </Form.Item>

        <Form.Item label="上传证明材料" name="files" rules={[{required: true,message:'请上传证明材料'}]}>
          <Upload {...fileupLoad} fileList={flowChartList} disabled={!readAndEdit}>
            <Button>
              <UploadOutlined />点击上传
            </Button>（单个文件大小不超过20M）
          </Upload>
        </Form.Item>
        <Form.Item>
          <div style={{textAlign: 'right'}}>
            <Button type="default" onClick={props.onClose} style={{marginRight: readAndEdit?'20px':''}}>取消</Button>
            {readAndEdit?
            <Button className="green-button" htmlType="submit" loading={btnLoading}>保存</Button>:''}
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default AddStandard;