import './expert-score.scss'
import cookie from "react-cookies";
import * as uniqid from "uniqid";
import { ExpertScore } from "./expert-score";
import { Divider } from "antd";
import React from "react";
import { OptionsComponent, UploadComponent } from "../reduce-pollution/reduce-pollution";
interface BasicRequireInfo {
    key: number
    ruleId: number//条目id
    itemId: number
    name: string//标题
    declare: string//填报说明
    gist: string//考核依据
    flag: number//选择的标签
    optionList: Array<SubjectOption>//选项
    children: Array<BasicRequireInfo>
    files: Array<any>
}
interface SubjectOption {
    flag: number//标识
    name: string//显示名称
    value: number
}
export const forEachChildren = (items: Array<BasicRequireInfo>, pIndex: number, id: number, proType: string, listData: Array<any>, readAndEdit: boolean, showExpert: boolean = true) => {
    const userInfo = cookie.load('faisUserInfo')

    const role = userInfo.roleIds[0]
    return items.map((item: BasicRequireInfo, index: any) =>
        <div key={uniqid.default()}>
            <div className="expert-score-group" >{pIndex + 1}.{index + 1} {item.name}
                {role === 2 && showExpert && <ExpertScore id={id} item={item} ></ExpertScore>
                }
            </div>
            {item.declare ? <div className="basic-requirement-container-left-small" >
                <img src="/assets/images/icon_prompt_normal.png" alt="" /><span className="small-span">{item.declare}</span>
            </div> : ""}
            {item.gist ? <div className="basic-requirement-container-left-small">
                <img src="/assets/images/icon_prompt_normal.png" alt="" /><span className="small-span">{item.gist}</span>
            </div> : ""}
            <div className="basic-requirement-container-left-small" style={{ margin: '8px 0px' }}>
                <OptionsComponent listData={listData} index={pIndex} proType={proType} childrenIndex={index} item={item} readAndEdit={readAndEdit}></OptionsComponent>
                <Divider type="vertical" />
                <UploadComponent listData={listData} proType={proType} readAndEdit={readAndEdit} fileIndex={pIndex} fileChildrenIndex={index} item={item}></UploadComponent>
            </div>
            <Divider></Divider>
        </div>
    )
}