import React from 'react';
import {Layout, Spin, message, BackTop} from 'antd';
import Head from '../../common/head/head';
import UserInfo from '../user-info/user-info';
import UserManagement from '../user-management/user-management';
import DiagnosisList from '../diagnosis/diagnosis';
import DiagnosisProcess from '../diagnosis-process/diagnosis-process';
import FiveStepRule from '../../common/fiveStep/fiveStep';
import {Router} from "@reach/router";
import Http from '../../utils/http';
import store from '../../utils/store';
import PreviewImg from '../../common/previewImg/previewImg';
import cookie from 'react-cookies';
import {navigate} from '@reach/router';
import http from '../../utils/http';
import DiagnosisListComponent from '../admin/diagnosis-list/diagnosis-list';
// import DataStatistics from '../dataStatistics/dataStatistics';
import DataStatisticsTabs from '../dataStatistics/dataStatisticsTabs'
import ExpertList from '../expert/expert-list/expert-list';
import MessageList from '../message-list/message-list';
import QuestionAndAnswer from '../questionAndAnswer/questionAndAnswer';
import AdminDeclarationPage from '../admin-declaration/admin-declaration';
import BusinessManageComponent from '../../common/business-manage/business-manage';
import ResourceQuery from '../resource-query/resource-query';
import WaterEffectAssessComponent from '../water-effect-assess/water-effect-assess';
import WaterEffectProcessComponent from '../water-effect-process/water-effect-process';
import WaterEffectExpert from '../water-effect-expert/water-effect-expert';
import WaterParkExpert from '../water-park/water-park-expert/water-park-expert';
import WaterParkEnterprise from '../water-park/water-park-enterprise/water-park-enterprise';
import WaterParkProcess from '../water-park/water-park-process/water-park-process';


export class HomeContainer extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            maxHeight: document.body.clientHeight - 80,
            loading: false,
            isInfo: true,
            isAdmin: false,
            isPass: false,
            isFive: false,
            roleIds: null,
            unreadMsgNumber: 0
        };
        this.adminInfo = this.adminInfo.bind(this);
        this.adminInfoFirst = this.adminInfoFirst.bind(this);
        this.fsemCount = this.fsemCount.bind(this);
        this.domRender = this.domRender.bind(this);
        this.isMsg = this.isMsg.bind(this);
    }

    cancelSub = () => {
    };

    adminInfoFirst() {
        Http.get('/admin/info/check/first').then((res: any) => {
            if (res && res.code === 200) {
                this.setState({
                    isInfo: res.data
                });
                if (!res.data) {
                    message.info({
                        content: '请先完善个人信息',
                        style: {
                            marginTop: '10vh'
                        }
                    });
                }
            }
        })
    }

    adminInfo() {
        if (cookie.load('isPass')) {
            message.warning('初始密码过于简单请到个人信息页面的密码管理修改密码');
            this.setState({
                isPass: true
            })
        } else {
            this.setState({
                isPass: false
            })
            Http.get('/admin/info').then((res: any) => {
                if (res && res.code === 200) {
                    if (res.data.roleIds[0] === 0) {
                        this.setState({
                            isAdmin: true,
                            roleIds: res.data.roleIds[0]
                        });
                    } else {
                        this.setState({
                            isAdmin: false,
                            roleIds: res.data.roleIds[0]
                        });
                        this.adminInfoFirst();
                    }
                }
            })
        }
    }

    fsemCount() {
        http.get('/admin/info/has/fsem/count').then((res: any) => {
            if (res && res.code === 200) {
                this.setState({
                    isFive: res.data
                })
            }
        })
    }

    isMsg() {
        http.get('/diagnosis/processRecordSheet/unreadMessagesCount').then((res: any) => {
            if (res && res.code === 200) {
                this.setState({
                    unreadMsgNumber: res.data
                })
            }
        })
    }

    domRender() {
        let {isInfo, isAdmin, isPass, isFive, roleIds, maxHeight} = this.state;
        if (roleIds === null) {
            return false;
        }
        if (roleIds === 2) {

            if (isPass) {
                return <>
                    <UserInfo path="/*" refresh={this.adminInfo}/>
                </>
            } else {
                return <>
                    <ExpertList path="diagnosisList/*"/>
                    <UserInfo path="user/*"/>
                    <WaterEffectExpert path="waterEffectAssess/*"/>
                    <WaterParkExpert path="waterParkEnterprise/*"/>
                    <DiagnosisProcess maxHeight={maxHeight} path="diagnosisProcess/:id"/>
                    <WaterEffectProcessComponent maxHeight={maxHeight} path="waterEffectProcess/:id"/>
                    <WaterParkProcess maxHeight={maxHeight} path="waterParkProcess/:id"/>
                    <MessageList path="/msg/*"/>
                    <ResourceQuery path="/resource/*"/>
                    <QuestionAndAnswer path="/question/*"/>
                </>
            }
        } else if (roleIds === 3) {
            // 园区
            return <>
                <WaterParkEnterprise path="waterParkEnterprise/*"/>
                <UserInfo path="user/*"/>
                <DiagnosisList path="diagnosisList/*"/>
                <DiagnosisProcess maxHeight={maxHeight} path="diagnosisProcess/:id"/>
                <MessageList path="/msg/*"/>
                <ResourceQuery path="/resource/*"/>
                <WaterParkProcess maxHeight={maxHeight} path="waterParkProcess/:id"/>
                <QuestionAndAnswer path="/question/*"/>
            </>

        } else {
            if (isAdmin) {
                return <>
                    <UserInfo path="user/*"/>
                    <UserManagement path="/*"/>{/* 用户管理 */}
                    <BusinessManageComponent path="businessManage/*"/>
                    {/* <DiagnosisListComponent path="adminDiagnosis/*" />诊断管理 */}
                    {/* <AdminDeclarationPage path="adminDeclaration/*" />申报管理 */}
                    {/*<DataStatistics path="dataStatistics/*" />/!* 数据统计 *!/*/}
                    <DataStatisticsTabs path="dataStatistics/*"/>
                    <DiagnosisProcess maxHeight={maxHeight} path="diagnosisProcess/:id"/>
                    <WaterEffectProcessComponent maxHeight={maxHeight} path="waterEffectProcess/:id"/>
                    <WaterParkProcess maxHeight={maxHeight} path="waterParkProcess/:id"/>
                    <MessageList path="/msg/*"/>
                    <ResourceQuery path="/resource/*"/>
                    <QuestionAndAnswer path="/question/*"/>
                </>
            } else if (isPass || !isInfo) {
                return <>
                    <UserInfo path="/*" refresh={this.adminInfo}/>
                </>
            } else if (isFive) {
                return <>
                    <FiveStepRule path="/*" refresh={this.fsemCount}></FiveStepRule>
                </>
            } else {
                // 企业
                return <>
                    <UserInfo path="user/*"/>
                    <DiagnosisList path="diagnosisList/*"/>
                    <WaterEffectAssessComponent path="waterEffectAssess/*"/>
                    <DiagnosisProcess maxHeight={maxHeight} path="diagnosisProcess/:id"/>
                    <WaterEffectProcessComponent maxHeight={maxHeight} path="waterEffectProcess/:id"/>
                    <MessageList path="/msg/*"/>
                    <ResourceQuery path="/resource/*"/>
                    <QuestionAndAnswer path="/question/*"/>
                </>
            }
        }

    }

    componentDidMount() {
        this.fsemCount();
        this.adminInfo();
        this.isMsg();
    }

    render() {
        const {Content} = Layout;
        let {maxHeight, loading, isInfo, isAdmin, unreadMsgNumber, isFive, roleIds} = this.state;
        return (
            <Layout>
                <PreviewImg></PreviewImg>
                {isFive && roleIds === 1 ? '' :
                    <Head isDashboard={false} isInfo={isAdmin} roleId={roleIds} isMsg={this.isMsg}
                          unreadMsgNumber={unreadMsgNumber}/>}

                <Spin tip="请耐心等待......" spinning={loading}>
                    <Content className="site-layout" style={{padding: '12px 20px',}}>
                        <div className="site-layout-background" id="handelDocID"
                             style={{minHeight: '500', height: maxHeight, overflowY: 'auto'}}>
                            <Router>
                                {this.domRender()}
                            </Router>
                        </div>
                    </Content>
                </Spin>
            </Layout>
        );
    }
}

export default HomeContainer;