import React, {useState, useEffect, useCallback} from 'react';
import './management-test.scss';
import {
    Row,
    Col,
    Select,
    Input,
    Table,
    Pagination,
    Space, Popover, message,
} from 'antd';
import {
    contestStatusType,
    page,
    Page,
    contestStatusEnum,
} from '../../../utils/entity';
import http from '../../../utils/http';
import {dateFormat} from '../../../utils/utils';
import cookie from 'react-cookies';
import {PhoneFilled} from "@ant-design/icons";

const AdminManagementTest = (props: any) => {
    const {Option} = Select;
    const {Search} = Input;
    const [prizeEnum, setprizeEnum] = useState<string>('');
    const [searchValue, setSearchValue] = useState<string>('');
    const [dataList, setDataList] = useState<Array<any>>([]);
    const [total, setTotal] = useState<number>(0);
    const [alltotal, setalltotal] = useState<number>(0);
    const [statuList, setStatusList] = useState<Array<any>>([]);
    const [currentPage, setCurrent] = React.useState(1);
    const getTableList = useCallback(() => {
        http.get('/project/declare/prize/page', {
            keyword: searchValue,
            pageNum: currentPage,
            pageSize: 10,
            prizeEnum: prizeEnum
        }).then((res: any) => {
            if (res && res.code === 200) {
                res.data.dataList.forEach((i: any, index: number) => {
                    i.key = index + 1;
                });
                if (prizeEnum == '') {
                    setalltotal(res.data.count)
                }
                setTotal(res.data.count);
                setDataList(res.data.dataList);
            }
        })
    }, [currentPage, searchValue, prizeEnum])

    const onChange = (value: any) => {
        setCurrent(1);
        setSearchValue(value.target.value);

    }
    const getStatusList = () => {
        http.get('/project/declare/status/count').then((res: any) => {
            if (res && res.code === 200) {
                let statusArr: Array<{ count: number, prizeEnum: contestStatusType, text: string }> = [];
                res.data.forEach((i: any, index: number) => {
                    i.key = index + 1;
                })
                res.data.forEach((item: { count: number, prizeEnum: contestStatusType }) => {
                    statusArr.push({
                        count: item.count,
                        prizeEnum: item.prizeEnum,
                        text: contestStatusEnum[item.prizeEnum]
                    })
                });
                setStatusList(statusArr);
            }
        })
    }
    const onSearch = (value: string) => {
        setSearchValue(value);
        setCurrent(1);
    }

    const changePage = (value: number) => {
        setCurrent(value);
    }


    useEffect(() => {
        cookie.remove('waterEffectEdit');
        getTableList();
        getStatusList();
    }, [getTableList])


    const changeStatus = (status: string) => {
        setprizeEnum(status);
    }


    let statusCol = statuList.map((item: { count: string, prizeEnum: contestStatusType, text: string }) => {
        return <Col key={item.prizeEnum}>
            <div onClick={e => changeStatus(item.prizeEnum)}
                 className={prizeEnum === item.prizeEnum ? 'effect-assess-list-status-item-active effect-assess-list-status-item' : 'effect-assess-list-status-item'}>{item.text}({item.count})
            </div>
        </Col>
    })

    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
            width: 60
        },
        {
            title: '园区/企业名称',
            dataIndex: 'companyName',
            key: 'companyName'
        },
        {
            title: '账号',
            dataIndex: 'account',
            key: 'account'
        },
        {
            title: '竞赛名称',
            dataIndex: 'prizeEnumName',
            key: 'prizeEnumName',
        },
        {
            title: '申报年份',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: '填写人',
            dataIndex: 'declarant',
            key: 'declarant',
            render: (text: string, list: any) => (
                list.declarant ?
                    <span>{text}<Popover
                        placement="topLeft"
                        content={<>电话:{list.phone}</>}
                        title="联系电话"
                        trigger="hover">
            <PhoneFilled style={{marginLeft: '5px', color: '#1C9A6A'}}/></Popover>
          </span> : ''
            )
        }, {
            title: '提交时间',
            dataIndex: 'createdTs',
            key: 'createdTs',
            render: (text: string, record: any) => (
                <span>{text ? dateFormat(new Date(text), 'YYYY-mm-dd hh:MM:ss') : ''}</span>
            )
        }, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, list: any) => (
                <img onClick={e => downFile(list.files)} style={{cursor: 'pointer'}}
                     src="/assets/images/icon_smallDownload.png"/>

            )
        },

    ]

    const downFile = (value: Array<{ fileId: number, showName: string }>) => {
        let idsArr: Array<string> = [];
        value.forEach((item: any) => {
            idsArr.push(item.fileId);
        });
        const hiade = message.info({
            type: 'loading',
            content: '下载中...!',
            duration: 0,
        });
        setTimeout(hiade, 2500);
        http.downZip('/system/file/zip/download?fileIds=' + idsArr.join(','), '竞赛管理文件申报资料');

    }
    return (
        <>
            <div className="water-effect-assess-container">
                <h1>竞赛管理</h1>
                <Row gutter={16} style={{marginBottom: '12px'}}>
                    <Space>
                        <Col>
                            <div onClick={e => changeStatus('')}
                                 className={prizeEnum === '' ? 'effect-assess-list-status-item-active effect-assess-list-status-item' : 'effect-assess-list-status-item'}>全部状态({alltotal})
                            </div>
                        </Col>
                        {statusCol}
                    </Space>
                    <Col span={6}>
                        <Search value={searchValue} onChange={onChange}
                                onSearch={onSearch} placeholder="搜索账号/园区/企业名称/填表人"
                                style={{width: '100%'}}/>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={dataList}
                    pagination={false}
                    size="small"
                />
                <div className="pagination">
                    <div className="pagination-total">总共{total}条信息</div>
                    <Pagination
                        defaultCurrent={1}
                        total={total}
                        current={currentPage}
                        hideOnSinglePage
                        onChange={changePage}
                    />
                </div>


            </div>
        </>
    )
}

export default AdminManagementTest;