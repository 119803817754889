import React,{ useState, useEffect  } from 'react';
import {Modal,Button} from 'antd';
import './modal-echarts.scss';
import { Column } from '@ant-design/charts';

const ModalEchants = (props:any) =>{
  const [isShow,setShow] = useState(false);
  const [data,setChartData] = useState([]);
  const [title,setTitle] = useState('图表信息');

  useEffect(()=>{
    if(props.isShow){
      setShow(props.isShow);
      setChartData(props.data);
      // setChartData(props.data);
      if(props.title){
        setTitle(props.title);
      }
    }
  },[props.isShow])

  const cancelModal = ()=>{
    setShow(false);
    props.getdata();
  }

  return(
    <>
      <Modal
        title={props.modelTitle}
        centered
        visible={isShow}
        width='55.87rem'
        onCancel={cancelModal}
        onOk={cancelModal}
      >
        <div className="echart-container">
          {data.map((i:any,dataIndex:number)=>{
            return <div key={dataIndex}>{i.name?<div className="echart-tag">{i.name}</div>:''}<div className="chart-container">{i.result.map((k:any,index:number)=>{
              return <div key={index} className="echart-item">
                {chartsItem({data:k.chartsData,titleName:k.tableName,isNaN:k.isNan})}
              </div>
            })}</div></div>
          })}
        </div>
      </Modal>
    </>
  )
}

const chartsItem = (props:{data:any,titleName:string,isNaN:boolean}) => {
  let paletteSemanticRed = '#F4BB16';
  let brandColor = '#497DE2';
  let activeStr = props.data.filter((i:any)=> i.isOwn===true);
  let config = {
    data:props.data,
    xField: 'label',
    yField: 'value',
    colorField:'isOwn',
    color:(isOwn:any)=>{
      if(activeStr && isOwn.label === activeStr[0]?.label){
        return paletteSemanticRed;
      } else {
        return brandColor;
      }
    },
    label: {
      content: function content(originData:any) {
        return originData.value + '%';
      }
    },
    xAxis:{
      label:{
        autoRotate:true,
        style:{
          fontSize:13
        }
      },
      title:{
        text:props.titleName,
        style:{
          fontWeight:'bold' as 'bold',
          fontSize:14
        }
      }
    },
    meta:{
      value:{
        type:'linear',
      }
    },
    tooltip: {
      formatter:(datum:any)=>{
        return {name:datum.label===activeStr[0]?.label?'当前企业':datum.label,value:datum.value+'%'}
      }
    }
    
  };
  return (
    <>{props.isNaN?<><p style={{textAlign:'center',color:'#cb8a08'}}>当前暂无样本数据</p><p>{props.titleName}</p></>:<Column {...config} />}</>
  )
  // return({props.data.isNan?<Column {...config} />:''})
}




export default ModalEchants;