import React from 'react';
import './annualProcess.scss';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { Table, Button, Space, Drawer, Pagination, message,InputNumber } from 'antd';
import Http from '../../../../utils/http';
import AddWork from '../addWork/addWork';
import AddWorkTable from '../addWorkTable/addWorkTable';
// import AddChildTable from '../addChildTable/addChildTable';
import AddWasteHeat from '../../allPlantInformation/addWasteHeat/addWasteHeat';
import { FormInstance } from 'antd/lib/form';
import { adminEditAndRead } from '../../../../utils/utils';

export class AnnualProcess extends React.Component<any,any>{
  editData:any = [];
  formRef: any;
  constructor(props:any){
    super(props);
    this.state = {
      total:10,
      currentPage:1,
      list:[],
      newDrawer:false,
    }
    this.processPage = this.processPage.bind(this);
    this.clickAdd = this.clickAdd.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.getData = this.getData.bind(this);
    this.getDiagnosis = this.getDiagnosis.bind(this);
  }
  createForm =  React.createRef<FormInstance>();
  processPage(){
    Http.get('/diagnosis/process/page',{
      diagnosisId:this.props.id,
      processType:'PRODUCE'
    }).then((res:any)=>{
      if(res&&res.code===200){
        this.setState({
          list:[...res.data]
        })
      }
    })
  }
  clickAdd(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }
  closeDrawer(isRefresh?:boolean){
    this.setState({
      newDrawer: false
    })
    this.processPage();
  }
  setFormRef = (el: any) => this.formRef = el;
  getFormRef(){
    this.formRef.vailForm();
  }
  onSubmitDrawer(){
    this.getFormRef();
  }
  getData(values:any){
    Http.put('/diagnosis/'+this.props.id,values).then((res:any)=>{
      if(res&&res.code===200){
        message.success('修改成功');
        this.getDiagnosis();
      }
    })
  }

  getDiagnosis(){
    Http.get('/diagnosis/'+this.props.id).then((res:any)=>{
      if(res&&res.code===200){
        this.createForm.current?.setFieldsValue({
          successRate:res.data?.successRate,
          fixedEcRate:res.data?.fixedEcRate,
          dyeingEcRate:res.data?.dyeingEcRate,
        })
      }
    })
  }
  componentDidMount(){
    this.processPage();
    this.getDiagnosis();
  }

  render(){
    // const {list}=this.state;
    // let dom = list.map((i:any)=>{
    //   return <div className="annual-process-item" key={i.id}>
    //     <AnnualProcessTableItem id={this.props.id} workData={i} Refresh={this.processPage} isShow={true} />
    //   </div>;
    // })
    return(
      <>
      {/* <div className="annual-process-container">
        <Form
          ref={this.createForm} onFinish={this.getData}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="一次染色成功率(%):" name="successRate">
                <InputNumber min={0} max={100} placeholder="请输入" style={{width:'80%',marginLeft:'20px'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="染色工序能耗占全厂总能耗的比例(%):" name="dyeingEcRate">
                <InputNumber min={0} max={100} placeholder="请输入" style={{width:'80%',marginLeft:'20px'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="定形工序能耗占全厂总能耗的比例(%):" name="fixedEcRate">
                <InputNumber min={0} max={100} placeholder="请输入" style={{width:'80%',marginLeft:'20px'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Button className="green-button" htmlType="submit" type="primary">保存</Button>
            </Col>
          </Row>
        </Form>
        <div className="annual-process-body">
          <div className="system-boundary-item-head-name">
            生产设备<PlusSquareOutlined onClick={this.clickAdd} style={{fontSize:'24px',marginLeft:'15px'}} className="icon-style" />
          </div>
          {dom}
          <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>
            </div>
          }
        >
          <AddWork 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id} 
            bindRef={this.setFormRef}
          ></AddWork>
        </Drawer>
        </div>
      </div> */}
      <div className="annual-process-container">
        <AnnualProcessType type="MOTORCOMMON" id={this.props.id}></AnnualProcessType>
      </div>
      <div className="annual-process-container">
        <AnnualProcessType type="MOTORCUSTOM" id={this.props.id}></AnnualProcessType>
      </div>
      <div className="annual-process-container">
        <AnnualProcessType type="HEAT" id={this.props.id}></AnnualProcessType>
      </div>
      </>
    )
  }
}

export class AnnualProcessTableItem extends React.Component<any,any>{
  dataList = [];
  editData:any = [];
  formRef: any;
  formChildRef: any;
  formChildTableRef: any;
  constructor(props:any){
    super(props);
    this.state={
      newDrawer: false,
      newChildDrawer:false,
      newChildTableDrawer:false,
      tabData:[],
      total:10,
      childTotal:10,
      currentPage:1,
      currentChildPage:1,
      expandedId:'',
      readAndEdit:true
    };
    this.expandTable = this.expandTable.bind(this);
    this.getChildFormRef = this.getChildFormRef.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.onSubmitChildDrawer = this.onSubmitChildDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.editChild = this.editChild.bind(this);
    // this.editChildTable = this.editChildTable.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.ecPage = this.ecPage.bind(this);
    this.clickChild = this.clickChild.bind(this);
    this.closeChildDrawer = this.closeChildDrawer.bind(this);
    this.ecChildPage = this.ecChildPage.bind(this);
    // this.onSubmitChildTableDrawer = this.onSubmitChildTableDrawer.bind(this);
    this.getChildFormTableRef = this.getChildFormTableRef.bind(this);
    this.closeChildTableDrawer = this.closeChildTableDrawer.bind(this);
    this.clickChildTable = this.clickChildTable.bind(this);
    this.deleteProcess = this.deleteProcess.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
  }
  setFormRef = (el: any) => this.formRef = el;
  setChildFormRef = (el: any) => this.formChildRef = el;
  setChildTableFormRef = (el: any) => this.formChildTableRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }
  getChildFormRef(){
    this.formChildRef.vailForm();
  }
  getChildFormTableRef(){
    this.formChildTableRef.vailForm();
  }

  deleteProcess(value:any){
    Http.DELETE('/diagnosis/process/'+value).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功');
        this.props.Refresh();
      }
    })
  }

  onSubmitDrawer(){
    this.getFormRef();
  }
  onSubmitChildDrawer(){
    this.getChildFormRef();
  }
  // onSubmitChildTableDrawer(){
  //   this.getChildFormTableRef();
  // }

  edit(){
    this.editData = this.props.workData;
    this.setState({
      newDrawer: true
    })
  }
  editChild(data?:any){
    this.editData = data;
    this.setState({
      newChildDrawer: true
    })
  }
  // editChildTable(data?:any){
  //   this.editData = data;
  //   this.setState({
  //     newChildTableDrawer: true
  //   })
  // }
  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.props.Refresh();
    };
    this.setState({
      newDrawer: false
    })
  }

  closeChildDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.ecPage();
    };
    this.setState({
      newChildDrawer: false
    })
  }

  closeChildTableDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.ecChildPage();
    };
    this.setState({
      newChildTableDrawer: false
    })
  }
  expandTable(expanded:boolean,data:any){
    if(expanded){
      this.setState({
        expandedId:data.id
      },()=>{
        this.ecChildPage();
      });
    }
  }
  clickChild(){
    this.editData = [];
    this.setState({
      newChildDrawer:true
    })
  }
  clickChildTable(){
    this.editData = [];
    this.setState({
      newChildTableDrawer:true
    })
  }
  deleteRow(data:any){
    Http.DELETE('diagnosis/process/ec/'+data.id).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功');
        this.ecPage();
      }
    })
  }
  // deleteDetailRow(data:any){
  //   Http.DELETE('diagnosis/process/ec/detail/'+data.id).then((res:any)=>{
  //     if(res&&res.code===200){
  //       message.success('删除成功');
  //       this.ecChildPage();
  //     }
  //   })
  // }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    this.ecPage();
  }
  ecChildPage(){
    let {currentChildPage,expandedId} = this.state;
    Http.get('/diagnosis/process/ec/detail/page',{
      diagnosisProcessEcId:expandedId,
      pageNum:currentChildPage,
      pageSize:10
    }).then((res:any)=>{
      res?.data?.dataList.forEach((i:any,index:number)=>{
        i.key = index + 1;
      });
      let {tabData} = this.state
      tabData.forEach((element:any)=>{
        if(element.id === expandedId){
          element.tabChildData = res.data.dataList;
          element.childTotal = res.data.count;
        }
      })
      this.setState({
        tabData:tabData
      })
    })
  }
  ecPage(){
    let {currentPage} = this.state;
    Http.get('/diagnosis/process/ec/page',{
      diagnosisId:this.props.id,
      diagnosisProcessId:this.props.workData.id,
      pageNum:currentPage,
      pageSize:10
    }).then((res:any)=>{
      res.data.dataList.forEach((i:any,index:number)=>{
        i.key = index + 1;
        i.tabChildData = [];
        i.childTotal = 100
      });
      this.setState({
        tabData:res.data.dataList,
        total:res.data.count
      })
    })
  }
  render(){
    const workingProcedureCol = [
      {
        title: '重点用能系统',
        dataIndex: 'energyCosName',
        key: 'energyCosName',
      },{
        title:'主要用能设备',
        dataIndex:'processDevice',
        key:'processDevice'
      },{
        title:'台数',
        dataIndex:'deviceNum',
        key:'deviceNum'
      },{
        title:'能源种类',
        dataIndex:'energyName',
        key:'energyName'
      },{
        title:'年消耗量',
        dataIndex:'cosNum',
        key:'cosNum'
      },{
        title:'计量单位',
        dataIndex:'unit',
        key:'unit'
      },{
        title:'备注',
        dataIndex:'comment',
        key:'comment'
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.editChild(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.deleteRow(record)}>删除</span>:''}
            
          </Space>
        ),
        width:150
      }
    ];
    const workData = this.state.tabData;
    let { readAndEdit } = this.state;
    // const {expandedId} = this.state;
    // const childColumns = [
    //   {
    //     title: '设备类型',
    //     dataIndex: 'deviceType',
    //     key: 'deviceType',
    //   },{
    //     title:'台数',
    //     dataIndex:'deviceNum',
    //     key:'deviceNum'
    //   },{
    //     title:'能源种类',
    //     dataIndex:'energyName',
    //     key:'energyName'
    //   },{
    //     title:'年消耗量',
    //     dataIndex:'cosNum',
    //     key:'cosNum'
    //   },{
    //     title:'计量单位',
    //     dataIndex:'unit',
    //     key:'unit'
    //   },{
    //     title:'备注',
    //     dataIndex:'comment',
    //     key:'comment'
    //   },{
    //     title: '操作',
    //     key: 'action',
    //     render: (text:any, record:any) => (
    //       <Space size="middle">
    //         <span className="green-table-a" onClick={e=>this.editChildTable(record)}>编辑</span>
    //         <span className="green-table-a" onClick={e=>this.deleteDetailRow(record)}>删除</span>
    //       </Space>
    //     ),
    //     width:150
    //   }
    // ];
    return(
      <div className="annual-process-table-container">
        {this.props.isShow?<><div className="annual-process-table-head">
          <div className="annual-process-table-title">
            <div>{this.props.workData?.processName + ' - ' + this.props.workData?.productName + ' - ' + this.props.workData?.num + this.props.workData?.unit}</div>
            <div>
              <EditOutlined onClick={this.edit} style={{marginRight:'10px'}} className="icon-style" />
              <DeleteOutlined onClick={e=>this.deleteProcess(this.props.workData.id)} className="icon-style" />
            </div>
          </div>
          <div className="annual-process-table-newadd">
            {readAndEdit?<Button className="green-button" onClick={this.clickChild}>新增</Button>:''}
            
          </div>
        </div></>:''}
        <Table
          columns={workingProcedureCol}
          dataSource={workData}
          pagination={false}
          size="small"
          // expandable={{
          //   onExpand:(expanded,record)=>this.expandTable(expanded,record),
          //   expandedRowRender:(record,index)=><Table columns={childColumns} size="small" dataSource={record.tabChildData} summary={() => (
          //     <Table.Summary.Row>
          //       <Table.Summary.Cell className="green-table-a" index={0}><span onClick={this.clickChildTable}>+ 新增</span></Table.Summary.Cell>
          //     </Table.Summary.Row>
          //   )} />
          // }}
        />
        <Drawer 
          title={this.editData.id?'修改工序边界':'新增工序边界'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddWork 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id} 
            bindRef={this.setFormRef}
          ></AddWork>
        </Drawer>
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeChildDrawer()}
          destroyOnClose={true}
          visible={this.state.newChildDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeChildDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitChildDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddWorkTable 
            closeDrawer={this.closeChildDrawer} 
            editData={this.editData} 
            id={this.props.id}
            workId={this.props.workData.id}
            processType="PRODUCE"
            bindRef={this.setChildFormRef}
          ></AddWorkTable>
        </Drawer>
        {/* <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeChildTableDrawer()}
          destroyOnClose={true}
          visible={this.state.newChildTableDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeChildTableDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.onSubmitChildTableDrawer} type="primary">
                确定
              </Button>
            </div>
          }
        >
          <AddChildTable 
            closeDrawer={this.closeChildTableDrawer} 
            editData={this.editData} 
            id={this.props.id}
            workId={this.props.workData.id}
            expandedId = {expandedId}
            bindRef={this.setChildTableFormRef}
          ></AddChildTable>
        </Drawer> */}
        {this.props.isShow?<>
          <AnnualProcessWasteHeat id={this.props.id} workId={this.props.workData.id} herRate={this.props.workData.herRate}></AnnualProcessWasteHeat>
        </>:''}
        
      </div>
    )
  }
}

export class AnnualProcessType extends React.Component<any,any>{
  editData:any = [];
  formRef: any;
  constructor(props:any){
    super(props);
    this.state={
      workId:'',
      herRate:0,
      list:[],
      readAndEdit:true
    }
    this.processPage = this.processPage.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.clickChild = this.clickChild.bind(this);
  }

  setFormRef = (el: any) => this.formRef = el;
  getFormRef(){
    this.formRef.vailForm();
  }
  processPage(){
    this.setState({
      list:[],
      workId:'',
    })
    Http.get('/diagnosis/process/page',{
      diagnosisId:this.props.id,
      processType:this.props.type
    }).then((res:any)=>{
      if(res&&res.code===200){
        this.setState({
          list:[...res.data],
          workId:res.data[0]?.id,
          herRate:res.data[0]?.herRate
        })
      }
    })
  }

  onSubmitDrawer(){
    this.getFormRef();
  }

  edit(data?:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }
  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.processPage();
    };
    this.setState({
      newDrawer: false
    })
  }
  clickChild(){
    this.editData = [];
    this.setState({
      newDrawer:true
    })
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    this.processPage();
  }
  render(){
    const {herRate,workId,list,readAndEdit} = this.state;
    let dom = list.map((i:any)=>{
      return <div className="annual-process-item" key={i.id}>
        <AnnualProcessTableItem id={this.props.id} workData={i} Refresh={this.processPage} />
      </div>;
    })
    return(
      <div className="waste-heat-container">
        <div className="waste-heat-head">
          <div style={{fontWeight:'bold',fontSize:'14px'}}>
            {this.props.type==="MOTORCOMMON"?"电动及拖动设备(通用)":this.props.type==="MOTORCUSTOM"?"电动及拖动设备(专用)":"锅炉及加热锅炉设备"}
          </div>
          <div>
            {readAndEdit?<Button className="green-button" onClick={this.clickChild}>新增</Button>:''}
            
          </div>
        </div>
        {dom}
        <Drawer 
          title={this.editData?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddWorkTable 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id}
            workId={workId}
            processType={this.props.type}
            bindRef={this.setFormRef}
          ></AddWorkTable>
        </Drawer>
        {workId?<><AnnualProcessWasteHeat id={this.props.id} workId={workId} herRate={herRate}></AnnualProcessWasteHeat></>:''}
      </div>
    )
  }
}

export class AnnualProcessWasteHeat extends React.Component<any, any>{
  editData:any = [];
  formRef: any;
  constructor(props:any){
    super(props);
    this.state = {
      tabData:[],
      newDrawer: false,
      total:10,
      currentPage:1,
      herRate:0,
      readAndEdit:true
    };
    this.ecPage = this.ecPage.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeHerRate = this.changeHerRate.bind(this);
    this.modifyHerRate = this.modifyHerRate.bind(this);
  }

  componentWillReceiveProps(nextProps:any) {
    if(nextProps.herRate !== this.props.herRateherRate) {
      this.setState({herRate: nextProps.herRate});
    }
    if(nextProps.workId !== this.props.workId){
      this.ecPage();
    }
  }

  setFormRef = (el: any) => this.formRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }

  onSubmitDrawer(){
    this.getFormRef();
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  changePage(page:any) {
    this.setState({
      currentPage:page
    });
    this.ecPage();
  }

  ecPage(){
    let {currentPage} = this.state;
    Http.get('/diagnosis/her/page',{
      diagnosisId:this.props.id,
      diagnosisProcessId:this.props.workId,
      pageNum:currentPage,
      pageSize:10,
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key=index;
        });
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count,
          herRate:this.props.herRate
        })
      }
    })
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.ecPage();
    };
    this.setState({
      newDrawer: false
    })
  }

  changeHerRate(e:any){
    this.setState({
      herRate:e.target.value
    },()=>{
      this.modifyHerRate();
    });
  }

  modifyHerRate(){
    Http.put('/diagnosis/process/'+this.props.workId+'/herRate',{
      herRate:this.state.herRate
    }).then((res:any)=>{
      if(res&&res.code===200){
        message.success('修改成功');
      }
    })
  }
  componentDidMount(){
    this.ecPage();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }

  render(){
    const workingProcedureCol = [
      {
        title: '项目名称',
        dataIndex: 'name',
        key: 'name',
      },{
        title:'余热余能回收量（GJ）',
        dataIndex:'num',
        key:'num'
      },{
        title:'备注',
        dataIndex:'remark',
        key:'remark'
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any) => (
          <Space size="middle">
            <span className="green-table-a"  onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a">删除</span>:''}
            
          </Space>
        ),
        width:150
      }
    ];
    const {currentPage,total,tabData,readAndEdit} = this.state;
    return(
      <div className="waste-heat-container">
        <p style={{color:'#737373',fontWeight: 'bold',fontSize:'14px'}}>余热余能回收</p>
        <div className="waste-heat-head">
          <div>
            余热余能回收率：<InputNumber disabled={!readAndEdit} min={0} max={100} defaultValue={this.props.herRate} style={{width:'160px'}} onBlur={e=>this.changeHerRate(e)} />%
          </div>
          <div>
            {readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''}
            
          </div>
        </div>
        <Table
          columns={workingProcedureCol}
          dataSource={tabData}
          pagination={false}
          size="small"
        />
        <div className="pagination">
          <div className="pagination-total">总共{tabData.length}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
        </div>
          <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddWasteHeat 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id}
            diagnosisProcessId={this.props.workId}
            type={0}
            bindRef={this.setFormRef}
          ></AddWasteHeat>
        </Drawer>
        
      </div>
    )
  }
}

export default AnnualProcess;