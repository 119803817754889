import React from 'react';
import './user-info.scss';
import {Anchor, Divider, Form, Input, Button, Upload, message, Cascader, Select, TreeSelect, Popover} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import Province from '../../utils/ssq.json';
import {FormInstance} from 'antd/lib/form';
import Http from '../../utils/http';
import cookie from 'react-cookies';
import {navigate} from '@reach/router';
import store from '../../utils/store';
import {OPENIMG} from '../../utils/actions';
import {verificationType} from '../../utils/utils';

class UserInfo extends React.Component<any, any> {
    province: any;
    upLoad: any;
    industryList: any;
    selectCode: any[] = [];
    isHidden: boolean = false;
    enter = React.createRef<FormInstance>();
    contacts = React.createRef<FormInstance>();
    password = React.createRef<FormInstance>();

    constructor(props: any) {
        super(props);
        let arr: any = [];
        Province.forEach(i => {
            let option = {
                value: i.name,
                label: i.name,
                children: this.provicePush(i.children)
            }
            arr.push(option);
        })
        this.province = arr;

        this.state = {
            fileList: [],
            email: '',
            roleId: 1,
            industryList: [],
            selectCode: [],
            isHidden: false
        };
        this.upLoad = {
            beforeUpload: (file: any) => {
                if (verificationType(file.type)) {
                    this.fileUpload(file);
                } else {
                    message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
                }
                return false;
            },
            onRemove: (file: { uid: any; }) => {
                let {fileList} = this.state;
                fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
                this.setState({fileList});
                return false;
            },
            onPreview: (file: any) => {
                store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
                return false;
            }
        };
        this.getInfo = this.getInfo.bind(this);
        this.getEnterData = this.getEnterData.bind(this);
        this.getContactData = this.getContactData.bind(this);
        this.getIndustryList = this.getIndustryList.bind(this);
    }

    fileUpload(file: any) {
        const formData = new FormData();
        formData.append('file', file);
        Http.post('/system/file/upload/auth', formData).then((res: any) => {
            if (res && res.code === 200) {
                let img = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    id: res.data,
                    url: ''
                };
                this.setState({
                    fileList: [img]
                })
            }

        })
    }

    provicePush(arr: any) {
        let returnArr: any = [];
        let option: any;
        arr.forEach((element: any) => {
            if (element.children) {
                option = {
                    value: element.name,
                    label: element.name,
                    children: this.provicePush(element.children)
                };
                returnArr.push(option);
            } else {
                option = {
                    value: element.name,
                    label: element.name
                };
                returnArr.push(option);
            }
        });
        return returnArr;
    }

    getInfo() {
        Http.get('/admin/info/self').then((res: any) => {
            if (res && res.code === 200) {
                console.log(res.data)

                if (res.data) {
                    this.setState({
                        email: res.data?.username,
                        roleId: res.data.roleId
                    });
                    if (res.data?.licenseFile) {
                        let img = {
                            uid: '1',
                            name: res.data.licenseFile.showName,
                            status: 'done',
                            id: res.data.licenseFile.fileId,
                            url: ''
                        };
                        this.setState({
                            fileList: [img]
                        });
                    }
                    this.enter.current?.setFieldsValue({
                        name: res.data.name,
                        company: res.data.company,
                        tax: res.data.tax,
                        region: [res.data.province, res.data.city, res.data.district],
                        industry: res.data.industry
                    });
                    if (res.data.province === null || res.data.city === null || res.data.district === null) {
                        this.enter.current?.setFieldsValue({
                            region: []
                        })
                    }
                    this.contacts.current?.setFieldsValue({
                        contact: res.data.contact,
                        idno: res.data.idno,
                        email: res.data.email,
                        phone: res.data.phone,
                        tel: res.data.tel
                    });
                    if (res.data.industryCodes) {
                        this.enter.current?.setFieldsValue({
                            industryCodes: res.data?.industryCodes ? res.data.industryCodes : []
                        });
                    }
                    if (res.data.roleId === 2) {
                        this.isHidden = true;
                    }
                }

            }
        })
    }

    getEnterData(value: any) {
        if (value.region) {
            value.province = value.region[0];
            value.city = value.region[1];
            value.district = value.region[2];
        }
        let {fileList} = this.state;
        if (fileList.length >= 1) {
            value.licenseFile = {
                fileId: fileList[0].id,
                showName: fileList[0].name
            }
        } else {
            value.licenseFile = {};
        }
        Http.put('/admin/info/info', value).then((res: any) => {
            if (res && res.code === 200) {
                message.success('修改成功');
                if (this.props.path.indexOf('user/*') !== -1) {
                    this.getInfo()
                } else {
                    this.props.refresh();
                }
            }
        })
    }

    getContactData(value: any) {
        Http.put('/admin/info/info', value).then((res: any) => {
            if (res && res.code === 200) {
                message.success('修改成功');
                if (this.props.path.indexOf('user/*') !== -1) {
                    this.getInfo()
                } else {
                    this.props.refresh();
                }
            }
        })
    }

    getPasswordData(value: any) {
        if (value.newPassword !== value.confirmPassword) {
            message.error('两次密码不一致，请重新输入');
            return false;
        } else {
            delete value.confirmPassword;
            Http.put('/admin/password', value).then((res: any) => {
                if (res && res.code === 200) {
                    message.success('密码修改成功，请重新登录');
                    cookie.remove('token');
                    cookie.remove('username');
                    navigate('/');
                }
            })
        }
    }

    getIndustryList() {
        Http.get('/apiWaterUrl/assess/industry/list').then((res: any) => {
            if (res && res.code === 200) {
                res.data.forEach((i: any, number: any) => {
                    i.key = i.code;
                })
                let arr: any[] = res.data;
                this._forEachIndustryList(arr);
                this.setState({
                    industryList: arr
                });
            }
        })
    }

    _forEachIndustryList(item: any) {
        item.forEach((i: any, index: any) => {
            i.key = i.code;
            i.label = i.codeName;
            i.value = i.code;
            if (i.children && i.children.length > 0) {
                this._forEachIndustryList(i.children);
                i.isLeaf = false;
            } else {
                i.isLeaf = true;
            }
        })
    }

    componentDidMount() {
        this.getInfo();
        this.getIndustryList();
    }

    render() {
        const {Link} = Anchor;
        const {Option} = Select;
        const {TreeNode} = TreeSelect;
        const formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 8},
        };
        const {fileList, email, roleId, industryList} = this.state;
        const getCurrentAnchor = () => {
            return '#enter';
        };
        return (
            <div className="user-info">
                <div className="user-info-container">
                    <div className="user-info-container-item" id="enter">
                        <h2><b>个人信息设置</b></h2>
                        <p><b>基本信息</b></p>
                        <Divider/>
                        <Form
                            {...formItemLayout}
                            ref={this.enter}
                            name="enter"
                            onFinish={this.getEnterData}
                        >
                            <Form.Item
                                label="用户姓名"
                                name="name"
                                rules={[{required: true}]}
                            >
                                <Input placeholder="请输入"/>
                            </Form.Item>

                            <Form.Item
                                label="用户账号"
                            ><span>{email}</span>
                            </Form.Item>
                            {roleId == 2 ? <>
                                <Form.Item
                                    label="所在机构"
                                    name="company"
                                >
                                    <Input placeholder="请输入"/>
                                </Form.Item>
                                <Form.Item
                                    label="统一社会信用代码(18位)"
                                    name="tax"
                                    rules={[
                                        {
                                            pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
                                            message: '统一社会信用代码格式不正确'
                                        }]}
                                >
                                    <Input placeholder="请输入"/>
                                </Form.Item>
                            </> : <>
                                <Form.Item
                                    label="所在机构"
                                    name="company"
                                    rules={[{required: true, message: '请输入机构名称'}]}
                                >
                                    <Input placeholder="请输入"/>
                                </Form.Item>
                                <Form.Item
                                    label="统一社会信用代码(18位)"
                                    name="tax"
                                    rules={roleId === 3 ? [{required: false}] : [
                                        {
                                            pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
                                            message: '统一社会信用代码格式不正确'
                                        },
                                        {required: true, message: '请输入18位社会信用代码'}]}
                                >
                                    <Input placeholder="请输入"/>
                                </Form.Item>
                                <Form.Item
                                    label="营业执照"
                                >
                                    <Upload {...this.upLoad} fileList={fileList}>
                                        <Button style={{width: '100%'}}>
                                            <UploadOutlined/>点击上传
                                        </Button>
                                    </Upload>
                                </Form.Item>

                                <Form.Item
                                    label="所在地区"
                                    name="region"
                                    rules={[{required: true, message: '请选择所在地区'}]}
                                >
                                    <Cascader options={this.province} placeholder="请选择区域"/>
                                </Form.Item>

                                {this.isHidden ? '' :
                                    <Form.Item
                                        label="所属行业"
                                        name="industryCodes"
                                        rules={roleId === 3 ? [{
                                            required: false,

                                        }] : [{required: true, message: '请选择所属行业'}]}
                                    >
                                        <TreeSelect
                                            showSearch
                                            style={{width: '100%'}}
                                            value={this.selectCode}
                                            dropdownStyle={{maxHeight: 400, overflow: 'auto', zIndex: 1000}}
                                            placeholder="请选择"
                                            allowClear
                                            multiple
                                            treeDefaultExpandAll
                                            filterTreeNode
                                        >
                                            {
                                                industryList.map((nodes: any) =>
                                                    <TreeNode value={nodes.code} title={nodes.codeName} key={nodes.code}
                                                              selectable={nodes.isLeaf}>
                                                        {nodes.children && nodes.children.length > 0 ?
                                                            nodes.children.map((node: any) =>
                                                                <TreeNode value={node.code} title={node.codeName}
                                                                          key={node.code} selectable={node.isLeaf}>
                                                                    {node.children && node.children.length > 0 ?
                                                                        node.children.map((i: any) =>
                                                                            <TreeNode value={i.code} title={
                                                                                <Popover style={{marginLeft: '20px'}}
                                                                                         content={
                                                                                             <div
                                                                                                 className="popover-container">
                                                                                                 <div
                                                                                                     className="popover-item">
                                                                                                     <div
                                                                                                         className="popover-item-title">
                                                                                                         <img
                                                                                                             src="/assets/images/icon_description_normal.png"
                                                                                                             alt=""/>
                                                                                                         <span
                                                                                                             style={{paddingLeft: '8px'}}>说明</span>
                                                                                                     </div>
                                                                                                     <div
                                                                                                         className="popover-item-content">
                                                                                                         <span>{i.explain}</span>
                                                                                                     </div>
                                                                                                 </div>
                                                                                                 <div
                                                                                                     className="popover-item">
                                                                                                     <div
                                                                                                         className="popover-item-title">
                                                                                                         <img
                                                                                                             src="/assets/images/icon_productType_normal.png"
                                                                                                             alt=""/>
                                                                                                         <span
                                                                                                             style={{paddingLeft: '8px'}}>产品种类</span>
                                                                                                     </div>
                                                                                                     <div
                                                                                                         className="popover-item-content">
                                                                                                         <span>{i.product}</span>
                                                                                                     </div>
                                                                                                 </div>
                                                                                             </div>
                                                                                         } title={null} trigger="hover"
                                                                                         placement="rightTop">
                                                                                <span
                                                                                    className="popover-content-span">{i.code}<span
                                                                                    style={{paddingLeft: '8px'}}>{i.codeName}</span></span>
                                                                                </Popover>

                                                                            } key={i.code} selectable={i.isLeaf}/>
                                                                        ) : ''
                                                                    }
                                                                </TreeNode>
                                                            ) : ''
                                                        }
                                                    </TreeNode>
                                                )
                                            }
                                        </TreeSelect>
                                    </Form.Item>}


                            </>}
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-col-6 ant-form-item-label"></div>
                                <div className="ant-col ant-col-2 ant-form-item-control">
                                    <Button type="primary" htmlType="submit"
                                            style={{backgroundColor: '#008552', borderColor: '#008552'}}>保存</Button>
                                </div>
                            </div>
                        </Form>
                    </div>

                    <div className="user-info-container-item" id="contacts">
                        <p><b>联系人信息</b></p>
                        <Divider/>
                        <Form
                            {...formItemLayout}
                            ref={this.contacts}
                            name="contacts"
                            onFinish={this.getContactData}
                        >
                            {
                                roleId !== 2 ? <Form.Item
                                    label="联系人姓名"
                                    name="contact"
                                >
                                    <Input placeholder="请输入"/>
                                </Form.Item> : ''
                            }

                            <Form.Item
                                label="身份证号"
                                name="idno"
                            >
                                <Input placeholder="请输入"/>
                            </Form.Item>
                            <Form.Item
                                label="联系邮箱"
                                name="email"
                                rules={[
                                    {
                                        pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                                        message: '邮箱格式不正确'
                                    },
                                    {required: true, message: '请输入联系邮箱'}]}
                            >
                                <Input placeholder="请输入"/>
                            </Form.Item>
                            <Form.Item
                                className="login-container-item"
                                label="手机"
                                name="phone"
                            >
                                <Input placeholder="请输入"/>
                            </Form.Item>
                            <Form.Item
                                className="login-container-item"
                                label="电话"
                                name="tel"
                            >
                                <Input placeholder="请输入"/>
                            </Form.Item>
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-col-6 ant-form-item-label"></div>
                                <div className="ant-col ant-col-2 ant-form-item-control">
                                    <Button type="primary" htmlType="submit"
                                            style={{backgroundColor: '#008552', borderColor: '#008552'}}>保存</Button>
                                </div>
                            </div>
                        </Form>
                    </div>

                    <div className="user-info-container-item" id="password">
                        <p><b>密码管理</b></p>
                        <Divider/>
                        <Form
                            {...formItemLayout}
                            ref={this.password}
                            name="password"
                            onFinish={this.getPasswordData}
                        >
                            <Form.Item
                                label="原始密码"
                                name="oldPassword"
                                rules={[{required: true}]}
                            >
                                <Input type="password" placeholder="请输入"/>
                            </Form.Item>
                            <Form.Item
                                label="新密码"
                                name="newPassword"
                                rules={[{
                                    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
                                    message: '密码必须为6-12位数字和字母组合'
                                }, {required: true}]}
                            >
                                <Input type="password" placeholder="请输入"/>
                            </Form.Item>
                            <Form.Item
                                label="确认密码"
                                name="confirmPassword"
                                rules={[{
                                    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
                                    message: '密码必须为6-12位数字和字母组合'
                                }, {required: true}]}
                            >
                                <Input type="password" placeholder="请输入"/>
                            </Form.Item>
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-col-6 ant-form-item-label"></div>
                                <div className="ant-col ant-col-2 ant-form-item-control">
                                    <Button type="primary" htmlType="submit"
                                            style={{backgroundColor: '#008552', borderColor: '#008552'}}>保存</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="user-info-nav">
                    <Anchor getCurrentAnchor={getCurrentAnchor}>
                        <Link href="#enter" title="基本信息"/>
                        <Link href="#contacts" title="联系人信息"/>
                        <Link href="#password" title="密码管理"/>
                    </Anchor>
                </div>
            </div>
        )
    }
}

export default UserInfo;