import React from 'react';
import './resultEnergyConsumption.scss';
import { Table } from 'antd';
import Http from '../../../../utils/http';
export class ResultsEnergyConsumption extends React.Component<any,any>{

  constructor(props:any){
    super(props);
    this.state = {
      dataList:{
        energyTableData:[],
        unitOutputValueData:[],
        energyProductsData:[],
        productPowerConsumptionData:[],
        yieEnergyData:[],
        annualEnergyWorkData:[],
        processUnitData:[],
        diagnosisProcessData_DYEING:[],
        diagnosisProcessData_FIXED:[]
      }
    };
    this.calculationList = this.calculationList.bind(this);
  }

  calculationList(){
    Http.get('/diagnosis/calculation/result/'+this.props.id).then((res:any)=>{
      if(res&&res.code===200&&res.data){
        let {dataList} = this.state;
        res.data.ecList.forEach((i:any,index:number)=>{
          i.key = index + 1;
        });
        dataList.energyTableData = res.data.ecList;

        res.data.uecList.forEach((i:any,index:number)=>{
          i.key = index + 1;
        });
        dataList.unitOutputValueData = res.data.uecList;

        res.data.cecList.forEach((i:any,index:number)=>{
          i.key = index + 1;
        });
        dataList.energyProductsData = res.data.cecList;

        res.data.productList.forEach((i:any,index:number)=>{
          i.key = index + 1;
        });
        dataList.productPowerConsumptionData = res.data.productList;

        res.data.processEcList.forEach((i:any,index:number)=>{
          i.key = index + 1;
        });
        dataList.processUnitData = res.data.processEcList;

        res.data.dfpEcsMap['DYEING'].forEach((i:any,index:number)=>{
          i.key = index + 1;
        });
        dataList.diagnosisProcessData_DYEING = res.data.dfpEcsMap['DYEING'];

        res.data.dfpEcsMap['FIXED'].forEach((i:any,index:number)=>{
          i.key = index + 1;
        });
        dataList.diagnosisProcessData_FIXED = res.data.dfpEcsMap['FIXED'];

        res.data.ecsMap['EC'].forEach((i:any)=>{
          i.items.forEach((k:any,index:number)=>{
            k.key = index + 1;
          })
          
        });
        dataList.yieEnergyData = res.data.ecsMap['EC'];

        res.data.ecsMap['WC'].forEach((i:any)=>{
          i.items.forEach((k:any,index:number)=>{
            k.key = index + 1;
          })
        });
        dataList.annualEnergyWorkData = res.data.ecsMap['WC'];

        this.setState({
          dataList:dataList
        });
      }
    })
  }
  componentDidMount(){
    this.calculationList();
  }

  render(){
    const energyTableCol = [
      {
        title: '项目',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '数量',
        dataIndex: 'value',
        key: 'value',
      },
      {
        title: '单位',
        dataIndex: 'unit',
        key: 'unit',
      }
    ];
    const unitYieldCol = [
      {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '年生产量',
        dataIndex: 'yield',
        key: 'yield',
      },
      {
        title: '计量单位',
        dataIndex: 'unit',
        key: 'unit',
      },
      {
        title: '综合能耗（kgce）',
        dataIndex: 'enNum',
        key: 'enNum',
      },
      {
        title: '单位产量综合能耗（单位）',
        dataIndex: 'uenNum',
        key: 'uenNum',
        render:(text:string,record:any)=>(
          <span>{text} kgce/{record.unit}</span>
          )
      },
      {
        title: '单位产量可比综合能耗（kgce/t）',
        dataIndex: 'uenCoNum',
        key: 'uenCoNum',
      }
    ];
    const electricUnitYieldCol = [
      {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: '产品名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '种类',
        dataIndex: 'attr1',
        key: 'attr1',
        render:(text:string,record:any)=>(
          <span>{text?text + '-' + record.attr2 + '-' + record.attr3:'其他'}</span>
        )
      },
      {
        title: '后整理工序',
        dataIndex: 'processName',
        key: 'processName',
        render:(text:string,record:any)=>(
          <span>{record.processName.join('-')}</span>
        )
      },
      {
        title: '年生产量',
        dataIndex: 'yield',
        key: 'yield',
      },
      {
        title: '计量单位',
        dataIndex: 'unit',
        key: 'unit',
      },
      {
        title: '标准品产量(t)',
        dataIndex: 'markYield',
        key: 'markYield',
      },
      // {
      //   title: '综合电耗（kWh）',
      //   dataIndex: 'elNum',
      //   key: 'elNum',
      // },
      // {
      //   title: '单位产量综合电耗（单位）',
      //   dataIndex: 'uelNum',
      //   key: 'uelNum',
      //   render:(text:string,record:any)=>(
      //   <span>{text} kWh/{record.unit}</span>
      //   )
      // },
      // {
      //   title: '单位产量可比综合电耗（kWh/t）',
      //   dataIndex: 'uelCoNum',
      //   key: 'uelCoNum',
      // }
    ];
    const yieEnergy = [
      {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '消费量',
        dataIndex: 'num',
        key: 'num',
      },
      {
        title: '计量单位',
        dataIndex: 'unit',
        key: 'unit',
      },
      {
        title: '折标准煤系数',
        dataIndex: 'coefficient',
        key: 'coefficient',
      },
      {
        title: '系数单位',
        dataIndex: 'coeUnit',
        key: 'coeUnit',
        render:(text:string)=>(
          <span>kgce/{text}</span>
        )
      },
      {
        title: '折标准煤（kgce)',
        dataIndex: 'kgce',
        key: 'kgce',
      }
    ];
    const processUnit = [
      {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: '主要用能设备',
        dataIndex: 'processDevice',
        key: 'processDevice',
      },
      {
        title: '台数',
        dataIndex: 'deviceNum',
        key: 'deviceNum',
      },
      {
        title: '能源种类',
        dataIndex: 'energyName',
        key: 'energyName',
      },
      {
        title: '年消耗量',
        dataIndex: 'cosNum',
        key: 'cosNum',
      },
      {
        title: '计量单位',
        dataIndex: 'unit',
        key: 'unit',
      },
      {
        title: '综合能耗（kgce）',
        dataIndex: 'ec',
        key: 'ec',
      }
    ];
    const processEnergy = [
      {
        title: '批次序号',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: '产品名称',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: '产量',
        dataIndex: 'yield',
        key: 'yield',
      },
      {
        title: '单位',
        dataIndex: 'unit',
        key: 'unit',
      },
      {
        title: '标准品产量（t）',
        dataIndex: 'calculationStandardYield',
        key: 'calculationStandardYield',
      },
      {
        title: '综合能耗（kgce）',
        dataIndex: 'ec',
        key: 'ec',
      },
      {
        title: '单位产品综合能耗(kgce/t)',
        dataIndex: 'uec',
        key: 'uec',
      }
      
    ];
    const {dataList} = this.state;
    return(
      <div className="result-table-container">
        <div className="result-table-item">
          <h3>一、年度综合能源消费量</h3>
          <Table size="small" columns={energyTableCol} dataSource={dataList.energyTableData} pagination={false} bordered></Table>
        </div>
        <div className="result-table-item">
          <h3>二、单位产值综合能耗 </h3>
          <Table size="small" columns={energyTableCol} dataSource={dataList.unitOutputValueData} pagination={false} bordered></Table>
        </div>
        <div className="result-table-item">
          <h3>三、单位产品综合能耗 </h3>
          <Table size="small" columns={energyTableCol} dataSource={dataList.energyProductsData} pagination={false} bordered></Table>
        </div>
        <div className="result-table-item">
          <h3>四、年度各产品生产量 </h3>
          <Table size="small" columns={electricUnitYieldCol} dataSource={dataList.productPowerConsumptionData} pagination={false} bordered></Table>
        </div>
        <div className="result-table-item">
          <h3>五、年度各能源品种消费量 </h3>
          {
            dataList.yieEnergyData.map((i:any,index:number)=>{
              return <div key={index}>
                <h4>({index + 1}) {i.name}</h4>
                <Table size="small" columns={yieEnergy} dataSource={i.items} pagination={false} bordered></Table>
              </div>
            })
          }
        </div>
        <div className="result-table-item">
          <h3>六、年度各耗能工质消费量 </h3>
          {
            dataList.annualEnergyWorkData.map((i:any,index:number)=>{
              return <div key={index}>
                <h4>({index + 1}) {i.name}</h4>
                <Table size="small" columns={yieEnergy} dataSource={i.items} pagination={false} bordered></Table>
              </div>
            })
          }
        </div>
        <div className="result-table-item">
          <h3>七、年度辅助生产系统能耗 </h3>
          <Table size="small" columns={processUnit} pagination={false} dataSource={dataList.processUnitData} bordered></Table>
        </div>
        <div className="result-table-item">
          <h3>八、专项诊断工序能耗 </h3>
          <h4>（一）染色工序 </h4>
          <Table size="small" columns={processEnergy} pagination={false} dataSource={dataList.diagnosisProcessData_DYEING} bordered></Table>
          <h4>(二）定形工序  </h4>
          <Table size="small" columns={processEnergy} pagination={false} dataSource={dataList.diagnosisProcessData_FIXED} bordered></Table>
        </div>
      </div>
    )
  }
}

export default ResultsEnergyConsumption;