import React from 'react';
import {Input, Form, Row, Col, Button,message, InputNumber, Radio, Select} from 'antd';
import Http from '../../../../utils/http';
import { FormInstance } from 'antd/lib/form';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { WasteGas } from '../wasteGas/wasteGas';
import {EmissionStandard} from '../emissionStandard/emissionStandard';
import { adminEditAndRead } from '../../../../utils/utils';

export class DyeingEquipment extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    this.state={
      isOpen:true,
      isTrue:true,
      readAndEdit:true
    }
    this.getData = this.getData.bind(this);
    this.changeOpen = this.changeOpen.bind(this);
    this.changeOption = this.changeOption.bind(this);
  }
  changeOpen(){
    let status = this.state.isOpen;
    this.setState({
      isOpen: !status
    })
  };
  changeOption(e:any){
    this.setState({
      isTrue:e.target.value==='T'?true:false
    })
    Http.put('/diagnosis/dyeing/fixed/'+this.props.id,{
      wasteGasEqu:e.target.value
    }).then((res:any)=>{
      if(res&&res.code===200){
        message.success('保存成功');
      }
    })
  }
  createForm = React.createRef<FormInstance>();
  getData(value:any){
    Http.put('/diagnosis/dyeing/fixed/'+this.props.id,value).then((res:any)=>{
      if(res&&res.code===200){
        message.success('修改完成');
        this.props.refresh(this.props.id);
      }
    })
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    });
    this.createForm.current?.setFieldsValue(this.props.equipment);
    if(this.props.equipment.wasteGasEqu === null || this.props.equipment.wasteGasEqu === ''){
      this.createForm.current?.setFieldsValue({
        wasteGasEqu:'T'
      });
      this.setState({
        isTrue:true
      })
    }
  }
  render(){
    let {isOpen,isTrue,readAndEdit} = this.state;
    let {Option} = Select;
    return(
      <div style={{marginBottom:'20px'}}>
      <div className="item-title-container" style={{marginBottom:'20px'}}>
        <div className="item-title">
          <img src="/assets/images/icon1.png" alt=''></img>
          <span>设备及工艺参数</span>
        </div>
        <div className="change-open" onClick={this.changeOpen}>
          {isOpen?<><span>收起</span><UpOutlined /></>:<><span>展开</span><DownOutlined /></>}
          </div>
      </div>
      {isOpen?<>
        <Form layout="vertical" style={{paddingLeft:'26px'}} ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="设备类型" name="equipCate" >
              {this.props.type === 0?<Select disabled={!readAndEdit} style={{ width:'100%' }}>
                <Option value="散纤纱缸">散纤纱缸</Option>
                <Option value="筒子纱缸">筒子纱缸</Option>
                <Option value="经轴纱缸">经轴纱缸</Option>
                <Option value="绞纱缸">绞纱缸</Option>
                <Option value="气流布缸">气流布缸</Option>
                <Option value="溢流布缸">溢流布缸</Option>
                <Option value="L型布缸">L型布缸</Option>
                <Option value="平幅床车">平幅床车</Option>
                <Option value="轧染床车">轧染床车</Option>
              </Select>:<Select disabled={!readAndEdit} style={{ width:'100%' }}>
                <Option value="针织定形机">针织定形机</Option>
                <Option value="梭织定形机">梭织定形机</Option>
              </Select>}
              
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="设备型号" name="equipModel">
              <Input placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="投产以来的年限" name="years" >
              <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          {
            this.props.type===0?<><Col span={12}>
              <Form.Item label="浴比" name="bathRatio" >
                <Input style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="主泵流量（L/min）" name="flow" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="电机功率（kW）" name="power" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="电机最高频率（HZ）" name="hertz" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="空压机压力值（kg）" name="pressure" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="电机利用率（%）" name="motorRate" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="技术人员工作年限（年）" name="workingYears" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="small-title">
                <img src="/assets/images/icon2.png" alt='' />
                <span>染色水质</span>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item label="染色水质-硬度（mg/L）" name="hardness" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="染色水质-PH" name="ph" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="染色水质-浊度（度）" name="turbidity" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="染色水质-铁离子（mg/L）" name="fe" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="染色水质-氯化物（mg/L）" name="cl" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={3}>
              {readAndEdit?<Button className="green-button" htmlType="submit">保存</Button>:''}
              
            </Col>
            </>:<>
            <Col span={12}>
              <Form.Item label="定形温度（摄氏度）" name="temperature" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="出布速度（m/min 米/分钟）" name="settingSpeed" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="烘箱节数（节）" name="oven" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="风机型号" name="fanModel" >
                <Input style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="排风系统用电功率（kW）" name="powerCons" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="全风压（Pa/m 帕/米）" name="windPressure" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="全风速（mph 英里/小时）" name="windSpeed" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="全风量（CMM 立方米/分钟）" name="windVolume" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="清理机箱频率（次/年）" name="clearRate" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="技术人员工作年限（年）" name="workingYears" >
                <InputNumber min={0} style={{width:'100%'}} placeholder="请输入" disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={24} style={{paddingLeft:'0',marginBottom:'10px'}}>
              <Col span={3}>
                {readAndEdit?<Button className="green-button" htmlType="submit">保存</Button>:''}
                
              </Col>
            </Col>
            <Col span={24}>
              <div className="small-title">
                <img src="/assets/images/icon3.png" alt='' />
                <span>处理装备与要求</span>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item label="1.是否安装废气处理装备" rules={[{required: true}]} name="wasteGasEqu" >
                <Radio.Group disabled={!readAndEdit} onChange={this.changeOption}>
                  <Radio value="T">是</Radio>
                  <Radio value="F">否</Radio>
                </Radio.Group>
              </Form.Item>
              {isTrue?<>
                <WasteGas datas={this.props.equipment.equs} dfProcessId={this.props.id} id={this.props.diagnosisId}></WasteGas>
              </>:''}
            </Col>
            <Col span={24}>
              <p style={{color:'rgba(0,0,0,0.85)',marginBottom:'10px',marginTop:'10px'}}><span style={{color:'red',marginRight:'5px'}}>*</span>2.废气治理排放满足的标准</p>
              <EmissionStandard datas={this.props.equipment.standards} dfProcessId={this.props.id} id={this.props.diagnosisId}></EmissionStandard>
            </Col>
            </>
          }
        </Row>
      </Form>
      </>:''}
      
      </div>
    )
  }
}
export default DyeingEquipment;