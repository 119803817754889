import React from 'react';
import './enterpriseTechnology.scss';
import { PlusSquareOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Space, Pagination,Drawer, message } from 'antd';
import Http from '../../../../utils/http';
import AddEnterpriseTechnology from '../addEnterpriseTechnology/addEnterpriseTechnology';
import AddEnterWork from '../addEnterWork/addEnterWork';
import { adminEditAndRead } from '../../../../utils/utils';

export class EnterpriseTechnology extends React.Component<any,any>{
  editData:any = [];
  formRef:any;
  constructor(props:any){
    super(props);
    this.state = {
      produceList:[],
      motorCommonList:[],
      MotorcustomList:[],
      heatList:[],
      newDrawer:false,
      readAndEdit:true
    };
    this.equipPageProduce = this.equipPageProduce.bind(this);
    this.equipPageMotorcommon = this.equipPageMotorcommon.bind(this);
    this.equipPageMotorcustom = this.equipPageMotorcustom.bind(this);
    this.equipPageHeat = this.equipPageHeat.bind(this);
    this.addTable = this.addTable.bind(this);
    this.refresh = this.refresh.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.setFormRef = this.setFormRef.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
  }
  equipPageProduce(){
    Http.get('/diagnosis/equip/page',{
      diagnosisId:this.props.id,
      processType:'PRODUCE'
    }).then((res:any)=>{
      res.data.forEach((i:any,index:number)=>{
        i.key = index + 1
      });
      this.setState({
        produceList: res.data
      });
    })
  }
  equipPageMotorcommon(){
    Http.get('/diagnosis/equip/page',{
      diagnosisId:this.props.id,
      processType:'MOTORCOMMON'
    }).then((res:any)=>{
      res.data.forEach((i:any)=>{
        i.key = i.id
      });
      this.setState({
        motorCommonList: res.data
      });
    })
  }
  equipPageMotorcustom(){
    Http.get('/diagnosis/equip/page',{
      diagnosisId:this.props.id,
      processType:'MOTORCUSTOM'
    }).then((res:any)=>{
      res.data.forEach((i:any)=>{
        i.key = i.id
      });
      this.setState({
        MotorcustomList: res.data
      });
    })
  }
  equipPageHeat(){
    Http.get('/diagnosis/equip/page',{
      diagnosisId:this.props.id,
      processType:'HEAT'
    }).then((res:any)=>{
      res.data.forEach((i:any,index:number)=>{
        i.key = index + 1
      });
      this.setState({
        heatList: res.data
      })
    })
  }
  addTable(){
    Http.post('/diagnosis/equip',{
      diagnosisId:this.props.id,
      processName:'默认工序名称',
      processType:'PRODUCE'
    }).then((res:any)=>{
      if(res&&res.code===200){
        message.success('新增成功');
        this.equipPageProduce();
      }
      
    })
  }
  refresh(){
    this.equipPageProduce();
  }
  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.equipPageProduce();
    };
    this.setState({
      newDrawer: false
    })
  }
  setFormRef = (el: any) => this.formRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }

  onSubmitDrawer(){
    this.getFormRef();
  }
  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    this.equipPageProduce();
    this.equipPageMotorcommon();
    this.equipPageMotorcustom();
    this.equipPageHeat();
  }
  render(){
    const {produceList,motorCommonList,MotorcustomList,heatList,readAndEdit} = this.state;
    let productList,electricList,boilerList = [];
    let motorList = [...motorCommonList,...MotorcustomList];
    productList = produceList.map((i:any,index:number)=>{
      return <EnterpriseTechnologyProductionItem refresh={this.refresh} key={i.id} id={this.props.id} tableId={i.id} bigIndex={1} smallIndex={index+1} tableName={i.processName}></EnterpriseTechnologyProductionItem>;
    });
    electricList = motorList.map((i:any,index:number)=>{
      return <EnterpriseTechnologyElectricItem refresh={this.refresh} key={i.id} id={this.props.id} tableId={i.id} bigIndex={2} smallIndex={index+1} tableName={i.processName}></EnterpriseTechnologyElectricItem>;
    });
    boilerList = heatList.map((i:any,index:number)=>{
      return <EnterpriseTechnologyBoilerItem refresh={this.refresh} key={i.id} id={this.props.id} tableId={i.id} bigIndex={3} smallIndex={index+1} tableName={i.processName}></EnterpriseTechnologyBoilerItem>;
    });
    return(
      <>
        <div className="enterprise-technology">
          <div className="system-boundary-item-head-name">
            生产设备<PlusSquareOutlined onClick={this.clickConsole} style={{fontSize:'24px',marginLeft:'15px'}} className="icon-style" />
          </div>
          {productList}
        </div>
        <div className="enterprise-technology">
          <div className="system-boundary-item-head-name">
            2.电机及拖动设备
          </div>
          {electricList}
        </div>
        <div className="enterprise-technology">
          <div className="system-boundary-item-head-name">
            3.锅炉及加热炉设备
          </div>
          {boilerList}
        </div>
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddEnterWork 
            closeDrawer={this.closeDrawer}
            editData={this.editData} 
            id={this.props.id}
            bindRef={this.setFormRef}
          ></AddEnterWork>
        </Drawer>
      </>
    )
  }
}

export class EnterpriseTechnologyProductionItem extends React.Component<any,any>{
  editData:any = [];
  editChildData:any = [];
  formRef: any;
  childFormRef: any;
  constructor(props:any){
    super(props);
    this.state = {
      isModify:true,
      newDrawer:false,
      newChildDrawer:false,
      tabData:[],
      total:10,
      currentPage:1,
      index:0,
      col:[
        {
          title: '序号',
          dataIndex: 'key',
          key: 'key'
        },{
          title:'设备类别及名称',
          dataIndex:'deviceName',
          key:'deviceName'
        },{
          title:'规格型号',
          dataIndex:'model',
          key:'model'
        },{
          title:'数量',
          dataIndex:'num',
          key:'num'
        },{
          title:'主要能源消费品种',
          dataIndex:'energyName',
          key:'energyName'
        },{
          title:'生产能力（万t等）',
          dataIndex:'productNum',
          key:'productNum'
        },{
          title:'节能措施',
          dataIndex:'savingMeasure',
          key:'savingMeasure'
        },{
          title: '操作',
          key: 'action',
          render: (text:any, record:any) => (
            <Space size="middle">
              <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
              {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.deleteTable(record)}>删除</span>:''}
              
            </Space>
          ),
          width:150
        }
      ],
      readAndEdit:true
    }
    this.edit = this.edit.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);

    this.getChildFormRef = this.getChildFormRef.bind(this);
    this.clickChildConsole = this.clickChildConsole.bind(this);
    this.onSubmitChildDrawer = this.onSubmitChildDrawer.bind(this);
    this.closeChildDrawer = this.closeChildDrawer.bind(this);

    this.changePage = this.changePage.bind(this);
    this.modifyTitle = this.modifyTitle.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
    this.deleteProcess = this.deleteProcess.bind(this);
    this.deleteTable = this.deleteTable.bind(this);
  };

  changePage(page:any) {
    this.setState({
      currentPage:page
    });
    this.ecPage();
  }
  ecPage(){
    Http.get('/diagnosis/equip/ec/page',{
      diagnosisEquipmentId:this.props.tableId,
      diagnosisId:this.props.id,
      pageNum:this.state.currentPage,
      pageSize:10
    }).then((res:any)=>{
      res?.data?.dataList.forEach((i:any,index:number)=>{
        i.key = index + 1;
      });
      this.setState({
        tabData:res?.data?.dataList,
        total:res?.data?.count
      })
    })
  }

  setFormRef = (el: any) => this.formRef = el;
  setChildFormRef = (el: any) => this.childFormRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }
  getChildFormRef(){
    this.childFormRef.vailForm();
  }

  onSubmitDrawer(){
    this.getFormRef();
  }
  onSubmitChildDrawer(){
    this.getChildFormRef();
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }

  clickChildConsole(){
    this.editChildData = {
      id:this.props.tableId,
      processName:this.props.tableName
    };
    this.setState({
      newChildDrawer: true
    })
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.ecPage();
    };
    this.setState({
      newDrawer: false
    })
  }

  closeChildDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.props.refresh();
    };
    this.setState({
      newChildDrawer: false
    })
  }

  edit(value:any){
    this.editData = value;
    this.setState({
      newDrawer: true
    })
  }
  deleteTable(value:any){
    Http.DELETE('/diagnosis/equip/ec/'+value.id).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功!');
        this.ecPage();
      }
    })
  }
  modifyTitle(){
    this.setState({
      modifyTitle: true,
    })
  }
  changeTitle(e:any){
    this.setState({
      titleValue:e.target.value
    })
  }
  deleteProcess(){
    Http.DELETE('/diagnosis/equip/'+this.props.tableId).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功');
        this.props.refresh()
      }
    })
  }
  componentDidMount(){
    this.ecPage();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }
  render(){
    const {total,currentPage,col,index,tabData,readAndEdit} = this.state;
    return(
      <div className="enterprise-technology-item">
        <div className="enterprise-technology-item-head">
          <div className="enterprise-technology-item-left">
            <div>{this.props.tableName}</div>
            {readAndEdit?this.state.isModify?<div>
              <EditOutlined onClick={this.clickChildConsole} className="icon-style" style={{marginRight:'10px'}} />
              <DeleteOutlined onClick={this.deleteProcess} className="icon-style" />
            </div>:'':''}
          </div>
          <div className="enterprise-technology-item-add">
            {readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''}
          </div>
        </div>
        <Table 
          columns={col}
          dataSource={tabData}
          pagination={false}
          bordered
          size="small"
          onChange={this.changePage}
        />
        <div className="pagination">
          <div className="pagination-total">总共{total}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
          />
        </div>
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddEnterpriseTechnology 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id}
            tableId={this.props.tableId}
            type={index}
            bindRef={this.setFormRef}
          ></AddEnterpriseTechnology>
        </Drawer>

        <Drawer 
          title={'编辑'}
          width={550}
          onClose={e=>this.closeChildDrawer()}
          destroyOnClose={true}
          visible={this.state.newChildDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeChildDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitChildDrawer} type="primary">
                确定
              </Button>:''}
            </div>
          }
        >
          <AddEnterWork 
            closeDrawer={this.closeChildDrawer}
            editData={this.editChildData} 
            id={this.props.id}
            bindRef={this.setChildFormRef}
          ></AddEnterWork>
        </Drawer>
      </div>
    )
  }
}

export class EnterpriseTechnologyElectricItem extends EnterpriseTechnologyProductionItem{
  constructor(props:any){
    super(props);
    this.state = {
      titleValue:'',
      modifyTitle:false,
      tabData:[],
      isModify: false,
      total:10,
      currentPage:1,
      index:1,
      col:[
        {
          title: '序号',
          dataIndex: 'key',
          key: 'key',
        },{
          title:'设备类别及名称',
          dataIndex:'deviceName',
          key:'deviceName'
        },{
          title:'规格型号',
          dataIndex:'model',
          key:'model'
        },{
          title:'数量',
          dataIndex:'num',
          key:'num'
        },{
          title:'主要能源消费品种',
          dataIndex:'energyName',
          key:'energyName'
        },{
          title:'功率(kW)',
          dataIndex:'productNum',
          key:'productNum'
        },{
          title:'能效等级',
          dataIndex:'efficiencyRank',
          key:'efficiencyRank'
        },{
          title:'配套电机',
          children:[
            {
              title:'型号',
              dataIndex:'supportMotorType',
              key:'supportMotorType'
            },{
              title:'能效等级',
              dataIndex:'supportMotorRank',
              key:'supportMotorRank'
            }
          ]
        },{
          title: '操作',
          key: 'action',
          render: (text:any, record:any) => (
            <Space size="middle">
              <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
              {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.deleteTable(record)}>删除</span>:''}
              
            </Space>
          ),
          width:150
        }
      ],
      readAndEdit:true
    }
  }

}

export class EnterpriseTechnologyBoilerItem extends EnterpriseTechnologyProductionItem{
  constructor(props:any){
    super(props);
    this.state = {
      titleValue:'',
      modifyTitle:false,
      tabData:[],
      isModify: false,
      total:10,
      currentPage:1,
      index:2,
      col:[
        {
          title: '序号',
          dataIndex: 'key',
          key: 'key',
        },{
          title:'设备类别及名称',
          dataIndex:'deviceName',
          key:'deviceName'
        },{
          title:'规格型号',
          dataIndex:'model',
          key:'model'
        },{
          title:'数量',
          dataIndex:'num',
          key:'num'
        },{
          title:'主要能源消费品种',
          dataIndex:'energyName',
          key:'energyName'
        },{
          title:'容量(t/h或MW)',
          dataIndex:'productNum',
          key:'productNum'
        },{
          title:'能效等级',
          dataIndex:'efficiencyRank',
          key:'efficiencyRank'
        },{
          title:'额定热效率(%)',
          dataIndex:'standardHeatRate',
          key:'standardHeatRate'
        },{
          title: '操作',
          key: 'action',
          render: (text:any, record:any) => (
            <Space size="middle">
              <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
              {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.deleteTable(record)}>删除</span>:''}
              
            </Space>
          ),
          width:150
        }
      ],
      readAndEdit:true
    }
  }
}

export default EnterpriseTechnology;