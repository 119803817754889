import { Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import './userAgreement.scss';
import Http from './../../utils/http';

const UserAgreement = (props: any) => {
    const [isShow, setShow] = useState(false);

    useEffect(() => {
        if (props.isShow) {
            setShow(props.isShow);
        }
    }, [props.isShow]);

    const handleOk = () => {
        setShow(true);
    }

    const handleCancel = () => {
        setShow(false);
        props.setShow();
    }

    const agree = () => {
        Http.put('/admin/info/agreeUserAgreement').then((res: any) => {

            if (res && res.code === 200) {
                props.agree();
            }
        })
    }

    return (<Modal
        visible={isShow}
        width='55rem'
        title="用户数据隐私政策"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
    >
        <div className="agreement-container">
            <div className="agreement-body">
                <div className="agreement-title">FAIS系统数据隐私政策</div>
                <div className="agreement-dont-weight">本数据隐私政策介绍了FAIS系统平台将如何收集、存储并使用您提交给www.fais.cn的数据。该政策适用于FAIS的所有用户及其他能接触到FAIS数据的人员，包括但不限于工厂、工业园区、品牌捐赠方等。</div>
                <div className="agreement-weight">平台承诺：我们会采用有效的技术手段和规则措施保护用户数据。未经用户许可，不会向其他第三方泄露任何用户非公开的数据。</div>
                <div className="agreement-ui-title">数据收集和储存</div>
                <div className="agreement-ui-body">
                    <ul>
                        <li>在您创建登录我们网络的帐户时，我们会收集您的个人信息，例如您的姓名、工作职位、公司名称、电子邮件、邮寄地址以及密码等。</li>
                        <li>在您访问或使用FAIS时，您计算机的操作系统、互联网协议（IP）地址、访问时间、浏览器类型和语言以及您在访问我们网站之前访问的网站将被自动记录。我们还将收集您在我们网站上的使用情况。</li>
                        <li>同样，如果您通过电子邮件与我们联系以获取有关支持，您自愿提供的其它个人信息也会被收集。</li>
                        <li>FAIS将以您的名义保留所有信息，包括存储于系统的文件和消息。</li>
                        <li>您上传的所有数据将存储于一个以CNTAC（China National Textile And Apparel Council，中国纺织工业联合会的英文缩写）命名的数据库中。</li>
                        <li>您的数据将会被适当的技术手段进行保护，以避免被他人采用不正当的手段获取或者不当使用。</li>
                    </ul>
                </div>

                <div className="agreement-ui-title">数据使用</div>

                <div className="agreement-ui-body">
                    <div className="agreement-ui-body-title">FAIS系统<span>收集以上数据后，将用于：</span></div>
                    <ul>
                        <li>增强和改进FAIS平台自身的产品和服务</li>
                        <li>选取工厂最佳实践案例，并在其所有者的同意下通过培训或其他途径与其他用户共享优秀案例</li>
                        <li>通过数据分析，为用户提供相应的诊断服务和解决方案</li>
                        <li>通过数据分析，帮助中国纺织工业联合会更好地了解行业趋势，高效制定纺织行业的标准与政策</li>
                    </ul>
                    <div className="agreement-ui-body-title">单个用户有权决定其数据的披露程度，但FAIS系统有权拥有一份用户的数据拷贝，并按照法律规定对数据进行合理使用。</div>
                </div>
                <div className="agreement-ui-body-title">问题和建议</div>
                <div className="agreement-dont-weight" style={{ marginBottom: 0 }}>如以上的政策与相关的法律法规不一致的，以最新及更严格的保护标准为准。</div>
                <div className="agreement-dont-weight" >如果您对本隐私政策有任何疑问或意见，请向我们发送您的问题和反馈。您可以发送电子邮件至fangzhijnhb@163.com联系我们，或者拨打电话 (86-10) 85229069/9520/9080联系我们。</div>
            </div>
            <div className="agree-btn">
                <Button type="primary" onClick={agree} style={{ color: '#fff', borderColor: '#15772C', background: '#15772C' }}>我已知晓，并同意</Button>
            </div>
        </div>
    </Modal>)

}


export default UserAgreement;