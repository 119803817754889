import './prod-baisc-info.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { waterEditAndRead, dateFormat, verificationType, verificationExcel } from '../../../../utils/utils';
import { Button, Table, Pagination, Space, Drawer, Row, Col, Popover, message, Modal, Upload } from 'antd';
import { page, Page } from '../../../../utils/entity';
import http from '../../../../utils/http';
import { ArrowUpOutlined, ArrowDownOutlined, UploadOutlined } from '@ant-design/icons';
import AddProdBaise from '../add-prod-baise/add-prod-baise';
import store from '../../../../utils/store';
import { OPENIMG, CLOSEIMG } from '../../../../utils/actions';

const ProdBaiscInfo = (props:any) => {
  const [readAndEdit,setReadAndEdit] = useState<boolean>(true);
  const [dataList,setDataList] = useState<Array<any>>([]);
  const [total,setTotal] = useState<number>(0);
  const [searchPage,setSearchPage] = useState<page>(new Page());
  const [visiable,setVisiable] = useState<boolean>(false);
  const [editData,setEditData] = useState<any>();
  const [deleteId,setDeleteId] = useState<number>(0);
  const [deleteVisible,setDeleteVisible] = useState<boolean>(false);
  const [flowChartList,setFlowChartList] = useState<Array<any>>([]);

  let fileupLoad = {
    beforeUpload: (file: any) => {
      if(verificationExcel(file.type)){
        flowChartListUpload(file);
      } else {
        message.error('文件上传仅支持xlsx格式后缀的文件');
      }
      return false;
    }
  };

  const flowChartListUpload = (file:any) => {
    const formData = new FormData();
    formData.append('file', file);
    http.post('/apiWaterUrl/assess/production/info/upload/'+props.id,formData).then((res:any)=>{
      if(res&&res.code===200){
        message.success("上传成功");
        getTableList();
      }else{
        message.error(res.message||'上传失败');
      }
    })
  }

  const pageChange = (page:number) => {
    let pageData = searchPage;
    pageData.SkipCount = (page - 1) * 10;
    pageData.PageNum = page;
    setSearchPage(pageData);
    getTableList();
  }

  const getTableList = useCallback(() => {
    http.get('/apiWaterUrl/assess/production/info/page',{
      assessId:props.id,
      pageNum:searchPage.PageNum,
      pageSize:10
    }).then((res:any) => {
      if(res && res.code === 200){
        res.data.dataList.forEach((i:any,index:number) => {
          i.key = index+1;
        });
        // setDataList(res.data.dataList);
        setTotal(res.data.count);
        setDataList(res.data.dataList);
      }
    })
  },[props.id, searchPage.PageNum])

  const deleteItem = (id:any) => {
    setDeleteId(id);
    setDeleteVisible(true);
  }

  const handleOk = () => {
    http.DELETE('/apiWaterUrl/assess/production/info/' + deleteId).then((res:any) => {
      if(res && res.code === 200){
        message.success("删除成功");
        handleCancel();
        getTableList();
      }
    })
  }

  const handleCancel = () => {
    setDeleteVisible(false);
  }

  const showDrawer = (data?:any) => {
    setEditData(data);
    setVisiable(true);
  }

  const onClose = () => {
    setVisiable(false);
  }

  const onSubmitDrawer = () => {
    onClose();
    getTableList();
  }

  const imgClick = (i:any) => {
    store.dispatch({type: OPENIMG,imgId:i.fileId,imgName:i.showName});
  }

  const downLoadExcel = () => {
    http.downPDF('/apiWaterUrl/download/production/info/excel');
  }

  useEffect(() => {
    setReadAndEdit(waterEditAndRead());
  }, [])

  useEffect(() => {
    getTableList();
  },[getTableList])

  const columns = [{
    title:'序号',
    dataIndex:'key',
    width:60
  },{
    title:'设备名称',
    dataIndex:'eqName',
    key:'eqName',
  },{
    title:'规格型号',
    dataIndex:'specs',
    key:'specs',
  },{
    title:'生产厂家',
    dataIndex:'manufacturer',
    key:'manufacturer',
  },{
    title:'生产日期',
    dataIndex:'produceTs',
    key:'produceTs',
    render: (text:string) => (
      <span>{text?dateFormat(new Date(text),'YYYY.mm'):''}</span>
    )
  },{
    title:'投用年份',
    dataIndex:'useTs',
    key:'useTs',
    render: (text:string) => (
      <span>{text?dateFormat(new Date(text),'YYYY.mm'):''}</span>
    )
  },{
    title:'数量',
    dataIndex:'quantity',
    key:'quantity',
    width:80
  },{
    title:'精度等级',
    dataIndex:'accuracy',
    key:'accuracy',
  },{
    title:'状态',
    dataIndex:'status',
    key:'status',
  },{
    title:'附件',
    dataIndex:'files',
    key:'files',
    render:(text:any, record:any) => (
      <Space size="middle">
        <Popover content={
          record?.files && record?.files.length > 0 ? record.files.map((i:any,index:number) => 
            <div key={index} onClick={() => imgClick(i)} className="div-a">{i.showName}</div>
          ) : null
        } title={null} trigger="hover">
          <span style={{color:'#00A968'}}>{record?.files.length | 0}</span>
        </Popover>
      </Space>
    )  
  },{
    title:'备注',
    dataIndex:'remark',
    key:'remark',
  },{
    title:'操作',
    key: 'action',
    fixed: 'right' as any,
    render:(text:any, record:any) => (
      readAndEdit?
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>编辑</span>
        <span className="green-table-a" onClick={() => deleteItem(record.id)}>删除</span>
      </Space> :
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>查看</span>
      </Space>    
    )  
  }]

  const showButtonView = () => {
    if(readAndEdit){
      return (
        <Row gutter={16} style={{width: '100%'}}>
          <Col span={12}>
            <div className="table-title">主要用水设备列表</div>
          </Col>
          <Col span={12}>
            <div className="button-margin-left">
              {/* <Button type="default" onClick={showDrawer}><ArrowUpOutlined />上传表格</Button> */}
              <div style={{display:'inline-block'}}>
                <Upload {...fileupLoad} fileList={[]} disabled={!readAndEdit} >
                  <Button type="primary" ghost>
                    {/* <UploadOutlined />上传表格 */}
                    <img src="/assets/images/icon_uploading_normal.png" className="button-img" alt=""></img>上传表格
                  </Button>
                </Upload>
              </div>
              <Button type="primary" ghost onClick={downLoadExcel}>
                {/* <ArrowDownOutlined /> */}
                <img src="/assets/images/icon_download_normal.png" className="button-img" alt=""></img>下载样表
              </Button>
              <Button className="green-button" onClick={showDrawer}>新增</Button>
            </div>
          </Col>
        </Row>
      )
    }else{
      return <div className="table-title">主要用水设备列表</div>;
    }
  }

  return (
    <>
      <div className="table-container">
        <div className="table-header">
          {showButtonView()}
        </div>
        <Table
          columns={columns}
          dataSource={dataList}
          pagination={false}
          size="small"
        />
        <div className="pagination">
          <div className="pagination-total">总共{total}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={searchPage.PageNum}
            hideOnSinglePage
            onChange={pageChange}
          />
          
        </div>
        <Drawer 
          title={editData?.id?'编辑':'新增'}
          width={550}
          onClose={onClose}
          destroyOnClose={true}
          visible={visiable}
          bodyStyle={{ paddingBottom: 10 }}
        >
          <AddProdBaise
            onClose={onClose} 
            editData={editData} 
            id={props.id}
            onSubmitDrawer={onSubmitDrawer}></AddProdBaise>
        </Drawer>
        <Modal
          title="删除"
          visible={deleteVisible}
          width="400px"
          cancelText="取消"
          okText="确定"
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose
          footer={[
            <Button key="cancel" type="default" onClick={handleCancel}>取消</Button>,
            <Button key="handleOk" type="primary" className="green-button" onClick={handleOk}>确认</Button>
          ]}
        >
          <p>确认要删除该项数据吗？</p>
        </Modal>
      </div>
    </>
  )
}

export default ProdBaiscInfo;