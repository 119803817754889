import React from 'react';
import ReactDOM from 'react-dom';
import {HomeContainer} from './view/home/home';
import {LoginComponent} from './view/login/login';
import HomePage from './view/index/index'
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import {Router} from '@reach/router';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import {ConfigProvider} from 'antd';
import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import DashboardPage from './view/dashboard/dashboard';
import DeclarationPage from './view/declaration/declaration';
import CompetZone from './view/competitionZone/competitionZone';

ReactDOM.render(
    (
        <ConfigProvider locale={zhCN}>
            <Router>
                <DashboardPage path="/dashboard"/>
                <HomePage path="/"/>
                <LoginComponent path="/login"/>
                <HomeContainer path="home/*"/>
                <DeclarationPage path="/declaration"/>
                <CompetZone path="/competitionZone"/>
            </Router>
        </ConfigProvider>),
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
