import React from 'react';
import Http from '../../../../utils/http';
import { Button, Drawer, message, Popover, Space, Table } from 'antd';
import './emissionStandard.scss';
import AddEmissionStandard from '../addEmissionStandard/addEmissionStandard';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';
import { adminEditAndRead } from '../../../../utils/utils';

interface emissionStandard {
  dfProcessId:string,
  id:string
}
export class EmissionStandard extends React.Component<any,any>{
  editData:any = [];
  formRef: any;
  constructor(props:emissionStandard){
    super(props);
    this.state = {
      data:[],
      newDrawer:false,
      readAndEdit:true
    };
    this.getList = this.getList.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
    this.edit = this.edit.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.downFile = this.downFile.bind(this);
  };

  setFormRef = (el: any) => this.formRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }

  onSubmitDrawer(){
    this.getFormRef();
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.getList();
    };
    this.setState({
      newDrawer: false
    })
  }
  
  getList(){
    Http.get('/diagnosis/dyeing/fixed/process/standard/list',{dfProcessId:this.props.dfProcessId}).then((res:any)=>{
      if(res.data.length!==0){
        res.data.forEach((i:any,index:number)=>{
          i.key = index + 1;
        });
        this.setState({
          data:res.data
        })
      }
    })
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer:true
    })
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  deleteRow(data:any){
    Http.DELETE('/diagnosis/dyeing/fixed/process/standard/'+data.id,).then((res:any)=>{
      message.success('删除成功!');
      this.setState({
        data:[]
      })
      this.getList();
    });
  }

  downFile(fileObj:{fileId:number,showName:string}){
    store.dispatch({type: OPENIMG,imgId:fileObj.fileId,imgName:fileObj.showName});
  }

  componentDidMount(){
    if(this.props.datas.length!==0){
      this.props.datas.forEach((i:any,index:number)=>{
        i.key = index + 1;
      });
    }
    this.setState({
      data:this.props.datas,
      readAndEdit:adminEditAndRead()
    })
  }

  render(){
    let {data,readAndEdit} = this.state;
    let columns = [{
      title:'序号',
      dataIndex:'key',
      key:'key',
      width:70,
      fixed: true,
    },{
      title:'标准名称',
      dataIndex:'standardName',
      key:'standardName'
    },{
      title:'排放指标',
      dataIndex:'index',
      key:'index'
    },{
      title:'上传附件',
      render:(text:any,record:any)=>(
        <Popover content={
          record.files?.map((i:any,index:number)=> <div key={index} onClick={e=>this.downFile(i)} className="div-a">{i.showName}</div>)
        } title="上传附件" trigger="hover">
          <span style={{color:'#0F9940'}}>{record.files?.length | 0}</span>
        </Popover>
      )
    },{
      title: '操作',
      key: 'action',
      render: (text:any, record:any) => (
        <Space size="middle">
          <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
          {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.deleteRow(record)}>删除</span>:''}
          
        </Space>
      ),
      width:100,
      fixed:'right' as any
    }];
    return(<>
      <div className="table-head-title">
        <div>共有{data.length}条信息</div>
        {readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''}
        
      </div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        scroll={{ x: 1000 }} 
        pagination={false}
        size="small"
      />
      <Drawer 
        title={this.editData.id?'编辑':'新增'}
        width={550}
        onClose={e=>this.closeDrawer()}
        destroyOnClose={true}
        visible={this.state.newDrawer}
        bodyStyle={{ paddingBottom: 10 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
              取消
            </Button>
            {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
              确定
            </Button>:''}
            
          </div>
        }
      >
        <AddEmissionStandard 
          closeDrawer={this.closeDrawer} 
          editData={this.editData} 
          id={this.props.id} 
          dfProcessId={this.props.dfProcessId}
          bindRef={this.setFormRef}
        ></AddEmissionStandard>
      </Drawer>
    </>)
  }
}

export default EmissionStandard;