import React, {ReactNode} from 'react';
import './water-park-one-2.scss';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Radio,
    Divider,
    Tooltip,
    Cascader,
    Select,
    message,
    InputNumber,
    Space,
    Popover,
    TreeSelect
} from 'antd';
import Province from '../../../../../utils/ssq.json';
import Http from '../../../../../utils/http';
import {FormInstance} from 'antd/lib/form';
import {waterEditAndRead,} from '../../../../../utils/utils';

export class WaterParkOne2 extends React.Component<any, any> {
    province: any;
    selectCode: any[] = [];
    industryList: any;
    createForm = React.createRef<FormInstance>();


    constructor(props: any) {
        super(props);
        let arr: any = [];
        Province.forEach(i => {
            let option = {
                value: i.name,
                label: i.name,
                children: this.provicePush(i.children)
            }
            arr.push(option);
        })
        this.province = arr;

        this.state = {
            readAndEdit: true,
            companyTypeEnum: 'COUNTRY',
            stust: true


        };


        this.getInfo = this.getInfo.bind(this);
        this.getForm = this.getForm.bind(this);
    }

    getForm(values: any) {

        let respose = {
            companyName: values.companyName,

            companyTypeEnum: values.companyTypeEnum,
            dept: values.dept,
            address: values.address,
            desc: values.desc,
            curator: values.curator,
            contacts: values.contacts,
            phone: values.phone,
            curatorJob: values.curatorJob,
            contactsJob: values.contactsJob,
            email: values.email,
            province: values.district[0],
            city: values.district[1],
            district: values.district[2],

        };
        Http.put('/apiWaterParkUrl/assess/base/info/' + this.props.id, respose).then((res: any) => {
            if (res && res.code === 200) {
                message.success('保存成功!');

                this.props.nextCurrent()

                this.getInfo();
            }
        })
    }

    getInfo() {
        Http.get('/apiWaterParkUrl/assess/base/info/' + this.props.id).then((res: any) => {

            if (res && res.data && res.code === 200) {
                // this.createForm.current?.setFieldsValue(res.data);
                this.createForm.current?.setFieldsValue({
                    companyName: res.data.companyName,
                    district: [res.data.province, res.data.city, res.data.district],
                    companyTypeEnum: res.data.companyTypeEnum,
                    dept: res.data.dept,
                    address: res.data.address,
                    desc: res.data.desc,
                    curator: res.data.curator,
                    contacts: res.data.contacts,
                    phone: res.data.phone,
                    curatorJob: res.data.curatorJob,
                    contactsJob: res.data.contactsJob,
                    email: res.data.email,

                });


            }
        })
    }


    componentDidMount() {
        this.getInfo();
        this.setState({
            readAndEdit: waterEditAndRead()
        })
    }

    provicePush(arr: any) {
        let returnArr: any = [];
        let option: any;
        arr.forEach((element: any) => {
            if (element.children) {
                option = {
                    value: element.name,
                    label: element.name,
                    children: this.provicePush(element.children)
                };
                returnArr.push(option);
            } else {
                option = {
                    value: element.name,
                    label: element.name
                };
                returnArr.push(option);
            }
        });
        return returnArr;
    }

    onChange(value: any) {
    }

    phoneValidator = (_: any, value: any, callback: any) => {
        if (value) {
            let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            if (reg.test(value)) {
                this.setState({
                    userPhone: value
                })
                return callback();
            }
            return Promise.reject(new Error('手机号无效'));
        } else {
            return Promise.reject(new Error('手机号不得为空'));
        }

    }


    render() {
        const formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 14},
        };
        const {TextArea} = Input;
        const {readAndEdit, stust} = this.state;

        return (
            <div className="enterprise-information" style={{width: '100%'}}>
                <div className="enterprise-information-item" style={{width: '100%'}}>
                    <Divider type="vertical" className="item-title-divider"/><p
                    className="item-title-p">园区基本信息</p>
                    <Divider/>
                    <Form
                        {...formItemLayout}
                        ref={this.createForm}
                        name="enterprise"
                        onFinish={this.getForm}
                        labelCol={{span: 8, offset: 0}}
                        wrapperCol={{span: 16, offset: 0}}
                        initialValues={{
                            'companyTypeEnum': 'COUNTRY',
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Col span={24}>
                                    <Form.Item
                                        label="园区名称"
                                        name="companyName"
                                        rules={[{required: true}]}
                                    >
                                        <TextArea placeholder="请输入园区名称，50字以内" maxLength={50}
                                                  autoSize={{minRows: 1, maxRows: 6}} disabled={!readAndEdit}/>
                                    </Form.Item>
                                </Col>


                                <Col span={24}>
                                    <Form.Item
                                        label="园区节水管理部门"
                                        name="dept"
                                        rules={[{required: true}]}
                                    >
                                        <TextArea placeholder="请输入部门名称，50字以内" maxLength={50}
                                                  autoSize={{minRows: 1, maxRows: 6}} disabled={!readAndEdit}/>
                                    </Form.Item>

                                </Col>

                            </Col>
                            <Col span={12}>
                                <Col span={24}>
                                    <Form.Item
                                        label="园区级别"
                                        name="companyTypeEnum"
                                        rules={[{required: true}]}
                                    >

                                        <Radio.Group disabled={!readAndEdit}>
                                            <Radio value='COUNTRY'>国家级</Radio>
                                            <Radio value='PROVINCE'>省级</Radio>
                                            <Radio value='CITY'>市级</Radio>
                                            <Radio value='COUNTY'>县级</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="所在地区"
                                        name="district"
                                        rules={[{required: true, message: '请选择所在地区'}]}
                                    >
                                        <Cascader options={this.province} placeholder="请选择区域"
                                                  disabled={!readAndEdit}/>
                                    </Form.Item>
                                </Col>


                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="详细地址"
                                    name="address"
                                    labelCol={{span: 4, offset: 0}}
                                    wrapperCol={{span: 20, offset: 0}}
                                >
                                    <Input placeholder="50字以内" maxLength={50} disabled={!readAndEdit}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="园区概况"
                                    name="desc"
                                    labelCol={{span: 4, offset: 0}}
                                    wrapperCol={{span: 20, offset: 0}}
                                >
                                    <TextArea maxLength={2000} placeholder="2000字以内" rows={2}
                                              disabled={!readAndEdit}/>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Divider type="vertical" className="item-title-divider"/><p
                        className="item-title-p">联系信息</p>
                        <Divider/>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Col span={24}>
                                    <Form.Item
                                        label="园区负责人"
                                        name="curator"
                                        rules={[{required: true}]}
                                    >
                                        <Input placeholder="请输入" disabled={!readAndEdit}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="园区联系人"
                                        name="contacts"
                                        rules={[{required: true}]}
                                    >
                                        <Input placeholder="请输入" disabled={!readAndEdit}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="联系电话"
                                        name="phone"
                                        rules={[{validator: this.phoneValidator, required: true}]}
                                    >
                                        <Input placeholder="请输入" disabled={!readAndEdit}/>
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={12}>
                                <Col span={24}>
                                    <Form.Item
                                        label="职务"
                                        name="curatorJob"
                                        rules={[{required: true}]}
                                    >
                                        <Input placeholder="请输入" disabled={!readAndEdit}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="职务"
                                        name="contactsJob"
                                        rules={[{required: true}]}
                                    >
                                        <Input placeholder="请输入" disabled={!readAndEdit}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="联系邮箱"
                                        name="email"
                                        rules={[{required: true, type: 'email'}]}
                                    >
                                        <Input placeholder="请输入" disabled={!readAndEdit}/>
                                    </Form.Item>
                                </Col>
                            </Col>
                        </Row>
                        {
                            readAndEdit ?
                                // <div className="ant-row ant-form-item">
                                //   <div className="ant-col ant-col-6 ant-form-item-label"></div>
                                //   <div className="ant-col ant-col-14 ant-form-item-control">
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item wrapperCol={{span: 24, offset: 0}} style={{textAlign: 'right'}}>
                                            <Button className="green-button" htmlType="submit" type="primary"
                                                    style={{marginRight: '10px'}}>保存</Button>
                                            {/*<Button disabled={stust} type="default"*/}
                                            {/*        onClick={this.props.nextCurrent}*/}
                                            {/*        style={{marginRight: '10px'}}>下一步</Button>*/}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                //   </div>
                                // </div>
                                : ''
                        }

                    </Form>
                </div>
            </div>
        )
    }
}

export default WaterParkOne2;
