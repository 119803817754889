import React from 'react';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import http from '../../utils/http';

const QuantitativeProductData = (props:any) => {

  const downLoadPdf = () => {
    http.downPDF('/apiWaterUrl/download/explain/pdf');
  }

  return(
    <>
    <div style={{width:'600px',height:'300px',overflow:'auto',fontSize:'12px',fontFamily:'Microsoft YaHei UI',color: '#333333'}}>
    <pre style={{width:'100%',whiteSpace:'pre-wrap'}}>
{`一、定性指标评价体系
1. 基本要求
  1.1 依据《生产安全事故报告和调查处理条例》，重大事故是指造成10人以上30人以下死亡，或者50人以上100人以下重伤，或者5000万元以上1亿元以下直接经济损失的事故。
  1.2 按照《中华人民共和国水法》规定，应当向有取水限额管理权的水行政主管部门或者流域管理机构申请领取取水许可证，并缴纳水资源费，取得取水权。
  1.3 水污染物排放应符合《GB 4287-2012 纺织染整工业水污染物排放标准》 、《GB 4287-2012/XG1-2015纺织染整工业水污染物排放标准修改单》、《GB 28936-2012 缫丝工业水污染物排放标准》 、《GB 28937-2012 毛纺工业水污染物排放标准》、《GB 28938-2012 麻纺工业水污染物排放标准》标准要求或符合地方标准。
2. 水效管理制度
  2.1 管理制度
    2.1.1 指企业节约用水上，管理人员架构与责任划分。
    2.1.2 企业有明确、详细的使用水计划以及节水方案。
    2.1.3 节水统计报表的制定，需遵守国家相关统计制度的规定，及时上报统计局批准，确保报表的合法性；报表中各类节水指标须与国家标准、省级标准统计口径保持一致。
  2.2 管理机构与人员
    2.2.1 有明确的领导负责企业用水、节水的管理和监督工作。
    2.2.2 有节水、用水的常务机构，下属成立工作小组，负责本单位生产装置、管理区域日常节水用水管理工作。
  2.3 管网（设备）管理
    2.3.1 通用设备指办公和事务用的通用性设备，包括文化办公机械、电机、变压器、锅炉、空气调节电器等设备。
    2.3.2 例如有污染物COD超标废水处理设备、磷超标废水处理设备等。
    2.3.3 图纸需清晰、准确，能清楚表达企业供水、用水和排水情况。
    2.3.4 制定日常巡查和保修检修供水和排水管道制度，落实相应责任。
  2.4 水计量管理
    2.4.1 该标准为：《GB 24789-2009 用水单位水计量器具配备和管理通则》 用水单位水计量器具配备和管理通则。
    2.4.2 纺织工厂在数据采集与在线监控中宜实现计量用水数据自动采集。
    2.4.3 认真收集、审核、整理、汇总各项数据，确保原始记录数据真实，统计数据具有规范性。
    2.4.4 生产用水指生产部门的用水；生活用水指工人日常生活所需用的水，包括饮用、洗涤、冲厕、洗澡等。
  2.5 水平衡测试
    2.5.1 包括备齐水表、流量计、温度表、秒表等测量工具，检查供水点和用水点的水表配备率及水表计量率等。
    2.5.2 包括企业层次、车间或用水系统层次、重要装置或设备层次。
    2.5.3 水平衡测试指对用水单元和用水系统的水量进行系统的测试、统计、分析得出水量平衡关系的过程。
  2.6 技术改造
    2.6.1节水通用工程改造指更换文化办公机械、变压器、空气调节电器等设备为节水型产品。
    2.6.2 技术设备和工艺改造后，有较显著的节水效果。
  2.7 宣传教育
    2.7.1 有节水管理专业人士对企业员工进行宣传教育、知识普及工作。
    2.7.2 企业内部对节水部门与人员进行奖励，对用水超标的部门与人员进行惩罚教育。
3. 减污治污与污水资源化
  3.1 回用水指生产系统内部将某一生产过程使用过的水，经适当处理后，用于同一用水系统内部或外部的其他生产过程的水。以2019年7月1日为时间节点，该时间点前开办的企业为现有企业，该时间点后的企业为新建企业。
  3.2 污水处理厂在运行过程中产生的污泥、臭气、噪声以及溢出的污水可能对环境造成二次污染。
  3.3 浓淡分流指将高污染水和低污染水分开、分质处理；清浊分流又称雨污分流。
  3.4 污水处理工程是指用各种方法将污水中所含的污染物分离出来或将其转化为无害物，从而使污水得到净化的工程项目。
  3.5 非常规水资源取水量是指工业企业取自海水、苦咸水、矿井水和城镇污水再生水等非常规水源的水的总量。
4. 节水技术应用
  4.1 节水技术是指减少水损失和浪费、提高用水效率和效益、实现非常规水资源利用的技术，可参考《国家鼓励的工业节水工艺、技术和装备目录》和《国家成熟适用节水技术推广目录（2020年）》涉及到的有关纺织行业的节水型技术，例如超低浴比高温高压纱线染色机、数码喷墨印花节水工艺、喷水织机废水处理回用集成技术和干式染料自动配送节水工艺等技术。企业在生产中应用了节水技术，可填报具体的技术信息，并提供节水技术应用证明材料。
5. 标准参编与获奖
  5.1 标准参编是指企业参与相关标准的制定、修订，单位和单位人员在标准中署名。需要提供标准参编证明材料。
  5.2 获奖是指企业获得地方、行业、国家等科技奖励，例如技术发明奖、科技进步奖、节水型企业等。需要提供获奖证明材料。
6. 管理体系实施
  6.1 管理体系的建立应充分考虑纺织企业 自身特点,充分融合企业现有的管理体系，从而保证企业管理体系的集成与统一。`}
    </pre>
    <pre style={{width:'100%',whiteSpace:'pre-wrap'}}>
{
`二、定量指标评价体系
1．基础指标
  1.1 单位产品取水量
  是指企业生产每百米机织产品或每吨针织产品的取水量。取水量范围指企业从各种常规水源提取的水量，包括取自地表水（以净水厂供水计量）、地下水、城镇供水工程，以及企业从市场购得的其他水或水的产品（如蒸汽、热水、地热水等）的水量。
  1.2 水重复利用率
  是指在一定的计量时间内，生产过程中使用的重复利用水量与用水量的百分比。重复利用水量是指在确定的用水单元或系统内，使用的所有未经处理和处理后重复使用的水量的总和，即循环水量和串联水量的总和；用水量是指在确定的用水单元或系统内，使用的各种水量的总和，即新水量和重复利用水量；新水量是指企业内用水单元或系统取自任何水源被该企业第一次利用的水量。
  水重复利用率=生产过程的重复利用水量÷（重复利用水量+取水量+非常规水资源取水量）×100%
  1.3 用水综合漏失率
  是指企业的漏失水量与新水量的百分比；漏失水量是指企业供水及用水管网和用水设备漏失的水量。
  用水综合漏失率=企业的漏失水量÷企业的取水量×100%
  1.4 冷凝水回用率
  是指在一定的计量时间内，蒸汽冷凝水回用量占锅炉蒸汽发汽量的百分比。冷凝水回用量是指蒸汽经使用（例如用于汽轮机等设备做功、加热、供热、汽提分离等）冷凝后，直接或经处理后回用于锅炉和其他系统的冷凝水量。
  冷凝水回用率=蒸汽冷凝水回用量÷产汽设备产汽量×蒸汽体积质量×100%
  1.5 冷却水回用率
  是指在一定的计量时间内，冷却水利用量占冷却水总量的百分比。
  冷却水回用率=冷却水循环量÷（冷却水循环量+冷却水循环系统补充水量）×100%

2. 附加指标
  2.1 废水回用率
  是指在一定的计量时间内，企业内生产的生活和生产污水，经处理再利用的水量与排水量的百分比；排水量是指对于确定的用水单元，完成生产过程和生产活动后排出企业之外以及排出该单元进入污水系统的水量。
  废水回用率=企业外排废水处理后的回用水量÷（企业外排废水处理后的回用水量+企业向外排放的废水量）
  2.2 万元工业增加值取水量
  是指在一定的计量时间内，实现一万元工业增加值的取水量。
  单位工业增加值取水量=项目取水量÷工业增加值，在FAIS水效评估模块中由系统自动计算。
  工业增加值=工业总产值+应交增值税-工业中间投入
  2.3 万元产值取水量
  是指在一定计量时间内，生产一万元工业产值的产品的取水量，在FAIS水效评估模块中由系统自动计算。`}
      </pre>
      <div style={{position:'absolute',bottom:'20px',right:'50px'}}>
        {/* <VerticalAlignBottomOutlined onClick={downLoadPdf}/> */}
        <img src="/assets/images/download_circle_normal.png" alt="" onClick={downLoadPdf} style={{cursor:'pointer'}}/>
      </div>
    </div>
    </>
  )
}

export default QuantitativeProductData;
