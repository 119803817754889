import { Col, Popover, Row, Table, Input, Select, Pagination,message } from 'antd';
import React,{useEffect, useState} from 'react';
import './admin-declaration.scss';
import {dateFormat} from '../../utils/utils';
import { PhoneFilled } from '@ant-design/icons';
import http from '../../utils/http';
import {broadcaster} from 'web-tool-lib';
import {Subscription} from 'rxjs';

interface selectInterface {
  label:string,
  key:string
}

const AdminDeclarationPage = (prop:any) => {
  const { Search } = Input
  const { Option } = Select;
  const broadcast = broadcaster;
  const unsubscrip = new Subscription();
  const [visiable,setVisiable] = useState<boolean>(false);

  const [stageCategory,setStage] = React.useState(0);
  const [applyRange, setApplyRange] = React.useState<string[]>([]);
  const [dataList,setData] = React.useState([]);
  const [keyword,setKeyword] = React.useState('');
  const [total,setTotal] = React.useState(0);
  const [currentPage,setCurrent]=React.useState(1);

  const stageCategoryOption = [
    {
      label:'全部',
      key:0
    },{
      label:'产业示范',
      key:1
    },{
      label:'推广应用',
      key:2
    }
  ];

  const applyRangeOption = [
    {
      label:'节水',
      key:'SAVE_WATER'
    },{
      label:'环保',
      key:'ENVIRONMENT'
    },{
      label:'节能',
      key:'ENERGY_SAVING'
    },{
      label:'减碳',
      key:'CARBON_REDUCING'
    },{
      label:'资源综合利用',
      key:'RESOURCES'
    }
  ]

  let columns = [
    {
      title:'序号',
      dataIndex:'key'
    },
    {
      title: '项目名称',
      dataIndex: 'projectTemplateName',
      key: 'projectTemplateName'
    },
    {
      title: '企业名称',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: '技术名称',
      dataIndex: 'technologyName',
      key: 'technologyName'
    },
    {
      title: '阶段类别',
      dataIndex: 'stageCategoryName',
      key: 'stageCategoryName'
    },
    {
      title: '适用范围',
      dataIndex: 'applyRangesName',
      key: 'applyRangesName'
    },
    {
      title: '申报年份',
      dataIndex: 'year',
      key: 'year',
      render:(text:string)=>(
        <span>{text?dateFormat(new Date(text),'YYYY'):''}</span>
      )
      },
    {
      title: '填写人',
      dataIndex: 'declarant',
      key: 'declarant',
      render:(text:string,list:any)=>(
        list.declarant?
        <span>{text}<Popover 
          placement="topLeft" 
          content={<>电话:{list.phone}</>} 
          title="联系电话" 
          trigger="hover">
            <PhoneFilled style={{marginLeft:'5px',color:'#1C9A6A'}} /></Popover>
          </span>:''
      )
    },
    {
      title: '提交时间',
      dataIndex: 'createdTs',
      key: 'createdTs',
      render:(text:string)=>(
        <span>{text?dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss'):''}</span>
      )
    },
    {
      title: '操作',
      key: 'action',
      dataIndex: 'action',
      render: (text:any,list:any) => (
        <img onClick={e=>downFile(list.files)} style={{cursor:'pointer'}} src="/assets/images/icon_smallDownload.png" />
      ),
    }
  ];

  const changeStage = (e:number) =>{
    setStage(e);
    setCurrent(1);
    // getDeclarePage();
  }

  const changeApply = (e:Array<string>) =>{
    setApplyRange(e);
    setCurrent(1);
    // getDeclarePage();
  }

  const onSearch = (value:any) => {
    setKeyword(value);
    setCurrent(1);
    // getDeclarePage();
  }
  
  const onChange = (e:any) => {
    setKeyword(e.target.value);
  }

  const changePage = (value: number) => {
    setCurrent(value);
    // getDeclarePage();
  }

  const downFile = (value:Array<{fileId:number,showName:string}>) => {
    if(!value){
      message.error('当前申报暂无资料下载');
      return false;
    }
    // setVisiable(true);
    broadcast.broadcast("onCloseDowloadMsg",true);
    let idsArr:Array<string> = [];
    value.forEach((item:any)=>{
      idsArr.push(item.fileId);
    });
    http.downZip('/system/file/zip/download?fileIds='+idsArr.join(','),'项目申报资料');
  }

  const getDeclarePage = () => {
    http.get('/project/declare/page',{
      keyword:keyword,
      applyRanges:applyRange,
      stageCategory:stageCategory,
      pageNum:currentPage,
      pageSize:10
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((item:any,index:number)=>{
          item.key = index+1;
        })
        setData(res.data.dataList);
        setTotal(res.data.count);
      }
    })
  }

  useEffect(()=>{
    getDeclarePage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[keyword,applyRange,stageCategory,currentPage])

  // useEffect(() => {
  //   let temp1 = broadcast.on('onCloseDowloadMsg').subscribe((data: any) => {
  //     setVisiable(false);
  //   });
  //   unsubscrip.add(temp1);
  //   return () => {
  //       unsubscrip.unsubscribe();
  //   }
  // },[broadcast, unsubscrip, visiable])

  return(
    <div className="diagnosis-list">
      {visiable ? <div className="download-box">正在下载中。。。</div> : ''}
      <h1>申报管理</h1>
      <Row gutter={16} style={{marginBottom:'12px'}}>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={12}>
              阶段类别 <Select value={stageCategory} style={{ width: '60%' }} onChange={changeStage}>
                {stageCategoryOption.map((item:any,index:number)=>{
                  return <Option key={index} value={item.key}>{item.label}</Option>
                })}
              </Select>
            </Col>
            <Col span={12}>
              适用范围 <Select mode="multiple" maxTagTextLength={2} maxTagCount={2} showArrow value={applyRange} style={{ width: '70%' }} onChange={changeApply}>
                {applyRangeOption.map((item:selectInterface,index:number)=>{
                  return <Option key={index} value={item.key}>{item.label}</Option>
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col offset={6} span={6}>
          <Search
            placeholder="搜索企业名称/填写人/专家"
            allowClear
            onSearch={onSearch}
            defaultValue={keyword}
            // onChange={onChange}
            style={{ width: '100%', margin: '0 10px' }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={dataList}
        pagination={false}
        size="small"
      />
      <div className="pagination">
        <div className="pagination-total">总共{total}条信息</div>
        <Pagination
          total={total}
          current={currentPage}
          hideOnSinglePage
          onChange={changePage}
        />
      </div>
    </div>
  )
}

export default AdminDeclarationPage;