import './evaluationDetails.scss';
import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Divider, Table, Col, Row, Modal, Button } from 'antd';
import http from '../../../../utils/http';
import { WaterQualitative, productType, typeEnum } from '../../../../utils/entity';
import * as uniqid from 'uniqid';
import cookie from "react-cookies";
import { rationSiItem } from '../../../water-effect/assessment-result/evaluate-detail/interface'

const EvaluationDetails = forwardRef((props: any, ref: any) => {
    const [rationList, setRationList] = useState<Array<rationSiItem>>([]);
    const [columnsList, setColumnsList] = useState<Array<any>>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(Object);

    useImperativeHandle(ref, () => {
        return {
            func: getTableList,
        };
    })

    const showModal = (text: Object) => {
        setModalData(text)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const rationColumns = (rationList: any) => {
        return [
            {
                title: '指标要求',
                key: 'name',
                name: 'name',
                render: (text: any, record: any) => {
                    return <span key={uniqid.default()}>
                        <span className='fontwww'>{text.name || '—'}</span>
                        <span className='fontwww1'>{text.sum || ' '}分</span>
                    </span>
                }
            },
            {
                title: '园区数据',
                key: 'value',
                name: 'value',
                render: (text: any, record: any) => {
                    return <span key={uniqid.default()}>{text.value || '—'}</span>
                }
            },
            {
                title: '对标基准',
                key: 'standard',
                name: 'standard',
                render: (text: any, record: any) => {
                    return <span key={uniqid.default()}>{text.standard || '—'}</span>
                }
            },
            {
                title: '得分',
                key: 'score',
                name: 'score',
                render: (text: any, record: any) => {
                    return <span key={uniqid.default()}>{text.score || '—'}</span>
                }
            }
        ]
    }
    const columns = (columnsList: any) => {
        return [
            {
                title: '一级指标',
                dataIndex: 'name',
                key: 'name',
                width: 120,
                render: (text: any, record: any) => (
                    <span key={uniqid.default()} className="first-item">{text}</span>
                )
            }, {
                title: '二级指标',
                dataIndex: 'details',
                key: 'details',
                render: (text: any, record: any) => (
                    text.length > 1 ?
                        text.map((i: any, index: number) => {
                            return <div key={uniqid.default()}
                                className="table-row-div"
                                style={{ borderBottom: index === text.length - 1 ? '' : '1px solid #F0F0F0', height: i.children?.length * 39 + 'px' }}>
                                {index + 1}.{i.name}
                            </div>
                        })
                        : '—'
                )
            }, {
                title: '指标要求',
                dataIndex: 'details',
                key: 'details',
                render: (text: any, record: any) => (
                    text.length > 0 ?
                        text.map((i: any, index: number) =>
                            i.children?.length > 0 ?
                                i.children.map((item: any, cIndex: number) => {
                                    return item.row !== 1 ? <div key={uniqid.default()}
                                        className="table-row-div"
                                        style={{ borderBottom: index === text.length - 1 && cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px' }}>
                                        {
                                            item.name ? <span>   {text.length > 1 ? index + 1 + '.' + Number(cIndex + 1) : cIndex + 1 + '.'}{item.name}</span> : '—'
                                        }
                                    </div>
                                        :
                                        <div key={uniqid.default()}>1</div>

                                })
                                : '—'
                        )
                        : '—'
                )
            }, {
                title: '园区水平',
                dataIndex: 'details',
                key: 'details',
                width: 250,
                render: (text: any, record: any) => (
                    text.length > 0 ?
                        text.map((i: any, index: number) =>
                            i.children?.length > 0 ?
                                i.children.map((item: any, cIndex: number) => {
                                    const flagName = item.optionList && item.optionList.find((o: any) => o.flag === item.flag)?.name || item.level || '—'
                                    return <div key={uniqid.default()} className="table-row-div" style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px' }}>
                                        {flagName}
                                    </div>
                                })
                                : '—'
                        )
                        : '—'
                )
            }, {
                title: '得分',
                dataIndex: 'details',
                key: 'details',
                width: 80,
                render: (text: any, record: any) => (
                    text.length > 0 ?
                        text.map((i: any, index: number) =>
                            i.children?.length > 0 ?
                                i.children.map((item: any, cIndex: number) => {
                                    return item.row !== 1
                                        ? <div key={uniqid.default()} className="table-row-div" style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px' }}>{item.score ? item.score + '分' : '—'}</div>
                                        :
                                        <div key={uniqid.default()} className="table-row-div table-bold" style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px' }}>
                                            {item.score ? '共' + item.score + '分' : '—'}
                                        </div>
                                })
                                : '—'
                        )
                        : '—'
                )
            },
        ]
    }
    const getTableList = useCallback(() => {
        // setColumnsList(columns)
        const userInfo = cookie.load('faisUserInfo')
        const role = userInfo.roleIds[0]
        http.get('/apiWaterParkUrl/assess/result/detail/' + props.id).then((res: any) => {
            if (res && res.data) {

                setRationList(res.data?.list);
                setColumnsList(res.data?.items);
            }



        })


    }, [props.id])

    useEffect(() => {
        getTableList();
    }, [getTableList])
    const userInfo = cookie.load('faisUserInfo')
    const role = userInfo.roleIds[0]

    return (
        <>
            <div className="evaluate-detail-container">
                <Divider type="vertical" className="item-title-divider" /><p className="card-div-title">定性评估</p>

                {
                    columnsList?.length >= 1 ?
                        <div className="card-bais-box">
                            <div>
                                <Table
                                    columns={columns(columnsList)}
                                    dataSource={columnsList}
                                    pagination={false}
                                    size="small"
                                />
                            </div>
                        </div>
                        : <></>
                }
                <Divider type="vertical" className="item-title-divider" /><p className="card-div-title">技术性指标</p>

                {
                    rationList?.length >= 1 ?
                        <div className="card-bais-box">
                            <div>
                                <Table
                                    columns={rationColumns(rationList)}
                                    dataSource={rationList}
                                    pagination={false}
                                    size="small"
                                />
                            </div>
                        </div>
                        : <></>
                }

            </div>

        </>
    )
})

export default EvaluationDetails;
