import React from 'react';
import {Table, Button,Drawer, Input, Popover, Space,Pagination, message} from 'antd';
import './wasteHeat.scss';
import Http from './../../../../utils/http';
import AddWasteHeat from '../addWasteHeat/addWasteHeat';
import { adminEditAndRead } from '../../../../utils/utils';

interface energyConsumptionProps{
  id: string,
  type: number
}
export class WasteHeat extends React.Component<any,any>{
  dataList = [];
  editData:any = [];
  formRef: any;
  constructor(props:energyConsumptionProps){
    super(props);
    this.state = {
      newDrawer: false,
      tabData:[],
      total:10,
      currentPage:1,
      herRate:0,
      readAndEdit:true
    };
    this.ecPage = this.ecPage.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.changePage = this.changePage.bind(this);
    this.delete = this.delete.bind(this);
    this.modifyHerRate = this.modifyHerRate.bind(this);
    this.getHerRate = this.getHerRate.bind(this);
    this.changeHer = this.changeHer.bind(this);
  }
  setFormRef = (el: any) => this.formRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }

  onSubmitDrawer(){
    this.getFormRef();
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  changePage(page:any) {
    this.setState({
      currentPage:page
    });
    this.ecPage();
  }

  ecPage(){
    let {currentPage} = this.state;
    Http.get('/diagnosis/her/page',{
      diagnosisId:this.props.id,
      pageNum:currentPage,
      pageSize:10,
    }).then((res:any)=>{
      if(res&&res.code === 200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key=index;
        });
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count
        })
      }
      
    })
  }
  delete(value:any){
    let url = '';
    if(this.props.type === 0){
      url = '/diagnosis/her/'+value.id;
    } else {
      url = '/diagnosis/dyeing/fixed/process/her/'+value.id;
    }
    Http.DELETE(url).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功!');
        if(this.props.type===0){
          this.ecPage();
        } else {
          this.props.closeDrawer(true);
        }
      }
    });
  }

  modifyHerRate(e:any){
    if(this.props.type === 0){
      Http.put('/diagnosis/'+this.props.id+'/herRate',{
        herRate:e.target.value
      }).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功');
          this.getHerRate();
        }
      })
    } else {
      Http.put('/diagnosis/dyeing/fixed/'+this.props.dfProcessId,{
        herRate:e.target.value
      }).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功');
        }
      })
    }
  }

  getHerRate(){
    Http.get('/diagnosis/'+this.props.id+'/herRate').then((res:any)=>{
      if(res&&res.code === 200){
          this.setState({
            herRate:res.data==='null'?0:res.data
          })
      }
    })
  }

  changeHer(e:any){
    if(!isNaN(Number(e.target.value))){
      this.setState({
        herRate:e.target.value
      })
    }
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      if(this.props.type===0){
        this.ecPage();
      } else {
        this.props.closeDrawer(true);
      }
    };
    this.setState({
      newDrawer: false
    })
  }

  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.type===0){
      this.ecPage();
      this.getHerRate();
    } else {
      let tableData = this.props.tableData;
      let hers = this.props.herRate;
      if(tableData){
        tableData.forEach((i:any,index:number)=>{
          i.key = index;
        });
      }
      this.setState({
        tabData: tableData,
        herRate: hers
      })
    }
  }
  render(){
    const columns = [
      {
        title: '项目名称',
        width: '25%',
        dataIndex: 'name',
        key: 'name',
      },{
        title: '余热余能回收量(GJ)',
        width: '30%',
        dataIndex: 'num',
        key: 'num'
      },{
        title:'备注',
        width: '25%',
        dataIndex:'remark',
        key:'remark',
        ellipsis:true,
        render:(text:string)=>(
          <Popover content={text} title="备注" trigger="hover">
            <span>{text}</span>
          </Popover>
        )
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.delete(record)}>删除</span>:''}
            
          </Space>
        )
      }
    ];
    const data = this.state.tabData;
    const {total,currentPage,herRate,readAndEdit} = this.state;
    return(
      <div className="application-energy" style={this.props.type===1?{padding:'0px',marginTop:'20px'}:{}}>
        <div className="table-title" style={this.props.type===0?{marginTop:'28px'}:{}}>余热余能回收</div>
        <div className="application-energy-head">
          <div style={{display:'flex',alignItems:'center'}}>余热余能回收率：<Input disabled={!readAndEdit} value={herRate} style={{width:'130px'}} placeholder="请输入数值" onChange={this.changeHer} onBlur={e=>this.modifyHerRate(e)} />%</div>
          {readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''}
          
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
        />
        {this.props.type===0?<>
          <div className="pagination">
          <div className="pagination-total">总共{data.length}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
        </div>
        </>:''}
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
            </div>
          }
        >
          <AddWasteHeat 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id} 
            type={this.props.type}
            dfProcessId={this.props.dfProcessId}
            bindRef={this.setFormRef}
          ></AddWasteHeat>
        </Drawer>
      </div>
    )
  }
}
export default WasteHeat;