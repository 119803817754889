import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './declaration.scss';
import Head from '../../common/head/head';
import {Checkbox, Col, Form, Input, Layout, Radio, Row, Table, Upload, message, DatePicker, Button} from 'antd';
import {CaretRightOutlined, CaretDownOutlined, DeleteOutlined} from '@ant-design/icons';
import store from '../../utils/store';
import {OPENIMG} from '../../utils/actions';
import {adminEditAndRead, verificationType} from '../../utils/utils';
import http from '../../utils/http';
import PreviewImg from '../../common/previewImg/previewImg';
import * as uniqid from 'uniqid';
import moment from 'moment';

const ThemeContext = React.createContext({
    applyRanges: [],
    files: [],
    stageCategory: '',
    technologyName: '',
    year: moment(new Date())
});

const DeclarationPage = (props: any) => {
    const {Content} = Layout;
    let maxHeight = document.body.clientHeight - 80;

    return (
        <Layout>
            <Head isDashboard={true} isInfo={false} roleId={2} unreadMsgNumber={0}/>
            <PreviewImg></PreviewImg>
            <Content className="site-layout" style={{padding: '12px 20px',}}>
                <div className="declaration-background"
                     style={{minHeight: '500px', height: maxHeight, overflowY: 'auto'}}>
                    <div className="declaration-title">绿色工艺、技术和装备申报</div>
                    <DownloadTable/>
                    <UploadInfo/>
                </div>
            </Content>
        </Layout>
    )
}

const DownloadTable = (props: any) => {
    const [colData, setData] = React.useState([]);


    const col = [
        {
            title: '文件名',
            dataIndex: 'showName'
        }, {
            title: '操作',
            key: 'action',
            fixed: 'right' as any,
            width: 100,
            render: (text: any, record: any) => <><img onClick={e => downFile(record.url)} style={{cursor: 'pointer'}}
                                                       src="/assets/images/icon_smallDownload.png"/></>,
        },
    ];

    const getDeclareList = () => {
        let params = {
            project: 'GREEN_PROCESS'
        }
        http.get('/declare/require/list', params).then((res: any) => {
            if (res && res.code === 200) {
                res.data.forEach((item: any, index: number) => {
                    item.key = index;
                })
                setData(res.data);
            }
        })
    }

    useEffect(() => {
        getDeclareList()
    }, []);

    const downFile = (url: string) => {
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('target', '_blank')
        a.click()
    }

    return (
        <>
            <div className="img-title">
                <img src="/assets/images/icon_download.png" alt=""/>申报要求下载
            </div>
            <Table columns={col} dataSource={colData} pagination={false}
                   size="small" style={{marginBottom: '1.8rem'}}/>
        </>
    )
}

const UploadInfo = () => {
    const [form] = Form.useForm();
    const [arrs, setArrs] = React.useState<any>([{
        applyRanges: [],
        files: [],
        stageCategory: '',
        technologyName: '',
        year: moment(new Date())
    }]);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);

    const layout = {
        labelCol: {span: 0},
        wrapperCol: {span: 6},
    };

    const addInfo = () => {
        let arr = arrs;
        arr.push({
            applyRanges: [],
            files: [],
            stageCategory: '',
            technologyName: '',
            year: moment(new Date())
        })
        setArrs([...arr]);
    }

    const saveInfo = () => {
        form.submit();
    }

    const getFormData = (value: any) => {
        let arr = arrs;
        let obj: any = [];
        arr.forEach((item: any, index: number) => {
            let file: any = [];
            if (item.applyRanges.length === 0 || item.files.length !== 2 || item.stageCategory === '' || item.technologyName === '' || item.year === '') {
                message.error('技术' + (index + 1) + '资料有未填写，请确认');
                return false;
            } else {
                item.files.forEach((i: any) => {
                    file.push({
                        fileId: i.id,
                        showName: i.name
                    })
                })
                obj.push({
                    applyRanges: item.applyRanges,
                    files: file,
                    stageCategory: item.stageCategory,
                    technologyName: item.technologyName,
                    year: item.year
                });
            }
        });
        if (obj.length !== arr.length) {
            message.error('提交错误,请刷新页面重新提交');
            return false;
        } else {
            setBtnLoading(true);
            http.post('/project/declare', {
                declarant: value.declarant,
                phone: value.phone,
                projectTemplate: 'GREEN_PROCESS',
                technologies: obj
            }).then((res: any) => {
                if (res && res.code === 200) {
                    message.success('提交成功！');
                }
                setBtnLoading(false);
            })
        }
    }

    const deleteItem = (value: number) => {
        if (arrs.length <= 1) {
            message.error('请至少上传一项申报资料');
            return false;
        } else {
            let arr = arrs;
            arr.splice(value, 1);
            setArrs([...arr]);
        }
    }

    return (
        <>
            <div className="img-title">
                <img src="/assets/images/icon_uploading.png" alt=""/>申报材料上传
            </div>

            {
                arrs.map((item: any, index: number) => {
                    return <ThemeContext.Provider key={uniqid.process()} value={arrs[index]}>
                        <InfoItem index={index} deleteItem={deleteItem}/>
                    </ThemeContext.Provider>
                })
            }

            <div className="add-form" onClick={addInfo}>+ 添加技术</div>
            <div className="info-item" style={{marginTop: '1.5rem'}}>
                <div className="info-item-container">
                    <Form {...layout} form={form} name="control" onFinish={getFormData}>
                        <Form.Item name="declarant" label="联系人" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="phone" label="联系电话" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            {/* <div className="add-form" onClick={saveInfo}>提交</div> */}
            <Button type={'primary'} ghost onClick={saveInfo} loading={btnLoading}
                    style={{marginLeft: '2.75rem'}}>提交</Button>
        </>
    )
}

const InfoItem = (props: any, ref: any) => {
    const [form] = Form.useForm();
    const [wordFile, setWordFile] = React.useState<any>([]);
    const [pdfFile, setPdfFile] = React.useState<any>([]);
    const [show, setShow] = React.useState<boolean>(true);

    let contextType = useContext(ThemeContext) as any;

    useEffect(() => {
        contextType.files.forEach((file: any) => {
            if (file) {
                if (file.name.indexOf('.pdf') !== -1) {
                    setPdfFile([file]);
                } else {
                    setWordFile([file]);
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            stageCategory: contextType.stageCategory,
            applyRanges: contextType.applyRanges,
            technologyName: contextType.technologyName,
            year: contextType.year,
            n: contextType.files,
        })
    }, [contextType.applyRanges, contextType.files, contextType.stageCategory, contextType.technologyName, contextType.year, form]);

    const formValueChange = (e: any) => {
        let arrKey = Object.keys(e);
        contextType[arrKey[0]] = e[arrKey[0]];
    }

    const wordUpLoad = {
        beforeUpload: (file: any) => {
            if (verificationType(file.type)) {
                wordFileUpload(file);
            } else {
                message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = wordFile;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setWordFile(fileList);
            contextType.files = [...wordFile, ...pdfFile];
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };

    const pdfUpLoad = {
        beforeUpload: (file: any) => {
            if (verificationType(file.type)) {
                pdfFileUpload(file);
            } else {
                message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = pdfFile;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setPdfFile(fileList);
            contextType.files = [...wordFile, ...pdfFile];
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };

    const wordFileUpload = (file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        http.post('/system/file/upload/auth', formData).then((res: any) => {
            if (res && res.code === 200) {
                let img = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    id: res.data,
                    url: ''
                };
                setWordFile([img]);
                contextType.files = [img, ...pdfFile];
            }
        })
    }

    const pdfFileUpload = (file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        http.post('/system/file/upload/auth', formData).then((res: any) => {
            if (res && res.code === 200) {
                let img = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    id: res.data,
                    url: ''
                };
                setPdfFile([img]);
                contextType.files = [...wordFile, img];
            }
        })
    }

    const changeShowState = () => {
        let isShow = !show;
        setShow(isShow);
    }

    const deleteItem = () => {
        props.deleteItem(props.index);
    }

    const layout = {
        labelCol: {span: 0},
        wrapperCol: {span: 7},
    };

    return (
        <div className="info-item">
            <div className="info-item-title">
                {show ? <CaretDownOutlined onClick={changeShowState} style={{color: '#07B06F', cursor: 'pointer'}}/> :
                    <CaretRightOutlined onClick={changeShowState} style={{color: '#07B06F', cursor: 'pointer'}}/>}
                <span>技术{props.index + 1}</span>&nbsp;&nbsp;&nbsp;<DeleteOutlined onClick={deleteItem}/>
            </div>
            {
                show ? <div className="info-item-container">
                    <Form {...layout} form={form} name="file-control" onValuesChange={formValueChange}>
                        <Form.Item name="technologyName" label="技术名称" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="stageCategory" label="阶段类别" rules={[{required: true}]}>
                            <Radio.Group>
                                <Radio value='DEMONSTRATE'>产业示范</Radio>
                                <Radio value='EXTENSION'>推广应用</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="applyRanges" label="适用范围" rules={[{required: true}]}>
                            <Checkbox.Group>
                                <Row>
                                    <Col span={5}>
                                        <Checkbox value="SAVE_WATER" style={{lineHeight: '32px'}}>节水</Checkbox>
                                    </Col>
                                    <Col span={5}>
                                        <Checkbox value="ENVIRONMENT" style={{lineHeight: '32px'}}>环保</Checkbox>
                                    </Col>
                                    <Col span={5}>
                                        <Checkbox value="ENERGY_SAVING" style={{lineHeight: '32px'}}>节能</Checkbox>
                                    </Col>
                                    <Col span={5}>
                                        <Checkbox value="CARBON_REDUCING" style={{lineHeight: '32px'}}>减碳</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value="RESOURCES" style={{lineHeight: '32px'}}>资源综合利用</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item name="year" label="申报年份" rules={[{required: true}]}>
                            <DatePicker
                                picker="year"
                                style={{'width': '100%'}}
                                // defaultValue={moment(new Date())}
                            />
                        </Form.Item>
                        <Form.Item name="n" label="上传申报材料" rules={[{required: true}]}>
                            <div className="file-container">
                                <Upload {...wordUpLoad} fileList={wordFile}>
                                    <div className="file-item">
                                        <div><img src="/assets/images/icon_add_word.png"/></div>
                                        <div>点击上传word材料</div>
                                    </div>
                                </Upload>

                                <Upload {...pdfUpLoad} fileList={pdfFile}>
                                    <div className="file-item">
                                        <div><img src="/assets/images/icon_add_pdf.png"/></div>
                                        <div>点击上传PDF材料</div>
                                        <div>（请上传盖章扫描件）</div>
                                    </div>
                                </Upload>
                            </div>
                        </Form.Item>
                    </Form>
                </div> : ''
            }

        </div>
    )
}

export default DeclarationPage;