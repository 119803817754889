import cookie from 'react-cookies';

export function dateFormat(date: any, fmt: any) {
    let ret;
    const opt: any = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),     // 日
        "h+": date.getHours().toString(),     // 时
        "M+": date.getMinutes().toString(),     // 时
        "s+": date.getSeconds().toString(),     // 时
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
        ;
    }
    ;
    return fmt;
}

export function verificationType(type: string) {
    if (type !== 'image/png' &&
        type !== 'image/jpeg' &&
        type !== 'image/jpg' &&
        type !== 'application/pdf' &&
        type !== 'application/vnd.ms-excel' &&
        type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        type !== 'application/msword' &&
        type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return false;
    } else {
        return true;
    }
}

//判断类型为word
export function verificationTypeWord(type: string) {
    if (type !== 'application/msword' &&
        type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return false;
    } else {
        return true;
    }
}

//判断类型为pdf
export function verificationTypePdf(type: string) {
    if (type !== 'application/pdf'
    ) {
        return false;
    } else {
        return true;
    }
}

export function verificationExcel(type: string) {
    if (type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return false;
    } else {
        return true;
    }
}

export function adminEditAndRead() {
    let isRead = cookie.load('editAndRead');
    return isRead === 'T' ? true : false;
}

export function waterEditAndRead() {
    let isRead = cookie.load('waterEffectEdit');
    return isRead === 'true' ? true : false;
}

export function isAdmin() {
    // let role = JSON.parse(cookie.load('faisUserInfo'))['roleIds'];
    let role = cookie.load('faisUserInfo');
    if (role.roleIds[0] === 0) {
        return true;
    }
    return false;
}

export function isExport() {
    let role = cookie.load('faisUserInfo');
    if (role.roleIds[0] === 2) {
        return true;
    }
    return false;
}

export function isChangeTab(diagnosisStatus: string, index: number) {
    if (isAdmin()) {
        return true;
    } else {
        if (diagnosisStatus === 'FINISH') {
            return true;
        } else {
            if (index === 0) {
                return false;
            }
            return true;
        }
    }
}

/**
 * @param diagnosisStatus
 * @param role 0管理员 1企业 2专家 3园区
 */
export function conclusionDisble(diagnosisStatus: string, role: number) {
    switch (role) {
        case 0:
            return (diagnosisStatus === 'WRITING' || diagnosisStatus === 'SENDBACK') ? true : false;
        case 1:
            return (diagnosisStatus === 'FINISH') ? false : true;
        case 2:
            return (diagnosisStatus === 'WRITING' || diagnosisStatus === 'SENDBACK') ? true : false;
        default:
            return true;
    }
}

export function handleStatisticsData(data: any, modelTitle: string) {
    data.forEach((element: any) => {
        element.result.forEach((i: any) => {
            i.chartsData = [];
            if (i.labels) {
                let amount = i.showData.reduce((total: number, num: number) => {
                    return total + num
                });
                i.labels.forEach((k: any, index: number) => {
                    i.chartsData.push({
                        label: k,
                        value: (i.showData[index] / (amount / 100)).toFixed(2),
                        isOwn: index === i.index ? true : false,
                    });
                    i.isNan = amount === 0 ? true : false
                })
            }
        })
    });

    return {data: data, modelTitle: modelTitle};
}


export enum statistics {
    diagnosis12 = '/statistics/eml',
    diagnosis22 = '/statistics/est',
    diagnosis23 = '/statistics/standard',
    diagnosis24 = '/statistics/emi/all',
    diagnosis61 = '/statistics/uec'
}

export type statosticsUrlName = 'diagnosis12' | 'diagnosis22' | 'diagnosis23' | 'diagnosis24' | 'diagnosis61';

//深拷贝
type _typeObj = { [anyKey: string]: any }

/**
 * @param copy 深拷贝
 * @param _object 如果不传返回为空对象 必须是js的{}对象
 * @param _obj 可选 返回传入的@param _object 必须是js的{}对象,
 */
export const copy = (_object: _typeObj, _obj: _typeObj = {}): _typeObj => {
    if (!(Object.prototype === Object.getPrototypeOf(_object))) {
        return new Error('传入参数***_object***类型错误')
    }
    for (let key in _object) {
        if (Object.prototype === Object.getPrototypeOf(_object[key])) {
            _obj[key] = copy(_object[key])
        } else {
            _obj[key] = _object[key]
        }
    }
    return _obj
}

export default {
    dateFormat,
    verificationType,
    adminEditAndRead,
    isAdmin,
    isChangeTab,
    isExport,
    conclusionDisble,
    handleStatisticsData
};