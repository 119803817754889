import React from 'react';
import { Form, Input, message, InputNumber} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Http from '../../../../utils/http';
import { adminEditAndRead } from '../../../../utils/utils';

export class AddWasteHeat extends React.Component<any,any>{
  constructor(props: any){
    super(props);
    this.state = {
      readAndEdit:true
    };
    props.bindRef(this);
    this.getData = this.getData.bind(this);
  }
  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(values:any){
    let url = '';
    if(this.props.type === 0){
      url = '/diagnosis/her';
    } else {
      url = '/diagnosis/dyeing/fixed/process/her'
    }
    if(this.props.editData.id){
      let requestData = {...values}
      Http.put(url+'/'+this.props.editData.id,requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功');
          this.props.closeDrawer(true);
        }
      })
    } else {
      let requestData = {...values,diagnosisId:this.props.id}
      if(this.props.type === 0){
        if(this.props.diagnosisProcessId){
          requestData = {...requestData,diagnosisProcessId:this.props.diagnosisProcessId};
        }
      } else {
        requestData = {...requestData,dfProcessId:this.props.dfProcessId}
      }
      
      Http.post(url,requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新增成功');
          this.props.closeDrawer(true);
        }
      })
    }
  }

  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.editData){
      this.createForm.current?.setFieldsValue({
        name:this.props.editData.name,
        num:this.props.editData.num,
        remark:this.props.editData.remark,
      })
    }
  }
  render(){
    const { TextArea } = Input;
    let {readAndEdit} = this.state;
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Form.Item label="项目名称" name="name" rules={[{required: true}]}>
          <Input disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="余热余能回收量（GJ）" name="num" rules={[{required: true}]}>
          <InputNumber style={{width:'100%'}} placeholder="输入回收量" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <TextArea rows={3} disabled={!readAndEdit} />
        </Form.Item>
      </Form>
    )
  }
}

export default AddWasteHeat;