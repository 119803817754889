import axios, {AxiosRequestConfig} from 'axios';
import {message} from 'antd';
import cookie from 'react-cookies';
import {navigate} from '@reach/router';
import Axios from 'axios';
import store from './store';
import {OPENPAGELOADING, CLOSEPAGELOADING} from './actions';
import httpUrlLocalhost from './../env/env.localhost';
import httpUrlProd from './../env/env.prod';
import {broadcaster} from 'web-tool-lib';

const broadcast = broadcaster;

let env = process.env.NODE_ENV;

let baseUrl = '';
let waterBaseUrl = '';
let waterParkBaseUrl = '';
if (env === 'development') {
    baseUrl = httpUrlLocalhost.apiUrl;
    waterBaseUrl = httpUrlLocalhost.apiWaterUrl;
    waterParkBaseUrl = httpUrlLocalhost.apiWaterParkUrl;
} else if (env === 'production') {
    baseUrl = httpUrlProd.apiUrl;  // 正式环境
    waterBaseUrl = httpUrlProd.apiWaterUrl;
    waterParkBaseUrl = httpUrlProd.apiWaterParkUrl;
}

let needLoadingRequestCount = 0;

function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        store.dispatch({type: OPENPAGELOADING})
    }
    needLoadingRequestCount++
}

function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        store.dispatch({type: CLOSEPAGELOADING})
    }
}

const service = axios.create({
    baseURL: baseUrl,
    timeout: 20000
})

service.interceptors.request.use(
    config => {

        showFullScreenLoading();
        if (config.url?.indexOf('apiWaterUrl/') != -1) {
            config.baseURL = waterBaseUrl;
            config.url = waterBaseUrl + config.url?.replace('apiWaterUrl/', '');
        } else if (config.url?.indexOf('apiWaterParkUrl/') != -1) {
            config.baseURL = waterParkBaseUrl;
            config.url = waterParkBaseUrl + config.url?.replace('apiWaterParkUrl/', '');
        } else {
            config.baseURL = config.baseURL?.replace('api/app', '');
        }
        if (cookie.load('token')) {
            config.headers.Authorization = cookie.load('token');
        }
        delete config.headers.Cookie;
        return config;
    },
    error => {
        tryHideFullScreenLoading();
        Promise.reject(error);
    }
)

service.interceptors.response.use(
    response => {
        tryHideFullScreenLoading()
        if (response.data instanceof Blob) {
            return response;
        } else {
            const res = response.data;
            if (res.code === 200) {
                return res;
            } else if (res.code === 500) {
                // message.error(res.message);
                return res;
            } else if (res.code === 205 || res.code === 203 || res.code === 400205) { // 需要跳转到登录页
                message.error(res.message);
                cookie.remove('token');
                cookie.remove('username');
                navigate('/login');
            } else {
                message.error(res.message);
            }
        }

    },
    error => {
        tryHideFullScreenLoading()
        message.error('请求跑丢了，请稍后重试');
    }
)

const post = (url: any, data?: any, config?: AxiosRequestConfig) => {
    return service.post(url, data, config);
}
const put = (url: any, data?: any) => {
    return service.put(url, data);
}

const DELETE = (url: any) => {
    return service.delete(url);
}

const get = (url: any, data?: any, config?: AxiosRequestConfig) => {
    if (data) {
        let getData = '?';
        for (let i in data) {
            let str = i + '=' + data[i] + '&';
            getData += str;
        }
        getData = getData.slice(0, getData.length - 1);
        url = url + getData;
    }
    return service.get(url, config);
}

const downPDF = (url: any) => {
    let domain = '';
    if (url.indexOf('/apiWaterUrl') !== -1) {
        domain = waterBaseUrl;
        url = url.replace('/apiWaterUrl', '');
    } else if (url.indexOf('/apiWaterParkUrl') !== -1) {
        domain = waterParkBaseUrl;
        url = url.replace('/apiWaterParkUrl', '');
    } else {
        domain = baseUrl;
    }
    Axios.get(domain + url, {
        headers: {
            Authorization: cookie.load('token')
        },
        responseType: "blob"
    }).then((res: any) => {
        const fileName = decodeURI(res.headers['filename']);
        let extArray = fileName.split('.');
        let ext = extArray[extArray.length - 1];
        let type = '';
        if (ext == "jpg") {
            type = "jpg"
        }
        if (ext == "jpeg") {
            type = "jpeg"
        }
        if (ext == "png") {
            type = "png"
        }
        if (ext == "pdf") {
            type = "application/pdf"
        }
        if (ext == "doc") {
            type = "application/msword"
        }

        if (ext == "docx") {
            type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        }
        // if (ext == "xls") {
        //   type = "application/vnd.ms-exceld"
        // }
        if (ext == "xls") {
            type = "application/vnd.ms-excel"
        }
        if (ext == "xlsx") {
            type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
        if (type == "") {
            message.info('不支持下载');
            return;
        }

        const blob = new Blob([res.data], {type: type});

        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        // document.body.removeChild(downloadElement);
        // window.URL.revokeObjectURL(href);

        // let a = document.createElement('a');
        // a.download = fileName;
        // a.style.display = 'none';
        // let blob = new Blob([res.data],{type:type});
        // a.href = URL.createObjectURL(blob);
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

        // const url = window.URL.createObjectURL(new Blob([res.data],{type:type}));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download',fileName);
        // document.body.appendChild(link);
        // link.click();
    })
}


const downFile = (url: any) => {
    let domain = '';
    if (url.indexOf('/apiWaterUrl') !== -1) {
        domain = waterBaseUrl;
        url = url.replace('/apiWaterUrl', '');
    } else if (url.indexOf('/apiWaterParkUrl') !== -1) {
        domain = waterParkBaseUrl;
        url = url.replace('/apiWaterParkUrl', '');
    } else {
        domain = baseUrl;
    }
    Axios.get(domain + url, {
        headers: {
            Authorization: cookie.load('token')
        }
    }).then((res: any) => {
        const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/msword'}));
        const link = document.createElement('a');
        link.href = url;
        // const fileName = decodeURI(res.headers['filename']);
        const fileName = '节能诊断报告';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    })
}

const downZip = (url: any, fileName: string) => {
    Axios.get(baseUrl + url, {
        responseType: 'blob',
        headers: {
            'Authorization': cookie.load('token'),
            'Content-Type': 'application/json;application/octet-stream'
        }
    }).then((res: any) => {
        let reader = new FileReader();
        reader.readAsText(res.data, 'utf-8');
        reader.onload = function () {
            try {
                const data = JSON.parse(reader.result as string);
                message.error(data.message);
            } catch (error) {
                const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/zip'}));
                const link = document.createElement('a');
                link.href = url;
                // const fileName = '节能诊断附件';
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                broadcast.broadcast("onCloseDowloadMsg", false);

            }

        }

    })
    return false
}


const getUrl = () => {
    let url = ''
    if (env === 'development') {
        // url = 'http://192.168.10.181:13365';  // 测试服务器环境
        url = 'http://192.168.10.134:13365';  // 测试服务器环境
        // url = 'http://192.168.10.177:13365';
    } else if (env === 'production') {
        url = 'https://www.fais.cn/api';  // 正式环境
    }
    return url;
}

const isToken = () => {
    if (cookie.load('token')) {
        return cookie.load('token');
    } else {
        return false;
    }
}

export default {
    getUrl,
    post,
    get,
    put,
    DELETE,
    isToken,
    downPDF,
    downFile,
    downZip
}