import { Badge, Col, Drawer, message, Modal, Row, Space, Table } from 'antd';
import React from 'react';
import './message-list.scss';
import MessageComponent from '../../common/message-component/message-component'
import Http from '../../utils/http';
import { AxiosResponse } from 'axios';
import { msgList, msgType, resData } from '../../utils/entity';
import { dateFormat } from '../../utils/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export class MessageList extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    this.state = {
      tabData:[],
      visible:false,
      msgId:'',
      msgTypeList:[],
      allTotal:0,
      status:''
    };
    this.onCloseDrawer = this.onCloseDrawer.bind(this);
    this.lookUp = this.lookUp.bind(this);
    this.msgPage = this.msgPage.bind(this);
    this.msgType = this.msgType.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.deleteMsg = this.deleteMsg.bind(this);
  }

  onCloseDrawer(){
    this.setState({
      visible:false
    })
  }

  refreshList(){
    this.setState({
      visible:false
    });
    this.msgPage();
    this.msgType();
  }

  changeStatus(status:string){
    this.setState({
      status:status
    },()=>{
      this.msgPage();
    })
  }

  msgPage(){
    let {allTotal,status} = this.state;
    Http.get('/diagnosis/processRecordSheet/page',{
      pageNum:1,
      pageSize:100,
      status:status
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((item:msgList,index:number)=>{
          item.key = index;
        });
        this.setState({
          tabData:res.data.dataList
        });
        if(status===''){
          this.setState({
            allTotal:res.data.count
          })
        }
      }
    })
  }

  msgType(){
    Http.get('/diagnosis/processRecordSheet/messageTypeCount').then((res:any)=>{
      if(res&&res.code===200){
        let arr:any = [];
        res.data.forEach((item:msgType) => {
          arr.push({
            count:item.count,
            type:item.messageType,
            text:item.messageType=='ONLINEDIAGNOSTICMESSAGES'?'在线诊断':'系统消息'
          })
        });
        this.setState({
          msgTypeList:arr
        });
      }
    })
  }
  
  lookUp(data:any){
    this.setState({
      msgId:data.id,
      visible: true
    })
  }

  showConfirm(data:any){
    let _that = this;
    Modal.confirm({
      title:'删除',
      icon: <ExclamationCircleOutlined />,
      content:'是否删除该消息',
      onOk(){
        _that.deleteMsg(data);
      }
    })
  }

  deleteMsg(data:any){
    Http.DELETE('/diagnosis/processRecordSheet/'+data.id).then((res:any)=>{
      if(res.code === 200){
        message.success('删除成功');
        this.msgPage();
        this.msgType();
      }
    })
  }

  componentDidMount(){
    this.msgPage();
    this.msgType();
  }

  render(){
    let {tabData,msgTypeList,msgId,allTotal,status} = this.state;
    let columns = [
      {
        title:'消息标题',
        dataIndex:'title',
        key:'title',
        render:(text:string,record:any) =>(
          record.isRead==='F'?<Badge color='orange' text={text} />:text
        )
      },
      {
        title: '消息类型',
        dataIndex: 'messageType',
        key: 'messageType',
        render:(text:string,record:any)=>(
          text=='ONLINEDIAGNOSTICMESSAGES'?'在线诊断':'系统消息'
        )
      },
      {
        title: '发出时间',
        dataIndex: 'sendTs',
        key: 'sendTs',
        render:(text:number)=>(
          dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss')
        )
      },
      {
        title: '操作',
        key: 'action',
        dataIndex: 'action',
        render: (text:any,list:any) => (
          <Space size="middle">
            <span onClick={e=>this.lookUp(list)} className="green-table-a">查看</span>
            <span onClick={e=>this.showConfirm(list)} className="green-table-a">删除</span>
          </Space>
        ),
      }
    ];
    return(
      <div className="diagnosis-list">
        <h1>消息中心</h1>
        <Row gutter={16} style={{marginBottom:'12px'}}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={4}>
                <div onClick={e=>this.changeStatus('')} className={status===''?'diagnosis-list-status-item-active diagnosis-list-status-item':'diagnosis-list-status-item'}>全部类型({allTotal})</div>
              </Col>
              {msgTypeList.map((item:any,index:number)=>{
                return <Col key={index} span={4}>
                  <div onClick={e=>this.changeStatus(item.status)} className={status===item.status?'diagnosis-list-status-item-active diagnosis-list-status-item':'diagnosis-list-status-item'}>{item.text}({item.count})</div>
                </Col>
              })}
            </Row>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={tabData}
          pagination={false}
          size="small"
        />
        <Drawer
          title='消息详情'
          width={640}
          placement="right"
          closable={true}
          onClose={this.onCloseDrawer}
          destroyOnClose={true}
          visible={this.state.visible}
        >
          <MessageComponent msgId={msgId} refClose={this.refreshList} />
        </Drawer>
      </div>
    )
  }
}

export default MessageList;