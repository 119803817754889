import React from 'react';
import { Row, Col, Checkbox, Button, message, DatePicker} from 'antd';
import './energySavingInformation.scss';
import Http from '../../../../utils/http';
import moment from 'moment';
import { dateFormat,adminEditAndRead } from '../../../../utils/utils';


export class EnergySavingInformation extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    this.state = {
      period:new Date(),
      readAndEdit:true
    };
    this.getProductInformation = this.getProductInformation.bind(this);
    this.periodChange = this.periodChange.bind(this);
    this.periodSave = this.periodSave.bind(this);
  }
  getProductInformation(){
    Http.get('/diagnosis/'+this.props.id).then((res:any)=>{
      if(res&&res.code===200){
        this.setState({
          period:res.data.period
        })
      }
    })
  }
  periodChange(e:any){
    this.setState({
      period:e
    });
  }
  periodSave(){
    Http.put('/diagnosis/'+this.props.id,{
      cl:'T',
      el:'T',
      gbd:'T',
      period:dateFormat(new Date(this.state.period),'YYYY'),
      sd:'T',
      wl:'T'
    }).then((res:any)=>{
      if(res&&res.code===200){
        message.success('保存成功');
      }
    })
  }
  disabledDate = (current:any) => {
    return current.valueOf() <= new Date('2000') || current.valueOf() >= Date.now();
  }
  componentDidMount(){
    this.getProductInformation();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }
  render(){
    const {period,readAndEdit} = this.state;
    const boundary = [{
      label:'企业层次',
      value:0
    },{
      label:'车间层次',
      value:1
    },{
      label:'设备层次(染色机，定形机)',
      value:2
    }];
    const depth = [{
      label:'通用基础诊断',
      value:0
    },{
      label:'专项诊断',
      value:1
    }]
    return(
      <div className="energy-saving-information">
        <Row className="row-bottom" justify="center">
          <Col span={2} style={{textAlign:'center'}}>诊断边界:</Col>
          <Col span={12}>
            <Checkbox.Group
              options={boundary}
              disabled
              defaultValue={[0,1,2]}
            />
          </Col>
        </Row>
        <Row className="row-bottom" justify="center">
          <Col span={2} style={{textAlign:'center'}}>诊断深度:</Col>
          <Col span={12}>
            <Checkbox.Group
              options={depth}
              disabled
              defaultValue={[0,1]}
            />
          </Col>
        </Row>
        <Row className="row-bottom" justify="center" align="middle">
          <Col span={2} style={{textAlign:'center'}}><span style={{color:'red'}}>*</span>统计期:</Col>
          <Col span={12}>
            <DatePicker
              disabled={!readAndEdit}
              picker="year"
              value={moment(period)} 
              onChange={this.periodChange} 
              disabledDate={this.disabledDate}
            />
          </Col>
        </Row>
        {readAndEdit?<Row justify="center">
          <Col span={12} offset={2}>
            <Button className="green-button" type="primary" onClick={this.periodSave} style={{marginRight:'10px'}}>保存</Button>
          </Col>
        </Row>:''}
        
      </div>
    )
  }
}

export default EnergySavingInformation;