// import { AxiosRequestConfig } from "axios";

export interface resData<T> {
    // data: {
    //   code: any
    //   data: T
    //   message: string
    //   status: boolean
    //   traceId: string
    // };
    // status: number;
    // statusText: string;
    // headers: any;
    // config: AxiosRequestConfig;
    // request?: any;
    code: any
    data: T
    message: string
    status: boolean
    traceId: string
}

export interface expertQuery {
    count: number
    dataList: Array<expertQueryDataList>
}

export interface expertQueryDataList {
    key?: number
    adminId: number
    appointTs: string
    company: string
    diagnosisExpertId: number
    id: number
    isAppoint: string
    name: string
    checked: boolean
}

export interface msgList {
    key?: number
    id: number
    isRead: responseTrueFalse,
    messageType: msgTypeEnum,
    sendTs: number,
    title: string
}

export interface msgType {
    count: number
    messageType: msgTypeEnum
}

export class processRecordSheet {
    isRead: responseTrueFalse
    operationDescribe: string
    operationSteps: string
    sendTs: string
    table: responseTrueFalse
    title: string
    tableItemList: Array<{
        title: string,
        value: string
    }>

    constructor(option: processRecordSheet) {
        this.isRead = (option && option.isRead) || 'F';
        this.operationDescribe = (option && option.operationDescribe) || '';
        this.operationSteps = (option && option.operationSteps) || '';
        this.sendTs = (option && option.sendTs) || '';
        this.table = (option && option.table) || 'F';
        this.title = (option && option.title) || '';
        this.tableItemList = (option && option.tableItemList) || []
    }
}

export interface msgInfo {
    operationDescribe: string
    operationSteps: string
    sendTs: string
    table: responseTrueFalse
    title: string
    tableItemList: Array<{
        title: string,
        value: string
    }>
}

export class conclusionDetail {
    canEdit: responseTrueFalse
    factoryPotential: {
        devicesUpgrade: string
        dyeEfficiencyUpgrade: string
        energyLostControl: string
        energyStructureUpgrade: string
        moldingEfficiencyUpgrade: string
        other: string
        processUpgrade: string
    }
    remark: string

    constructor(option: conclusionDetail) {
        this.canEdit = (option && option.canEdit) || 'F';
        this.factoryPotential = (option && option.factoryPotential) || {
            devicesUpgrade: '1',
            dyeEfficiencyUpgrade: '',
            energyLostControl: '',
            energyStructureUpgrade: '',
            moldingEfficiencyUpgrade: '',
            other: '',
            processUpgrade: ''
        };
        this.remark = (option && option.remark) || '';
    }
}

export class conclusionDetailItem {
    checked?: boolean
    content: string
    effectTime: string
    name: string
    prospectEffect: number
    prospectProfit: number
    sum: number
    type: conclusionDetailType

    constructor(option: conclusionDetailItem) {
        this.content = (option && option.content) || '';
        this.effectTime = (option && option.effectTime) || '';
        this.name = (option && option.name) || '';
        this.prospectEffect = (option && option.prospectEffect) || 0;
        this.prospectProfit = (option && option.prospectProfit) || 0;
        this.sum = (option && option.sum) || 0;
        this.type = (option && option.type) || 'ENERGY_LOST_CONTROL';
        this.checked = (option && option.checked) || false;
    }
}

export interface conclusionDetailItemInterface {
    checked?: boolean
    content: string
    effectTime: string
    name: string
    prospectEffect: number
    prospectProfit: number
    sum: number
    type: conclusionDetailType
}

export interface page {
    KeyWord: string,
    SkipCount: number,
    MaxResultCount: number,
    Sorting?: string
    PageSize?: number
    Permission?: string
    PageNum?: number
}

export class Page {
    KeyWord: string
    SkipCount: number
    MaxResultCount: number;
    Sorting?: string
    PageNum?: number

    constructor(pageObj?: page) {
        this.KeyWord = pageObj?.KeyWord || '';
        this.SkipCount = pageObj?.SkipCount || 0;
        this.MaxResultCount = pageObj?.MaxResultCount || 10;
        this.Sorting = pageObj?.Sorting || '';
        this.PageNum = pageObj?.PageNum || 1;
    }
}

export class ProductInfo {
    key?: string
    files: Array<FilesItem>
    firstProduction: ProductItem
    secondProduction: ProductItem
    thirdProduction: ProductItem
    parameterInfoList?: Array<{
        coefficientId: number
        flag: number
        value: number
    }>
    productName: string
    productItemCode: string

    constructor(option?: ProductInfo) {
        this.files = option?.files || [];
        this.firstProduction = option?.firstProduction || new ProductItem();
        this.secondProduction = option?.secondProduction || new ProductItem();
        this.thirdProduction = option?.thirdProduction || new ProductItem();
        this.productName = option?.productName || '';
        this.productItemCode = option?.productItemCode || '';
    }
}

export class ProductItem {
    key?: string
    annualOutput: ProductItemDetail
    condensateReuseRate: ProductItemDetail
    coolingWaterReuseRate: ProductItemDetail
    industrialOutput: ProductItemDetail
    leakageRate: ProductItemDetail
    outputValue: ProductItemDetail
    wastewaterReuseRate: ProductItemDetail
    waterIntake: ProductItemDetail
    waterReuseRate: ProductItemDetail
    waterIntakePerUnitOutputValue: number
    waterIntakePerUnitProduct: number
    waterConsumptionPerUnitIndustrialAddedValue: number
    standardProductOutput: number

    constructor(option?: ProductItem) {
        this.annualOutput = option?.annualOutput || new ProductItemDetail();
        this.condensateReuseRate = option?.condensateReuseRate || new ProductItemDetail();
        this.coolingWaterReuseRate = option?.coolingWaterReuseRate || new ProductItemDetail();
        this.industrialOutput = option?.industrialOutput || new ProductItemDetail();
        this.leakageRate = option?.leakageRate || new ProductItemDetail();
        this.outputValue = option?.outputValue || new ProductItemDetail();
        this.wastewaterReuseRate = option?.wastewaterReuseRate || new ProductItemDetail();
        this.waterIntake = option?.waterIntake || new ProductItemDetail();
        this.waterReuseRate = option?.waterReuseRate || new ProductItemDetail();
        this.waterIntakePerUnitOutputValue = option?.waterIntakePerUnitOutputValue || 0;
        this.waterIntakePerUnitProduct = option?.waterIntakePerUnitProduct || 0;
        this.waterConsumptionPerUnitIndustrialAddedValue = option?.waterConsumptionPerUnitIndustrialAddedValue || 0;
        this.standardProductOutput = option?.standardProductOutput || 0;
    }
}

export class ProductItemDetail {
    key?: string
    files: Array<FilesItem>
    value: number

    constructor(option?: ProductItemDetail) {
        this.files = option?.files || [];
        this.value = option?.value || 0;
    }
}

export class FilesItem {
    key?: string
    fileId: number
    showName: string

    constructor(option?: FilesItem) {
        this.fileId = option?.fileId || 0;
        this.showName = option?.showName || '';
    }
}

export interface ProductDetatil {
    key?: string
    files: FilesItem
    parameterInfoList: Array<{
        coefficientId: number
        flag: number
        value: number
    }>
    productItemCode: string
    productName: string
    productions: ProductionItem
}

export class ProductionItem {
    key?: number
    firstItem: ProductItemDetail
    secondItem: ProductItemDetail
    threeItem: ProductItemDetail
    typeEnum?: productType
    isInput?: boolean

    constructor(parameters: ProductionItem) {
        this.key = parameters?.key || 0;
        this.firstItem = parameters?.firstItem || new ProductItemDetail();
        this.secondItem = parameters?.secondItem || new ProductItemDetail();
        this.threeItem = parameters?.threeItem || new ProductItemDetail();
        this.typeEnum = parameters?.typeEnum || 'V1';
        this.isInput = parameters?.isInput || false;
    }
}


export interface WaterSummarizedResult {
    key?: string
    companyName: string
    period: string
    results: SummarizedResObj
}

export interface SummarizedResObj {
    basicCheck: boolean
    qualitativeScore: ScoreObj
    rationScore: Array<ScoreProductItem>
    result: Array<ScoreProductItem>
    basicTexts: Array<{ key: string, value: string }>
}

export interface ScoreProductItem {
    key?: string
    productName: string
    scoreObject: ScoreObj
}

export interface ScoreObj {
    additional: number
    additionalQualified: boolean
    basic: number
    basicQualified: boolean
}

export interface WaterQualitative {
    key?: string
    name: string//一级指标对象
    details: Array<SecondIndicator>//二级指标对象
    list: Array<SecondIndicator>//二级指标对象
}

export interface SecondIndicator {
    key?: string
    name: string
    level: string
    score: number
    children: Array<SecondIndicator>
}

export enum statusEnum {
    WRITING = '填写中',
    COMMITTED = '已提交',
    DIAGNOSIS = '诊断中',
    SENDBACK = '退回修改',
    FINISH = '完成诊断',
    COMMENTSSUBMITTED = '已提交意见'
}

export enum waterStatusEnum {
    SUBMITTED = '已提交',
    WRITE = '填写中',
    ING = '评审中',
    END = '完成评审',
    EXPIRE = '评审过期'
}

export enum contestStatusEnum {
    COMPANY = '标杆企业',
    COLLECTIVE = '先进集体',
    PERSON = '先进个人',
    PARK = '标杆园区',
}

export enum applicationEnum {
    NEWLY = '新建',
    REFORM = '改造'
}

export enum managementEnum {
    QUALITY = '质量管理体系',
    ENVIRONMENT = '环境管理体系',
    HEALTHY = '职业健康安全管理体系',
    ENERGY = '能源管理体系'
}

export enum awardsEnum {
    COUNTRY = '国家级',
    PROVINCE = '省部/协会级',
    CITY = '地市级'
}

export enum standardEnum {
    INTERNATIONAL = '国际标准',
    COUNTRY = '国家标准',
    INDUSTRY = '行业标准',
    LOCAL = '地方标准',
    GROUP = '团体标准'
}

export enum typeEnum {
    V1 = '年产值（万元）',
    V2 = '工业增加值（万元）',
    V3 = '年产量',
    V4 = '标准品产量',
    V5 = '取水量（m3）',
    V6 = '单位产品取水量',
    V7 = '水重复利用率（%）',
    V8 = '用水综合漏失率（%）',
    V9 = '冷凝水回用率（%）',
    V10 = '冷却水回用率（%）',
    V11 = '废水回用率（%）',
    V12 = '单位工业增加值取水量（m3/万元）',
    V13 = '单位产值取水量（m3/万元）'
}

export type productType = 'V1' | 'V2' | 'V3' | 'V4' | 'V5' | 'V6' | 'V7' | 'V8' | 'V9' | 'V10' | 'V11' | 'V12' | 'V13';


export type standardType = 'INTERNATIONAL' | 'COUNTRY' | 'INDUSTRY' | 'LOCAL' | 'GROUP';

export type awardsType = 'COUNTRY' | 'PROVINCE' | 'CITY';

export type managementType = 'QUALITY' | 'ENVIRONMENT' | 'HEALTHY' | 'ENERGY';

export type applicationType = 'NEWLY' | 'REFORM';

export type waterStatusType = 'SUBMITTED' | 'WRITE' | 'ING' | 'END' | 'EXPIRE';

export type contestStatusType = 'COMPANY' | 'COLLECTIVE' | 'PERSON' | 'PARK';

export type statusResponse = 'WRITING' | 'COMMITTED' | 'DIAGNOSIS' | 'SENDBACK' | 'FINISH' | 'COMMENTSSUBMITTED';

export type msgTypeEnum = 'SYSTEMMESSAGE' | 'ONLINEDIAGNOSTICMESSAGES';

export type responseTrueFalse = 'T' | 'F';

export type conclusionDetailType =
    'ENERGY_LOST_CONTROL'
    | 'DEVICES_UPGRADE'
    | 'ENERGY_MANAGER_UPGRADE'
    | 'PROCESS_UPGRADE'
    | 'ENERGY_STRUCTURE_UPGRADE';