import './water-park-assessment.scss';
import React, {useEffect, useState, useCallback, useRef} from 'react';
import {Tabs, Button, message, Modal} from 'antd';
import http from '../../../utils/http';
import {sum} from 'lodash';
import {waterEditAndRead} from '../../../utils/utils';
import ResultsSummary from './resultsSummary/resultsSummary';
import EvaluationDetails from './evaluationDetails/evaluationDetails';

const WaterParkAssessment = (props: any) => {
    const {TabPane} = Tabs;
    const [isShowInitPage, setIsShowInitPage] = useState<boolean>(true);
    // const summarizedRef = useRef<any>();
    // const summarizedRef = React.createRef<any>();
    const summarizedRef = useRef<any>();
    const evaluateRef = useRef<any>();
    const [readAndEdit, setReadAndEdit] = useState<boolean>(true);
    const [refGif, setRefGif] = useState<boolean>(false);
    const [isShowBtn, setIsShowBtn] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>()
    const [modalList, setModalList] = useState<Array<any>>([]);
    const [tibs, setTibs] = useState<boolean>()
    const onChangeTabs = () => {

    }
    const handleOk = () => {
    }
    const handleCancel = () => {
    }

    const handleNavigate = (data: any) => {
        if (summarizedRef.current) {
            summarizedRef.current?.func();
        }
        if (evaluateRef.current) {
            evaluateRef.current.func();
        }
    }

    const getEvaluationStatus = () => {
        http.get('/apiWaterParkUrl/assess/evaluation/' + props.id).then((res: any) => {

            if (res && res.code === 200) {
                console.log(res);

                setIsShowInitPage(res.data);
            }
        })
    }

    useEffect(() => {
        setReadAndEdit(waterEditAndRead());
    }, [])

    useEffect(() => {
        getEvaluationStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getEvaluationResult = () => {

        http.get(`/apiWaterParkUrl/assess/rules/item/list/company/check/${props.id}`).then((res: any) => {
            if (res.data.tip == false || res.data.list.length > 0) {
                setTibs(res.data.tip)
                setModalList(res.data.list)
                setModalVisible(true);
            }
            setRefGif(true);

            http.post('/apiWaterParkUrl/assess/evaluation/result/' + props.id).then((res: any) => {
                if (res && res.code === 200) {
                    message.success("评估成功");
                    setIsShowInitPage(false);
                    // setTimeout(() => {
                    handleNavigate(true);
                    // },500)
                    setRefGif(true);
                    // handleNavigate(true);
                }
            })


        })
    }

    return (


        <>

            {
                isShowInitPage ?
                    refGif ?
                        <div className="add-product-contanier">
                            <div>
                                <img alt="" src="/assets/images/water_default_loading_01.gif"/>
                            </div>
                            <div className="contanier-padding">
                                <p style={{paddingBottom: '8px', textAlign: 'center'}}>正在计算中......</p>
                                <p style={{paddingBottom: '8px'}}>生成自评估结果需要一点时间，请耐心等待</p>
                            </div>
                        </div>
                        :
                        <div className="add-product-contanier">
                            <div>
                                <img alt="" src="/assets/images/img_default_shuixiao.png"/>
                            </div>
                            <div className="contanier-padding">
                                <p style={{paddingBottom: '8px'}}>生成自评估结果需要一点时间，请耐心等待</p>
                                <Button className={readAndEdit ? 'green-button' : ''} disabled={!readAndEdit}
                                        onClick={getEvaluationResult}>自评估</Button>
                            </div>
                        </div>
                    :
                    <Tabs type="editable-card" className="myTab" defaultActiveKey="1" onChange={onChangeTabs}
                          tabBarGutter={0} addIcon={
                        <div className="assessment-title">
                            <img src="/assets/images/icon_prompt_normal.png" alt=""/>
                            <span className="title-span">首次评估后，如果更新了园区数据，请点击“自评估”更新评估结果</span>

                        </div>
                    } tabBarExtraContent={
                        <div className="assessment-tab-extra">
                            <div>
                                <Button style={{marginLeft: '8px'}} className={readAndEdit ? 'green-button' : ''}
                                        disabled={!readAndEdit} onClick={getEvaluationResult}>自评估</Button>

                            </div>
                        </div>
                    } tabBarStyle={
                        {margin: 0}
                    }>
                        <TabPane tab={<span className="pane-title">汇总结果</span>} key="1" closeIcon={<></>}>
                            <ResultsSummary id={props.id} ref={summarizedRef}></ResultsSummary>
                        </TabPane>
                        <TabPane tab={<span className="pane-title">评估明细</span>} key="2" closeIcon={<></>}>
                            <EvaluationDetails id={props.id} ref={evaluateRef}></EvaluationDetails>
                        </TabPane>
                    </Tabs>

            }


            <Modal
                title='以下信息未填写，或已填写符合但未上传证明材料'
                visible={modalVisible}
                onOk={handleOk}
                closable={false}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => {
                        setModalVisible(false)
                    }}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => {
                        setModalVisible(false)
                        props.handleBigCurrent(1)
                    }}>
                        去补充
                    </Button>,
                ]}
            >

                <div>
                    {tibs ? '' :
                        <div className="margin-bottom-20">还未填写园区产业基本信息，请前往基本信息页面补充</div>}
                    {
                        modalList.length > 0 && modalList.map((list, index) => {
                            return <div className="expert-testing-bold " key={index}>
                                {index + 1 + ' ' + list.name + '>'}

                                {
                                    list.children?.length &&
                                    list.children.map((child: any, sIndex: number) => {
                                        return <div className="expert-testing-bold" key={sIndex + 100}>
                                            {index + 1 + '.' + (sIndex + 1) + ' ' + child.name + '>'}
                                            {
                                                child.children?.length &&
                                                child.children.map((item: any, cIndex: number) => {
                                                    return <div className="expert-testing" key={cIndex + 100}>
                                                        {index + 1 + '.' + (cIndex + 1)} {item.name} (<span
                                                        style={{color: "red"}}>{item.tip}</span>)
                                                    </div>
                                                })
                                            }
                                        </div>
                                    })
                                }
                            </div>

                        })

                    }
                </div>

            </Modal>
        </>
    )
}

export default WaterParkAssessment;
