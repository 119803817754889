import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
const Histogram = (props:any) => {
    const chartRef = useRef<any>();
    useEffect(() => {
        const chart = echarts.init(chartRef.current)
        const options = {
            ...props.options,
            toolbox: {
                show: true,
                feature: {
                    mark: {
                        show: true
                    },
                    saveAsImage: {
                        show: true,
                        pixelRatio: 1,
                        title: '保存图片',
                        type: 'png',
                        lang: ['点击保存']
                    }
                }
            },
        }
        chart.setOption(options)
        return () => {
            chart.dispose()
        }
    },[props.options])

    // @ts-ignore
    return(
        <div style={{position:'relative',width:'100%'}}>
            <div style={{width: props?.width || "600px", height: props?.height || "400px"}} ref={chartRef}>
            </div>
        </div>
    )
}
export default Histogram
