import './water-saving-technology.scss';
import React, { useState, useCallback, useEffect } from 'react';
import { Table, Pagination, Drawer, Button, Space, Popover, Row, Col, Modal, message } from 'antd';
import { page, Page, applicationType, applicationEnum } from '../../../../utils/entity';
import http from '../../../../utils/http';
import { waterEditAndRead, dateFormat } from '../../../../utils/utils';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';
import AddWaterSaving from '../add-water-saving/add-water-saving';
import {ExpertScore} from "../common/expert-score";
import cookie from "react-cookies";
import {result} from "lodash";
import {getScore,getRulesScore} from "../common/expert-additional";
const WaterSavingTechnology =  (props:any) => {
  const [readAndEdit,setReadAndEdit] = useState<boolean>(true);
  const [dataList,setDataList] = useState<Array<any>>([]);
  const [total,setTotal] = useState<number>(0);
  const [searchPage,setSearchPage] = useState<page>(new Page());
  const [visiable,setVisiable] = useState<boolean>(false);
  const [editData,setEditData] = useState<any>();
  const [deleteId,setDeleteId] = useState<number>(0);
  const [deleteVisible,setDeleteVisible] = useState<boolean>(false);
  const [expertData,setExpertData] = useState<any>()

  const onSubmitDrawer = () => {
    onClose();
    getTableList();
  }

  const pageChange = (page:number) => {
    let pageData = searchPage;
    pageData.SkipCount = (page - 1) * 10;
    pageData.PageNum = page;
    setSearchPage(pageData);
    getTableList();
  }

  const getTableList = useCallback(() => {
    http.get('/apiWaterUrl/assess/saving/technology/page',{
      assessId:props.id,
      pageNum:searchPage.PageNum,
      pageSize:10
    }).then((res:any) => {
      if(res && res.code === 200){
        res.data.dataList.forEach((i:any,index:number) => {
          i.key = index+1;
        });
        setDataList(res.data.dataList);
        setTotal(res.data.count);
      }
    })
  },[props.id, searchPage.PageNum])

  const showDrawer = (data?:any) => {
    setEditData(data);
    setVisiable(true);
  }

  const onClose = () => {
    setVisiable(false);
  }

  useEffect(() => {
    setReadAndEdit(waterEditAndRead());
  }, [])

  useEffect(  () =>  {
    getTableList();
    let role = cookie.load('faisUserInfo');
    if(role.roleIds[0] === 2){
      getRulesScore('JSJS',5,props.id).then((res:any)=>{
        setExpertData(res)
      })
    }

  },[getTableList])

  const imgClick = (i:any) => {
    store.dispatch({type: OPENIMG,imgId:i.fileId,imgName:i.showName});
  }

  const deleteItem = (id:any) => {
    setDeleteId(id);
    setDeleteVisible(true);
  }

  const handleOk = () => {
    http.DELETE('/apiWaterUrl/assess/saving/technology/' + deleteId).then((res:any) => {
      if(res && res.code === 200){
        message.success("删除成功");
        handleCancel();
        getTableList();
      }
    })
  }

  const handleCancel = () => {
    setDeleteVisible(false);
  }



  const columns = [{
    title:'序号',
    dataIndex:'key',
    width:60
  },{
    title:'技术名称',
    dataIndex:'technicalName',
    key:'technicalName',
  },{
    title:'应用的工序/工艺',
    dataIndex:'process',
    key:'process',
  },{
    title:'应用项目类型',
    dataIndex:'applicationItemEnum',
    key:'applicationItemEnum',
    render:(text:applicationType,record:any) => (
      <span>{applicationEnum[text]}</span>
    )
  },{
    title:'建设时间',
    dataIndex:'produceTs',
    key:'produceTs',
    render: (text:string) => (
      <span>{text?dateFormat(new Date(text),'YYYY.mm'):''}</span>
    )
  },{
    title:'投运时间',
    dataIndex:'useTs',
    key:'useTs',
    render: (text:string) => (
      <span>{text?dateFormat(new Date(text),'YYYY.mm'):''}</span>
    )
  },{
    title:'节水量（m3）',
    dataIndex:'quantity',
    key:'quantity',
  },{
    title:'证明材料',
    dataIndex:'files',
    key:'files',
    render:(text:any, record:any) => (
      <Space size="middle">
        <Popover content={
          record?.files && record?.files.length > 0 ? record.files.map((i:any,index:number) => 
            <div key={index} onClick={() => imgClick(i)} className="div-a">{i.showName}</div>
          ) : null
        } title={null} trigger="hover">
          <span>共 <span style={{color:'#00A968'}}>{record?.files.length | 0}</span> 个</span>
        </Popover>
      </Space>
    )  
  },{
    title:'备注',
    dataIndex:'remark',
    key:'remark',
  },{
    title:'操作',
    key: 'action',
    fixed: 'right' as any,
    render:(text:any, record:any) => (
      readAndEdit?
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>编辑</span>
        <span className="green-table-a" onClick={() => deleteItem(record.id)}>删除</span>
      </Space>:
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>查看</span>
      </Space>
    )  
  }]

  const showButtonView = () => {

    if(readAndEdit){
      return (
        <div className="button-margin-left">
          <Button className="green-button" onClick={showDrawer}>新增</Button>
        </div>
      )
    }else{
      return '';
    }
  }

  const userInfo = cookie.load('faisUserInfo')
  const role =  userInfo.roleIds[0]
  // const getScore = () =>{
  //   console.log('getScore',expertData)
  //   if (expertData && role === 2){
  //     return  <ExpertScore id={props.id} item={expertData} ></ExpertScore>
  //   }
  // }
  // const getRulesScore = () =>{
  //   http.get('/apiWaterUrl/assess/rules/score/score',{
  //     assessId:props.id,
  //     additional:'JSJS',
  //   }).then(response =>{
  //     const item = {
  //       maxScore:5,
  //       expertScore:response.data,
  //       assessId:props.id,
  //       additional:"JSJS"
  //     }
  //     setExpertData(item)
  //   })
  // }
  return (
        <>
          <div className="table-container">
            <Row gutter={16} style={{paddingBottom: '8px'}}>
              <Col  span={18}>
                <div className="table-title">节水技术应用：应用国家级、省部/协会级、地市级等节水技术</div>
                <div className="table-header-content">
                  <img src="/assets/images/icon_prompt_normal.png" alt=""/><span style={{paddingLeft: '8px'}}>节水技术是指减少水损失和浪费、提高用水效率和效益、实现非常规水资源利用的技术，可参考《国家鼓励的工业节水工艺、技术和装备目录》和《国家成熟适用节水技术推广目录（2020年）》涉及到的有关纺织行业的节水型技术，例如超低浴比高温高压纱线染色机、数码喷墨印花节水工艺、喷水织机废水处理回用集成技术和干式染料自动配送节水工艺等技术。企业在近三年内应用了节水技术，可填报具体的技术信息，并提供节水技术应用证明材料。</span>
                </div>
                <div className="table-header-content">
                  <img src="/assets/images/icon_prompt_normal.png" alt=""/><span style={{paddingLeft: '8px'}}>查阅节水技术应用说明。</span>
                </div>

              </Col>

              <Col  span={6}>
                {showButtonView()}
                {getScore(expertData,props.id)}
              </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={dataList}
                pagination={false}
                size="small"
            />
            <div className="pagination">
              <div className="pagination-total">总共{total}条信息</div>
              <Pagination
                  total={total}
                  defaultCurrent={searchPage.PageNum}
                  hideOnSinglePage
                  onChange={pageChange}
              />

            </div>
            <Drawer
                title={editData?.id?'编辑':'新增'}
                width={550}
                onClose={onClose}
                destroyOnClose={true}
                visible={visiable}
                bodyStyle={{ paddingBottom: 10 }}
            >
              <AddWaterSaving
                  onClose={onClose}
                  editData={editData}
                  id={props.id}
                  onSubmitDrawer={onSubmitDrawer}></AddWaterSaving>
            </Drawer>
            <Modal
                title="删除"
                visible={deleteVisible}
                width="400px"
                cancelText="取消"
                okText="确定"
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                footer={[
                  <Button key="cancel" type="default" onClick={handleCancel}>取消</Button>,
                  <Button key="handleOk" type="primary" className="green-button" onClick={handleOk}>确认</Button>
                ]}
            >
              <p>确认要删除该项数据吗？</p>
            </Modal>
          </div>
        </>
    )


}

export default WaterSavingTechnology;