import React from 'react';
import {Table, Button,Drawer, Space,Pagination, message} from 'antd';
import './workingFluidConsumption.scss';
import Http from './../../../../utils/http';
import AddWorkingFluidConsumption from '../addWorkingFluidConsumption/addWorkingFluidConsumption';
import { adminEditAndRead } from '../../../../utils/utils';

interface energyConsumptionProps{
  id:string,
  type: number
}
export class WorkingFluidConsumption extends React.Component<any,any>{
  dataList = [];
  editData:any = [];
  formRef: any;
  constructor(props:energyConsumptionProps){
    super(props);
    this.state = {
      newDrawer: false,
      tabData:[],
      total:10,
      currentPage:1,
      readAndEdit:true
    };
    this.ecPage = this.ecPage.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.changePage = this.changePage.bind(this);
    this.delete = this.delete.bind(this);
  }
  setFormRef = (el: any) => this.formRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }

  onSubmitDrawer(){
    this.getFormRef();
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  changePage(page:any) {
    this.setState({
      currentPage:page
    });
    this.ecPage();
  }

  ecPage(){
    let {currentPage} = this.state;
    Http.get('/diagnosis/ec/page',{
      diagnosisId:this.props.id,
      pageNum:currentPage,
      pageSize:10,
      type:'WC'
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key=index;
        });
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count
        })
      }
    })
  }

  delete(value:any){
    if(this.props.type === 0){
      Http.DELETE('/diagnosis/ec/'+value.id,).then((res:any)=>{
        if(res&&res.code===200){
          message.success('删除成功!');
          this.ecPage();
        }
      });
    } else {
      Http.DELETE('/diagnosis/dyeing-fixed/'+value.id).then((res:any)=>{
        if(res&&res.code===200){
          message.success('删除成功!');
          this.props.closeDrawer(true);
        }
      });
    }
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      if(this.props.type===0){
        this.ecPage();
      } else {
        this.props.closeDrawer(true);
      }
    };
    this.setState({
      newDrawer: false
    })
  }

  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.type===0){
      this.ecPage();
    } else {
      let tableData = this.props.tableData;
      if(tableData){
        tableData.forEach((i:any,index:number)=>{
          i.key = index;
        });
      }
      this.setState({
        tabData: tableData
      })
    }
  }
  render(){
    const columns = [
      {
        title: '能源类别',
        dataIndex: 'energyName',
        width: '25%',
        key: 'energyName',
      },{
        title: '消费量',
        dataIndex: 'num',
        key: 'num',
        width: '30%',
      },{
        title:'单位',
        dataIndex:'unit',
        key:'unit',
        width: '25%',
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.delete(record)}>删除</span>:''}
          </Space>
        ),
        width:150
      }
    ];
    const data = this.state.tabData;
    const {total,currentPage,readAndEdit} = this.state;
    return(
      <div className="application-energy" style={this.props.type===1?{padding:'0px'}:{}}>
        <div className="application-energy-head">
          <div className="table-title">耗能工质消费</div>
          {readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''}
          
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
        />
        {this.props.type===0?<>
          <div className="pagination">
          <div className="pagination-total">总共{data.length}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
        </div>
        </>:''}
        
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddWorkingFluidConsumption 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id} 
            type={this.props.type}
            bindRef={this.setFormRef}
          ></AddWorkingFluidConsumption>
        </Drawer>
      </div>
    )
  }
}
export default WorkingFluidConsumption;