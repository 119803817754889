import { OPENPAGELOADING, CLOSEPAGELOADING, OPENIMG, CLOSEIMG, SETROLEIDS } from './actions';
const initState = {
    pageLoadingVal: false,
    imgState: false,
    imgId: '',
    imgName: '',
    roleId: '',
    roleIds: [],
}
const AppReducer = (state = initState, action) => {
    switch (action.type) {
        case OPENPAGELOADING: {
            return {
                pageLoadingVal: true
            }
        }
        case CLOSEPAGELOADING: {
            return {
                pageLoadingVal: false
            }
        }
        case OPENIMG: {
            return {
                imgState: true,
                imgId: action.imgId,
                imgName: action.imgName
            }
        }
        case CLOSEIMG: {
            return {
                imgState: false,
                imgId: '',
                imgName: ''
            }
        }
        case SETROLEIDS: {
            return {
                roleIds: action.roleIds,
                roleId: '',
            }
        }
        default: {
            return state
        }
    }
}

export default AppReducer