import './assessment-container';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Tabs, Button, message, Modal } from 'antd';
import SummarizedResult from '../summarized-result/summarized-result';
import EvaluateDetail from '../evaluate-detail/evaluate-detail';
import http from '../../../../utils/http';
import { sum } from 'lodash';
import { waterEditAndRead } from '../../../../utils/utils';

const AssessmentContainer = (props: any) => {
    const { TabPane } = Tabs;
    const [isShowInitPage, setIsShowInitPage] = useState<boolean>(true);
    // const summarizedRef = useRef<any>();
    // const summarizedRef = React.createRef<any>();
    const summarizedRef = useRef<any>();
    const evaluateRef = useRef<any>();
    const [readAndEdit, setReadAndEdit] = useState<boolean>(true);
    const [refGif, setRefGif] = useState<boolean>(false);
    const [isShowBtn, setIsShowBtn] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>()
    const [modalList, setModalList] = useState<Array<any>>([]);
    const onChangeTabs = () => {

    }
    const handleOk = () => { }
    const handleCancel = () => { }

    const handleNavigate = (data: any) => {
        if (summarizedRef.current) {
            summarizedRef.current?.func();
        }
        if (evaluateRef.current) {
            evaluateRef.current.func();
        }
    }

    const getEvaluationStatus = () => {

        http.get('/apiWaterUrl/assess/evaluation/' + props.id).then((res: any) => {
            if (res && res.code === 200) {
                setIsShowInitPage(res.data);
            }
        })
    }

    useEffect(() => {
        setReadAndEdit(waterEditAndRead());
    }, [])

    useEffect(() => {
        getEvaluationStatus();
        getResultStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getResultStatus = () => {

        http.get('/apiWaterUrl/assess/result/' + props.id).then((res: any) => {
            if (res && res.code === 200) {
                setIsShowBtn(res.data.submit || false);
            }
        })
    }

    const sumbitResult = () => {
        Modal.confirm({
            title: '提交审核',
            content: '如果企业信息或数据有更新，请先点击“自评估”更新评估结果后，再提交',
            onOk: () => {

                http.put('/apiWaterUrl/assess/submit/audit/' + props.id).then((res: any) => {
                    if (res && res.code === 200) {
                        message.success("提交成功");
                        getResultStatus();
                    }
                })
            }
        })
    }

    const getEvaluationResult = () => {


        http.get(`/apiWaterUrl/assess/rules/item/list/company/check/${props.id}`).then((res: any) => {
            if (res.data.length) {
                setModalList(res.data)
                setModalVisible(true);
            }
            setRefGif(true);
            http.post('/apiWaterUrl/assess/evaluation/result/' + props.id).then((res: any) => {
                if (res && res.code === 200) {
                    message.success("评估成功");
                    setIsShowInitPage(false);
                    // setTimeout(() => {
                    handleNavigate(true);
                    // },500)
                    setRefGif(true);
                    // handleNavigate(true);
                }
            })

        })
    }

    return (
        <>
            {
                isShowInitPage ?
                    refGif ?
                        <div className="add-product-contanier">
                            <div>
                                <img alt="" src="/assets/images/water_default_loading_01.gif" />
                            </div>
                            <div className="contanier-padding">
                                <p style={{ paddingBottom: '8px', textAlign: 'center' }}>正在计算中......</p>
                                <p style={{ paddingBottom: '8px' }}>生成自评估结果需要一点时间，请耐心等待</p>
                            </div>
                        </div>
                        :
                        <div className="add-product-contanier">
                            <div>
                                <img alt="" src="/assets/images/img_default_shuixiao.png" />
                            </div>
                            <div className="contanier-padding">
                                <p style={{ paddingBottom: '8px' }}>生成自评估结果需要一点时间，请耐心等待</p>
                                <Button className={readAndEdit ? 'green-button' : ''} disabled={!readAndEdit} onClick={getEvaluationResult}>自评估</Button>
                            </div>
                        </div>
                    :
                    <Tabs type="editable-card" className="myTab" defaultActiveKey="1" onChange={onChangeTabs} tabBarGutter={0} addIcon={
                        <div className="assessment-title">
                            <img src="/assets/images/icon_prompt_normal.png" alt="" />
                            <span className="title-span">首次评估后，如果更新了企业数据，请点击“自评估”更新评估结果</span>

                        </div>
                    } tabBarExtraContent={
                        <div className="assessment-tab-extra">
                            <div>
                                <Button className={isShowBtn ? 'green-button' : ''} disabled={!readAndEdit || !isShowBtn} onClick={sumbitResult}>提交审核</Button>
                                <Button style={{ marginLeft: '8px' }} className={readAndEdit ? 'green-button' : ''} disabled={!readAndEdit} onClick={getEvaluationResult}>自评估</Button>
                
                            </div>
                        </div>
                    } tabBarStyle={
                        { margin: 0 }
                    }>
                        <TabPane tab={<span className="pane-title">汇总结果</span>} key="1" closeIcon={<></>}>
                            <SummarizedResult id={props.id} proType={props.proType} ref={summarizedRef}></SummarizedResult>
                        </TabPane>
                        <TabPane tab={<span className="pane-title">评估明细</span>} key="2" closeIcon={<></>}>
                            <EvaluateDetail id={props.id} proType={props.proType} ref={evaluateRef}></EvaluateDetail>
                        </TabPane>
                    </Tabs>

            }

            <Modal
                title="定性评估中以下指标未填写"
                visible={modalVisible}
                onOk={handleOk}
                closable={false}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => { setModalVisible(false) }}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => {
                        setModalVisible(false)
                        props.handleBigCurrent(1)
                    }}>
                        去补充
                    </Button>,
                ]}
            >

                <div>
                    {
                        modalList.length > 0 && modalList.map((list, index) => {
                            return <div className="expert-testing-bold " key={index}>
                                {index + 1 + ' ' + list.name + '>'}
                                {
                                    list.children?.length &&

                                    list.children.map((child: any, sIndex: number) => {
                                        return <div className="expert-testing-bold" key={sIndex + 100}>
                                            {index + 1 + '.' + (sIndex + 1) + ' ' + child.name + '>'}
                                            {
                                                child.children?.length &&
                                                child.children.map((item: any, cIndex: number) => {
                                                    return <div className="expert-testing" key={cIndex + 100}>
                                                        {index + 1 + '.' + (sIndex + 1) + ' ' + (cIndex + 1)}  {item.name} (<span style={{ color: "red" }}>{item.tip}</span>)
                                                    </div>
                                                })
                                            }
                                        </div>
                                    })
                                }
                            </div>

                        })

                    }
                </div>

            </Modal>
        </>
    )
}

export default AssessmentContainer;
