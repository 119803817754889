import React, {useEffect, useState} from 'react';
import Histogram from "../echart/histogram";
import './index.scss'
import Http from "../../../utils/http";
import {ExportOutlined, SyncOutlined} from "@ant-design/icons";
import * as XLSX from "xlsx";

const UserBasicInfoStatistics = (props: any) => {
    const [visible, setVisible] = useState<boolean>(false)
    const [visibleIndustry, setVisibleIndustry] = useState<boolean>(false)
    const [visibleUserType, setVisibleUserType] = useState<boolean>(false)
    const [userAreaOptions, setUserAreaOptions] = useState<any>({})
    const [userIndustryOptions, setUserIndustryOptions] = useState<any>({})
    const [userRoleOptions, setUserRoleOptions] = useState<any>({})
    const [userArea, setUserArea] = useState<any>({})
    const [userIndustry, setUserIndustry] = useState<any>({})
    const [userRole, setUserRole] = useState<any>({})

    const getStatistics = () => {
        Http.get('/system/statistics/user/area', {
            type: 'LINE'
        }).then((res: any) => {
            setUserArea(res.data)
            setUserAreaOptions({
                // 标题
                title: {}, // 提示框组件
                tooltip: {
                    // trigger: 'axis'
                }, color: ['#00A968'], // 图例组件
                legend: {}, // x轴
                xAxis: {
                    type: 'category', data: res.data.labels
                }, // y轴
                yAxis: {
                    type: 'value'
                }, series: [{
                    data: res.data.values, type: 'bar', // 柱状图
                }]
            })

        })

        Http.get('/system/statistics/user/industry', {
            type: 'LINE'
        }).then((res: any) => {
            setUserIndustry(res.data)
            setUserIndustryOptions({
                // 标题
                title: {}, // 提示框组件
                tooltip: {
                    // trigger: 'axis'
                }, color: ['#00A968'], // 图例组件
                legend: {}, // x轴
                xAxis: {
                    type: 'category', data: res.data.labels
                }, // y轴
                yAxis: {
                    type: 'value'
                }, series: [{
                    data: res.data.values, type: 'bar', // 柱状图
                }]
            })
        })
        Http.get('/system/statistics/user/role', {
            type: 'PIE'
        }).then((res: any) => {
            setUserRole(res.data)
            setUserRoleOptions({
                title: {}, tooltip: {
                    trigger: 'item'
                }, legend: {
                    top: 'bottom'
                }, color: ['#3C86CA', '#F5C000'], series: [{
                    name: '用户类型', type: 'pie', radius: '50%', data: res.data.dataList, emphasis: {
                        itemStyle: {
                            shadowBlur: 10, shadowOffsetX: 0, shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            })
        })
    }

    const download = () => {
        let userAreaObj: any = {}
        let userIndustryObj: any = {}
        let userRoleObj: any = {}
        userArea.values.forEach((item: any, index: number) => {
            userAreaObj[userArea.labels[index]] = item
        })
        userIndustry.values.forEach((item: any, index: number) => {
            userIndustryObj[userIndustry.labels[index]] = item
        })
        userRole.dataList.forEach((item: any, index: number) => {
            userRoleObj[item.name] = item.value
        })
        let headerArray = userRole.dataList.map((item: any, index: number) => {
            return item.name
        })
        let book = XLSX.utils.book_new()
        let sheet = XLSX.utils.json_to_sheet([userAreaObj], {
            header: userArea.labels
        })
        let sheet1 = XLSX.utils.json_to_sheet([userIndustryObj], {
            header: userIndustry.labels
        })
        let sheet2 = XLSX.utils.json_to_sheet([userRoleObj], {
            header: headerArray
        })
        XLSX.utils.book_append_sheet(book, sheet, '用户所属地区')
        XLSX.utils.book_append_sheet(book, sheet1, '用户所属行业')
        XLSX.utils.book_append_sheet(book, sheet2, '用户类型')
        XLSX.writeFile(book, '统计表.xlsx')
    }

    useEffect(() => {
        getStatistics()
    }, [])

    return (<>
        <div className={'flex'} style={{justifyContent: "space-between", margin: "20px 30px"}}>
            <div></div>
            <div>
                <SyncOutlined onClick={() => getStatistics()} style={{
                    color: '#00A566', cursor: "pointer", fontWeight: "bold", fontSize: "18px", marginRight: '18px'
                }}/>
                <ExportOutlined onClick={() => download()} style={{
                    color: '#00A566', cursor: "pointer", fontWeight: "bold", fontSize: "18px"
                }}/>
            </div>

        </div>
        <div className={visible ? 'his-group is-down' : 'his-group'}>
            <h2 className={'flex'}>
                <span> 用户所属地区 </span>
                {visible ? <img onClick={() => setVisible(false)} src="/assets/images/icon_tomoreTriangle_grey2x.png"
                                alt="path"/> :
                    <img onClick={() => setVisible(true)} src="/assets/images/icon_tomoreTriangle_grey_top2x.png"
                         alt="path"/>}
            </h2>
            <Histogram width={"100%"} options={userAreaOptions}></Histogram>
        </div>
        <div className={visibleIndustry ? 'his-group is-down' : 'his-group'}>
            <h2 className={'flex'}>
                <span> 用户所属行业 </span>
                {visibleIndustry ? <img onClick={() => setVisibleIndustry(false)}
                                        src="/assets/images/icon_tomoreTriangle_grey2x.png" alt="path"/> :
                    <img onClick={() => setVisibleIndustry(true)}
                         src="/assets/images/icon_tomoreTriangle_grey_top2x.png" alt="path"/>}
            </h2>
            <Histogram width={"100%"} options={userIndustryOptions}></Histogram>
        </div>
        <div className={visibleUserType ? 'his-group is-down' : 'his-group'}>
            <h2 className={'flex'}>
                <span> 用户类型 </span>
                {visibleUserType ? <img onClick={() => setVisibleUserType(false)}
                                        src="/assets/images/icon_tomoreTriangle_grey2x.png" alt="path"/> :
                    <img onClick={() => setVisibleUserType(true)}
                         src="/assets/images/icon_tomoreTriangle_grey_top2x.png" alt="path"/>}
            </h2>
            <Histogram width={"100%"} height={"440px"} options={userRoleOptions}></Histogram>
        </div>
    </>)
}

export default UserBasicInfoStatistics