import React, {useEffect, useState,useImperativeHandle} from 'react';
import Histogram from "../../echart/histogram";
import './index.scss'
import {Divider, Modal, Pagination, Table} from "antd";
import {getColumns,columns,scoreColumns} from "./columns";
import Http from "../../../../utils/http";
const XLSX = require('xlsx')

const WWF = (props:any) =>{
    const [visible,setVisible] = useState<boolean>(false)
    const [visibleAll,setVisibleAll] = useState<boolean>(false)
    const [total,setTotal] = useState<number>(50)
    const [dataList,setDataList] = useState<Array<any>>([]);
    const [statisticsItemDistribution,setStatisticItemDistribution] = useState<Array<any>>([])
    const [adminInfoPageFsem,setAdminInfoPageFsem] = useState<Array<any>>([])
    const [allPageData,setAllPageData] = useState<Array<any>>([]);
    const [statisticsTotalDistribution,setStatisticTotalDistribution] = useState<Object>({})
    const [requestData,setRequestData] = useState<Object>({pageNum:1})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData,setModalData] = useState(Object);
    const [totalScore,setTotalScore] = useState<number>(0)

    useImperativeHandle(props.onRef, () => {
        return {
            download: download,
            refresh:refresh
        };
    });
    const download = () =>{
        let arr:Array<Object> = []
        dataList.forEach((list:any)=>{
            list.items&& list.items.length && list.items.forEach((item:any,index:number)=>{
                let ls = {
                    '问卷分组':list.name,
                    '问卷题目':(index+1)+ '、'+item.name,
                    '平均得分':item.avg,
                    '1分':item.scores.find((r:any) => r.score === 1)?.count,
                    '6分':item.scores.find((r:any) => r.score === 6)?.count,
                    '2分':item.scores.find((r:any) => r.score === 2)?.count,
                    '3分':item.scores.find((r:any) => r.score === 3)?.count,
                    '4分':item.scores.find((r:any) => r.score === 4)?.count,
                    '5分':item.scores.find((r:any) => r.score === 5)?.count,
                }
                arr = [...arr,ls]
            })
        })

        let allPage = allPageData.map((item:any,index:number) =>{
            return {
                '序号':index+1, '企业名称':item.company, '用户账号':item.username,'总得分':item.score
            }
        })


        let book = XLSX.utils.book_new()
        let sheet = XLSX.utils.json_to_sheet(arr, {
            header: ['问卷分组', '问卷题目', '1分','2分', '3分', '4分', '5分', '6分', '平均得分']
        })
        let rc = dataList.map((item:any) =>{
            let name = item.name
            let r = arr.findIndex((r:any) => r['问卷分组'] === name)
            let r_list = arr.filter((r:any) => r['问卷分组'] === name)
            return {
                s:{
                    c:0,
                    r:r+1
                },
                e:{
                    c:0,
                    r:r_list.length+r
                }
            }
        })
        sheet["!merges"]=rc
        let sheet2 = XLSX.utils.json_to_sheet(allPage, {
            header: ['序号', '企业名称', '用户账号','总得分']
        })

        // 将Sheet写入工作簿
        XLSX.utils.book_append_sheet(book, sheet, '单项得分统计表')
        XLSX.utils.book_append_sheet(book, sheet2, '总分统计表')

        // 写入文件，直接触发浏览器的下载
        XLSX.writeFile(book, '单项和总分统计表.xlsx')
    }

    const refresh = () => {
        getStatisticsItemDistribution();
        getAdminInfoPageFsem()
    }

    const getAdminInfoPageFsem = () => {
        Http.get('/admin/info/page/fsem',requestData).then((response) => {
            response.data.dataList.forEach((i:any,index:number)=>{
                i.key=index + 1;
            });
            setAdminInfoPageFsem(response.data.dataList)
            setTotal(response.data.count)
        })
        Http.get('/admin/info/page/fsem',{pageNum:1,pageSize:999999999}).then((response) => {
            response.data.dataList.forEach((i:any,index:number)=>{
                i.key=index + 1;
            });
            setAllPageData(response.data.dataList)
            let num = (response.data.dataList.map((item:any) => item.score).reduce((prev:number, cur:number) => prev + cur) / response.data.dataList.length ).toFixed(2)
            setTotalScore(Number(num))
        })
    }

    const getStatisticsItemDistribution = () =>{
        Http.get('/fsem/statistics/item/distribution',{
            type:'LINE'
        }).then((res:any)=>{
            setStatisticItemDistribution(res.data)
        })
        Http.get('/fsem/statistics/total/distribution',{
            type:'LINE'
        }).then((res:any)=>{
            const  color = ['#F55926','#FD7E55','#FEB041','#FFC936','#69EEBB','#2BD091','#00A566',
                '#69EEE5','#69EEE5','#69EEE5','#00A59A','#55D4FD','#26C1F5','#418FFE','#366CFF']
            const ls = res.data.values.map(((i:any,idx:number)=>{
                const obj = {
                    value: i,
                    itemStyle: {
                        color: color[idx]
                    }
                }
                return obj
            }))
            setStatisticTotalDistribution({
                // 标题
                title: {
                },

                // 提示框组件
                tooltip: {
                    // trigger: 'axis'
                },
                // 图例组件
                legend:{

                },
                // x轴
                xAxis: {
                    type: 'category',
                    data: res.data.labels
                },
                // y轴
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: ls,
                    type:'bar', // 柱状图
                }]
            })
        })
        Http.get('/fsem/statistics/item/score').then((response)=>{
            response.data.forEach((i:any,index:number)=>{
                i.key=index + 1;
            });
            setDataList(response.data)
        })
    }

    useEffect(()=>{
        getStatisticsItemDistribution()
    },[])
    useEffect(()=>{
        getAdminInfoPageFsem()
    },[requestData])

    const showModal = (text:any) => {
        Http.get('/fsem/quest/answer/list/company',{queId:text.queId,score:text.score}).then((res:any)=>{
            res.data.forEach((i:any,index:number)=>{
                i.key=index + 1;
            });
            text.list = res.data
            setModalData(text)
        })
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const changePage = (page:any) => {
        const data = {
            pageNum:page
        }
        setRequestData(data)
    }

    return (
        <>
            <div  className={visible ? 'his-group-new is-down' : 'his-group-new' }>
                <h2 className={'flex'}>
                    <span> 单项得分统计 </span>
                    {
                        visible ? <img onClick={ () => setVisible(false)} src="/assets/images/icon_tomoreTriangle_grey2x.png" alt="path" /> :
                            <img onClick={ () => setVisible(true)} src="/assets/images/icon_tomoreTriangle_grey_top2x.png" alt="path" />
                    }

                </h2>

                <div>
                    <div className={'all-title'}>
                        <div> <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">单项得分统计表</p></div>
                        <div className={'at-right'}>
                            <span> 有效问卷总数（企业）：</span><span className={'atr-g'}>{total}</span>
                        </div>
                    </div>
                    <Table
                        columns={getColumns(showModal)}
                        dataSource={dataList}
                        pagination={false}
                        size="small"
                        style={{paddingBottom:'50px'}}
                    />
                </div>

                <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">单项得分统计图</p>
                <div className={'group-his'}>
                    {
                        statisticsItemDistribution.map((item:any,index  )=>{
                            const  color = ['#FD7E55','#FFB14E','#FFC936','#69EEBB','#69EEBB','#00A566']
                            const ls = item.statistics.values.map(((i:any,idx:number)=>{
                                const obj = {
                                    value: i,
                                    itemStyle: {
                                        color: color[idx]
                                    }
                                }
                                return obj
                            }))
                            const options =  {
                                // 标题
                                title: {
                                    // subtext:item.name,
                                    subtextStyle:{
                                        fontSize:12,
                                        width:300,
                                        overflow:"break",
                                    }
                                },
                                // 提示框组件
                                tooltip: {
                                    // trigger: 'axis'
                                },
                                // 图例组件
                                legend:{
                                    //     data:['销量'],
                                    //     show:true
                                },
                                // x轴
                                xAxis: {
                                    type: 'category',
                                    data: item.statistics.labels
                                },
                                // y轴
                                yAxis: {
                                    type: 'value'
                                },
                                series: [{
                                    data: ls,
                                    type:'bar', // 柱状图
                                }]
                            }
                            return <div key={index} className={"item-his"}>
                                <p style={{fontSize:'14px',fontWeight:'bold',color: "#262626",textAlign:'center'}}>{item.name}</p>
                                <Histogram  width={"100%"} height={"260px"} options={options} ></Histogram>
                            </div>
                        })
                    }
                </div>

            </div>

            <div style={{marginTop:'20px'}}  className={visibleAll ? 'his-group-new is-down' : 'his-group-new' }>
                <h2 className={'flex'}>
                    <span> 总分统计 </span>
                    {
                        visibleAll ? <img onClick={ () => setVisibleAll(false)} src="/assets/images/icon_tomoreTriangle_grey2x.png" alt="path" /> :
                            <img onClick={ () => setVisibleAll(true)} src="/assets/images/icon_tomoreTriangle_grey_top2x.png" alt="path" />
                    }

                </h2>

                <div>
                    <div className={'all-title'}>
                       <div> <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">总分统计表</p></div>
                        <div className={'at-right'}>
                            <span> 有效问卷总数（企业）：</span><span className={'atr-g'}>{total}</span>
                            <span> 总分平均值（满分84）：</span><span className={'atr-g'}>{totalScore}分</span>
                        </div>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={adminInfoPageFsem}
                        size="small"
                        pagination={false}
                    />
                    <div className="pagination" style={{paddingBottom:'50px'}}>
                        <div className="pagination-total">总共{total}条信息</div>
                        <Pagination
                            total={total}
                            hideOnSinglePage
                            onChange={(e) => changePage(e)}
                        />
                        {/*<Pagination defaultCurrent={1} total={50} />;*/}
                    </div>
                </div>

                <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">总分分布图</p>
                <div className={'group-his'}>
                    <Histogram  width={"100%"} height={"440px"} options={statisticsTotalDistribution} ></Histogram>
                </div>
            </div>

            <Modal title="企业列表"
                   visible={isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   footer={[ ]}
            >
                <div>
                    <p style={{color:"#666666",fontSize:"14px",fontWeight:"bold",textAlign:"center"}}>{modalData.one} </p>
                    <p style={{textAlign:"center"}}>{modalData.two} (得分：{modalData.score})</p>
                    <Table
                        columns={scoreColumns}
                        dataSource={modalData.list}
                        pagination={false}
                        size="small"
                    />

                </div>
            </Modal>

        </>
    )
}

export default WWF