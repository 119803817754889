import React from 'react';
import './user-management.scss';
import { Table, Space, Row, Col, Select, Input, Button, Pagination, Drawer, message, Popover, Modal} from 'antd';
import Http from '../../utils/http';
import {dateFormat} from '../../utils/utils';
import AddUser from '../../common/addUser/addUser';
import _ from 'lodash';
import { AnyARecord } from 'dns';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import http from '../../utils/http';

class UserManagement extends React.Component<any, any>{
  editData:any = [];
  formRef:any;
  constructor(props:any){
    super(props);
    this.state={
      dataList:[],
      newDrawer:false,
      total:10,
      currentPage:1,
      roleId:'',
      industry:'',
      keyword:''
    }
    this.getAdminPage = this.getAdminPage.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.changePage = this.changePage.bind(this);
    this.searchTab = this.searchTab.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeIndustry = this.changeIndustry.bind(this);
    this.resetPassWord = this.resetPassWord.bind(this);
    this.putPassWord = this.putPassWord.bind(this);
  }
  getAdminPage(){
    let {industry,keyword,roleId,currentPage} = this.state;
    Http.get('/admin/info/page',{
      industry:industry,
      keyword:keyword,
      roleId:roleId,
      pageNum:currentPage,
      pageSize:10,
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key = index;
          i.industry = _.join(i.industry,'，');
        });
        this.setState({
          dataList:res.data.dataList,
          total:res.data.count
        })
      }
    })
  }

  componentDidMount(){
    this.getAdminPage();
  }

  setFormRef = (el: any) => this.formRef = el;

  onSubmitDrawer(){
    this.getFormRef();
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.getAdminPage();
    };
    this.setState({
      newDrawer: false
    })
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  getFormRef(){
    this.formRef.vailForm();
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }

  deleteUser(value:any){
    Http.DELETE('/admin/'+value.id).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功!');
        this.getAdminPage();
      }
    })
  }
  changePage(page:any) {
    this.setState({
      currentPage:page
    },()=>{
      this.getAdminPage();
    });
  }

  searchTab(value:any){
    this.setState({
      keyword:value,
      currentPage:1
    },()=>{
      this.getAdminPage();
    })
  }

  changeType(e:any){
    this.setState({
      roleId:e,
      currentPage:1
    },()=>{
      this.getAdminPage();
    })
  }

  changeIndustry(e:any){
    this.setState({
      industry:e,
      currentPage:1
    },()=>{
      this.getAdminPage();
    })
  }

  resetPassWord(data:any){
    let name = '是否重置 ' +data.company + '-' + data.name + ' 的密码';
    let _that = this;
    Modal.confirm({
      title:'确认重置密码',
      icon: <ExclamationCircleOutlined />,
      content:name,
      onOk(){
        _that.putPassWord(data);
      }
    })
  }

  putPassWord(data:any){
    http.put('/admin/password/'+data.id+'',{password:'F123456'}).then((res:any)=>{
      if(res&&res.code===200){
        message.success('重置成功!');
      } else {
        message.success('重置失败');
      }
    })
  }

  render() {
    const columns = [
      {
        title: '用户名称',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '用户账号',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: '用户类型',
        dataIndex: 'roleName',
        key: 'roleName'
      },
      {
        title: '创建日期',
        dataIndex: 'createdTs',
        key: 'createdTs',
        render:(text:string)=>(
        <span>{dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss')}</span>
        )
      },
      {
        title: '所在机构',
        dataIndex: 'company',
        key: 'company',
      },
      {
        title: '所属行业',
        dataIndex: 'industry',
        key: 'industry',
        width:150,
        ellipsis:true,
        render:(text:AnyARecord)=>(
          <Popover content={text} title="行业" trigger="hover">
            <span>{text}</span>
          </Popover>
        )
      },
      {
        title: '操作',
        key: 'action',
        dataIndex: 'action',
        render: (text: any, record:any ) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record)}>编辑</span>
            <span className="green-table-a" onClick={e=>this.resetPassWord(record)}>重置密码</span>
            <span className="green-table-a" onClick={e=>this.deleteUser(record)}>删除</span>
          </Space>
        ),
      },
    ];

    const { Option } = Select;
    const { Search } = Input;
    const {dataList,currentPage,total,keyword,industry,roleId} = this.state;  
    return (
      <div className="user-container">
        <h1>用户列表</h1>
        <Row gutter={16} style={{marginBottom:'12px'}}>
          <Col span={4}>
            <Select defaultValue={roleId} style={{ width: '100%' }} onChange={this.changeType}>
              <Option value="">全部用户类型</Option>
              <Option value="2">专家</Option>
              <Option value="1">企业</Option>
              <Option value="3">园区</Option>
            </Select>
          </Col>
          <Col span={4}>
            <Select defaultValue={industry} style={{ width: '100%' }} onChange={this.changeIndustry}>
              <Option value="">所有行业</Option>
              <Option key={1} value="棉纺织及印染精加工">棉纺织及印染精加工</Option>,
              <Option key={2} value="毛纺织及染整精加工">毛纺织及染整精加工</Option>,
              <Option key={3} value="麻纺织及染整精加工">麻纺织及染整精加工</Option>,
              <Option key={4} value="丝绢纺织及印染精加工">丝绢纺织及印染精加工</Option>,
              <Option key={5} value="化纤织造及印染精加工">化纤织造及印染精加工</Option>,
              <Option key={6} value="针织或钩针编织物及其制品制造">针织或钩针编织物及其制品制造</Option>,
              <Option key={7} value="家用纺织制成品制造">家用纺织制成品制造</Option>,
              <Option key={8} value="产业用纺织制成品制造">产业用纺织制成品制造</Option>
            </Select>
          </Col>
          <Col span={4}>
            <Search
              placeholder="用户名称/账号/机构"
              defaultValue={keyword}
              onSearch={value => this.searchTab(value)}
              style={{ width: '100%' }}
            />
          </Col>
          <Col offset={10} span={2}>
            <Button type="primary" onClick={this.clickConsole} style={{background:'#00A566',borderColor:'#00A566'}}>新增用户</Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={dataList}
          pagination={false}
          size="small"
        />
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          hideOnSinglePage
          onChange={this.changePage}
        />
        <Drawer 
          title={this.editData.id?'编辑用户':'新增用户'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>
            </div>
          }
        >
          <AddUser
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id}
            bindRef={this.setFormRef}></AddUser>
        </Drawer>
        
      </div>
      
    );
  }
}

export default UserManagement;