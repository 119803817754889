import React, {useState, useEffect, useLayoutEffect} from 'react';
import './water-park-process.scss';
import {Steps, Divider, Button} from 'antd';
import {url} from 'inspector';
import {CheckCircleOutlined} from '@ant-design/icons';
import {navigate, useParams, useLocation} from '@reach/router';
import WaterParkOne1 from './water-park-one/water-park-one-1/water-park-one-1';
import WaterParkOne2 from './water-park-one/water-park-one-2/water-park-one-2';
import WaterParkOne3 from './water-park-one/water-park-one-3/water-park-one-3';
import BasicRequirements from '../../water-effect/qualitative-evaluation/basic-requirements/basic-requirements';
import WaterEffectManage from '../../water-effect/qualitative-evaluation/water-effect-manage/water-effect-manage';
import EncouragingIndicators from '../EncouragingIndicators/EncouragingIndicators';
import TechnicalIndicators from '../TechnicalIndicators/TechnicalIndicators';
import http from '../../../utils/http';
import cookie from 'react-cookies';
import WaterParkAssessment from '../water-park-assessment/water-park-assessment';

const {Step} = Steps;

const WaterParkProcess = (props: any) => {
    const [bigCurrent, setBigCurrent] = useState<number>(Number(localStorage.getItem('bigWater')) ? Number(localStorage.getItem('bigWater')) : 0);
    const [current, setCurrent] = useState<number>(Number(localStorage.getItem('currentWater')) ? Number(localStorage.getItem('currentWater')) : 0);
    const [name, setName] = useState<string>('');
    const routeParams = useParams();
    const location = useLocation();

    const id = routeParams.id;
    const selectImg = '/assets/images/icon_pen_selected.png';
    const normalImg = '/assets/images/icon_pen_normal.png';
    const resSelectImg = '/assets/images/icon_result_selected.png';
    const resNormalImg = '/assets/images/icon_result_normal.png';

    const onChange = (current: any) => {
        setCurrent(current);

        localStorage.setItem('currentWater', current);
    }

    const goBack = () => {
        let roleId = cookie.load('faisUserInfo');

        if (roleId.roleIds.includes(0)) {
            if (location.pathname.indexOf('/waterParkProcess') != -1) {

                navigate('/home/businessManage/adminWaterParkExpert');
            } else {

                navigate('/home/businessManage/adminWaterEffectAssess');
            }

        } else if (roleId.roleIds.includes(1) || roleId.roleIds.includes(2)) {
            navigate('/home/waterEffectAssess');
        } else if (roleId.roleIds.includes(3)) {
            navigate('/home/waterParkEnterprise');
        }
    }

    const onChangeBig = (bigCurrent: any) => {

        localStorage.setItem('bigWater', bigCurrent);
        localStorage.removeItem('currentWater');
        setBigCurrent(bigCurrent);

        setCurrent(0);
    };


    const nextCurrent = () => {
        let curr = current + 1;
        onChange(curr);
    }

    useEffect(() => {
        cookie.remove('waterEffectEdit', {path: '/'});
        editable();
    })


    const editable = () => {
        http.get('/apiWaterParkUrl/assess/' + id + '/editable').then((res: any) => {
            if (res && res.code === 200) {


                cookie.save('waterEffectEdit', res.data, {path: '/'});
                sessionStorage.setItem('waterEffectEdit', JSON.stringify(res.data));

            }
        })
    }

    const stepView = () => {
        switch (bigCurrent) {
            case 0:
                return <Steps size="small" style={{maxWidth: '100%'}} current={current} onChange={onChange}
                              className="site-navigation-steps-value" type="navigation">
                    <Step title="节水评价基本信息"/>
                    <Step title="园区基本信息"/>
                    <Step title="园区产业基本信息 "/>
                </Steps>;
            case 1:
                return <div>
                    <span className="contanier-title">基本要求</span>
                </div>
            case 2:
                return <div>
                    <span className="contanier-title">节水管理指标</span>
                </div>
            case 3:
                return <div>
                    <span className="contanier-title">技术性指标</span>
                </div>
            case 4:
                return <div>
                    <span className="contanier-title">鼓励性指标</span>
                </div>
            case 5:
                return <div>
                    <span className="contanier-title">园区节水评估结果</span>
                </div>

        }
        ;
    }

    const currentDOMView = () => {
        switch (bigCurrent) {
            case 0:
                switch (current) {
                    case 0:
                        return <WaterParkOne1 id={id} nextCurrent={nextCurrent}/>;
                    case 1:
                        return <WaterParkOne2 id={id} nextCurrent={nextCurrent}/>;
                    case 2:
                        return <WaterParkOne3 id={id}/>;
                }
                break;
            case 1:
                switch (current) {
                    case 0:
                        return <BasicRequirements id={id} proType={'yuanqu'} nextCurrent={nextCurrent}/>;

                }
                break;
            case 2:
                switch (current) {
                    case 0:
                        return <WaterEffectManage id={id} proType={'yuanqu'} nextCurrent={nextCurrent}/>;
                }
                break;
            case 3:
                switch (current) {
                    case 0:
                        return <TechnicalIndicators id={id} proType={'yuanqu'} nextCurrent={nextCurrent}/>;
                }
                break;
            case 4:
                switch (current) {
                    case 0:
                        return <EncouragingIndicators id={id} proType={'yuanqu'} nextCurrent={nextCurrent}/>;
                }
                break;
            case 5:
                switch (current) {
                    case 0:
                        return <WaterParkAssessment id={id} handleBigCurrent={setBigCurrent}/>;
                }
                break;
        }
    }

    return (
        <>
            <div className="water-effect-process-container">
                <div className="left-nav">
                    <Steps current={bigCurrent} onChange={onChangeBig} className="step-value-width"
                           direction="vertical">
                        <Step title="基本信息" className={bigCurrent === 0 ? 'step-active' : ''}
                              icon={<img src={bigCurrent >= 0 ? selectImg : normalImg} alt=""></img>}/>
                        <Step title="基本要求" className={bigCurrent === 1 ? 'step-active' : ''}
                              icon={<img src={bigCurrent >= 1 ? selectImg : normalImg} alt=""></img>}/>
                        <Step title="水效管理指标" className={bigCurrent === 2 ? 'step-active' : ''}
                              icon={<img src={bigCurrent >= 2 ? selectImg : normalImg} alt=""></img>}/>
                        <Step title="技术性指标" className={bigCurrent === 3 ? 'step-active' : ''}
                              icon={<img src={bigCurrent >= 3 ? selectImg : normalImg} alt=""></img>}/>
                        <Step title="鼓励性指标" className={bigCurrent === 4 ? 'step-active' : ''}
                              icon={<img src={bigCurrent >= 4 ? selectImg : normalImg} alt=""></img>}/>
                        <Step title="评估结果" className={bigCurrent === 5 ? 'step-active' : ''}
                              icon={<img src={bigCurrent >= 5 ? resSelectImg : resNormalImg} alt=""></img>}/>
                    </Steps>
                </div>
                <div className="right-container">
                    <div className="head-nav"
                         style={{backgroundColor: bigCurrent === 2 || bigCurrent === 3 || bigCurrent === 4 || bigCurrent === 5 || bigCurrent === 1 ? '' : '#fff'}}>
                        <div className="step">
                            <div className="step-container"
                                 style={{height: bigCurrent === 2 || bigCurrent === 3 || bigCurrent === 4 || bigCurrent === 5 || bigCurrent === 1 ? '' : '52px'}}>
                                {stepView()}
                            </div>

                        </div>
                        <div className="head-right">
                            <img src="/assets/images/icon_return_normal.png" alt=""></img>
                            <Button className="head-right-button" type="text" onClick={goBack}>返回列表</Button>
                        </div>
                    </div>
                    <div className="body-container" style={{height: props.maxHeight - 70}}>
                        {currentDOMView()}
                        {/* <div className="show-echants-rect" onClick={this.showEchart}>
              <div></div>
              <div></div>
              <div></div>
            </div> */}
                    </div>
                    {/* <ModalEchants getdata={this.setShowChart.bind(this)} isShow={isShowChart} data={chartData.data} modelTitle={chartData.modelTitle} type={echartIndex} /> */}
                </div>
            </div>
        </>
    )
}

export default WaterParkProcess;