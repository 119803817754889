import React from 'react';
import {Input, Select, Form, message, Row, Col, InputNumber} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Http from '../../../../utils/http';
import { adminEditAndRead } from '../../../../utils/utils';

export class AddEnterpriseTechnology extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    props.bindRef(this);
    this.state={
      list:[],
      readAndEdit:true
    };
    this.getData = this.getData.bind(this);
    this.energyList = this.energyList.bind(this);
  };
  
  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(values:any){
    delete values.energyName;
    if(this.props.editData.id){
      Http.put('/diagnosis/equip/ec/'+this.props.editData.id,values).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功');
          this.props.closeDrawer(true);
        }
      })
    } else {
      values.diagnosisId = this.props.id;
      values.diagnosisEquipmentId = this.props.tableId;
      Http.post('/diagnosis/equip/ec',values).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新增成功');
          this.props.closeDrawer(true);
        }
      })
    }
  }

  energyList(){
    let ec = new Promise(function (resolve){
      resolve(Http.get('/energy/list',{type:'EC'}));
    });
    let wc = new Promise(function (resolve){
      resolve(Http.get('/energy/list',{type:'WC'}));
    });
    Promise.all([ec,wc]).then((res:any)=>{
      let list = [...res[0].data,...res[1].data];
      this.setState({
        list:list
      })
    })
  }

  componentDidMount(){
    if(this.props.editData.id){
      this.createForm.current?.setFieldsValue(this.props.editData);
    }
    this.energyList();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }

  render(){
    const { Option } = Select;
    const {list,readAndEdit} = this.state;
    const product = list.map((i:any)=>{
      return <Option key={i.id} value={i.id}>{i.name}</Option>;
    });

    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
          <p style={{fontSize:'14px',fontWeight:'bold'}}>生产设备</p>
          <Form.Item label="设备类别及名称" name="deviceName" rules={[{required: true}]}>
            <Input disabled={!readAndEdit} />
          </Form.Item>
          <Form.Item label="规格型号" name="model" rules={[{required: true}]}>
            <Input disabled={!readAndEdit} />
          </Form.Item>
          <Form.Item label="数量" name="num" rules={[{required: true}]}>
            <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
          </Form.Item>
          <Form.Item label="主要能源消费品种" name="energyId" rules={[{required: true}]}>
            <Select style={{ width: '100%' }} placeholder="请选择" disabled={!readAndEdit} >
              {product}
            </Select>
          </Form.Item>
          <p style={{fontSize:'14px',fontWeight:'bold'}}>设备性能</p>
          {
            this.props.type===0?<>
            <Form.Item label="生产能力(万吨等)" name="productNum" rules={[{required: true}]}>
              <Input style={{width:'100%'}} disabled={!readAndEdit} />
            </Form.Item>
            <Form.Item label="节能措施" name="savingMeasure" rules={[{required: true}]}>
              <Input disabled={!readAndEdit} />
            </Form.Item>
            </>:''
          }
          {
            this.props.type===1?<><Row gutter={16}>
              <Col span={12}>
                <Form.Item label="功率(kW)" name="productNum" rules={[{required: true}]}>
                  <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="能效等级" name="efficiencyRank" rules={[{required: true}]}>
                  <Input disabled={!readAndEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="配套电机-型号" name="supportMotorType" rules={[{required: true}]}>
              <Input disabled={!readAndEdit} />
            </Form.Item>
            <Form.Item label="配套电机-能效等级" name="supportMotorRank" rules={[{required: true}]}>
              <Input disabled={!readAndEdit} />
            </Form.Item>
            </>:''
          }
          {
            this.props.type ===2?<><Row gutter={16}>
            <Col span={12}>
              <Form.Item label="容量（t/h或MW）" name="productNum" rules={[{required: true}]}>
                <Input style={{width:'100%'}} disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="能效等级" name="efficiencyRank" rules={[{required: true}]}>
                <Input disabled={!readAndEdit} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="额定热效率(%)" name="standardHeatRate" rules={[{required: true}]}>
            <InputNumber min={0} max={100} style={{width:'100%'}} disabled={!readAndEdit} />
          </Form.Item>
          </>:''
          }
        
      </Form>
    )
  }
}

export default AddEnterpriseTechnology;