import React from 'react';
import './expert-list.scss';
import { Table, Space, Row, Col, Select,Pagination} from 'antd';
import { navigate } from '@reach/router';
import Http from './../../../utils/http';
import {dateFormat} from '../../../utils/utils';
import { statusEnum, statusResponse } from '../../../utils/entity';

class ExpertList extends React.Component<any,any>{
  constructor(props: any){
    super(props);
    this.state = {
      tabData:[],
      total:10,
      currentPage:1,
      status:'',
      statuList:[],
      allTotal:0
    };
    this.getPage = this.getPage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.listBtnRender = this.listBtnRender.bind(this);
  }


  changePage(page:any) {
    this.setState({
      currentPage:page
    },()=>{
      this.getPage();
    });
  }

  getPage(){
    let {currentPage,status} = this.state;
    Http.get('/diagnosis/page/expert',{
      pageNum:currentPage,
      pageSize:10,
      status:status
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key = index + 1;
        })
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count
        });
        if(status===''){
          this.setState({
            allTotal:res.data.count
          })
        }
      }
    })
  }

  lookUp(e:any,data:any){
    // store.dispatch({type: SETDIAGNOSISSTATUS,statu:data.status});
    localStorage.clear();
    localStorage.setItem('diagnosisStatus',data.status);
    navigate('/home/diagnosisProcess/'+data.id);
  }

  getStatu(){
    Http.get('/diagnosis/expertDiagnosisStatusCount').then((res:any)=>{
      if(res&&res.code === 200){
        let statusArr:Array<{count:number,status:statusResponse,text:string}> = [];
        res.data.forEach((item:{count:number,status:statusResponse})=>{
          statusArr.push({
            count:item.count,
            status:item.status,
            text:statusEnum[item.status]
          })
        });
        this.setState({
          statuList:statusArr
        });
      }
    })
  }

  changeStatus(status:string){
    this.setState({
      status:status
    },()=>{
      this.getPage();
    })
  }

  componentDidMount() {
    this.getPage();
    this.getStatu();
  }

  listBtnRender(list:any){
    if(list.status!=='COMMITTED' && list.status!=='DIAGNOSIS'){
      return <Space size="middle">
        <span onClick={e=>this.lookUp(e,list)} className="green-table-a">查看</span>
      </Space>
    }
    if(list.status==='COMMITTED' || list.status==='DIAGNOSIS'){
      return <Space size="middle">
        <span onClick={e=>this.lookUp(e,list)} className="green-table-a">在线诊断</span>
      </Space>
    }
  }

  render() {
    const columns = [
      {
        title:'序号',
        dataIndex:'key'
      },
      {
        title:'企业名称',
        dataIndex:'company',
        key:'company'
      },
      {
        title: '统计期',
        dataIndex: 'period',
        key: 'period'
      },
      {
        title: '填写时间',
        dataIndex: 'createdTs',
        key: 'createdTs',
        render:(text:string)=>(
          <span>{dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss')}</span>
        )
      },
      {
        title: '诊断时间',
        dataIndex: 'applyTs',
        key: 'applyTs',
        render:(text:string)=>(
          <span>{dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss')}</span>
        )
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render:(text:string)=>(
          text==='SENDBACK'?'退回修改':text==='COMMENTSSUBMITTED'?'已提交意见':text==='DIAGNOSIS'?'诊断中':'完成诊断'
        )
      },
      {
        title: '操作',
        key: 'action',
        dataIndex: 'action',
        render: (text:any,list:any) => (
          this.listBtnRender(list)
        ),
      }
    ];

    const {tabData,total,currentPage,statuList,allTotal,status} = this.state;

    let statusCol = statuList.map((item:{count:string,status:statusResponse,text:string})=>{
      return <Col span={4} key={item.status}>
        <div onClick={e=>this.changeStatus(item.status)} className={status===item.status?'diagnosis-list-status-item-active diagnosis-list-status-item':'diagnosis-list-status-item'}>{item.text}({item.count})</div>
      </Col>
    })

    return (
      <div className="diagnosis-container">
        <h1>诊断列表</h1>
        <Row gutter={16} style={{marginBottom:'12px'}}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={4}>
                <div onClick={e=>this.changeStatus('')} className={status===''?'diagnosis-list-status-item-active diagnosis-list-status-item':'diagnosis-list-status-item'}>全部状态({allTotal})</div>
              </Col>
              {statusCol}
            </Row>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={tabData}
          pagination={false}
          size="small"
        />
        <div className="pagination">
          <div className="pagination-total">总共{tabData.length}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
        </div>
      </div>
      
    );
  }
} 

export default ExpertList;