import React from 'react';
import { Form, Input, Row, Col, Select, message, InputNumber} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Http from '../../../../utils/http';
import { adminEditAndRead } from '../../../../utils/utils';

export class AddWorkingFluidConsumption extends React.Component<any,any>{
  constructor(props: any){
    super(props);
    this.state = {
      energyData:[],
      readAndEdit:true
    };
    props.bindRef(this);
    this.selectChange = this.selectChange.bind(this);
    this.getData = this.getData.bind(this);
  }

  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(values:any){
    let url = '';
    if(this.props.type === 0){
      url = '/diagnosis/ec/';
    } else {
      url = '/diagnosis/dyeing-fixed/'
    }
    if(this.props.editData.id){
      let requestData = {...values,type:'WC'}
      Http.put(url+ this.props.editData.id,requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功');
          this.props.closeDrawer(true);
        }
      })
    } else {
      let requestData = {...values};
      if(this.props.type === 0){
        requestData = {...requestData,diagnosisId:this.props.id,type:'WC'}
      } else {
        url = '/diagnosis/dyeing-fixed?fixedProcessId='+this.props.id;
      }
      
      Http.post(url,requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新增成功');
          this.props.closeDrawer(true);
        }
      })
    }
  }

  selectChange(e:any){
    let data = this.state.energyData;
    let unit = data.filter((i:any)=> i.id===e)[0].unit;
    this.createForm.current?.setFieldsValue({
      unit: unit
    })
  }

  energyList(){
    if(this.props.type === 0){
      Http.get('/diagnosis/ec/'+this.props.id+'/energy/type?type=WC').then((res:any)=>{
        if(res&&res.code===200){
          this.setState({
            energyData:res.data
          })
        }
      })
    } else {
      Http.get('/diagnosis/dyeing-fixed/'+this.props.id+'/energy/type?type=WC').then((res:any)=>{
        if(res&&res.code===200){
          this.setState({
            energyData:res.data
          })
        }
      })
    }
    
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    this.energyList();
    if(this.props.editData.id){
      this.createForm.current?.setFieldsValue({
        energyId:this.props.editData.energyId,
        num:this.props.editData.num,
        unit:this.props.editData.unit,
      })
    }
  }
  render(){
    const { Option } = Select;
    let {readAndEdit} = this.state;
    const optionList = this.state.energyData.map((i:any)=>{
      return <Option disabled={i.used==='T'?true:false} key={i.id} value={i.id}>{i.name}</Option>;
    })
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Form.Item label="耗能工质类型" name="energyId" rules={[{required: true}]}>
          <Select style={{ width: '100%' }} onChange={this.selectChange} disabled={!readAndEdit}>
            {optionList}
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="消费量" name="num" rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} placeholder="输入消费量" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="单位" name="unit">
              <Input disabled/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default AddWorkingFluidConsumption;