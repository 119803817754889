import * as uniqid from "uniqid";
import React from "react";
import './index.scss'

export  const columns = [
    {
        title:'序号',
        dataIndex:'key',
        key:'key',
        width:70,
        fixed: true,
        align:"center" as any
    },
    {
        title: '企业名称',
        dataIndex: 'company',
        align:"center" as any
    },
    {
        title: '用户账号',
        dataIndex: 'username',
        align:"center" as any
    },
    {
        title: '总得分',
        dataIndex: 'score',
        sorter: (a:any, b:any) => a.score - b.score,
        align:"center" as any
    },
]

export const scoreColumns = [
    {
        title:'序号',
        dataIndex:'key',
        key:'key',
        width:70,
        fixed: true,
        align:"center" as any
    },
    {
        title: '企业名称',
        dataIndex: 'company',
        align:"center" as any
    },
]

 const getColumns = (showModal:any) => {
    let columns:any = [
        {
            title: '问卷分组',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any) => (
                <span style={{fontWeight:"bold"}} >{text}</span>
            )
        },
        {
            title:'问卷题目',
            dataIndex:'items',
            key:'items',
            render:(text:any,record:any) => (
                text.length > 1 ?
                    text.map((i:any,index:number) => {
                        return <div key={uniqid.default()}
                                    className="table-row-div"
                                    style={{borderBottom: index === text.length-1 ? '':'1px solid #F0F0F0',height: '40px'}}>
                            <span style={{marginRight:"30px"}}> {index+1}</span>{i.name}
                        </div>
                    })
                    : '—'
            )
        },
        {
            title:'1 分',
            dataIndex:'items',
            key:'items',
            render:(text:any,record:any) => (
                text.length > 1 ?
                    text.map((i:any,index:number) => {
                        const itemText = {
                            one:record.name,
                            two:index +1 +'、'+ i.name,
                            queId:i.id,
                            score:1,
                            list:[]
                        }

                        return <div key={uniqid.default()}
                                    className="table-row-div"
                                    onClick={()=>{showModal(itemText)}}
                                    style={{borderBottom: index === text.length-1 ? '':'1px solid #F0F0F0',height: '40px',color:'#00A566',textDecoration:'underline',cursor:"pointer"}}>
                            { i.scores.find((r:any) => r.score === 1)?.count }
                        </div>
                    })
                    : '—'
            )
        },
        {
            title:'2 分',
            dataIndex:'items',
            key:'items',
            render:(text:any,record:any) => (
                text.length > 1 ?
                    text.map((i:any,index:number) => {
                        const itemText = {
                            one:record.name,
                            two:index +1 +'、'+ i.name,
                            queId:i.id,
                            score:2,
                            list:[]
                        }
                        return <div key={uniqid.default()}
                                    className="table-row-div"
                                    onClick={()=>{showModal(itemText)}}
                                    style={{borderBottom: index === text.length-1 ? '':'1px solid #F0F0F0',height: '40px',color:'#00A566',textDecoration:'underline',cursor:"pointer"}}>
                            { i.scores.find((r:any) => r.score === 2)?.count }
                        </div>
                    })
                    : '—'
            )
        },
        {
            title:'3 分',
            dataIndex:'items',
            key:'items',
            render:(text:any,record:any) => (
                text.length > 1 ?
                    text.map((i:any,index:number) => {
                        const itemText = {
                            one:record.name,
                            two:index +1 +'、'+ i.name,
                            queId:i.id,
                            score:3,
                            list:[]
                        }
                        return <div key={uniqid.default()}
                                    className="table-row-div"
                                    onClick={()=>{showModal(itemText)}}
                                    style={{borderBottom: index === text.length-1 ? '':'1px solid #F0F0F0',height: '40px',color:'#00A566',textDecoration:'underline',cursor:"pointer"}}>
                            { i.scores.find((r:any) => r.score === 3)?.count }
                        </div>
                    })
                    : '—'
            )
        },
        {
            title:'4 分',
            dataIndex:'items',
            key:'items',
            render:(text:any,record:any) => (
                text.length > 1 ?
                    text.map((i:any,index:number) => {
                        const itemText = {
                            one:record.name,
                            two:index +1 +'、'+ i.name,
                            queId:i.id,
                            score:4,
                            list:[]
                        }
                        return <div key={uniqid.default()}
                                    className="table-row-div"
                                    onClick={()=>{showModal(itemText)}}
                                    style={{borderBottom: index === text.length-1 ? '':'1px solid #F0F0F0',height: '40px',color:'#00A566',textDecoration:'underline',cursor:"pointer"}}>
                            { i.scores.find((r:any) => r.score === 4)?.count }
                        </div>
                    })
                    : '—'
            )
        },
        {
            title:'5 分',
            dataIndex:'items',
            key:'items',
            render:(text:any,record:any) => (
                text.length > 1 ?
                    text.map((i:any,index:number) => {
                        const itemText = {
                            one:record.name,
                            two:index +1 +'、'+ i.name,
                            queId:i.id,
                            score:5,
                            list:[]
                        }
                        return <div key={uniqid.default()}
                                    className="table-row-div"
                                    onClick={()=>{showModal(itemText)}}
                                    style={{borderBottom: index === text.length-1 ? '':'1px solid #F0F0F0',height: '40px',color:'#00A566',textDecoration:'underline',cursor:"pointer"}}>
                            { i.scores.find((r:any) => r.score === 5)?.count }
                        </div>
                    })
                    : '—'
            )
        },
        {
            title:'6 分',
            dataIndex:'items',
            key:'items',
            render:(text:any,record:any) => (
                text.length > 1 ?
                    text.map((i:any,index:number) => {
                        const itemText = {
                            one:record.name,
                            two:index +1 +'、'+ i.name,
                            queId:i.id,
                            score:6,
                            list:[]
                        }
                        return <div key={uniqid.default()}
                                    className="table-row-div"
                                    onClick={()=>{showModal(itemText)}}
                                    style={{borderBottom: index === text.length-1 ? '':'1px solid #F0F0F0',height: '40px',color:'#00A566',textDecoration:'underline',cursor:"pointer"}}>
                            { i.scores.find((r:any) => r.score === 6)?.count }
                        </div>
                    })
                    : '—'
            )
        },
        {
            title:'平均得分',
            dataIndex:'items',
            key:'items',
            render:(text:any,record:any) => (
                text.length > 1 ?
                    text.map((i:any,index:number) => {
                        return <div key={uniqid.default()}
                                    className="table-row-div"
                                    style={{borderBottom: index === text.length-1 ? '':'1px solid #F0F0F0',height: '40px'}}>
                            {i.avg}
                        </div>
                    })
                    : '—'
            )
        },
    ]

    return columns
}

export {getColumns}


