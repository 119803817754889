import React from 'react';
import Http from '../../../../utils/http';
import {Row, Col, Select, Form, Input, Button, message, Upload, DatePicker, InputNumber} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';
import { adminEditAndRead, verificationType } from '../../../../utils/utils';

export class AddApplicationEnergy extends React.Component<any,any>{
  upLoad:any;
  state = {
    energyData:[],
    fileList:[],
    preview:false,
    previewUrl:'',
    readAndEdit:true
  };
  constructor(props: any){
    super(props);
    props.bindRef(this);
    this.upLoad = {
      beforeUpload: (file:any) => {
        if(verificationType(file.type)){
          this.fileUpload(file);
        } else {
          message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
        }
        return false;
      },
      onRemove: (file:any)=>{
        let {fileList} = this.state;
        fileList = fileList.filter(i=> i['uid'] !== file.uid);
        this.setState({fileList});
        return false;
      },
      onPreview:(file:any)=>{
        store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name})
        return false;
      }
    };
    this.getData = this.getData.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }

  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  fileUpload(file:any){
    const formData = new FormData();
    formData.append('file', file);
    Http.post('/system/file/upload/auth',formData).then((res:any)=>{
      if(res&&res.code===200){
        let img = {
          uid:file.uid,
          name:file.name,
          status:'done',
          id:res.data,
          url:''
        };
        this.setState({
          fileList:[...this.state.fileList,img]
        })
      }
    })
  }

  getData(values:any){
    const {fileList} = this.state;
    values.files = [];
    if(values.workTs){
      values.workTs = new Date(values.workTs).getTime();
    }
    if(values.buildTs){
      values.buildTs = new Date(values.buildTs).getTime();
    }
    fileList.forEach((i:any)=>{
      values.files.push({
        fileId:i.id,
        showName:i.name
      });
    })

    if(this.props.editData.id){
      Http.put('/diagnosis/est/'+this.props.editData.id,values).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功!');
          this.props.closeDrawer(true);
        }
      })
    } else {
      let requestData = {...values,diagnosisId:this.props.id}
      Http.post('/diagnosis/est',requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新建成功!');
          this.props.closeDrawer(true);
        }
      })
    }
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.editData){
      this.createForm.current?.setFieldsValue({
        name:this.props.editData.name,
        unit:this.props.editData.unit,
        remark:this.props.editData.remark,
        buildTs:moment(this.props.editData.buildTs),
        workTs:moment(this.props.editData.workTs),
        saving:this.props.editData.saving,
        tech:this.props.editData.tech,
        projectType:this.props.editData.projectType,
      });
      if(this.props.editData.urls&&this.props.editData.urls.length>0){
        let arr:any = [];
        this.props.editData.urls.forEach((i:any,index:number)=>{
          let img = {
            uid:index,
            name:i.showName,
            status:'done',
            id:i.fileId,
            url:''
          };
          arr.push(img);
        });
        this.setState({
          fileList:arr
        })
      }
    }
  }

  render(){
    const { Option } = Select;
    const { TextArea } = Input;
    const {fileList,readAndEdit} = this.state;
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Form.Item label="技术名称" name="name" rules={[{required: true}]}>
          <Input placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="应用的工艺/工序" name="tech">
          <TextArea rows={2} placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="应用项目类型" name="projectType">
          <Select style={{ width: '100%' }} placeholder="请选择" disabled={!readAndEdit} >
            <Option value="新建">新建</Option>
            <Option value="改造">改造</Option>
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="建设时间" name="buildTs">
              <DatePicker picker="month" style={{width:'100%'}} placeholder="请选择月份" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="投运时间" name="workTs">
              <DatePicker picker="month" style={{width:'100%'}} placeholder="请选择月份" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="节能量/减排量" name="saving" rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} placeholder="请输入数值" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
          <Form.Item label="单位" name="unit" rules={[{required: true}]}>
            <Input placeholder="请输入单位" disabled={!readAndEdit} />
          </Form.Item>
          </Col>
        </Row>
        <Form.Item label="备注" name="remark">
          <TextArea rows={2} placeholder="请输入" disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="上传附件">
          <Upload {...this.upLoad} fileList={fileList} disabled={!readAndEdit} >
            <Button>
              <UploadOutlined />点击上传
            </Button>支持.xls .xlsx .doc .docx .pdf .jpg格式，单个文件大小不超过20M
          </Upload>
        </Form.Item>
      </Form>
    )
  }
}

export default AddApplicationEnergy;