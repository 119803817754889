import React,{ useState, useEffect  } from 'react';
import './questionAndAnswer.scss';
import { Button, Input, Typography, Modal, Spin, message, Radio, Badge, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import http from './../../utils/http';
import {dateFormat,isAdmin} from '../../utils/utils';
const { Paragraph } = Typography
const { TextArea } = Input;

let callbackLists:any = {};
let eventHub = {
  broadcast(eventName:string,data?:any){
    let callbackList = callbackLists[eventName];
    if(!callbackList){
      return;
    }
    for(let i = 0;i<callbackList.length;i++){
      callbackList[i](data||'');
    }
  },
  on(eventName:string,callback:any){
    if(!callbackLists[eventName]){
      callbackLists[eventName] = [];
    }
    callbackLists[eventName].push(callback);
  }
}

const QuestionAndAnswer = (props:any) =>{
  const isadmin = isAdmin();

  return(
    <div className="question-and-answer-container">
      <div className="question-and-answer-content" id="handelDocID">
        <div className="question-and-answer-content-title">问题与建议中心</div>
        {isadmin?'':QuestionContainer()}
        {QuestionList({admin:isadmin})}
      </div>
    </div>
  )
}

const QuestionContainer = () =>{
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [textAreaValue,setTextAreaValue] = useState('');
  const [isSpin,setIsSpin] = useState(false);

  const handleOk = () =>{
    if(textAreaValue){
      setIsSpin(true);
      http.post('/guestbook',{questionContent:textAreaValue}).then((res:any)=>{
        if(res&&res.code===200){
          message.success('问题发布成功，请耐心等待管理员回复。回复后，可在问答列表搜索查看。');
          setIsSpin(false);
          setTextAreaValue('');
          setIsModalVisible(false);
          eventHub.broadcast('refreshList',true);
        }
      })
    } else {
      message.warn('请填写问题');
    }
  }

  const handleCancel = () =>{
    setTextAreaValue('');
    setIsModalVisible(false);
  }

  const textAreaChange = (e:any) =>{
    setTextAreaValue(e.currentTarget.value);
  }
  return(
    <>
      <div className="question-container">
        <img src="/assets/images/q&a.png" alt="去提问" style={{marginRight:'1.5rem'}} />
        <div className="question-btn-container">
          <span style={{color:'#4c5448',fontSize:'1rem',marginRight:'0.875rem'}}>好的问题将帮助更多的人</span>
          <div className="question-btn" onClick={()=> setIsModalVisible(true)}>去提问</div>
        </div>
      </div>

      <Modal title="提问" visible={isModalVisible} confirmLoading={isSpin} onOk={handleOk} onCancel={handleCancel}>
        <Spin spinning={isSpin}>
          <p>提问经管理员回复后将会展现给大家，请耐心等待</p>
          <TextArea rows={4} placeholder='请输入你的问题吧' value={textAreaValue} onChange={textAreaChange} />
        </Spin>
      </Modal>
    </>
  )
}

function QuestionList(props:{admin:boolean}){
  const [keyword,setKeyword] = useState('');
  const [questionList,setQuestionList] = useState([]);
  const [status,setStatus] = useState('T');
  const [editText,setEditText] = useState('');
  const [isUnRead,setUnRead] = useState(true);

  const keywordChange = (e:any) =>{
    setKeyword(e.currentTarget.value);
  }

  const searchKeyword = () =>{
    if(props.admin){
      getAdminQuestionList(keyword);
    } else {
      getQuestionList(keyword);
    }
    
  }

  const changeStatus = (e:any) =>{
    setStatus(e.target.value);
  }

  const getAdminQuestionList = (keyword:string) => {
    http.get('/guestbook/admin/page',{keyword:keyword,pageSize:10000,pageNum:1,status:status}).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((item:any)=>{
          item.isEdit = false;
        })
        setQuestionList(res.data.dataList);
      }
    })
  }

  const getQuestionList = (keyword:string) =>{
    http.get('/guestbook/company/page',{keyword:keyword,pageSize:10000,pageNum:1}).then((res:any)=>{
      if(res&&res.code===200){
        setQuestionList(res.data.dataList);
      }
    })
  }

  const clickEdit = (index:number) =>{
    let data = questionList as Array<any>;
    data.forEach((item:any,x:number)=>{
      if(x===index){
        item.isEdit = true;
      } else {
        item.isEdit = false;
      }
    })
    
    setQuestionList(Array.from(data) as never);
  }

  const textAreaChange = (e:any) =>{
    setEditText(e.currentTarget.value);
  }

  const cancelEdit = () =>{
    let data = questionList as Array<any>;
    data.forEach((item:any)=>{
      item.isEdit = false;
    })
    setQuestionList(Array.from(data) as never);
  }

  const replyQuestion = (index:number) => {
    let data = questionList as Array<any>;
    http.post('/guestbook/reply/ques/'+data[index].id,{replyContent:editText}).then((res:any)=>{
      if(res&&res.code===200){
        message.success('确认发布成功');
        setEditText('');
        getUnread();
        getAdminQuestionList('');
      }
    })
  }

  const getUnread = () => {
    http.get('/guestbook/no/operation/count').then((res:any)=>{
      if(res&&res.code===200){
        if(res.data&&res.data!=0){
          setUnRead(true);
        } else {
          setUnRead(false);
        }
      }
    })
  }


  useEffect (()=>{
    if(props.admin){
      getAdminQuestionList('');
    } else {
      getQuestionList('');
      eventHub.on('refreshList',function(){
        getQuestionList('');
      })
    }
    
    
  },[])

  useEffect (()=>{
    if(props.admin){
      getUnread();
      getAdminQuestionList('');
    }
  },[status])

  const style = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#1088e9',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
  };

  return(
    <>
      <div className="question-list-container">
        <div className="question-list-search">
          <Input className="question-list-search-input" value={keyword} onChange={keywordChange} placeholder="搜索标题关键字" size="large" />
          <Button className="question-list-search-btn" onClick={searchKeyword} size="large" type="primary" shape="round" icon={<SearchOutlined />} >搜索</Button>
        </div>
        {props.admin?<div className="switch-status">
          <Radio.Group onChange={changeStatus} defaultValue={status}>
            <Radio.Button value="T">已回复</Radio.Button>
            <Radio.Button value="F">{isUnRead?<Badge status="warning">未回复</Badge>:'未回复'}</Radio.Button>
          </Radio.Group>
        </div>:''}
        
        <div className="question-list">
          {questionList.map((item:any,index:number)=>{
            return <div className="question-list-item" key={index}>
            <div className="question-list-item-question">
              <div style={{display:'flex',alignItems:'center',width:'80%'}}>
                <div className="quesion-list-item-question-icon">问</div>
                <div className="quesion-list-item-question-title">
                  <Tooltip title={item.questionContent}>
                    <Paragraph ellipsis style={{marginBottom:'0'}}>
                      {item.questionContent}
                    </Paragraph>
                  </Tooltip>
                </div>
              </div>
              <div className="quesion-list-item-time">{dateFormat(new Date(item.createdTs),'YYYY-mm-dd hh:MM:ss')}</div>
            </div>
            {item.replyContent!==null?
              <div className="question-list-item-answer">
                <div style={{display:'flex',alignItems:'center'}}>
                  <div className="quesion-list-item-answer-icon">答</div>
                  <div className="quesion-list-item-answer-title">
                    <Paragraph ellipsis={{rows:2,expandable:true,symbol:'展开查看更多'}} style={{marginBottom:'0'}}>
                      {item.replyContent}
                    </Paragraph>
                  </div>
                </div>
                <div className="quesion-list-item-time">{dateFormat(new Date(item.replyTs),'YYYY-mm-dd hh:MM:ss')}</div>
              </div>:<div className="not-question-container">
                {item.isEdit?<>
                  <TextArea rows={4} value={editText} onChange={textAreaChange} />
                  <div className="not-question-submit-container">
                    <Button style={{color:'#fff',background:'#0F9940',borderColor:'#0F9940'}} onClick={e=>replyQuestion(index)}>确认发布</Button>
                    <Button style={{color:'#4d4d4d',background:'#ECECEC',borderColor:'#ECECEC',marginLeft:'1rem'}} onClick={cancelEdit} >取消</Button>
                  </div>
                </>:<div onClick={e=>clickEdit(index)} className="not-question-container-btn">回复</div>}
              </div>
            }
          </div>
          })}
        </div>
      </div>
    </>
  )
}

export default QuestionAndAnswer;