import './evaluate-detail.scss';
import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Divider, Table, Col, Row ,Modal, Button} from 'antd';
import http from '../../../../utils/http';
import { WaterQualitative, productType, typeEnum } from '../../../../utils/entity';
import * as uniqid from 'uniqid';
import {RightCircleOutlined,LeftCircleOutlined } from '@ant-design/icons'
import cookie from "react-cookies";
import {rationColumns,getColumns,scoreColumns} from './columns'
import {production, rationItem,expertItem} from './interface'
import {chain, values} from "lodash";

const EvaluateDetail = forwardRef((props:any,ref:any) => {
    const [dataList,setDataList] = useState<Array<any>>([]);
    const [rationList,setRationList] = useState<Array<rationItem>>([]);
    const [columnsList,setColumnsList] = useState<Array<any>>([]);
    const [creatColumns,setCreatColumns] = useState<Array<any>>([]);
    const [expertColumns,setExpertColumns] = useState<Array<any>>([]);
    const [visible,setVisible] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData,setModalData] = useState(Object);

    useImperativeHandle(ref,()=>{
        return{
            func:getTableList,
        };
    })

    const showModal = (text:Object) => {
        setModalData(text)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };



    const getTableList = useCallback(() => {
        // setColumnsList(columns)
        const userInfo =  cookie.load('faisUserInfo')
        const role =  userInfo.roleIds[0]
        http.get('/apiWaterUrl/assess/result/qualitative/'+props.id).then((res:any)=>{
            if(res.data.length){
                res.data[0]?.list[0]?.children.forEach((e:any)=>{
                     e.score = null
                    e.expertScore = null
                })
            }
            let a:any = []
            let expertScoreList:any = []
            if(res.data.length > 0){
                res.data.forEach((o:any,index:number) =>{
                        if(o.list?.length > 0){
                            o.list.forEach((i:any,cIndex:number) => {
                                if(i.children?.length > 0){
                                    i.children.forEach((item:any,iIndex:number) => {
                                        if(item.score) a.push(item.score)
                                        if(item.expertScore) expertScoreList.push(item.expertScore)
                                    })
                                }
                            })
                        }
                }
                )
            }
            let qy =  a.length && a.reduce((pre:number,cur:number)=>{return pre +cur})
                 // console.log('a',qy)
            let expertScore =  expertScoreList.length && expertScoreList.reduce((pre:number,cur:number)=>{return pre +cur})
            let arr:any = [
                { "name":'定性评估总得分',key:uniqid.default(),details:'',
                    list: [
                        { children:
                                [
                                    {score:qy,expertScore:expertScore,row:1,key:uniqid.default(),name:'',details:''}
                                ],
                            row:1,key:uniqid.default(),name:'',details:''
                        }
                    ]
                },
            ];
            res.data = [...res.data,...arr]
            // console.log(arr)
            res.data?.forEach((i:WaterQualitative) => {
                i.key = uniqid.default();
                i.details = i.list
            })
            setDataList( res.data);
        })
        http.get('/apiWaterUrl/assess/result/ration/'+props.id).then((res:any)=>{
            res.data?.forEach((item:rationItem) => {
                item.key = uniqid.default();
                if(item.rows?.length>0){
                    item.rows.forEach((i:production,index:number) => {
                        i.key = index;
                    })
                    if(!item.folding){
                        item.rows.splice(item.rows.findIndex(i => i.key === 1),1);
                    }
                }
            })
            setRationList(res.data);
        })
        if(role === 0){
            http.get('/apiWaterUrl/assess/expert/list/'+props.id).then((res:any)=>{
                res.data?.forEach((i:WaterQualitative) => {
                    i.key = uniqid.default();
                    i.details = i.list
                })
                if(res.data.length){
                    let arr:any = []
                    res.data.forEach((item:expertItem)=>{
                        arr.push(
                            {
                                title:item.name,
                                dataIndex:'details',
                                key:'zj',
                                width:80,
                                render:(text:any,record:any) => (
                                    text.length > 0 ?
                                        text.map((i:any,index:number) =>
                                            i.children?.length > 0 ?
                                                i.children.map((child:any,cIndex:number) => {
                                                    let score = '-'
                                                    if(child?.scores?.length){
                                                       let obj = child.scores.find((c:any)=>{
                                                            return c.creatorName == item.name
                                                        })
                                                        score = obj?.score ? obj?.score + '分' : '-'
                                                    }

                                                    return <div key={uniqid.default()} className="table-row-div" style={{borderBottom: cIndex === i.children.length-1 ? '':'1px solid #F0F0F0',height:'39px'}}>{score}</div>
                                                })
                                                : '—'
                                        )
                                        : '—'
                                )
                            },
                        )
                    })
                    setExpertColumns(arr)
                }
            })
        }
    },[props.id])

    useEffect(() => {
        getTableList();
    },[getTableList])
    const userInfo =  cookie.load('faisUserInfo')
    const role =  userInfo.roleIds[0]

    return (
        <>
            <div className="evaluate-detail-container">
                <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">定性评估</p>
                <Table
                    columns={getColumns(role,expertColumns,visible,setVisible,showModal)}
                    dataSource={dataList}
                    pagination={false}
                    size="small"
                    style={{paddingBottom:'50px'}}
                />
                <Divider type="vertical" className="item-title-divider"/><p className="card-div-title">定量评估</p>
                <Row gutter={16}>
                    {
                        rationList?.length >= 1 ?
                            <Col span={12} style={{padding:'16px'}} key={uniqid.default()}>
                                <div className="card-bais-box">
                                    <p className="card-div-title font16" style={{textAlign:'center'}}><b>{rationList[0].productName}</b></p>
                                    <div>
                                        <Table
                                            columns={rationColumns(rationList)}
                                            dataSource={rationList[0]?.rows}
                                            pagination={false}
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        rationList?.length > 1 ?
                            <Col span={12} style={{padding:'16px'}} key={uniqid.default()}>
                                <div className="card-bais-box">
                                    <p className="card-div-title font16" style={{textAlign:'center'}}><b>{rationList[1].productName}</b></p>
                                    <div>
                                        <Table
                                            columns={rationColumns(rationList)}
                                            dataSource={rationList[1]?.rows}
                                            pagination={false}
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                </Row>
            </div>
            <Modal title="专家评分明细"
                   visible={isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   footer={[ ]}
            >
                <div>
                    <p style={{color:"#666666",fontSize:"14px",fontWeight:"bold",textAlign:"center"}}>{modalData.one} -  {modalData.two}</p>
                    <p style={{textAlign:"center"}}>{modalData.there}</p>
                    <Table
                        columns={scoreColumns}
                        dataSource={modalData.list}
                        pagination={false}
                        size="small"
                    />

                </div>
            </Modal>
        </>
    )
})

export default EvaluateDetail;
