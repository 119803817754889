import React from 'react';
import './addUser.scss';
import { Form, Input, Row, Col, Select, message, Radio, Cascader } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Http from '../../utils/http';
import Province from '../../utils/ssq.json';

export class AddUser extends React.Component<any, any>{
    province: any;
    constructor(props: any) {
        super(props);
        this.state = {
            energyData: [],
            type: '1'
        };
        let arr: any = [];
        Province.forEach(i => {
            let option = {
                value: i.name,
                label: i.name,
                children: this.provicePush(i.children)
            }
            arr.push(option);
        })
        this.province = arr;
        props.bindRef(this);
        this.getData = this.getData.bind(this);
        this.provicePush = this.provicePush.bind(this);
        this.changeType = this.changeType.bind(this);
        this.adminInfo = this.adminInfo.bind(this);
        this.blurUserName = this.blurUserName.bind(this);
    }
    blurUserName(e: any) {
        this.createForm.current?.setFieldsValue({
            email: e.target.value
        })
    }
    provicePush(arr: any) {
        let returnArr: any = [];
        let option: any;
        arr.forEach((element: any) => {
            if (element.children) {
                option = {
                    value: element.name,
                    label: element.name,
                    children: this.provicePush(element.children)
                };
                returnArr.push(option);
            } else {
                option = {
                    value: element.name,
                    label: element.name
                };
                returnArr.push(option);
            }
        });
        return returnArr;
    }

    createForm = React.createRef<FormInstance>();

    vailForm() {
        this.createForm.current?.submit();
    }

    changeType(e: any) {
        this.setState({
            type: e.target.value
        })
    }

    adminInfo() {
        Http.get('/admin/info/' + this.props.editData.id).then((res: any) => {
            if (res && res.code === 200) {
                this.setState({
                    type: String(res.data.roleId)
                })
                this.createForm.current?.setFieldsValue(res.data);
                this.createForm.current?.setFieldsValue({
                    roleId: String(res.data.roleId),
                    region: [res.data.province, res.data.city, res.data.district]
                });
            }
        })
    }
    getData(values: any) {
        let resData;
        if (this.state.type === '1') {
            resData = {
                roleId: values.roleId,
                name: values.name,
                username: values.username,
                password: values.password,
                company: values.company,
                tax: values.tax,
                province: values.region[0],
                city: values.region[1],
                district: values.region[2],
                industry: values.industry,
                contact: values.contact,
                idno: values.idno,
                email: values.email,
                phone: values.phone,
                tel: values.tel,
            }
        } else {
            resData = {
                roleId: values.roleId,
                name: values.name,
                username: values.username,
                password: values.password,
                company: values.company,
                tax: values.tax,
                province: values.region[0],
                city: values.region[1],
                district: values.region[2],
                idno: values.idno,
                email: values.email,
                phone: values.phone,
                tel: values.tel,
            }
        }

        if (this.props.editData.id) {
            Http.put('/admin/info/info/super/' + this.props.editData.id, resData).then((res: any) => {
                if (res && res.code === 200) {
                    message.success('修改成功!');
                    this.props.closeDrawer(true);
                }

            })
        } else {
            Http.post('/admin/info/create', resData).then((res: any) => {
                if (res && res.code === 200) {
                    message.success('新建成功!');
                    this.props.closeDrawer(true);
                }
            })
        }
    }

    componentDidMount() {
        if (this.props.editData.id) {
            this.adminInfo();
            // this.createForm.current?.setFieldsValue(this.props.editData);
        } else {
            this.createForm.current?.setFieldsValue({
                roleId: '1',
                password: 'F123456',
                region: ['北京市', '市辖区', '朝阳区'],
                industry: ['棉纺织及印染精加工']
            })
        }
    }
    render() {
        const { Option } = Select;
        const { type } = this.state;
        return (
            <Form layout="vertical" ref={this.createForm} className="add-user" name="add-product" onFinish={this.getData}>
                <h3>用户类型</h3>
                <Form.Item name="roleId" rules={[{ required: true }]}>
                    <Radio.Group style={{ marginBottom: '15px' }} onChange={this.changeType} buttonStyle="solid">
                        <Radio.Button disabled={this.props.editData.id ? true : false} value="1">企业</Radio.Button>
                        <Radio.Button disabled={this.props.editData.id ? true : false} value="3">园区</Radio.Button>
                        <Radio.Button disabled={this.props.editData.id ? true : false} value="2">专家</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <h3>基本信息</h3>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item label={type === '2' ? '专家名称' : '用户名称'} name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item label="用户账号" name="username" rules={[{
                            pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                            message: '邮箱格式不正确'
                        }, { required: true }]}>
                            <Input onBlur={this.blurUserName} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item label="初始密码(初始密码为F123456,可修改)" name="password">
                            <Input type="password" />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    type !== '2' ? <>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="所在机构" name="company" rules={type === '3' ? [{ required: true }] : [{ required: false }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="统一社会信用代码" name="tax"
                                    rules={[
                                        {
                                            pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
                                            message: '统一社会信用代码格式不正确'
                                        }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="所在地区"
                                    name="region"
                                    rules={[{ required: true, message: '请选择所在地区' }]}
                                >
                                    <Cascader options={this.province} placeholder="请选择区域" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="所属行业"
                                    name="industry"
                                    rules={this.state.type === '3' ? [{ required: false }] : [{ required: true, message: '请选择所属行业' }]}
                                >
                                    <Select mode="multiple" style={{ width: '100%' }}>
                                        <Option key={1} value="棉纺织及印染精加工">棉纺织及印染精加工</Option>,
                                        <Option key={2} value="毛纺织及染整精加工">毛纺织及染整精加工</Option>,
                                        <Option key={3} value="麻纺织及染整精加工">麻纺织及染整精加工</Option>,
                                        <Option key={4} value="丝绢纺织及印染精加工">丝绢纺织及印染精加工</Option>,
                                        <Option key={5} value="化纤织造及印染精加工">化纤织造及印染精加工</Option>,
                                        <Option key={6} value="针织或钩针编织物及其制品制造">针织或钩针编织物及其制品制造</Option>,
                                        <Option key={7} value="家用纺织制成品制造">家用纺织制成品制造</Option>,
                                        <Option key={8} value="产业用纺织制成品制造">产业用纺织制成品制造</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                        <h3>联系人信息</h3>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="联系人姓名" name="contact">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="身份证号" name="idno">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="联系邮箱" name="email" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="手机" name="phone">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="电话" name="tel">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </> : <>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="所在机构" name="company">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="统一社会信用代码" name="tax">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="所在地区"
                                    name="region"
                                    rules={[{ required: true, message: '请选择所在地区' }]}
                                >
                                    <Cascader options={this.province} placeholder="请选择区域" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="身份证号" name="idno">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="联系邮箱" name="email" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="手机" name="phone">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label="电话" name="tel">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                }
            </Form>
        )
    }
}

export default AddUser;