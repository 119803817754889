import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './competitionZone.scss';
import Head from '../../common/head/head';
import {Checkbox, Col, Form, Input, Layout, Radio, Row, Table, Upload, message, DatePicker, Button} from 'antd';
import {CaretRightOutlined, CaretDownOutlined, DeleteOutlined} from '@ant-design/icons';
import store from '../../utils/store';
import {OPENIMG} from '../../utils/actions';
import {adminEditAndRead, verificationTypePdf, verificationTypeWord} from '../../utils/utils';
import http from '../../utils/http';
import PreviewImg from '../../common/previewImg/previewImg';
import * as uniqid from 'uniqid';
import moment from 'moment';

const ThemeContext = React.createContext({
    items: [],
    declarant: '',
    phone: '',
    year: moment(new Date())
});

const CompetZone = (props: any) => {
    const {Content} = Layout;
    let maxHeight = document.body.clientHeight - 80;

    return (
        <Layout>
            <Head isDashboard={true} isInfo={false} roleId={2} unreadMsgNumber={0}/>
            <PreviewImg></PreviewImg>
            <Content className="site-layout" style={{padding: '12px 20px',}}>
                <div className="declaration-background"
                     style={{minHeight: '500px', height: maxHeight, overflowY: 'auto'}}>
                    <div className="declaration-title">全国纺织行业节水增效劳动竞赛</div>
                    <DownloadTable/>
                    <UploadInfo/>
                </div>
            </Content>
        </Layout>
    )
}

const DownloadTable = (props: any) => {
    const [colData, setData] = React.useState([]);


    const col = [
        {
            title: '文件名',
            dataIndex: 'showName'
        }, {
            title: '操作',
            key: 'action',
            fixed: 'right' as any,
            width: 100,
            render: (text: any, record: any) => <><img onClick={e => downFile(record.url)} style={{cursor: 'pointer'}}
                                                       src="/assets/images/icon_smallDownload.png"/></>,
        },
    ];
    useEffect(() => {
        getDeclareList()
    }, []);
    const getDeclareList = () => {
        let params = {
            project: 'CONTEST'
        }
        http.get('/declare/require/list', params).then((res: any) => {
            if (res && res.code === 200) {
                res.data.forEach((item: any, index: number) => {
                    item.key = index;
                })
                setData(res.data);
            }
        })
    }


    const downFile = (url: string) => {
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('target', '_blank')
        a.click()
    }

    return (
        <>
            <div className="img-title">
                <img src="/assets/images/icon_download.png" alt=""/>申报要求下载
            </div>
            <Table columns={col} dataSource={colData} pagination={false}
                   size="small" style={{marginBottom: '1.8rem'}}/>
        </>
    )
}

const UploadInfo = () => {
    const [form] = Form.useForm();
    const [wordFile1, setWordFile1] = React.useState<any>([]);
    const [pdfFile1, setPdfFile1] = React.useState<any>([]);
    const [wordFile2, setWordFile2] = React.useState<any>([]);
    const [pdfFile2, setPdfFile2] = React.useState<any>([]);
    const [wordFile3, setWordFile3] = React.useState<any>([]);
    const [pdfFile3, setPdfFile3] = React.useState<any>([]);
    const [wordFile4, setWordFile4] = React.useState<any>([]);
    const [pdfFile4, setPdfFile4] = React.useState<any>([]);


    const [show, setShow] = React.useState<boolean>(true);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [maiBolel1, setmaiBolel1] = React.useState<boolean>(false);
    const [maiBolel2, setmaiBolel2] = React.useState<boolean>(false);
    const [maiBolel3, setmaiBolel3] = React.useState<boolean>(false);
    const [maiBolel4, setmaiBolel4] = React.useState<boolean>(false);
    const [maiBolel5, setmaiBolel5] = React.useState<boolean>(false);
    const [maiBolel6, setmaiBolel6] = React.useState<boolean>(false);
    const [maiBolel7, setmaiBolel7] = React.useState<boolean>(false);
    const [maiBolel8, setmaiBolel8] = React.useState<boolean>(false);
    const [openstart, setopenstart] = React.useState<boolean>(true);
    let contextType = useContext(ThemeContext) as any;
    const [obj, setobj] = React.useState<any>({});


    useEffect(() => {
        if (sessionStorage.getItem('roleId') == '3') {
            setShow(false)
        }
        form.setFieldsValue({
            items: [],
            declarant: '',
            phone: '',
            year: moment(new Date())
        })
    }, []);
    const saveInfo = () => {
        saveopen()
        form.submit();
    }
    const saveopen = () => {

        let objs = {
            declarant: form.getFieldsValue().declarant,
            items: [
                {
                    files: [],
                    prizeEnum: 'PARK'
                },
            ],
            phone: form.getFieldsValue().phone,
            year: moment(form.getFieldsValue().year).format('yyyy')
        };
        if (sessionStorage.getItem('roleId') == '3') {

            objs.items.forEach((item: any) => {
                item.files = [{
                    fileId: wordFile4[0]?.id,
                    showName: wordFile4[0]?.name
                },
                    {
                        fileId: pdfFile4[0]?.id,
                        showName: pdfFile4[0]?.name
                    }]
            })
        } else {

            let qiye: any = {}
            let jiti: any = {}
            let geren: any = {}
            let arr: any = []

            if (wordFile1.length) {
                arr.push({
                    prizeEnum: 'COMPANY',
                    files: [{
                        fileId: wordFile1[0]?.id,
                        showName: wordFile1[0]?.name
                    },
                        {
                            fileId: pdfFile1[0]?.id,
                            showName: pdfFile1[0]?.name
                        }]
                })
            }
            if (wordFile2.length) {
                arr.push({
                    prizeEnum: 'COLLECTIVE',
                    files: [{
                        fileId: wordFile2[0]?.id,
                        showName: wordFile2[0]?.name
                    },
                        {
                            fileId: pdfFile2[0]?.id,
                            showName: pdfFile2[0]?.name
                        }]
                })
            }
            if (wordFile3.length) {
                arr.push({
                    prizeEnum: 'PERSON',
                    files: [{
                        fileId: wordFile3[0]?.id,
                        showName: wordFile3[0]?.name
                    },
                        {
                            fileId: pdfFile3[0]?.id,
                            showName: pdfFile3[0]?.name
                        }]
                })

            }
            objs = {
                declarant: form.getFieldsValue().declarant,
                items: arr,
                phone: form.getFieldsValue().phone,
                year: moment(form.getFieldsValue().year,).format('yyyy')
            };

        }
        setobj(objs)
    }


    const getFormData = () => {
        obj.declarant = form.getFieldsValue().declarant
        obj.phone = form.getFieldsValue().phone
        obj.year = moment(form.getFieldsValue().year).format('yyyy')
        console.log(obj)
        http.post('/project/declare/prize', obj).then((res: any) => {
            if (res && res.code === 200) {
                message.success('提交成功！');

            } else {
                message.success('提交失败！');
            }
            setBtnLoading(false);

        })


    }

    const wordUpLoad1 = {
        beforeUpload: (file: any) => {
            if (verificationTypeWord(file.type)) {
                wordFileUpload(file, '1');
            } else {
                message.error('文件上传仅支持doc,docx格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = wordFile1;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setWordFile1(fileList);
            if (!fileList.length) {
                setmaiBolel1(true)
                setopenstart(true)
            } else {
                setopenstart(false)
                setmaiBolel1(false)
            }
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };
    const wordUpLoad2 = {
        beforeUpload: (file: any) => {
            if (verificationTypeWord(file.type)) {
                wordFileUpload(file, '2');
            } else {
                message.error('文件上传仅支持doc,docx格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = wordFile2;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setWordFile2(fileList);
            if (!fileList.length) {
                setmaiBolel3(true)
                setopenstart(true)
            } else {
                setopenstart(false)
                setmaiBolel3(false)
            }
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };
    const wordUpLoad3 = {
        beforeUpload: (file: any) => {
            if (verificationTypeWord(file.type)) {
                wordFileUpload(file, '3');
            } else {
                message.error('文件上传仅支持doc,docx格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = wordFile3;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setWordFile3(fileList);
            if (!fileList.length) {
                setmaiBolel5(true)
                setopenstart(true)

            } else {
                setopenstart(false)
                setmaiBolel5(false)
            }
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };
    const wordUpLoad4 = {
        beforeUpload: (file: any) => {
            if (verificationTypeWord(file.type)) {
                wordFileUpload(file, '4');
            } else {
                message.error('文件上传仅支持doc,docx格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = wordFile4;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setWordFile4(fileList);
            if (!fileList.length) {
                setmaiBolel7(true)
                setopenstart(true)
            } else {
                setopenstart(false)
                setmaiBolel7(false)
            }
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };

    const pdfUpLoad1 = {
        beforeUpload: (file: any) => {
            if (verificationTypePdf(file.type)) {
                pdfFileUpload(file, '1');
            } else {
                message.error('文件上传仅支持PDF格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = pdfFile1;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setPdfFile1(fileList);
            if (!fileList.length) {
                setmaiBolel2(true)
                setopenstart(true)
            } else {
                setopenstart(false)
                setmaiBolel2(false)
            }
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };
    const pdfUpLoad2 = {
        beforeUpload: (file: any) => {
            if (verificationTypePdf(file.type)) {
                pdfFileUpload(file, '2');
            } else {
                message.error('文件上传仅支持PDF格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = pdfFile2;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setPdfFile2(fileList);
            if (!fileList.length) {
                setmaiBolel4(true)
                setopenstart(true)
            } else {
                setopenstart(false)
                setmaiBolel4(false)
            }
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };
    const pdfUpLoad3 = {
        beforeUpload: (file: any) => {
            if (verificationTypePdf(file.type)) {
                pdfFileUpload(file, '3');
            } else {
                message.error('文件上传仅支持PDF格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = pdfFile3;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setPdfFile3(fileList);
            if (!fileList.length) {
                setopenstart(true)
                setmaiBolel6(true)
            } else {
                setopenstart(false)
                setmaiBolel6(false)
            }
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };
    const pdfUpLoad4 = {
        beforeUpload: (file: any) => {
            if (verificationTypePdf(file.type)) {
                pdfFileUpload(file, '4');
            } else {
                message.error('文件上传仅支持PDF格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: { uid: any; }) => {
            let fileList = pdfFile4;
            fileList = fileList.filter((i: { [x: string]: any; }) => i['uid'] !== file.uid);
            setPdfFile4(fileList);
            if (!fileList.length) {
                setopenstart(true)
                setmaiBolel8(true)
            } else {
                setopenstart(false)
                setmaiBolel8(false)
            }
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };

    const wordFileUpload = (file: any, i: any) => {
        const formData = new FormData();
        formData.append('file', file);
        http.post('/system/file/upload/auth', formData).then((res: any) => {
            if (res && res.code === 200) {
                let img = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    id: res.data,
                    url: ''
                };

                switch (i) {
                    case '1':
                        setWordFile1([img]);
                        if (!pdfFile1.length) {
                            setopenstart(true)
                            setmaiBolel2(true)
                        } else {
                            setopenstart(false)
                            setmaiBolel2(false)
                        }
                        setmaiBolel1(false)
                        break
                    case  '2':
                        setWordFile2([img]);
                        if (!pdfFile2.length) {
                            setopenstart(true)
                            setmaiBolel4(true)
                        } else {
                            setopenstart(false)
                            setmaiBolel4(false)
                        }
                        setmaiBolel3(false)
                        break
                    case '3':
                        setWordFile3([img]);
                        if (!pdfFile3.length) {
                            setopenstart(true)
                            setmaiBolel6(true)
                        } else {
                            setopenstart(false)
                            setmaiBolel6(false)
                        }
                        setmaiBolel5(false)
                        break
                    case  '4':
                        setWordFile4([img]);
                        if (!pdfFile4.length) {
                            setopenstart(true)
                            setmaiBolel8(true)
                        } else {
                            setopenstart(false)
                            setmaiBolel8(false)
                        }
                        setmaiBolel7(false)
                        break

                }
            }
        })
    }

    const pdfFileUpload = (file: any, i: any) => {
        const formData = new FormData();
        formData.append('file', file);
        http.post('/system/file/upload/auth', formData).then((res: any) => {
            if (res && res.code === 200) {
                let img = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    id: res.data,
                    url: ''
                };
                switch (i) {
                    case '1':
                        setPdfFile1([img]);
                        if (!wordFile1.length) {
                            setmaiBolel1(true)
                            setopenstart(true)
                        } else {
                            setopenstart(false)
                            setmaiBolel1(false)
                        }
                        setmaiBolel2(false)
                        break
                    case  '2':
                        setPdfFile2([img]);
                        if (!wordFile2.length) {
                            setopenstart(true)
                            setmaiBolel3(true)
                        } else {
                            setopenstart(false)
                            setmaiBolel3(false)
                        }
                        setmaiBolel4(false)
                        break
                    case  '3':
                        setPdfFile3([img]);
                        if (!wordFile3.length) {
                            setopenstart(true)
                            setmaiBolel5(true)
                        } else {
                            setopenstart(false)
                            setmaiBolel5(false)
                        }
                        setmaiBolel6(false)
                        break
                    case '4':
                        setPdfFile4([img]);
                        if (!wordFile4.length) {
                            setopenstart(true)
                            setmaiBolel7(true)
                        } else {
                            setopenstart(false)
                            setmaiBolel7(false)
                        }
                        setmaiBolel8(false)
                        break
                    default:
                        break;
                }
            }

        })
    }

    const cxcca = <div className='margin-top-10 margin-bottom-10'><span
        style={{'color': 'red'}}>* </span> 申报奖励
    </div>


    return (
        <>
            <div className='comitsBox'>
                <Form form={form} name="control" onFinish={getFormData}>

                    <div className="img-title">
                        <img src="/assets/images/icon_uploading.png" alt=""/>申报材料上传
                    </div>

                    <Form.Item name="year" label="申报年份" rules={[{required: true}]}>
                        <DatePicker
                            picker="year"
                            format={'yyyy'}
                            style={{'width': '30%'}}
                        />
                    </Form.Item>

                    <Form.Item label={cxcca} rules={[{required: true}]}>

                    <span className='alinconter flexmax' style={{'width': '600px'}}>  <img
                        src="/assets/images/icon_prompt_normal.png"
                        style={{
                            'width': '14px',
                            'height': '14px',
                            'marginRight': '5px'
                        }} alt=""/>
                        <span
                            style={{'color': '#999999'}}>每一项申报都必须同时上传word材料和PDF材料，否则视为无效</span></span>
                    </Form.Item>


                    <div className="info-item">
                        <div className="info-item-container">

                            {/*园区进来显示*/}
                            {
                                show ?
                                    <div>
                                        <div className='flexmax jusswb'>
                                            <div className='margin-right-10 w100'>
                                                <div className='flexmax jusconr'>
                                                    {
                                                        wordFile1.length > 0 && pdfFile1.length > 0 ?
                                                            <span className="namensabus">
                                           <img src="/assets/images/title_green.png"/>
                                            <span className='namensabus-text'>节水标杆企业</span>
                                        </span>
                                                            :
                                                            <span className="namensabus">
                                            <img src="/assets/images/title_normal.png"/>
                                            <span className='namensabus-text1'>节水标杆企业</span>
                                        </span>
                                                    }
                                                </div>
                                                <div
                                                    className={`flexmax jusconr ${wordFile1.length > 0 && pdfFile1.length > 0 ? 'uobox1' : 'uobox'}`}>
                                                    <Form.Item>
                                                        <div className='margin-top-10 margin-bottom-10'><span
                                                            style={{'color': 'red'}}>* </span> 上传申报材料
                                                        </div>
                                                        <div className="file-container">
                                                            <Upload {...wordUpLoad1} fileList={wordFile1}>
                                                                <div className="file-item">
                                                                    <div><img src="/assets/images/icon_add_word.png"/>
                                                                    </div>
                                                                    <div>点击上传word材料</div>
                                                                    {
                                                                        !maiBolel1 ? "" :
                                                                            <div className='wodidjes'
                                                                                 style={{'color': 'red'}}>
                                                                                请上传word材料，否则视为无效
                                                                            </div>
                                                                    }
                                                                </div>

                                                            </Upload>

                                                            <Upload {...pdfUpLoad1} fileList={pdfFile1}>
                                                                <div className="file-item">
                                                                    <div><img src="/assets/images/icon_add_pdf.png"/>
                                                                    </div>
                                                                    <div>点击上传PDF材料</div>
                                                                    {
                                                                        !maiBolel2 ? "" :
                                                                            <div className='wodidjes'
                                                                                 style={{'color': 'red'}}>
                                                                                请上传PDF材料，否则视为无效
                                                                            </div>
                                                                    }
                                                                </div>

                                                            </Upload>
                                                        </div>
                                                    </Form.Item>
                                                </div>

                                            </div>
                                            <div className='margin-left-10 w100'>
                                                <div className='flexmax jusconr'>
                                                    {
                                                        wordFile2.length > 0 && pdfFile2.length > 0 ?
                                                            <span className="namensabus">
                                           <img src="/assets/images/title_green.png"/>
                                            <span className='namensabus-text'>节水创新先进集体</span>
                                        </span>
                                                            :
                                                            <span className="namensabus">
                                            <img src="/assets/images/title_normal.png"/>
                                            <span className='namensabus-text1'>节水创新先进集体</span>
                                        </span>
                                                    }
                                                </div>
                                                <div
                                                    className={`flexmax jusconr ${wordFile2.length > 0 && pdfFile2.length > 0 ? 'uobox1' : 'uobox'}`}>
                                                    <Form.Item>
                                                        <div className='margin-top-10 margin-bottom-10'><span
                                                            style={{'color': 'red'}}>* </span> 上传申报材料
                                                        </div>
                                                        <div className="file-container">
                                                            <Upload {...wordUpLoad2} fileList={wordFile2}>
                                                                <div className="file-item">
                                                                    <div><img src="/assets/images/icon_add_word.png"/>
                                                                    </div>
                                                                    <div>点击上传word材料</div>
                                                                    {
                                                                        !maiBolel3 ? "" :
                                                                            <div className='wodidjes'
                                                                                 style={{'color': 'red'}}>
                                                                                请上传word材料，否则视为无效
                                                                            </div>
                                                                    }
                                                                </div>

                                                            </Upload>

                                                            <Upload {...pdfUpLoad2} fileList={pdfFile2}>
                                                                <div className="file-item">
                                                                    <div><img src="/assets/images/icon_add_pdf.png"/>
                                                                    </div>
                                                                    <div>点击上传PDF材料</div>
                                                                    {
                                                                        !maiBolel4 ? "" :
                                                                            <div className='wodidjes'
                                                                                 style={{'color': 'red'}}>
                                                                                请上传PDF材料，否则视为无效
                                                                            </div>
                                                                    }
                                                                </div>

                                                            </Upload>
                                                        </div>
                                                    </Form.Item>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='margin-top-10 prosn50'>
                                            <div className='margin-right-10'>
                                                <div className='flexmax jusconr'>
                                                    {
                                                        wordFile3.length > 0 && pdfFile3.length > 0 ?
                                                            <span className="namensabus">
                                           <img src="/assets/images/title_green.png"/>
                                            <span className='namensabus-text'>节水创新先进个人</span>
                                        </span>
                                                            :
                                                            <span className="namensabus">
                                            <img src="/assets/images/title_normal.png"/>
                                            <span className='namensabus-text1'>节水创新先进个人</span>
                                        </span>
                                                    }
                                                </div>
                                                <div
                                                    className={`flexmax jusconr ${wordFile3.length > 0 && pdfFile3.length > 0 ? 'uobox1' : 'uobox'}`}>
                                                    <Form.Item>
                                                        <div className='margin-top-10 margin-bottom-10'><span
                                                            style={{'color': 'red'}}>* </span> 上传申报材料
                                                        </div>
                                                        <div className="file-container">
                                                            <Upload {...wordUpLoad3} fileList={wordFile3}>
                                                                <div className="file-item">
                                                                    <div><img src="/assets/images/icon_add_word.png"/>
                                                                    </div>
                                                                    <div>点击上传word材料</div>
                                                                    {
                                                                        !maiBolel5 ? "" :
                                                                            <div className='wodidjes'
                                                                                 style={{'color': 'red'}}>
                                                                                请上传word材料，否则视为无效
                                                                            </div>
                                                                    }
                                                                </div>

                                                            </Upload>

                                                            <Upload {...pdfUpLoad3} fileList={pdfFile3}>
                                                                <div className="file-item">
                                                                    <div><img src="/assets/images/icon_add_pdf.png"/>
                                                                    </div>
                                                                    <div>点击上传PDF材料</div>
                                                                    {
                                                                        !maiBolel6 ? "" :
                                                                            <div className='wodidjes'
                                                                                 style={{'color': 'red'}}>
                                                                                请上传PDF材料，否则视为无效
                                                                            </div>
                                                                    }
                                                                </div>

                                                            </Upload>
                                                        </div>
                                                    </Form.Item>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    : <div className='margin-top-10 prosn50'>
                                        <div className='margin-right-10'>
                                            <div className='flexmax jusconr'>
                                                {
                                                    wordFile4.length > 0 && pdfFile4.length > 0 ?
                                                        <span className="namensabus">
                                           <img src="/assets/images/title_green.png"/>
                                            <span className='namensabus-text'>节水标杆园区</span>
                                        </span>
                                                        :
                                                        <span className="namensabus">
                                            <img src="/assets/images/title_normal.png"/>
                                            <span className='namensabus-text1'>节水标杆园区</span>
                                        </span>
                                                }
                                            </div>
                                            <div
                                                className={`flexmax jusconr ${wordFile4.length > 0 && pdfFile4.length > 0 ? 'uobox1' : 'uobox'}`}>
                                                <Form.Item>
                                                    <div className='margin-top-10 margin-bottom-10'><span
                                                        style={{'color': 'red'}}>* </span> 上传申报材料
                                                    </div>
                                                    <div className="file-container">
                                                        <Upload {...wordUpLoad4} fileList={wordFile4}>
                                                            <div className="file-item">
                                                                <div><img src="/assets/images/icon_add_word.png"/></div>
                                                                <div>点击上传word材料</div>
                                                                {
                                                                    !maiBolel7 ? "" :
                                                                        <div className='wodidjes' style={{'color': 'red'}}>
                                                                            请上传word材料，否则视为无效
                                                                        </div>
                                                                }
                                                            </div>

                                                        </Upload>

                                                        <Upload {...pdfUpLoad4} fileList={pdfFile4}>
                                                            <div className="file-item">
                                                                <div><img src="/assets/images/icon_add_pdf.png"/></div>
                                                                <div>点击上传PDF材料</div>
                                                                {
                                                                    !maiBolel8 ? "" :
                                                                        <div className='wodidjes' style={{'color': 'red'}}>
                                                                            请上传PDF材料，否则视为无效
                                                                        </div>
                                                                }
                                                            </div>

                                                        </Upload>
                                                    </div>
                                                </Form.Item>
                                            </div>

                                        </div>
                                    </div>
                            }
                        </div>

                    </div>

                    <div className="img-title">
                        <img src="/assets/images/icon_uploading.png" alt=""/>申报人联系信息
                    </div>
                    <div className="info-item" style={{marginTop: '1.5rem'}}>
                        <div className="info-item-container">
                            <Form.Item name="declarant" label="联系人" rules={[{required: true}]}>
                                <Input style={{'width': '30%'}}/>
                            </Form.Item>
                            <Form.Item name="phone" label="联系电话" rules={[{required: true}]}>
                                <Input style={{'width': '30%'}}/>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>

            {/* <div className="add-form" onClick={saveInfo}>提交</div> */}


            <Button type={'primary'} onClick={saveInfo} disabled={openstart} loading={btnLoading}
                    style={{marginLeft: '2.75rem'}}>提交</Button>


        </>
    )
}

export default CompetZone;