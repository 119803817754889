import './enterpriseLevelDisplay.scss';
import React,{ useState, useEffect  } from 'react';
import { Column } from '@ant-design/charts';
import http from '../../../../utils/http';
import {handleStatisticsData} from '../../../../utils/utils';
import { Tag } from 'antd';

const EnterPriseLevelDisplay = (props:any) => {
  const [data,setChartData] = useState([]);

  const getAll = () => {
    http.get(`/statistics/statistics/all/${props.id}`).then((res:any)=>{
      if(res&&res.code===200){
        res.data.forEach((element:any) => {
          element.result.forEach((i:any)=>{
            i.result.forEach((k:any)=>{
              k.chartsData = [];
              if(k.labels){
                let amount = k.showData.reduce((total:number,num:number)=>{return total + num});
                k.labels.forEach((k1:any,index:number)=>{
                  k.chartsData.push({
                    label:k1,
                    value: (k.showData[index] / (amount/100)).toFixed(2),
                    isOwn:index===k.index?true:false
                  });
                  k.isNan = amount===0?true:false;
                })
              }
            })
          })
        });
        setChartData(res.data);
      }
    })
  }

  useEffect(()=>{
    getAll();
  },[])
  
  return (<>
    <div className="enter-prise-level-display-container">
      {data.map((i:any,index:number)=>{
        return <div key={index} className="enter-prise-level-display-container-item">
          <div className="enter-prise-level-display-container-item-head">{i.label}</div>
          {i.result.map((k:any,iIndex:number)=>{
            return <div key={iIndex}>{k.name?<div className="echart-tag">{k.name}</div>:''}
            {k.result.map((element:any,kIndex:number)=>{
              return <div key={kIndex} className="enter-prise-level-display-container-item-body">
              
              <div className="enter-prise-level-display-container-item-body-left">
                {EnterItem(element)}
              </div>
              <div className="enter-prise-level-display-container-item-body-right">
                <div className="enter-prise-level-display-container-item-body-right-container">
                  <div className="enter-prise-level-display-container-item-body-right-container-item">
                    <span>{element.tableName}</span>
                    <span>数量分布占比</span>
                  </div>
                  {element.chartsData.map((j:any,elementIndex:number)=>{
                    return <div key={elementIndex} className="enter-prise-level-display-container-item-body-right-container-item">
                      <span>{j.label}{elementIndex===element.index?<Tag style={{marginLeft:'5px'}} color="gold">当前企业</Tag>:''}</span>
                      <span>{j.value=='NaN'?'当前暂无样本数据':j.value+'%'}</span>
                    </div>
                  })}
                  
                </div>
              </div>
            </div>})}</div>
          })}
        </div>
        
      })}
      
    </div>
  </>)
} 

const EnterItem = (data:any) => {
  let paletteSemanticRed = '#F4BB16';
  let brandColor = '#497DE2';
  let activeStr = data.chartsData.filter((i:any)=> i.isOwn===true);
  let config = {
    data:data.chartsData,
    xField: 'label',
    yField: 'value',
    colorField:'isOwn',
    color:(isOwn:any)=>{
      if(activeStr && isOwn.label === activeStr[0]?.label){
        return paletteSemanticRed;
      } else {
        return brandColor;
      }
    },
    label: {
      content: function content(originData:any) {
        return originData.value + '%';
      }
    },
    xAxis:{
      label:{
        autoRotate:true,
        style:{
          fontSize:13
        }
      },
      title:{
        text:data.tableName,
        style:{
          fontWeight:'bold' as 'bold',
          fontSize:14
        }
      }
    },
    meta:{
      value:{
        type:'linear',
      }
    },
    tooltip: {
      formatter:(datum:any)=>{
        return {name:datum.label===activeStr[0]?.label?'当前企业':datum.label,value:datum.value+'%'}
      }
    }
  };
  return(<>{data.isNan?<><p style={{textAlign:'center',fontWeight:'bold',color:'#cb8a08',marginTop:'30%'}}>当前暂无样本数据</p><p style={{textAlign:'center',fontWeight:'bold'}}>{data.tableName}</p></>:<Column {...config} />}</>)
}

export default EnterPriseLevelDisplay;