import { Button, Col, Drawer, Form, Input, message, Modal, Pagination, Popover, Row, Space, Table, Tag } from 'antd';
import React from 'react';
import './diagnosis-list.scss';
import { navigate } from '@reach/router';
import Http from './../../../utils/http';
import {dateFormat} from '../../../utils/utils';
import {expertQueryDataList } from '../../../utils/entity'
import { FormInstance } from 'antd/lib/form';
import { PhoneFilled, PhoneOutlined ,DeleteOutlined} from '@ant-design/icons';


enum statusEnum {
  WRITING = '填写中',
  COMMITTED = '已提交',
  DIAGNOSIS = '诊断中',
  SENDBACK = '退回修改',
  FINISH = '完成诊断'
}

type statusResponse = 'WRITING' | 'COMMITTED' | 'DIAGNOSIS' | 'SENDBACK' | 'FINISH';

export class DiagnosisListComponent extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    this.state = {
      tabData:[],
      currentPage:1,
      total:10,
      allTotal:10,
      keyWord:'',
      statuList:[],
      status:'',

      visible:false,
      modelVisible:false,
      drawerkeyWord:'',
      drawerCurrentPage:1,
      drawerTotal:10,
      drawerData:[],
      drawerSelect:[],

      diagnosisId:0,
      modelDiagnosisId:0
    };
    this.getPage = this.getPage.bind(this);
    this.getStatu = this.getStatu.bind(this);
    this.changePage = this.changePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.tabBtnRender = this.tabBtnRender.bind(this);
    this.onOpenDrawer = this.onOpenDrawer.bind(this);
    this.onCloseDrawer = this.onCloseDrawer.bind(this);
    this.onSearchExpert = this.onSearchExpert.bind(this);
    this.expertList = this.expertList.bind(this);
    this.save = this.save.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.deleteExpert = this.deleteExpert.bind(this);
  }
  createForm = React.createRef<FormInstance>();

  getStatu(){
    Http.get('/diagnosis/diagnosisStatusCount').then((res:any)=>{
      if(res&&res.code === 200){
        let statusArr:Array<{count:number,status:statusResponse,text:string}> = [];
        res.data.forEach((item:{count:number,status:statusResponse})=>{
          statusArr.push({
            count:item.count,
            status:item.status,
            text:statusEnum[item.status]
          })
        });
        this.setState({
          statuList:statusArr
        });
      }
    })
  }

  changeStatus(status:string){
    this.setState({
      status:status
    },()=>{
      this.getPage();
    })
  }

  deleteExpert(obj:any){
    let {diagnosisId} = this.state;
    const data = {
        assessId:diagnosisId,
        expertId:obj.id,
      }
    Http.DELETE(`/apiWaterUrl/assess/expert?assessId=${data.assessId}&expertId=${data.expertId}`).then((res:any)=>{
      if(res&&res.code===200){
        message.info('操作成功')
      }
    })
  }

  getPage(){
    let {currentPage,keyWord,status} = this.state;
    Http.get('/diagnosis/admin/page',{
      keyword:keyWord,
      pageNum:currentPage,
      pageSize:10,
      status:status
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key = index + 1;
        })
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count
        })
        if(status===''){
          this.setState({
            allTotal:res.data.count
          })
        }
      }
    })
  }

  lookUp(data:any){
    // store.dispatch({type: SETDIAGNOSISSTATUS,statu:data.status});
    localStorage.clear();
    localStorage.setItem('diagnosisStatus',data.status);
    navigate('/home/diagnosisProcess/'+data.id);
  }

  componentDidMount(){
    this.getPage();
    this.getStatu();
  }

  changePage(page:any) {
    this.setState({
      currentPage:page
    },()=>{
      this.getPage();
    });
  }

  onSearch(value:any){
    this.setState({
      keyWord:value
    },()=>{
      this.getPage();
    })
  }

  onSearchExpert(value:any){
    this.setState({
      drawerkeyWord:value
    },()=>{
      this.expertList();
    })
  }

  tabBtnRender(data:any){
    if(data.status==='COMMITTED' || data.status === 'DIAGNOSIS'){
      return <>
        <span onClick={e=>this.lookUp(data)} className="green-table-a">在线诊断</span>
        <span onClick={e=>this.onOpenDrawer(data.id)} className="green-table-a">指定专家</span>
        <span onClick={e=>this.openModel(data.id)} className="green-table-a">退回</span>
      </>
    } else {
      return <span onClick={e=>this.lookUp(data)} className="green-table-a">查看</span>
    }
  }

  onOpenDrawer(id:number){
    this.setState({
      visible:true,
      diagnosisId:id
    },()=>{
      this.expertList();
    });
  }

  openModel(id:number){
    this.setState({
      modelVisible:true,
      modelDiagnosisId:id
    });
  }

  expertList(){
    let {diagnosisId,drawerData,drawerkeyWord,drawerCurrentPage,drawerTotal} = this.state;
    Http.get('/expert/queryPageList',{
      diagnosisId:diagnosisId,
      keyWord:drawerkeyWord,
      pageSize:100
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((item:expertQueryDataList,index:number)=>{
          item.key = index;
          item.checked = item.isAppoint === 'T'?true:false;
        });
        this.setState({
          drawerData:res.data.dataList,
        })

      }
    })
  }

  onCloseDrawer(){
    this.setState({
      visible:false
    })
  }

  handleCancel(){
    this.setState({
      modelVisible:false
    })
  }

  handleOk(){
    Http.put('/diagnosis/expert/handle/adminSendBackDiagnosis',{
      id:this.state.modelDiagnosisId,
      sendBackReason:this.createForm.current?.getFieldValue('sendBackReason')
    }).then((res:any)=>{
      if(res&&res.code===200){
        message.success('退回成功');
        this.handleCancel();
        this.getPage();
      }
    })
  }

  save(){
    let {drawerSelect,diagnosisId} = this.state;
    let arr:any = [];
    drawerSelect.forEach((item:expertQueryDataList)=>{
      arr.push({
        adminId:item.adminId,
        expertName:item.name
      })
    })
    let responseData = {
      diagnosisId:diagnosisId,
      experts:arr
    };
    Http.put('/diagnosis/handle/appointExpert',responseData).then((res:any)=>{
      if(res&&res.code===200){
        message.success('指定成功!等待专家接受');
        this.getPage();
      }
    })
  }

  render(){
    const { Search,TextArea } = Input;
    let {tabData,total,allTotal,currentPage,statuList,drawerData,status} = this.state;
    let columns = [
      {
        title:'序号',
        dataIndex:'key'
      },
      {
        title: '企业名称',
        dataIndex: 'company',
        key: 'company'
      },
      {
        title: '填写人',
        dataIndex: 'contactName',
        key: 'contactName',
        render:(text:string,list:any)=>(
          list.contactName?
          <span>{text}<Popover 
            placement="topLeft" 
            content={<>电话:{list.contactTel}</>} 
            title="联系电话" 
            trigger="hover">
              <PhoneFilled style={{marginLeft:'5px',color:'#1C9A6A'}} /></Popover>
            </span>:''
        )
      },
      {
        title: '统计期',
        dataIndex: 'period',
        key: 'period'
      },
      {
        title: '填写时间',
        dataIndex: 'createdTs',
        key: 'createdTs',
        render:(text:string)=>(
          <span>{text?dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss'):''}</span>
        )
      },
      {
        title: '诊断时间',
        dataIndex: 'applyTime',
        key: 'applyTime',
        render:(text:string)=>(
          <span>{text?dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss'):''}</span>
        )
      },
      {
        title: '诊断专家',
        dataIndex: 'experts',
        key: 'experts',
        render:(text:string,list:any)=>(
          <span>{list.experts?list.experts.join(','):''}</span>
        )
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render:(text:string)=>(
          text==='WRITING'?'填写中':text==='COMMITTED'?'已提交':text==='DIAGNOSIS'?'诊断中':text==='SENDBACK'?'退回修改':'完成诊断'
        )
      },
      {
        title: '操作',
        key: 'action',
        dataIndex: 'action',
        render: (text:any,list:any) => (
          <Space size="middle">
            
            {this.tabBtnRender(list)}
          </Space>
        ),
      }
    ];
    let drawerColumns = [
      {
        title: '专家姓名',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '所在机构',
        dataIndex: 'company',
        key: 'company'
      },
      {
        title: '状态',
        dataIndex: 'isAppoint',
        key: 'isAppoint',
        render:(text:string,r:any)=>(
          text==='T'? <div> <Tag color="blue">已指定</Tag> <DeleteOutlined onClick={ () => this.deleteExpert(r)} style={{cursor:"pointer"}} /> </div> :''
        )
      }
    ];
    let statusCol = statuList.map((item:{count:string,status:statusResponse,text:string})=>{
      return <Col span={4} key={item.status}>
        <div onClick={e=>this.changeStatus(item.status)} className={status===item.status?'diagnosis-list-status-item-active diagnosis-list-status-item':'diagnosis-list-status-item'}>{item.text}({item.count})</div>
      </Col>
    })
    return(
      <div className="diagnosis-list">
        <h1>诊断列表</h1>
        <Row gutter={16} style={{marginBottom:'12px'}}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={4}>
                <div onClick={e=>this.changeStatus('')} className={status===''?'diagnosis-list-status-item-active diagnosis-list-status-item':'diagnosis-list-status-item'}>全部状态({allTotal})</div>
              </Col>
              {statusCol}
            </Row>
          </Col>
          <Col offset={6} span={6}>
            <Search
              placeholder="搜索企业名称/填写人/专家"
              allowClear
              onSearch={this.onSearch}
              style={{ width: '100%', margin: '0 10px' }}
            />
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={tabData}
          pagination={false}
          size="small"
        />
        <div className="pagination">
          <div className="pagination-total">总共{total}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
        </div>
        <Drawer
          title='指定专家'
          width={640}
          placement="right"
          closable={true}
          onClose={this.onCloseDrawer}
          destroyOnClose={true}
          visible={this.state.visible}
        >
          <div className="expert-drawer-search">
            <Search placeholder="搜索专家" onSearch={this.onSearchExpert} style={{ width: 300 }} />
            <Button type="primary" onClick={this.save} className="green-button">指定</Button>
          </div>
          <Table
            rowSelection={{
              type:'checkbox',
              getCheckboxProps:record=>({
                disabled:record.isAppoint==='T',
                name:record.name
              }),
              onChange:(selectedRowKeys,selectedRows) =>{
                let {drawerSelect} = this.state;
                drawerSelect = selectedRows;
                this.setState({
                  drawerSelect
                })
              }
            }}
            columns={drawerColumns}
            dataSource={drawerData}
            pagination={false}
            size="small"
          />
        </Drawer>
        <Modal
          title="退回"
          visible={this.state.modelVisible}
          onOk={this.handleOk}
          destroyOnClose={true}
          onCancel={this.handleCancel}
        >
          <Form layout="vertical" ref={this.createForm} name="returnUser">
            <Form.Item label="修改意见" name="sendBackReason">
              <TextArea placeholder="请输入" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default DiagnosisListComponent;

