import React from 'react';
import './index.scss';
import { navigate } from "@reach/router"

class HomePage extends React.Component<any,any>{
  render(){
    return(
      <div>
        <HomePageBody></HomePageBody>
        {/* <FiveStepRule></FiveStepRule> */}
      </div>
    )
  }
}
function HomePageBody (){

  function clickLogin(){
    navigate('/login');
  }

  return(
    <>
      <div style={{height:document.body.clientHeight}} className="container">
        <div className="container-img">
          <img src="/assets/images/fais.png" alt="fais"/>
          <div className="login-btn" onClick={clickLogin}>
            <span style={{cursor:'pointer'}}>登入</span>
            <img src="/assets/images/login-path.png" alt="path" />
          </div>
          <div className="fais-title">纺织行业绿色发展服务平台</div>
          <div className="fais-introduce">
            <div>简介:</div>
            <div>集工厂评估和解决方案为一体,</div>
            <div>高效完成节水、节能、减排、合规性等多领域的评估和提升。</div>
            <br />
            <div>通过高效便捷的评估系统和专业可靠的解决方案矩阵,</div>
            <div>推动工厂获得可持续的管理和改进。</div>
          </div>
          <div className="container-logo">
            <img src="/assets/images/cntac.png" alt="中国纺织工业联合会" />
            <img src="/assets/images/wwf.png" alt="WWF" />
            <img src="/assets/images/weblca.png" style={{width:'105px',height:'105px'}} alt="WebLCA" />
          </div>
        </div>
      </div>
      <div style={{height:document.body.clientHeight}} className="lca-propaganda">
        <div className="fais-introduce-title">
          <span>纺织行业绿色发展服务平台</span>
          <img src="/assets/images/fais.png" alt="fais" />
        </div>
        <div className="fais-introduce-container">
          <div className="fais-introduce-item">工业节能诊断</div>
          <div className="fais-introduce-item">绿色设计产品评价</div>
          <div className="fais-introduce-item">绿色工厂评价</div>
          <div className="fais-introduce-item">绿色供应链评价</div>
          <div className="fais-introduce-item">绿色园区评价</div>
          <div className="fais-introduce-item">产品生命周期/碳足迹评价</div>
          <div className="fais-introduce-item">节水型企业/水效领跑者评价</div>
        </div>
      </div>
    </>
  )
}

export default HomePage;