import './add-prod-baise.scss';
import React, { useEffect, useState, useRef } from 'react';
import { Form, Cascader, Input, Select, Row, Col, Upload, Button, InputNumber, message, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { waterEditAndRead, verificationType } from '../../../../utils/utils';
import store from '../../../../utils/store';
import http from '../../../../utils/http';
import { OPENIMG } from '../../../../utils/actions';
import moment from 'moment';
import * as uniqid from 'uniqid';

const AddProdBaise = (props:any) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [readAndEdit,setReadAndEdit] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [flowChartList,setFlowChartList] = useState<Array<any>>([]);
  const flowListRef = useRef<Array<any>>([]);
  const monthFormat = 'YYYY/MM';
  const [btnLoading,setBtnLoading] = useState<boolean>(false);
  const optionList = [
    {
      value: '完好',
      name: '完好'
    },{
      value: '异常',
      name: '异常'
    },{
      value: '故障',
      name: '故障'
    }
  ]


  useEffect(() => {
    flowListRef.current = flowChartList;
  },[flowChartList])

  let fileupLoad = {
    beforeUpload: (file: any) => {
      if(verificationType(file.type)){
        flowChartListUpload(file);
      } else {
        message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
      }
      return false;
    },
    onRemove: (file:any)=>{
      let list = flowChartList.filter((i:any)=> i['uid'] !== file.uid);
      setFlowChartList(list);
      return false;
    },
    onPreview:(file:any)=>{
      store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name})
      return false;
    }
  };

  const flowChartListUpload = (file:any) => {
    const formData = new FormData();
    formData.append('file', file);
    http.post('/system/file/upload/auth',formData).then((res:any)=>{
      if(res&&res.code===200){
        let img = {
          uid:file.uid,
          name:file.name,
          status:'done',
          id:res.data,
          url:''
        };
        setFlowChartList([...flowChartList,img]);
      }
    })
  }

  const onChange = (date:any,dateString:any) => {
  }

  const dateChange = (date:any) => {
  }



  useEffect(() => {
    setReadAndEdit(waterEditAndRead());
  }, [])

  useEffect(() => {
    if(props.editData){
      form.setFieldsValue({
        eqName: props.editData.eqName,
        specs: props.editData.specs,
        manufacturer: props.editData.manufacturer,
        produceTs: props.editData.produceTs?moment(props.editData.produceTs):null,
        useTs: props.editData.useTs?moment(props.editData.useTs):null,
        quantity: props.editData.quantity,
        accuracy: props.editData.accuracy,
        status: props.editData.status,
        remark: props.editData.remark,
        files: props.editData.files
      })
      if(props.editData.files&&props.editData.files.length>0){
        let arr:any = [];
        props.editData.files.forEach((i:any,index:number)=>{
          let img = {
            uid:index,
            name:i.showName,
            status:'done',
            id:i.fileId,
            url:''
          };
          arr.push(img);
        });
        setFlowChartList(arr);
      }
    }
  },[form, props.editData])

  const onFinish = (values:any) => {
    let obj = Object.assign({},values);
    let arr:any = [];
    flowListRef.current.forEach((i:any) => {
      arr.push({fileId:i.id,showName:i.name});
    })
    obj.files = arr;
    setBtnLoading(true);
    if(props.editData.id){
      http.put('/apiWaterUrl/assess/production/info/' + props.editData.id,{...obj}).then((res:any) => {
        if(res && res.code === 200){
          message.success("修改成功");
          props.onSubmitDrawer();
        }
        setBtnLoading(false);
      })
    }else{
      http.post('/apiWaterUrl/assess/production/info',{...obj,assessId:props.id}).then((res:any) => {
        if(res && res.code === 200){
          message.success("新增成功");
          props.onSubmitDrawer();
        }
        setBtnLoading(false);
      })
    }
  }


  return (
    <>
      <Form layout="vertical" form={form} name="add-product" onFinish={onFinish}>
        <Form.Item label="设备名称" name="eqName" rules={[{required: true}]}>
          <Input disabled={!readAndEdit} placeholder="请输入"/>
        </Form.Item>
        <Form.Item label="型号规格" name="specs" rules={[{required: true}]}>
          <Input disabled={!readAndEdit} placeholder="请输入"/>
        </Form.Item>
        <Form.Item label="生产厂家" name="manufacturer">
          <Input disabled={!readAndEdit} placeholder="请输入"/> 
        </Form.Item>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="生产日期" name="produceTs">
              <DatePicker picker="month" style={{width:'100%'}}/>    
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="投用年份" name="useTs" rules={[{required: true}]}>
              <DatePicker picker="month" style={{width:'100%'}}/>
              {/* <DatePicker value={useTsVal} picker="month" style={{width:'100%'}}/> */}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="数量" name="quantity" rules={[{required: true}]}>
          <InputNumber disabled={!readAndEdit} placeholder="请输入" style={{width:'100%'}}/>
        </Form.Item>
        <Form.Item label="精度等级" name="accuracy">
          <Input disabled={!readAndEdit} placeholder="请输入"/> 
        </Form.Item>
        <Form.Item label="状态" name="status">
          {/* <Input disabled={!readAndEdit} placeholder="请输入"/>  */}
          <Select disabled={!readAndEdit} placeholder="请选择">
            {
              optionList.map((i:any,index:number) => 
                <Option key={uniqid.default()} value={i.name}>{i.name}</Option>
              )
            }
          </Select> 
        </Form.Item>
        <Form.Item label="备注" name="remark" >
          <TextArea placeholder='描述技术名称、原理、案例、效果' rows={2} disabled={!readAndEdit} />
        </Form.Item>

        <Form.Item label="上传附件" name="files" rules={[{required: true}]}>
          <Upload {...fileupLoad} fileList={flowChartList} disabled={!readAndEdit}>
            <Button style={{width:'100%'}}>
              <UploadOutlined />点击上传
            </Button>支持.xls .xlsx .doc .docx .pdf .jpg格式，单个文件大小不超过20M
          </Upload>
        </Form.Item>
        <Form.Item>
          <div style={{textAlign: 'right'}}>
            <Button type="default" onClick={props.onClose} style={{marginRight: readAndEdit?'20px':''}}>取消</Button>
            {readAndEdit?
            <Button className="green-button" htmlType="submit" loading={btnLoading}>保存</Button>:''}
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default AddProdBaise;