import './product-info-detail.scss';
import React, {
    forwardRef,
    FunctionComponent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import http from '../../../../utils/http';
import {
    Button,
    Cascader,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Popover,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Upload
} from 'antd';
import {verificationType, waterEditAndRead} from '../../../../utils/utils';
import * as uniqid from 'uniqid';
import {ProductDetatil, ProductionItem, ProductItemDetail, productType, typeEnum} from '../../../../utils/entity';
import {UploadOutlined} from '@ant-design/icons';
import store from '../../../../utils/store';
import {OPENIMG} from '../../../../utils/actions';
import {first} from 'lodash';
import QuantitativeProductData from '../../quantitative-product-data';

interface ProductItem {
    key?: string
    code: string
    children: Array<ProductItem>
    coefficientList: Array<coefficient>
    folding: boolean
    level: number
    name: string
    unit: string
    value?: string
    label?: any
}

interface coefficient {
    coefficientId: number
    fillingMethodEnum: string
    name: string
    optionList: Array<{ flag: number, name: string }>
    unit: string
    isRequired?: boolean
}

interface DataChart {
    initProductName?: any
    refList?: any
    step?: number
    period?: number
    initName?: string
    tabKey?: string
    id?: any
    isEdit?: boolean
    ref?: any
}

class ItemObj {
    item0: number
    item1: number
    item2: number
    item3: number
    item4: number
    item5: number
    item11: number
    item12: number

    constructor(params?: any) {
        this.item0 = params?.item0 || null;
        this.item1 = params?.item1 || null;
        this.item2 = params?.item2 || null;
        this.item3 = params?.item3 || null;
        this.item4 = params?.item4 || null;
        this.item5 = params?.item5 || null;
        this.item11 = params?.item11 || null;
        this.item12 = params?.item12 || null;
    }
}

const ProductInfoDetail: FunctionComponent<DataChart> = forwardRef((props: any, ref: any) => {
    const [basicInfo] = Form.useForm();
    const [parameterInfo] = Form.useForm();
    const [productData] = Form.useForm();
    const [readAndEdit, setReadAndEdit] = useState<boolean>(false);
    const {Option} = Select;
    const [optionList, setOptionList] = useState<Array<ProductItem>>([]);
    const [chooseData, setChooseData] = useState<Array<coefficient>>([]);
    const [step, setStep] = useState<number>(props.step);
    // const [tabKey,setTabKey] = useState<string>(props.tabKey)
    const [tabKey, setTabKey] = useState<string>()
    const [showParameterInfo, setShowParameterInfo] = useState<boolean>(false);
    const [flowChartList, setFlowChartList] = useState<Array<any>>([]);
    const flowListRef = useRef<Array<any>>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [currentName, setCurrentName] = useState<string>('');
    const [tableData, setTableData] = useState<Array<any>>([]);
    const [chooseUnit, setChooseUnit] = useState<string>('');
    const [firstObj, setFirstObj] = useState<ItemObj>(new ItemObj())
    const [secondObj, setSecondObj] = useState<ItemObj>(new ItemObj())
    const [threeObj, setThreeObj] = useState<ItemObj>(new ItemObj())
    const [productItemCode, setProductItemCode] = useState<Array<string>>([]);
    const [parameterData, setParameterData] = useState<Array<any>>([]);
    const [dataObj, setDataObj] = useState<ProductDetatil>();
    const [currentPeriod, setCurrentPeriod] = useState<number>();
    const [currentType, setCurrentType] = useState<string>('');
    const [zBType, setZBType] = useState<boolean>(false);
    // const threeObj = new ItemObj()

    // const [status,setStatus] = useState<boolean>(true)
    useImperativeHandle(ref, () => ({
        childFun: (data: any) => {
            // setTabKey(data);
            if (props.tabKey === data) {
                getProductItemList();
            }
        }
    }))


    useEffect(() => {
        flowListRef.current = flowChartList;
    }, [flowChartList])

    const filter = (inputValue: string, path: any) =>{
        if(inputValue){
            return  path.some(
                (option:any) => (option.label as string).indexOf(inputValue) > -1,
            );
        }

    }

    // function filter(inputValue:any, path:any) {
    //     return path.some((option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    // }
    let fileupLoad = {
        beforeUpload: (file: any) => {
            if (verificationType(file.type)) {
                flowChartListUpload(file);
            } else {
                message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
            }
            return false;
        },
        onRemove: (file: any) => {
            let list = flowChartList.filter((i: any) => i['uid'] !== file.uid);
            setFlowChartList(list);
            return false;
        },
        onPreview: (file: any) => {
            store.dispatch({type: OPENIMG, imgId: file.id, imgName: file.name})
            return false;
        }
    };

    const flowChartListUpload = (file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        http.post('/system/file/upload/auth', formData).then((res: any) => {
            if (res && res.code === 200) {
                message.success('上传成功');
                let img = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    id: res.data,
                    url: ''
                };
                setFlowChartList([...flowChartList, img]);
            }
        })
    }


    const chooseOption = (value: any, options: any) => {
        if(value.length){
            if (options[options.length - 1].folding) {
                setShowParameterInfo(true);
                setChooseData(options[options.length - 1].coefficientList);
                setChooseUnit(options[options.length - 1].unit);
            } else {
                setShowParameterInfo(false);
                setChooseUnit(options[options.length - 1].unit);
            }
            setProductItemCode(value);
        }
    }

    const onChangeInput = (e: any) => {
        setCurrentName(e.target.value);
    }

    const backUpStep = useCallback(() => {
        let type = step;
        if (type > 1) {
            if (showParameterInfo) {
                setStep(type - 1);
            } else {
                setStep(type - 2);
            }
        }
    }, [showParameterInfo, step])

    const refStep = useRef<boolean>(true);

    const nextStep = () => {
        refStep.current = false;
        let type = step;
        if (showParameterInfo) {
            setStep(type + 1);
            type = type + 1;
        } else {
            setStep(type + 2);
            type = type + 2;
        }
        if (step === 1 && !props.isEdit) {
            props.initProductName(tabKey, currentName);
        }

        if (step === 2) {
            initParameterData();
        }
        if (type === 3 && props.step === 1 && !props.isEdit) {
            initDataSource();
        }
    }

    const initParameterData = () => {
        let arr: any = [];
        chooseData.forEach((item: any) => {
            if (item.fillingMethodEnum === 'DROP_DOWN_LIST') {
                arr.push({
                    coefficientId: item.coefficientId,
                    flag: parameterInfo.getFieldsValue()[item.coefficientId]
                })
            } else {
                arr.push({
                    coefficientId: item.coefficientId,
                    value: parameterInfo.getFieldsValue()[item.coefficientId]
                })
            }
        })
        setParameterData(arr);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getProductDetail = (list: any) => {
        http.get('/apiWaterUrl/assess/product/' + tabKey, {
            assessId: props.id
        }).then((res: any) => {
            if (res && res.data) {
                setDataObj(res.data);
                let imgArr: any = [];
                res.data.files?.forEach((i: any, index: number) => {
                    let img = {
                        uid: index,
                        name: i.showName,
                        status: 'done',
                        id: i.fileId,
                        url: ''
                    };
                    imgArr.push(img);
                })
                setFlowChartList(imgArr.slice());
                //step==1
                let code = res.data.productItemCode;
                code = code.substring(0, 4);
                basicInfo.setFieldsValue({
                    prizeEnum: [code, res.data.productItemCode],
                    name: res.data.productName
                })  //prizeEnum  name currentName
                setProductItemCode([code, res.data.productItemCode].slice());
                setCurrentName(res.data.productName.slice());
                list.forEach((item: ProductItem) => {
                    if (item.code === code) {
                        item.children.forEach((i: ProductItem) => {
                            if (i.code === res.data.productItemCode) {
                                chooseOption([code, res.data.productItemCode], [item, i]);
                            }
                        })
                    }
                });
                let status = res.data.fillBySelf ? res.data.fillBySelf : false;
                setZBType(status);

                if (res.data.parameterInfoList) {
                    setShowParameterInfo(true)
                    res.data.parameterInfoList?.forEach((item: any) => {
                        // let keyMap:any = {name:item.coefficientId};
                        // let obj:any = {name:item.flag||item.value};
                        // let obj2 = Object.keys(obj).reduce((newData:any,key:any) => {
                        //   let newKey = keyMap[key].toString() || key.toString();
                        //   newData[newKey] = obj[key];
                        //   return newData;
                        // },{})
                        // parameterInfo.setFieldsValue(obj2);
                        let ids = item.coefficientId;
                        parameterInfo.setFieldsValue({[ids]: item.flag || item.value})
                    })
                    setParameterData(res.data.parameterInfoList);
                }
                console.timeEnd('start');

                //step==3
                res.data.productions?.forEach((item: any, i: number) => {
                    item.key = i;
                    if (i === 0 || i === 1 || i === 2 || i === 3 || i === 4 || i === 5 || i === 11 || i === 12) {
                        if (i === 0 || i === 1 || i === 2 || i === 4) {
                            item.isInput = true;
                        } else if (i === 3 && res.data.fillBySelf) {
                            item.isInput = true;
                        } else {
                            item.isInput = false;
                        }
                        switch (i) {
                            case 0:
                                initResultObj(firstObj, 'item0', item.firstItem.value);
                                initResultObj(secondObj, 'item0', item.secondItem.value);
                                initResultObj(threeObj, 'item0', item.threeItem.value);
                                break;
                            case 1:
                                initResultObj(firstObj, 'item1', item.firstItem.value);
                                initResultObj(secondObj, 'item1', item.secondItem.value);
                                initResultObj(threeObj, 'item1', item.threeItem.value);
                                break;
                            case 2:
                                initResultObj(firstObj, 'item2', item.firstItem.value);
                                initResultObj(secondObj, 'item2', item.secondItem.value);
                                initResultObj(threeObj, 'item2', item.threeItem.value);
                                break;
                            case 3:
                                productData.setFieldsValue({
                                    'firstItem3': item.firstItem.value,
                                    'secondItem3': item.secondItem.value,
                                    'threeItem3': item.threeItem.value
                                })
                                initResultObj(firstObj, 'item3', item.firstItem.value);
                                initResultObj(secondObj, 'item3', item.secondItem.value);
                                initResultObj(threeObj, 'item3', item.threeItem.value);
                                break;
                            case 4:
                                initResultObj(firstObj, 'item4', item.firstItem.value);
                                initResultObj(secondObj, 'item4', item.secondItem.value);
                                initResultObj(threeObj, 'item4', item.threeItem.value);
                                break;

                            case 5:
                                productData.setFieldsValue({
                                    'firstItem5': item.firstItem.value,
                                    'secondItem5': item.secondItem.value,
                                    'threeItem5': item.threeItem.value
                                })
                                initResultObj(firstObj, 'item5', item.firstItem.value);
                                initResultObj(secondObj, 'item5', item.secondItem.value);
                                initResultObj(threeObj, 'item5', item.threeItem.value);
                                break;
                            case 11:
                                productData.setFieldsValue({
                                    'firstItem11': item.firstItem.value,
                                    'secondItem11': item.secondItem.value,
                                    'threeItem11': item.threeItem.value
                                })
                                initResultObj(firstObj, 'item11', item.firstItem.value);
                                initResultObj(secondObj, 'item11', item.secondItem.value);
                                initResultObj(threeObj, 'item11', item.threeItem.value);
                                break;
                            case 12:
                                productData.setFieldsValue({
                                    'firstItem12': item.firstItem.value,
                                    'secondItem12': item.secondItem.value,
                                    'threeItem12': item.threeItem.value
                                })
                                initResultObj(firstObj, 'item12', item.firstItem.value);
                                initResultObj(secondObj, 'item12', item.secondItem.value);
                                initResultObj(threeObj, 'item12', item.threeItem.value);
                                break;
                        }
                    } else {
                        item.isInput = true;
                    }
                })
                // console.log(res.data.productions)
                let data = res.data.productions;
                if (!res.data.parameterInfoList) {
                    // data.splice(3,1);
                    delete data[3];
                }
                setTableData(data);
                // console.log(data)
            }
        })
    }

    const initResultObj = (resultObj: any, item: string, value: number) => {
        resultObj[item] = value;
    }

    const onFinish = (value: any) => {
        let obj;
        let resData = tableData;
        resData.forEach((item: ProductionItem) => {
            if (item.key === 5) {
                item.firstItem.value = productData.getFieldValue('firstItem5');
                item.secondItem.value = productData.getFieldValue('secondItem5');
                item.threeItem.value = productData.getFieldValue('threeItem5');
            } else if (item.key === 11) {
                item.firstItem.value = productData.getFieldValue('firstItem11');
                item.secondItem.value = productData.getFieldValue('secondItem11');
                item.threeItem.value = productData.getFieldValue('threeItem11');
            } else if (item.key === 12) {
                item.firstItem.value = productData.getFieldValue('firstItem12');
                item.secondItem.value = productData.getFieldValue('secondItem12');
                item.threeItem.value = productData.getFieldValue('threeItem12');
            }
        })

        if (!zBType) {
            let data = new ProductionItem({
                key: 3,
                typeEnum: 'V4',
                isInput: false,
                firstItem: new ProductItemDetail(),
                secondItem: new ProductItemDetail(),
                threeItem: new ProductItemDetail()
            });
            if (showParameterInfo) {
                data.firstItem.value = productData.getFieldValue('firstItem3');
                data.secondItem.value = productData.getFieldValue('secondItem3');
                data.threeItem.value = productData.getFieldValue('threeItem3');
                resData[3] = data;
            } else {
                data.firstItem.value = firstObj.item3;
                data.secondItem.value = secondObj.item3;
                data.threeItem.value = threeObj.item3;
                if (props.isEdit && !resData[3]) {
                    resData[3] = data;
                } else {
                    resData.splice(3, 0, data);
                }
            }
        }

        //判断必填项
        let type: boolean = false;
        if (zBType) {
            resData.forEach((item: ProductionItem) => {
                if (item.key !== 2 && !Number(item.firstItem.value)  )  {
                    type = true;
                }
            })
        } else {
            resData.forEach((item: ProductionItem) => {
                if (!Number(item.firstItem.value) && item.key !== 8 && item.key !== 9 && item.key !== 10 ) {
                    type = true;
                }
            })
        }
        if (type) {
            message.info("请填写必填项");
            if (!showParameterInfo) {
                delete resData[3];
            }
            return;
        }

        if (resData.length !== 13) {
            message.info("操作问题，请联系管理员");
            console.log(resData);
            return;
        }
        // return;

        if (showParameterInfo) {
            //组装二级
            let files: any = [];
            flowChartList.forEach((file: any) => {
                files.push({
                    fileId: file.id,
                    showName: file.name
                })
            })
            let arr: any = [];
            chooseData.forEach((item: any) => {
                if (item.fillingMethodEnum === 'DROP_DOWN_LIST') {
                    arr.push({
                        coefficientId: item.coefficientId,
                        flag: parameterInfo.getFieldsValue()[item.coefficientId]
                    })
                } else {
                    arr.push({
                        coefficientId: item.coefficientId,
                        value: parameterInfo.getFieldsValue()[item.coefficientId]
                    })
                }
            })

            obj = {
                assessId: props.id,
                files: files,
                parameterInfoList: arr,
                productItemCode: productItemCode[productItemCode.length - 1],
                productName: currentName,
                productions: resData,
                fillBySelf: zBType
            }
        } else {
            obj = {
                assessId: props.id,
                productItemCode: productItemCode[productItemCode.length - 1],
                productName: currentName,
                productions: resData,
                fillBySelf: zBType
            }
        }

        if (props.isEdit) {
            http.put('/apiWaterUrl/assess/product/' + tabKey, obj).then((res: any) => {
                if (res && res.code === 200) {
                    message.success("保存成功！");
                    props.refList(tabKey);
                } else {
                    message.error(res.message);
                }
            })
        } else {
            http.post('/apiWaterUrl/assess/product', obj).then((res: any) => {
                if (res && res.code === 200) {
                    message.success("保存成功！");
                    props.refList(res.data);
                } else {
                    message.error(res.message);
                }
            })
        }
    }

    const initDataSource = () => {
        let arr: Array<ProductionItem> = [];
        for (let i = 1; i <= 13; i++) {
            let v: productType = 'V' + i as productType;
            if (i === 4 || i === 6 || i === 12 || i === 13) {
                if (!showParameterInfo && i === 4) {
                } else {
                    if (zBType && i === 4) {
                        arr[i - 1] = new ProductionItem({
                            typeEnum: v,
                            isInput: true,
                            firstItem: new ProductItemDetail(),
                            secondItem: new ProductItemDetail(),
                            threeItem: new ProductItemDetail()
                        });
                    } else {
                        arr[i - 1] = new ProductionItem({
                            typeEnum: v,
                            isInput: false,
                            firstItem: new ProductItemDetail(),
                            secondItem: new ProductItemDetail(),
                            threeItem: new ProductItemDetail()
                        });
                    }
                }
            } else {
                arr[i - 1] = new ProductionItem({
                    typeEnum: v,
                    isInput: true,
                    firstItem: new ProductItemDetail(),
                    secondItem: new ProductItemDetail(),
                    threeItem: new ProductItemDetail()
                });
            }
        }
        arr.forEach((i: ProductionItem, index: number) => {
            i.key = index;
        })
        setTableData(arr);
    }

    const forEachOptions = (items: Array<ProductItem>) => {
        items.forEach((i: ProductItem) => {
            i.key = uniqid.default();
            if (i.level === 1) {
                i.label = i.code + ' ' + i.name;
            } else {
                i.label = i.name;
            }
            i.value = i.code;
            if (i.children && i.children.length > 0) {
                forEachOptions(i.children);
            }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getProductItemList = () => {
        http.get('/apiWaterUrl/assess/product/item/list').then((res: any) => {
            if (res && res.data) {
                forEachOptions(res.data);
                setOptionList(res.data);
                if (props.isEdit) {
                    getProductDetail(res.data);
                }
            }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (refStep.current) {
            setStep(props.step);
            setTabKey(props.tabKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setReadAndEdit(waterEditAndRead());
        // let isRead = cookie.load('waterEffectEdit')
        let isRead = sessionStorage.getItem('waterEffectEdit') === 'true' ? true : false;
        setReadAndEdit(isRead)
        console.log(readAndEdit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const count = useRef(true);

    useEffect(() => {
        // if(count.current){
        if (tabKey) {
            getProductItemList();
        }
        // }
        // return ()=>{
        //   count.current=false;
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabKey])

    const parameterDataChange = (id: any, value: any) => {
        parameterInfo.setFieldsValue({[id]: value});
    }

    const onChange = (e: any) => {
        setZBType(e.target.checked);
        refCount.current = refCount.current + 1;
        if (props.isEdit && !e.target.checked) {
            let arr = ['first', 'second', 'three']
            let objArr = [firstObj, secondObj, threeObj];
            arr.forEach((i: any, index: number) => {
                if (objArr[index].item2) {
                    calculateItem3(i, objArr[index], objArr[index].item2);
                }
            })
        }
    }

    const chooseDataDomView = () => {
        return (
            chooseData.map((item: coefficient) => {
                if (item.fillingMethodEnum === 'DROP_DOWN_LIST') {
                    return <Col span={12} key={uniqid.default()}>
                        <Form.Item label={item.name} name={item.coefficientId} rules={[{required: !(zBType)}]}>
                            <Select disabled={!readAndEdit} placeholder="请选择"
                                    onChange={(value: any) => parameterDataChange(item.coefficientId, value)}>
                                {
                                    item.optionList.map((i: any, index: number) =>
                                        <Option key={uniqid.default()} value={i.flag}>{i.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                } else {
                    return <Col span={12} key={uniqid.default()}>
                        <Form.Item label={item.name} name={item.coefficientId} rules={[{required: !(zBType)}]}>
                            <Input type="number" disabled={!readAndEdit} suffix={item.unit} placeholder="请输入数值"/>
                        </Form.Item>
                    </Col>
                }
            })
        )
    }

    const refCount = useRef<number>(0);

    useEffect(() => {
        let data = [...tableData];
        data.forEach((i: ProductionItem, index: number) => {
            if (i) {
                // eslint-disable-next-line no-self-assign
                i.key = i.key;
                if (i.key === 3) {
                    i.isInput = zBType;
                }
            } else {
                delete data[index]
            }
        })
        setTableData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refCount.current])


    const handleOk = () => {
        refCount.current = refCount.current + 1;
        let data = tableData;
        data.forEach((item: ProductionItem, index: number) => {
            // item.firstItem = new ProductItemDetail();
            let v: productType = item.typeEnum || 'V1';
            if (item.key === 3 || item.key === 5 || item.key === 11 || item.key === 12) {
                if (zBType && item.key === 3) {
                    data[index] = new ProductionItem({
                        key: item.key,
                        isInput: true,
                        typeEnum: v,
                        firstItem: currentPeriod === props.period ? new ProductItemDetail() : item.firstItem,
                        secondItem: currentPeriod === props.period - 1 ? new ProductItemDetail() : item.secondItem,
                        threeItem: currentPeriod === props.period - 2 ? new ProductItemDetail() : item.threeItem
                    })
                } else {
                    data[index] = new ProductionItem({
                        key: item.key,
                        isInput: false,
                        typeEnum: v,
                        firstItem: currentPeriod === props.period ? new ProductItemDetail() : item.firstItem,
                        secondItem: currentPeriod === props.period - 1 ? new ProductItemDetail() : item.secondItem,
                        threeItem: currentPeriod === props.period - 2 ? new ProductItemDetail() : item.threeItem
                    })
                }
            } else {
                data[index] = new ProductionItem({
                    key: item.key,
                    isInput: true,
                    typeEnum: v,
                    firstItem: currentPeriod === props.period ? new ProductItemDetail() : item.firstItem,
                    secondItem: currentPeriod === props.period - 1 ? new ProductItemDetail() : item.secondItem,
                    threeItem: currentPeriod === props.period - 2 ? new ProductItemDetail() : item.threeItem

                })
            }
        })
        setTableData(data);

        if (currentType === 'first') {
            productData.setFieldsValue({firstItem3: 0, firstItem5: 0, firstItem11: 0, firstItem12: 0})
        } else if (currentType === 'second') {
            productData.setFieldsValue({secondItem3: 0, secondItem5: 0, secondItem11: 0, secondItem12: 0})
        } else {
            productData.setFieldsValue({threeItem3: 0, threeItem5: 0, threeItem11: 0, threeItem12: 0})
        }
        handleCancel();
    }

    const handleCancel = () => {
        setVisible(false);
    }

    const initialTagImg = (id: number) => {
        switch (id) {
            case 4:
                return <Popover content={
                    <p style={{maxWidth: '400px'}}>
                        单位产品取水量是指企业生产每百米机织产品或每吨针织产品的取水量。取水量范围指企业从各种常规水源提取的水量，包括取自地表水（以净水厂供水计量）、地下水、城镇供水工程，以及企业从市场购得的其他水或水的产品（如蒸汽、热水、地热水等）的水量。
                    </p>
                } title={<span className="item-div-popover-title">填报说明</span>} trigger="hover"
                                placement="bottomLeft">
                    <img className="img" src="/assets/images/icon_description_normal.png" alt=""/>
                </Popover>
            case 6:
                return <Popover content={
                    <p style={{maxWidth: '400px'}}>
                        水重复利用率是指在一定的计量时间内，生产过程中使用的重复利用水量与用水量的百分比。重复利用水量是指在确定的用水单元或系统内，使用的所有未经处理和处理后重复使用的水量的总和，即循环水量和串联水量的总和；用水量是指在确定的用水单元或系统内，使用的各种水量的总和，即新水量和重复利用水量；新水量是指企业内用水单元或系统取自任何水源被该企业第一次利用的水量。<br/>
                        水重复利用率=生产过程的重复利用水量÷（重复利用水量+取水量+非常规水资源取水量）×100%
                    </p>
                } title={<span className="item-div-popover-title">填报说明</span>} trigger="hover"
                                placement="bottomLeft">
                    <img className="img" src="/assets/images/icon_description_normal.png" alt=""/>
                </Popover>
            case 7:
                return <Popover content={
                    <p style={{maxWidth: '400px'}}>
                        用水综合漏失率是指企业的漏失水量与新水量的百分比；漏失水量是指企业供水及用水管网和用水设备漏失的水量。<br/>
                        用水综合漏失率=企业的漏失水量÷企业的取水量×100%
                    </p>
                } title={<span className="item-div-popover-title">填报说明</span>} trigger="hover"
                                placement="bottomLeft">
                    <img className="img" src="/assets/images/icon_description_normal.png" alt=""/>
                </Popover>
            case 8:
                return <Popover content={
                    <p style={{maxWidth: '400px'}}>
                        冷凝水回用率是指在一定的计量时间内，蒸汽冷凝水回用量占锅炉蒸汽发汽量的百分比。冷凝水回用量是指蒸汽经使用（例如用于汽轮机等设备做功、加热、供热、汽提分离等）冷凝后，直接或经处理后回用于锅炉和其他系统的冷凝水量。<br/>
                        冷凝水回用率=蒸汽冷凝水回用量÷产汽设备产汽量×蒸汽体积质量×100%
                    </p>
                } title={<span className="item-div-popover-title">填报说明</span>} trigger="hover"
                                placement="bottomLeft">
                    <img className="img" src="/assets/images/icon_description_normal.png" alt=""/>
                </Popover>
            case 9:
                return <Popover content={
                    <p style={{maxWidth: '400px'}}>
                        冷却水回用率是指在一定的计量时间内，冷却水利用量占冷却水总量的百分比。<br/>
                        冷却水回用率=冷却水循环量÷（冷却水循环量+冷却水循环系统补充水量）×100%
                    </p>
                } title={<span className="item-div-popover-title">填报说明</span>} trigger="hover"
                                placement="bottomLeft">
                    <img className="img" src="/assets/images/icon_description_normal.png" alt=""/>
                </Popover>
            case 10:
                return <Popover content={
                    <p style={{maxWidth: '400px'}}>
                        废水回用率是指在一定的计量时间内，企业内生产的生活和生产污水，经处理再利用的水量与排水量的百分比；排水量是指对于确定的用水单元，完成生产过程和生产活动后排出企业之外以及排出该单元进入污水系统的水量。<br/>
                        废水回用率=企业外排废水处理后的回用水量÷（企业外排废水处理后的回用水量+企业向外排放的废水量）
                    </p>
                } title={<span className="item-div-popover-title">填报说明</span>} trigger="hover"
                                placement="bottomLeft">
                    <img className="img" src="/assets/images/icon_description_normal.png" alt=""/>
                </Popover>
        }
    }

    const initProductTypeTag = (record: any, chooseUnit: string, v: productType) => {
        const t  = productItemCode[productItemCode.length - 1]
        if (record.key === 5) {
            return  <Space><span>{record.isInput ? <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}（m3/{chooseUnit}）<Tag
                className="tag-box"> {
                (['171101', '171201', '178101'].includes(productItemCode[productItemCode.length - 1]))  ? '24' : '20'
            } 分</Tag>
                </span>{initialTagImg(record.key)}
            </Space>
        }else if (record.key === 4) {
            return <Space><span>{record.isInput ?
                <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}</span>{initialTagImg(record.key)}
            </Space>
        } else if (record.key === 6){
            return <Space><span>{record.isInput ? <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}<Tag
                className="tag-box"> {
                (['171101', '171201', '178101'].includes(productItemCode[productItemCode.length - 1]))  ? '10' : '8'
            } 分</Tag></span>{initialTagImg(record.key)}
            </Space>


        }else if (record.key === 7){
            return  <Space><span>{record.isInput ? <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}<Tag
                className="tag-box"> {
                (['171101', '171201', '178101'].includes(productItemCode[productItemCode.length - 1]))  ? '6' : '4'
            } 分</Tag></span>{initialTagImg(record.key)}
            </Space>




        }else if (record.key === 8){
            return <Space> <span>{record.isInput ? <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}<Tag
                className="tag-box"> {
                (['171101', '171201', '178101'].includes(productItemCode[productItemCode.length - 1]))  ? '0' : '4'
            } 分</Tag></span>{initialTagImg(record.key)}
            </Space>
        }
        else if (record.key === 9){
            return <Space> <span>{record.isInput ? <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}<Tag
                className="tag-box"> {
                (['171101', '171201', '178101'].includes(productItemCode[productItemCode.length - 1]))  ? '0' : '4'
            } 分</Tag></span>{initialTagImg(record.key)}
            </Space>
        }
        else if (record.key === 10){
            return <Space>  <span>{record.isInput ? <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}<Tag
                className="tag-box"> {
                (['171101', '171201'].includes(productItemCode[productItemCode.length - 1]))  ? '0' : '8'
            } 分</Tag></span>{initialTagImg(record.key)}
            </Space>
        }else if (record.key === 11){
            return <Space>  <span>{record.isInput ? <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]} <Tag
                className="tag-box"> {
                (['171101', '171201'].includes(productItemCode[productItemCode.length - 1]))  ? '12' : '4'
            } 分</Tag></span>{initialTagImg(record.key)}
            </Space>
        }
    }


    const inputOnChange = (type: string, text: any, e?: any) => {
        refCount.current = refCount.current + 1;
        let data = [...tableData];
        data.forEach((i: ProductionItem, index: number) => {
            if (i) {
                // eslint-disable-next-line no-self-assign
                i.key = i.key;
            }
        })

        //验证产品参数信息必填项
        let status: boolean = false;
        if (text.key === 2 && !zBType) {
            chooseData.forEach((i: any) => {
                if (!parameterInfo.getFieldsValue()[i.coefficientId]) {
                    status = true;
                }
            })
        }
        if (status) {
            message.info("请选择产品参数信息必填项");
            return;
        }

        data.forEach((item: ProductionItem) => {
            if (item) {
                if (item.key === text.key) {
                    if (type === 'first') {
                        item.firstItem.value = e.target.value;
                    } else if (type === 'second') {
                        item.secondItem.value = e.target.value;
                    } else {
                        item.threeItem.value = e.target.value;
                    }
                }
            }
        })
        if (!showParameterInfo) {
            if (data.filter(i => !i).length > 0) {
                data.splice(3, 1);
            }
        }
        setTableData(data);

        let resultObj = type === 'first' ? firstObj : type === 'second' ? secondObj : threeObj;
        if (text.key === 2 && !zBType) {
            if (showParameterInfo) {
                if (e.target.value) {
                    resultObj.item2 = Number(e.target.value);
                    calculateItem3(type, resultObj, e.target.value);
                }
            } else {
                //初始化标准品产量
                initItem3(type, resultObj, e.target.value);
            }
        }

        //企业自行填写标准品产量
        if (text.key === 3 && zBType) {
            initItem3(type, resultObj, e.target.value);
            if (resultObj.item3 && resultObj.item4) {
                resultObj.item5 = resultObj.item4 / resultObj.item3;
                if (type === 'first') {
                    productData.setFieldsValue({'firstItem5': resultObj.item5.toFixed(4)})
                } else if (type === 'second') {
                    productData.setFieldsValue({'secondItem5': resultObj.item5.toFixed(4)})
                } else {
                    productData.setFieldsValue({'threeItem5': resultObj.item5.toFixed(4)})
                }
            }
        }

        /*0：年产值（item12单位产值取水量）
          1：工业增加值（item11单位工业增加水耗）
          2：年产量（计算item5单位产品取水量）
          4：取水量（计算item5单位产品取水量，更新item11，item12）
        */
        if (text.key === 0 || text.key === 1 || text.key === 2 || text.key === 4) {
            if (text.key === 0) {
                resultObj.item0 = Number(e.target.value);
                if (resultObj.item4) {
                    resultObj.item12 = resultObj.item4 / resultObj.item0;
                    if (type === 'first') {
                        productData.setFieldsValue({'firstItem12': resultObj.item12.toFixed(4)})
                    } else if (type === 'second') {
                        productData.setFieldsValue({'secondItem12': resultObj.item12.toFixed(4)})
                    } else {
                        productData.setFieldsValue({'threeItem12': resultObj.item12.toFixed(4)})
                    }
                }
            }
            if (text.key === 1) {
                resultObj.item1 = Number(e.target.value);
                if (resultObj.item4) {
                    resultObj.item11 = resultObj.item4 / resultObj.item1;
                    if (type === 'first') {
                        productData.setFieldsValue({'firstItem11': resultObj.item11.toFixed(4)})
                    } else if (type === 'second') {
                        productData.setFieldsValue({'secondItem11': resultObj.item11.toFixed(4)})
                    } else {
                        productData.setFieldsValue({'threeItem11': resultObj.item11.toFixed(4)})
                    }
                }
            }
            if (resultObj.item3 && text.key === 2 && resultObj.item4) {
                resultObj.item5 = resultObj.item4 / resultObj.item3;
                if (type === 'first') {
                    productData.setFieldsValue({'firstItem5': resultObj.item5.toFixed(4)})
                } else if (type === 'second') {
                    productData.setFieldsValue({'secondItem5': resultObj.item5.toFixed(4)})
                } else {
                    productData.setFieldsValue({'threeItem5': resultObj.item5.toFixed(4)})
                }
            }
            if (text.key === 4) {
                resultObj.item4 = Number(e.target.value);
                if (resultObj.item3) {
                    resultObj.item5 = resultObj.item4 / resultObj.item3;
                    if (type === 'first') {
                        productData.setFieldsValue({'firstItem5': resultObj.item5.toFixed(4)})
                    } else if (type === 'second') {
                        productData.setFieldsValue({'secondItem5': resultObj.item5.toFixed(4)})
                    } else {
                        productData.setFieldsValue({'threeItem5': resultObj.item5.toFixed(4)})
                    }
                }
                if (resultObj.item1) {
                    resultObj.item11 = resultObj.item4 / resultObj.item1;
                    if (type === 'first') {
                        productData.setFieldsValue({'firstItem11': resultObj.item11.toFixed(4)})
                    } else if (type === 'second') {
                        productData.setFieldsValue({'secondItem11': resultObj.item11.toFixed(4)})
                    } else {
                        productData.setFieldsValue({'threeItem11': resultObj.item11.toFixed(4)})
                    }
                }
                if (resultObj.item0) {
                    resultObj.item12 = resultObj.item4 / resultObj.item0;
                    if (type === 'first') {
                        productData.setFieldsValue({'firstItem12': resultObj.item12.toFixed(4)})
                    } else if (type === 'second') {
                        productData.setFieldsValue({'secondItem12': resultObj.item12.toFixed(4)})
                    } else {
                        productData.setFieldsValue({'threeItem12': resultObj.item12.toFixed(4)})
                    }
                }
            }
        }
        if (type === 'first') {
            setFirstObj(resultObj)
        } else if (type === 'second') {
            setSecondObj(resultObj)
        } else {
            setThreeObj(resultObj)
        }
    }

    const initItem3 = (type: string, resultObj: any, value: any) => {
        // if(type === 'first'){
        resultObj.item3 = Number(value);
        // }else if(type === 'second'){
        //   resultObj.item3 = Number(value);
        // }else{
        //   resultObj.item3 = Number(value);
        // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const calculateItem3 = useCallback((type: string, resultObj: any, value: any) => {
        let arr: any = [];
        chooseData.forEach((item: any) => {
            if (item.fillingMethodEnum === 'DROP_DOWN_LIST') {
                arr.push({
                    coefficientId: item.coefficientId,
                    flag: parameterInfo.getFieldsValue()[item.coefficientId]
                })
            } else {
                arr.push({
                    coefficientId: item.coefficientId,
                    value: parameterInfo.getFieldsValue()[item.coefficientId]
                })
            }
        })
        setParameterData(arr);

        http.post('/apiWaterUrl/assess/product/standard/calculation', {
            annualOutput: value,
            assessId: props.id,
            parameterInfoList: arr,
            productItemCode: productItemCode[productItemCode.length - 1]
        }).then((res: any) => {
            if (res && res.data) {
                initItem3(type, resultObj, res.data);
                if (type === 'first') {
                    productData.setFieldsValue({'firstItem3': Number(resultObj.item3).toFixed(4)})
                } else if (type === 'second') {
                    productData.setFieldsValue({'secondItem3': Number(resultObj.item3).toFixed(4)})
                } else {
                    productData.setFieldsValue({'threeItem3': Number(resultObj.item3).toFixed(4)})
                }
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameterData, productData, productItemCode, props.id, props.isEdit])


    const calculate = (text: any, type: string) => {
        if (text.key === 3) {
            // return item.item3;
            if (type === 'first') {
                return <Form.Item name="firstItem3" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            } else if (type === 'second') {
                return <Form.Item name="secondItem3" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            } else {
                return <Form.Item name="threeItem3" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            }
        } else if (text.key === 5) {
            // return item.item5;
            if (type === 'first') {
                return <Form.Item name="firstItem5" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            } else if (type === 'second') {
                return <Form.Item name="secondItem5" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            } else {
                return <Form.Item name="threeItem5" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            }

        } else if (text.key === 11) {
            // return item.item11;
            if (type === 'first') {
                return <Form.Item name="firstItem11" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            } else if (type === 'second') {
                return <Form.Item name="secondItem11" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            } else {
                return <Form.Item name="threeItem11" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            }

        } else if (text.key === 12) {
            // return item.item12;
            if (type === 'first') {
                return <Form.Item name="firstItem12" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            } else if (type === 'second') {
                return <Form.Item name="secondItem12" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            } else {
                return <Form.Item name="threeItem12" style={{margin: 0}}>
                    <Input disabled={true} type="text" className="calculate-input" style={{border: 'none'}}/>
                </Form.Item>
            }
        }
    }

    const resetValue = (value: number, type: string) => {
        setCurrentType(type);
        setCurrentPeriod(value);
        setVisible(true);
    }

    let columns = [{
        title: '统计年度',
        key: 'typeEnum',
        name: 'typeEnum',
        width: 220,
        render: (text: ProductionItem, record: any) => {
            console.log('text',text)
            console.log('recordrecord',record)
            // chooseUnit
            let v: productType = text.typeEnum || 'V1';
            if (record.key > 3 && record.key < 11) {
                return initProductTypeTag(record, chooseUnit, v);
            }
            if (record.key === 2) {
                return <span>{record.isInput && (!zBType) ?
                    <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}（{chooseUnit}）</span>
            }
            if (record.key === 3) {
                return <span>{record.isInput && (zBType) ?
                    <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}（{chooseUnit}）</span>
            }
            if (record.key === 11) {
                return <span>{typeEnum[v]}
                    <Tag className="tag-box">{(['171101', '171201'].includes(productItemCode[productItemCode.length - 1]))  ? '12' : '4'}分</Tag>
                </span>
            }
            return <span>{record.isInput ? <span style={{color: '#E51E1E'}}>*</span> : ''}{typeEnum[v]}</span>
        }
    }, {
        title: <div className="columns-delete">
            <span><span style={{color: '#E51E1E'}}>*</span>{props.period}年</span>
            <img className="img" src="/assets/images/icon_delete_normal.png" alt=""
                 onClick={() => resetValue(props.period, 'first')}/>
        </div>,
        key: 'firstItem',
        name: 'firstItem',
        width: 200,
        render: (text: any, record: any) => {
            let v: productType = text.typeEnum || 'V1';
            let otherDisabledState: boolean = otherDisabled(v)
            if (!otherDisabledState) {
                if (record.key === 3) {
                    return (
                        record.isInput && zBType ?
                            <>
                                <Input disabled={!readAndEdit} type="number" value={text.firstItem.value}
                                       placeholder="输入数值" onChange={(e) => inputOnChange('first', text, e)}
                                       style={{width: '100px', marginRight: '8px'}}/>
                                <UploadComponent readonly={readAndEdit} listData={tableData} fileIndex={text.key}
                                                 show={props.isEdit} fileChildrenIndex={'firstItem'}
                                                 item={text}></UploadComponent>
                            </>
                            : <>{calculate(text, 'first')}</>
                    )
                } else {
                    return (
                        record.isInput ?
                            <>
                                {/*<Input disabled={!readAndEdit} type="number" value={text.firstItem.value}*/}
                                {/*       placeholder="输入数值" onChange={(e) => inputOnChange('first', text, e)}*/}
                                {/*       style={{width: '100px', marginRight: '8px'}}/>*/}
                                <Input disabled={!readAndEdit} type="number" value={text.firstItem.value}
                                       placeholder="输入数值" onChange={(e) => inputOnChange('first', text, e)}
                                       style={{width: '100px', marginRight: '8px'}}/>
                                <UploadComponent readonly={readAndEdit} listData={tableData} fileIndex={text.key}
                                                 show={props.isEdit} fileChildrenIndex={'firstItem'}
                                                 item={text}></UploadComponent>
                            </>
                            : <>{calculate(text, 'first')}</>
                    )
                }
            }
        }
    }, {
        // title: props.period-1 + '年',
        title: <div className="columns-delete">
            <span>{props.period - 1}年</span>
            <img className="img" src="/assets/images/icon_delete_normal.png" alt=""
                 onClick={() => resetValue(props.period - 1, 'second')}/>
        </div>,
        key: 'secondItem',
        name: 'secondItem',
        width: 200,
        render: (text: any, record: any) => {
            let v: productType = text.typeEnum || 'V1';
            let otherDisabledState: boolean = otherDisabled(v)
            if (!otherDisabledState) {
                if (record.key === 3) {
                    return (
                        record.isInput && zBType ?
                            <>
                                <Input disabled={!readAndEdit} type="number" value={text.secondItem.value}
                                       placeholder="输入数值" onChange={(e) => inputOnChange('second', text, e)}
                                       style={{width: '100px', marginRight: '8px'}}/>
                                <UploadComponent readonly={readAndEdit} listData={tableData} fileIndex={text.key}
                                                 fileChildrenIndex={'secondItem'} item={text}
                                                 style={{padding: '0px 8px'}}></UploadComponent>
                            </>
                            : <>{calculate(text, 'second')}</>
                    )
                } else {
                    return (
                        record.isInput ?
                            <>
                                <Input disabled={!readAndEdit} type="number" value={text.secondItem.value}
                                       placeholder="输入数值" onChange={(e) => inputOnChange('second', text, e)}
                                       style={{width: '100px', marginRight: '8px'}}/>
                                <UploadComponent readonly={readAndEdit} listData={tableData} fileIndex={text.key}
                                                 fileChildrenIndex={'secondItem'} item={text}
                                                 style={{padding: '0px 8px'}}></UploadComponent>
                            </>
                            : <>{calculate(text, 'second')}</>
                    )
                }
            }
        }
    }, {
        // title: props.period-2 + '年',
        title: <div className="columns-delete">
            <span>{props.period - 2}年</span>
            <img className="img" src="/assets/images/icon_delete_normal.png" alt=""
                 onClick={() => resetValue(props.period - 2, 'three')}/>
        </div>,
        key: 'threeItem',
        name: 'threeItem',
        width: 200,
        render: (text: any, record: any) => {
            let v: productType = text.typeEnum || 'V1';
            let otherDisabledState: boolean = otherDisabled(v)
            if (!otherDisabledState) {
                if (record.key === 3) {
                    return (
                        record.isInput && zBType ?
                            <>
                                <Input disabled={!readAndEdit} type="number" value={text.threeItem.value}
                                       placeholder="输入数值"
                                       onChange={(e) => inputOnChange('three', text, e)}
                                       style={{width: '100px', marginRight: '8px'}}/>
                                <UploadComponent readonly={readAndEdit} listData={tableData} fileIndex={text.key}
                                                 fileChildrenIndex={'threeItem'} item={text}></UploadComponent>
                            </>
                            : <>{calculate(text, 'three')}</>
                    )
                } else {
                    return (
                        record.isInput ?
                            <>
                                <Input disabled={!readAndEdit} type="number" value={text.threeItem.value}
                                       placeholder="输入数值"
                                       onChange={(e) => inputOnChange('three', text, e)}
                                       style={{width: '100px', marginRight: '8px'}}/>
                                <UploadComponent readonly={readAndEdit} listData={tableData} fileIndex={text.key}
                                                 fileChildrenIndex={'threeItem'} item={text}></UploadComponent>
                            </>
                            : <>{calculate(text, 'three')}</>
                    )
                }
            }
        }
    }]

    const initRow = (record: any, index: number) => {
        if (record.key > 4 && record.key < 10) {
            return 'rowColor1';
        } else if (record.key >= 10) {
            return 'rowColor2';
        } else {
            return '';
        }
    }

    const otherDisabled = (v: String):boolean => {
        let t: boolean = (v === 'V9' && (['171101', '171201', '178101'].includes(productItemCode[productItemCode.length - 1]))) ||
            (v === 'V10' && (['171101', '171201', '178101'].includes(productItemCode[productItemCode.length - 1]))) ||
            (v === 'V11' && (['171101', '171201'].includes(productItemCode[productItemCode.length - 1])))
        return t
    }


    return (
        <>
            <div className="product-info-detail-saving-information">
                <div className="tabs-header">
                    <img className="img" src="/assets/images/icon_prompt_normal.png" alt=""/>
                    <span className="header-title"> 选择产品种类 {">"} (补充产品参数) {">"} 填写产品生产数据</span>
                </div>
                <Form initialValues={{prizeEnum: productItemCode, name: currentName}}
                      form={basicInfo} name="basic-info" onFinish={nextStep} labelCol={{span: 6, offset: 0}}
                      wrapperCol={{span: 18, offset: 0}}>
                    <Divider type="vertical" className="item-title-divider"/><p
                    className="item-title-p">产品基本信息</p>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="产品种类" name="prizeEnum" rules={[{required: true}]}>
                                <Cascader options={optionList}
                                          expandTrigger="hover"
                                    // value={productItemCode}
                                          onChange={chooseOption}
                                          placeholder="请选择"
                                          showSearch={{ filter }}
                                          disabled={!readAndEdit || step !== 1}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="产品名称" name="name" rules={[{required: true}]}
                                       extra="请根据选择的产品种类填写，如xxx棉机织物、xxx棉印染针织物">
                                <Input value={currentName} onChange={onChangeInput} placeholder="请输入"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    {step === 1 ?
                        <Row gutter={16}>
                            <Col span={24}>
                                {/* <Form.Item> */}
                                <div style={{textAlign: 'right'}}>
                                    <Button type="default" htmlType="button" style={{marginRight: '20px'}}
                                            onClick={() => backUpStep()}>取消</Button>
                                    <Button className="green-button" htmlType="submit">下一步</Button>
                                </div>
                                {/* </Form.Item> */}
                            </Col>
                        </Row> : ''}
                </Form>

                {step >= 2 && showParameterInfo ?
                    <Form form={parameterInfo} name="parameter-info" onFinish={nextStep} labelCol={{span: 6, offset: 0}}
                          wrapperCol={{span: 18, offset: 0}}>
                        <Divider type="vertical" className="item-title-divider"/><p
                        className="item-title-p">产品参数信息</p>
                        <Checkbox onChange={onChange} checked={zBType} style={{padding: '0px 8px 0px 20px'}}></Checkbox><span
                        className="zb-font">企业自行填写标准品产量</span>
                        <Row gutter={16}>
                            {chooseDataDomView()}
                            <Col span={12}>
                                <Form.Item label="证明材料" name="files">
                                    <Upload {...fileupLoad} fileList={flowChartList} disabled={!readAndEdit}>
                                        <Button style={{width: '100%'}}>
                                            <UploadOutlined/>点击上传
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        {step === 2 ?
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div style={{textAlign: 'right'}}>
                                        <Button type="default" style={{marginRight: '20px'}}
                                                onClick={() => backUpStep()}>取消</Button>
                                        <Button className="green-button" htmlType="submit">下一步</Button>
                                    </div>
                                </Col>
                            </Row> : ''}
                    </Form> : ''}

                {step >= 3 ? <>
                    <Form form={productData} name="product-data" onFinish={onFinish}>
                        <Divider type="vertical" className="item-title-divider"/><p className="item-title-p">产品生产数据（近三年）
                        <Popover content={
                            <QuantitativeProductData/>
                        } title={<span className="item-div-popover-title">填报说明</span>} trigger="hover"
                                 placement="bottomLeft">
                            <img className="img" src="/assets/images/icon_description_normal.png" alt=""/>
                        </Popover>
                    </p>
                        <div style={{textAlign: 'right', color: '#333333'}}>
                            <span style={{paddingRight: '16px'}}><div
                                className="div-box-span-color1"></div>基础评分项</span>
                            <span><div className="div-box-span-color2"></div>附加评分项</span>
                        </div>
                        <Table
                            columns={columns}
                            pagination={false}
                            dataSource={tableData}
                            size="small"
                            rowClassName={initRow}
                        />
                        <Row gutter={16} style={{paddingTop: '30px'}}>
                            <Col span={24}>
                                {
                                    readAndEdit ?
                                        <div style={{textAlign: 'right'}}>
                                            {props.isEdit ? '' :
                                                <Button type="default" style={{marginRight: '20px'}}
                                                        onClick={() => backUpStep()}>取消</Button>
                                            }
                                            <Button className="green-button" htmlType="submit">保存</Button>
                                        </div>
                                        : ''
                                }
                            </Col>
                        </Row>
                    </Form>
                </> : ''}

                <Modal
                    title={
                        <div style={{textAlign: "center"}}>
                            <img className="img" src="/assets/images/popup_icon_delete_normal.png" alt=""
                                 style={{cursor: 'pointer'}}/>
                        </div>
                    }
                    getContainer={false}
                    visible={visible}
                    width="400px"
                    cancelText="取消"
                    okText="清空"
                    onOk={handleOk}
                    onCancel={handleCancel}
                    destroyOnClose
                    footer={[
                        <Button key="cancel" type="default" onClick={handleCancel}>取消</Button>,
                        <Button key="handleOk" type="primary" className="green-button" onClick={handleOk}>确认</Button>
                    ]}
                >
                    <p>点击将清空本产品（{currentName}）{currentPeriod}年的所有生产数据信息，请确认是否清空？ </p>
                </Modal>

            </div>
        </>
    )
})

export class UploadComponent extends React.Component<any, any> {
    upLoad: any;

    constructor(props: any) {
        super(props);
        this.state = {
            value: 0,
            show: this.props.show,
            listData: this.props.listData,
            fileList: [],
            // fileIndex:!this.props.show&&this.props.fileIndex>2?this.props.fileIndex-1:this.props.fileIndex,
            fileIndex: this.props.fileIndex,
            fileChildrenIndex: this.props.fileChildrenIndex,
            readAndEdit: this.props.readonly,
            item: this.props.item
        }
        this.upLoad = {
            beforeUpload: (file: any) => {
                if (verificationType(file.type)) {
                    this.fileUpload(file);
                } else {
                    message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
                }
                return false;
            },
            onRemove: (file: any) => {
                if (!this.props.readonly) {
                    message.info('没有操作权限');
                    return false;
                }
                let {listData, fileIndex, fileChildrenIndex} = this.state;
                listData[fileIndex][fileChildrenIndex].files = listData[fileIndex][fileChildrenIndex].files.filter((i: any) => i['uid'] !== file.uid) || [];
                this.setState({listData});
                return false;
            },
            onPreview: (file: any) => {
                store.dispatch({type: OPENIMG, imgId: file.fileId, imgName: file.showName});
                return false;
            }
        };
        this.clickUpload = this.clickUpload.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
    }

    fileUpload(file: any) {
        const formData = new FormData();
        formData.append('file', file);
        http.post('/system/file/upload/auth', formData).then((res: any) => {
            if (res && res.code === 200) {
                message.success('上传成功');
                let img = {
                    uid: file.uid,
                    showName: file.name,
                    // status:'done',
                    fileId: res.data,
                    // url:''
                };
                let {listData, fileIndex, fileChildrenIndex} = this.state;
                if (listData[fileIndex]) {
                    listData[fileIndex][fileChildrenIndex].files = [...listData[fileIndex][fileChildrenIndex].files, img];
                }
                this.setState({
                    listData: listData
                })
            }
        })
    }

    clickUpload(index: number, childrenIndex: number) {
        this.setState({
            fileIndex: index,
            fileChildrenIndex: childrenIndex
        })
    }

    componentDidMount() {
        // this.setState({
        //   // readAndEdit:waterEditAndRead()
        //   readAndEdit: this.props.readonly
        // })
    }

    render() {
        const {readAndEdit, fileIndex, fileChildrenIndex, item, listData, show} = this.state;
        return (
            <>
                <Upload disabled={!readAndEdit} {...this.upLoad}
                        fileList={listData[fileIndex][fileChildrenIndex].files || []} showUploadList={false}>
                    <Button style={{width: '100%'}} onClick={e => this.clickUpload(fileIndex, fileChildrenIndex)}>
                        <UploadOutlined/>证明材料
                    </Button>
                </Upload>
                {
                    listData[fileIndex][fileChildrenIndex].files.length > 0 ?
                        <Popover content={
                            listData[fileIndex][fileChildrenIndex].files.map((i: any, index: number) =>
                                    <div key={index}>
              <span onClick={() => this.upLoad.onPreview(i)} className="div-a" style={{paddingRight: '16px'}}>
                {i.showName}
              </span>
                                        <img src="/assets/images/icon_delete_normal.png" className="div-a img" alt=""
                                             onClick={() => this.upLoad.onRemove(i)}/>
                                    </div>
                            )
                        } title={null} trigger="hover">
                            <span style={{paddingLeft: '8px'}}><span
                                style={{color: '#00A968'}}>{listData[fileIndex][fileChildrenIndex].files?.length | 0}</span> 个</span>
                        </Popover> : ''
                }
            </>
        )
    }
}

export default ProductInfoDetail;