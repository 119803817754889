import React from 'react';
import './diagnosis.scss';
import { Table, Space, Row, Col, Select, Button, Modal, Checkbox,Pagination, message, DatePicker} from 'antd';
import { navigate } from '@reach/router';
import Http from './../../utils/http';
import {dateFormat} from '../../utils/utils';
import moment from 'moment';
import store from '../../utils/store';
import { SETDIAGNOSISSTATUS } from '../../utils/actions';
import { statusEnum, statusResponse } from '../../utils/entity';
import { ExclamationCircleOutlined } from '@ant-design/icons';

class DiagnosisList extends React.Component<any,any>{
  constructor(props: any){
    super(props);
    this.state = {
      visible: false,
      tabData:[],
      period:new Date(),
      total:10,
      currentPage:1,
      status:'',
      statuList:[],
      allTotal:0
    };
    this.showNewCreate = this.showNewCreate.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.getPage = this.getPage.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
    this.changePage = this.changePage.bind(this);
    this.deleteDiagnosis = this.deleteDiagnosis.bind(this);
    this.listBtnRender = this.listBtnRender.bind(this);
    this.submitDiagnosis = this.submitDiagnosis.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
  }

  changePeriod(e:any){
    this.setState({
      period:e
    })
  }

  getStatu(){
    Http.get('/diagnosis/diagnosisStatusCount').then((res:any)=>{
      if(res&&res.code === 200){
        let statusArr:Array<{count:number,status:statusResponse,text:string}> = [];
        res.data.forEach((item:{count:number,status:statusResponse})=>{
          statusArr.push({
            count:item.count,
            status:item.status,
            text:statusEnum[item.status]
          })
        });
        this.setState({
          statuList:statusArr
        });
      }
    })
  }

  changePage(page:any) {
    this.setState({
      currentPage:page
    },()=>{
      this.getPage();
    });
  }

  getPage(){
    let {currentPage,status} = this.state;
    Http.get('/diagnosis/page',{
      pageNum:currentPage,
      pageSize:10,
      status:status
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key = index + 1;
        })
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count
        });
        if(status===''){
          this.setState({
            allTotal:res.data.count
          })
        }
      }
    })
  }

  showNewCreate(){
    this.setState({
      visible: true,
    });
  }
  
  handleOk(){
    Http.post('/diagnosis',{
      cl:'T',
      el:'T',
      gbd:'T',
      period:dateFormat(new Date(this.state.period),'YYYY'),
      sd:'T',
      wl:'T'
    }).then((res:any)=>{
      if(res&&res.code === 200){
        message.success('创建成功');
        this.setState({
          visible: false,
        });
        this.getPage();
      }
    })
  };

  handleCancel(){
    this.setState({
      visible: false,
    });
  }

  deleteDiagnosis(id:number){
    Http.DELETE('/diagnosis/'+id).then((res:any)=>{
      if(res.code === 200){
        message.success('删除成功');
        this.getPage();
      }
    })
  }

  showConfirm(id:number){
    let _that = this;
    Modal.confirm({
      title:'删除',
      icon: <ExclamationCircleOutlined />,
      content:'是否删除该诊断',
      onOk(){
        _that.deleteDiagnosis(id);
      }
    })
  }

  lookUp(e:any,data:any){
    localStorage.clear();
    localStorage.setItem('diagnosisStatus',data.status);
    navigate('/home/diagnosisProcess/'+data.id);
  }

  disabledDate = (current:any) => {
    return current.valueOf() <= new Date('2000') || current.valueOf() >= Date.now();
  }

  componentDidMount() {
    this.getPage();
    this.getStatu();
  }

  submitDiagnosis(e:any,id:any){
    Http.put('/diagnosis/handle/submitDiagnosis/'+id).then((res:any)=>{
      if(res&&res.code===200){
        message.success('提交成功');
        this.getPage();
      }
    })
  }

  changeStatus(status:string){
    this.setState({
      status:status
    },()=>{
      this.getPage();
    })
  }

  listBtnRender(list:any){
    if(list.status === 'WRITING' || list.status === 'SENDBACK'){
      return <Space size="middle">
        <span onClick={e=>this.lookUp(e,list)} className="green-table-a">编辑</span>
        <span onClick={e=>this.submitDiagnosis(e,list.id)} className="green-table-a">提交诊断</span>
        <span onClick={e=>this.showConfirm(list.id)} className="green-table-a">删除</span>
      </Space>
    } else {
      return <Space size="middle">
        <span onClick={e=>this.lookUp(e,list)} className="green-table-a">查看</span>
      </Space>
    }
  }

  render() {
    const {visible} = this.state;
    const columns = [
      {
        title:'序号',
        dataIndex:'key'
      },
      {
        title: '统计期',
        dataIndex: 'period',
        key: 'period'
      },
      {
        title: '填写时间',
        dataIndex: 'createdTs',
        key: 'createdTs',
        render:(text:string)=>(
          <span>{dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss')}</span>
        )
      },
      {
        title: '服务机构',
        dataIndex: 'companyList',
        key: 'companyList',
        render:(text: string,list:any)=>(
        // <span>{list.status==='SENDBACK'?'':list.status==='COMMITTED'?'中纺联行业专家'}</span>
        <span>{list.status==='COMMITTED'||list.status==='WRITING'||list.status==='SENDBACK'?'':'中纺联行业专家'}</span>
        )
      },
      {
        title: '诊断专家',
        dataIndex: 'expertList',
        key: 'expertList',
        render:(text:string,list:Array<string>)=>(
          {...list}
        )
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render:(text:string)=>(
          text==='WRITING'?'填写中':text==='COMMITTED'?'已提交':text==='DIAGNOSIS'?'诊断中':text === 'SENDBACK'?'退回修改':'完成诊断'
        )
      },
      {
        title: '操作',
        key: 'action',
        dataIndex: 'action',
        render: (text:any,list:any) => (
          // <Space size="middle">
          //   <span onClick={e=>this.lookUp(e,list.id)} className="green-table-a">编辑</span>
          //   <span onClick={e=>this.deleteDiagnosis(e,list.id)} className="green-table-a">删除</span>
          // </Space>
          this.listBtnRender(list)
        ),
      }
    ];
    
    const {tabData,period,total,currentPage,statuList,allTotal,status} = this.state;

    const boundary = [{
      label:'企业层次',
      value:0
    },{
      label:'车间层次',
      value:1
    },{
      label:'设备层次(染色机,定形机)',
      value:2
    }];
    const depth = [{
      label:'通用基础诊断',
      value:0
    },{
      label:'专项诊断',
      value:1
    }];
    let statusCol = statuList.map((item:{count:string,status:statusResponse,text:string})=>{
      return <Col span={4} key={item.status}>
        <div onClick={e=>this.changeStatus(item.status)} className={status===item.status?'diagnosis-list-status-item-active diagnosis-list-status-item':'diagnosis-list-status-item'}>{item.text}({item.count})</div>
      </Col>
    })
    return (
      <div className="diagnosis-container">
        <h1>诊断列表</h1>
        <Row gutter={16} style={{marginBottom:'12px'}}>
          {/* <Col span={4}>
            <Select defaultValue="0" style={{ width: '100%' }} allowClear>
              <Option value="0">所有状态</Option>
            </Select>
          </Col> */}
          <Col span={12}>
            <Row gutter={16}>
              <Col span={4}>
                <div onClick={e=>this.changeStatus('')} className={status===''?'diagnosis-list-status-item-active diagnosis-list-status-item':'diagnosis-list-status-item'}>全部状态({allTotal})</div>
              </Col>
              {statusCol}
            </Row>
          </Col>
          <Col offset={10} span={2}>
            <Button type="primary" onClick={this.showNewCreate} style={{background:'#00A566',borderColor:'#00A566'}}>新增诊断</Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={tabData}
          pagination={false}
          size="small"
        />
        <div className="pagination">
          <div className="pagination-total">总共{tabData.length}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
        </div>
        <Modal
          title="新增诊断"
          visible={visible}
          width="560px"
          cancelText="取消"
          okText="确定"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          bodyStyle={{height:'300px'}}
        >
          <div className="modal-container">
            <div className="modal-diagnosis-item">
              <p>诊断边界</p>
              <Checkbox.Group
                options={boundary}
                disabled
                defaultValue={[0,1,2]}
              />
            </div>
            <div className="modal-diagnosis-item">
              <p>诊断深度</p>
              <Checkbox.Group
                options={depth}
                disabled
                defaultValue={[0,1]}
              />
            </div>
            <div className="modal-diagnosis-item">
              <p><span style={{color:'red'}}>*</span>统计期</p>
              <DatePicker
                picker="year"
                value={moment(period)} 
                onChange={this.changePeriod} 
                disabledDate={this.disabledDate}
              />
            </div>
            
          </div>
        </Modal>
      </div>
      
    );
  }
}

export default DiagnosisList;