import React, { useState, useEffect } from 'react';
import './water-park-one-3.scss'
import { Row, Col, Input, Radio, Form, InputNumber, Button, message, Tooltip } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { waterEditAndRead } from '../../../../../utils/utils'
import Http from '../../../../../utils/http';
import { FormInstance } from 'antd/lib/form';

export class WaterParkOne3 extends React.Component<any, any>{
    // const WaterParkOne3 = (props: any) => {
    // const formRef = React.createRef<FormInstance>()
    createForm = React.createRef<FormInstance>();


    constructor(props: any) {
        super(props);
        this.state = {
            showMo: 'PD',
            readAndEdit: true,
            park: {},
            lead: {},
            print: {},
            spin: {},
            parktext: "",
            spintext: "",
            spintextQS: "",
            leadtext: "",
            leadtextQS: "",
            printtext: "",
            printtextQS: "",
        }
        this.onChange = this.onChange.bind(this);
        this.getForm = this.getForm.bind(this);
        this.onAbosfrt = this.onAbosfrt.bind(this)
        this.getInfo = this.getInfo.bind(this)
        this.onParkOut = this.onParkOut.bind(this)
        this.onParkWater = this.onParkWater.bind(this)
        this.onSpinOut = this.onSpinOut.bind(this)
        this.onSpinWater = this.onSpinWater.bind(this)
        this.onLeadOut = this.onLeadOut.bind(this)
        this.onLeadWater = this.onLeadWater.bind(this)
        this.onPrintOut = this.onPrintOut.bind(this)
        this.onPrintWater = this.onPrintWater.bind(this)


    }


    onChange = (e: any) => {
        this.setState({
            showMo: e.target.value
        })

    }
    getForm = (values: any) => {

        let respose = {
            explain: values.explain,
            lead: values.lead,
            park: values.park,
            spin: values.spin,
            print: values.print,
            wmlEnum: values.wmlEnum,


        };
        Http.put('/apiWaterParkUrl/assess/base/info/industry/' + this.props.id, respose).then((res: any) => {
            if (res && res.code === 200) {
                message.success('保存成功!');
                this.getInfo();
            }
        })

    }
    getInfo() {
        Http.get('/apiWaterParkUrl/assess/base/info/' + this.props.id).then((res: any) => {

            if (res && res.data && res.code === 200) {
                // this.createForm.current?.setFieldsValue(res.data);
                this.createForm.current?.setFieldsValue(res.data);

                this.setState({
                    showMo: res.data.wmlEnum,
                    park: res.data.park,
                    lead: res.data.lead,
                    print: res.data.print,
                    spin: res.data.spin,
                    parktext: res.data.park?.qs,
                    spintext: res.data.spin?.prop,
                    spintextQS: res.data.spin?.qs,
                    leadtext: res.data.lead?.prop,
                    leadtextQS: res.data.lead?.qs,
                    printtext: res.data.print?.prop,
                    printtextQS: res.data.print?.qs,

                })
            }
        })
    }



    componentDidMount() {
        this.setState({
            readAndEdit: waterEditAndRead()
        })
        this.getInfo();
    };
    onPrintOut() {
        let print = this.createForm.current?.getFieldValue('print');
        let spin = this.createForm.current?.getFieldValue('spin');
        this.setState({
            printtextQS: (print?.waterNum / print?.output).toFixed(2),
            printtext: ((print?.output / spin?.output) * 100).toFixed(0),
        })
    }
    onPrintWater() {
        let print = this.createForm.current?.getFieldValue('print');
        this.setState({
            printtextQS: (print?.waterNum / print?.output).toFixed(2),
        })
    }

    onLeadOut() {
        let lead = this.createForm.current?.getFieldValue('lead');
        let spin = this.createForm.current?.getFieldValue('spin');
        this.setState({
            leadtextQS: (lead?.waterNum / lead?.output).toFixed(2),
            leadtext: ((lead?.output / spin?.output) * 100).toFixed(0),
        })
    }
    onLeadWater() {
        let lead = this.createForm.current?.getFieldValue('lead');
        this.setState({
            leadtextQS: (lead?.waterNum / lead?.output).toFixed(2),
        })
    }


    onSpinOut() {
        let spin = this.createForm.current?.getFieldValue('spin');
        let print = this.createForm.current?.getFieldValue('print');
        let park = this.createForm.current?.getFieldValue('park');
        let lead = this.createForm.current?.getFieldValue('lead');
        this.setState({
            spintext: ((spin?.output / park?.output) * 100).toFixed(0),
            spintextQS: (spin?.waterNum / spin?.output).toFixed(2),
            leadtext: ((lead?.output / spin?.output) * 100).toFixed(0),
            printtext: ((print?.output / spin?.output) * 100).toFixed(0),
        });
    }
    onSpinWater() {
        let spin = this.createForm.current?.getFieldValue('spin');
        this.setState({
            spintextQS: (spin?.waterNum / spin?.output).toFixed(2),
        })
    }

    onParkOut() {
        let park = this.createForm.current?.getFieldValue('park');
        let spin = this.createForm.current?.getFieldValue('spin');
        this.setState({
            parktext: (park?.waterNum / park?.output).toFixed(2),
            spintext: ((spin?.output / park?.output) * 100).toFixed(0)

        });


    }
    onParkWater() {
        let park = this.createForm.current?.getFieldValue('park');

        this.setState({
            parktext: (park?.waterNum / park?.output).toFixed(2)
        });


    }




    onAbosfrt = () => {

    }


    render() {

        const { readAndEdit, showMo, park, lead, print, spin, parktext
            , spintext
            , spintextQS,
            leadtext
            , leadtextQS,
            printtext,
            printtextQS, } = this.state;
        const maxnh = () => {
            return <div>
                <h4 style={{ "color": "#333333", 'fontWeight': 'bold' }}>纺织主导产业：</h4>
                <div style={{ 'color': "#5B5B5B" }}>指工业增加值占园区纺织产业50%以上的纺织产业，如无占比50%以上的，取占比最大的纺织产业作为纺织主导产业</div>
            </div>
        }
        return (
            <>
                <div className="enterprise-information">
                    <div className="enterprise-information-item">

                        <Form labelCol={{ style: { width: 120 } }} ref={this.createForm} onFinish={this.getForm} labelAlign='right'
                            initialValues={{
                                'wmlEnum': 'PD'
                            }}
                        >
                            <Row gutter={16}>

                                <Col span={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            rules={[{ required: true }]}
                                            label="园区主导产业"
                                            name='wmlEnum'
                                            labelCol={{ style: { width: 130 } }}
                                            tooltip={
                                                {
                                                    overlay: maxnh,
                                                    placement: 'rightTop',
                                                    color: '#fff',
                                                    overlayInnerStyle: {
                                                        width: '424px',
                                                        color: '#000',
                                                        padding: '16px 20px',
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        fontWeight: 500,
                                                        fontFamily: 'PingFang SC-中黑体, PingFang SC',
                                                    },
                                                    icon: (
                                                        <img className="img" src="/assets/images/icon_description_normal.png" alt="" />
                                                    )

                                                }
                                            }

                                        >
                                            <Radio.Group onChange={this.onChange} disabled={!readAndEdit}>
                                                <Radio value='PD'>印染产业</Radio>
                                                <Radio value='CF'>化纤产业</Radio>
                                                <Radio value='OTHER'>其他</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="园区产业说明"
                                        rules={[{ required: true }]}
                                        name="explain"
                                    >
                                        <Input placeholder='50字以内' maxLength={50} disabled={!readAndEdit} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <span>园区</span>
                                    <div className='contentcard'>
                                        <Form.Item name={['park', 'num']} label="企业数量" rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} addonAfter="家" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item label='工业增加值' name={['park', 'output']} rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} onChange={this.onParkOut} addonAfter="万元" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item label='统计年度取水量' name={['park', 'waterNum']} rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} onChange={this.onParkWater} addonAfter="m3" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item style={{ "display": "none" }} name={['park', 'qs']}>
                                        </Form.Item>
                                        <p className='nowrap'><span className='namebild'>万元工业增加值取水量</span> <span className='namevild1'>{isNaN(parktext) ? '0.00' : parktext || '0.00'} m3</span>

                                        </p>

                                        <Form.Item label='销售收入' name={['park', 'income']} >
                                            <InputNumber style={{ width: '80%' }} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item label='总产值' name={['park', 'total']} >
                                            <InputNumber style={{ width: '80%' }} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                        </Form.Item>
                                    </div>
                                </Col>


                                <Col span={12}>
                                    <span>纺织产业</span>
                                    <div className='contentcard'>
                                        <Form.Item name={['spin', 'num']} label='企业数量' rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} addonAfter="家 " disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item name={['spin', 'output']} label='工业增加值' rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} onChange={this.onSpinOut} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item style={{ "display": "none" }} name={['spin', 'prop']}>
                                        </Form.Item>
                                        <p className='nowrap'><span className='namebild'>工业增加值在园区的占比</span> <span className='namevild1'>{isNaN(spintext) ? '0' : spintext || '0'} %</span>

                                        </p>
                                        <Form.Item name={['spin', 'waterNum']} label='统计年度取水量' rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} onChange={this.onSpinWater} min={0} addonAfter="m3" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item style={{ "display": "none" }} name={['spin', 'qs']}>
                                        </Form.Item>
                                        <p className='nowrap'><span className='namebild'>万元工业增加值取水量</span> <span className='namevild1'>{isNaN(spintextQS) ? '0.00' : spintextQS || '0.00'} m3</span>

                                        </p>
                                        <Form.Item name={['spin', 'income']} label='销售收入' rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item name={['spin', 'total']} label='总产值' rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <span style={{ margin: '20px 0px 0px 0px', display: 'block' }}>纺织主导产业</span>
                                    <div className='contentcard'>
                                        <Form.Item name={['lead', 'num']} label='企业数量' rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} addonAfter="家" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item name={['lead', 'output']} label='工业增加值' rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} onChange={this.onLeadOut} addonAfter="万元" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item style={{ "display": "none" }} name={['lead', 'prop']}>
                                        </Form.Item>
                                        <p className='nowrap'><span className='namebild'>工业增加值在纺织产业的占比</span> <span className='namevild1'>{isNaN(leadtext) ? '0' : leadtext || '0'} %</span>

                                        </p>

                                        <Form.Item name={['lead', 'waterNum']} label='统计年度取水量' rules={[{ required: false, message: '请输入擅长主攻方向！', }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} onChange={this.onLeadWater} addonAfter="m3" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item style={{ "display": "none" }} name={['lead', 'qs']}>
                                        </Form.Item>

                                        <p className='nowrap'><span className='namebild'>万元工业增加值取水量</span> <span className='namevild1'>{isNaN(leadtextQS) ? "0.00" : leadtextQS || '0.00'} m3</span>

                                        </p>
                                        <Form.Item name={['lead', 'income']} label='销售收入' rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                        </Form.Item>
                                        <Form.Item name={['lead', 'total']} label='总产值' rules={[{ required: true }]}>
                                            <InputNumber style={{ width: '80%' }} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                        </Form.Item>
                                    </div>
                                </Col>
                                {showMo !== 'PD' ?
                                    <Col span={12}>
                                        <span style={{ margin: '20px 0px 0px 0px', display: 'block' }}>印染产业</span>
                                        <div className='contentcard' >
                                            <Form.Item name={['print', 'num']} label='企业数量' rules={[{ required: true }]}>
                                                <InputNumber style={{ width: '80%' }} min={0} addonAfter="家" disabled={!readAndEdit} />
                                            </Form.Item>
                                            <Form.Item name={['print', 'output']} label='工业增加值' >
                                                <InputNumber style={{ width: '80%' }} onChange={this.onPrintOut} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                            </Form.Item>
                                            <Form.Item style={{ "display": "none" }} name={['print', 'prop']}>
                                            </Form.Item>
                                            <p className='nowrap'><span className='namebild'>工业增加值在纺织产业的占比</span> <span className='namevild1'>{isNaN(printtext) ? '0' : printtext || '0'} %</span>


                                            </p>
                                            <Form.Item name={['print', 'waterNum']} label='统计年度取水量 ' >
                                                <InputNumber style={{ width: '80%' }} onChange={this.onPrintWater} min={0} addonAfter="m3" disabled={!readAndEdit} />
                                            </Form.Item>
                                            <Form.Item style={{ "display": "none" }} name={['print', 'qs']}>
                                            </Form.Item>
                                            <p className='nowrap'><span className='namebild'>万元工业增加值取水量</span> <span className='namevild1'>{isNaN(printtextQS) ? '0.00' : printtextQS || '0.00'} m3</span>



                                            </p>
                                            <Form.Item name={['print', 'income']} label='销售收入' >
                                                <InputNumber style={{ width: '80%' }} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                            </Form.Item>
                                            <Form.Item name={['print', 'total']} label='总产值' >
                                                <InputNumber style={{ width: '80%' }} min={0} addonAfter="万元" disabled={!readAndEdit} />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    : " "}
                            </Row>



                            {readAndEdit ?
                                // <div className="ant-row ant-form-item">
                                //   <div className="ant-col ant-col-6 ant-form-item-label"></div>
                                //   <div className="ant-col ant-col-14 ant-form-item-control">
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item wrapperCol={{ span: 24, offset: 0 }} style={{ textAlign: 'right' }}>
                                            <Button className="green-button" htmlType="submit" type="primary" style={{ marginRight: '10px' }}>保存</Button>
                                            {/* <Button type="default" onClick={this.onAbosfrt} style={{ marginRight: '10px' }}>下一步</Button> */}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                //   </div>
                                // </div>
                                : ''}
                        </Form>


                    </div>
                </div>
            </>)
    }



}
export default WaterParkOne3