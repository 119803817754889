import * as uniqid from "uniqid";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import React, { forwardRef, useState } from "react";
import { productType, typeEnum } from "../../../../utils/entity";
import { production } from "./interface"

const getColumns = (role: number, expertColumns: Array<any>, visible: boolean, setVisible: any, showModal: any) => {
    let arr = [
        {
            title: () => {
                return (
                    <div className='table-title'>
                        <div>专家评分均值</div>
                        <div className='tt-right'>
                            {
                                !visible && <LeftCircleOutlined onClick={() => {
                                    setVisible(true)
                                }} style={{ color: '#00A968', cursor: 'pointer' }} />
                            }
                            {
                                visible && <RightCircleOutlined onClick={() => { setVisible(false) }} style={{ color: '#00A968', cursor: 'pointer' }} />
                            }
                        </div>
                    </div>
                )
            },
            dataIndex: 'details',
            key: 'details1',
            width: 80,
            render: (text: any, record: any) => (
                text.length > 0 ?
                    text.map((i: any, index: number) =>
                        i.children?.length > 0 ?
                            i.children.map((item: any, cIndex: number) => {
                                const itemText = {
                                    one: record.name,
                                    two: index + 1 + ',' + i.name,
                                    there: (text.length > 1 ? index + 1 + '.' + Number(cIndex + 1) : cIndex + 1 + '.') + item.name,
                                    list: [{
                                        expertScore: item.expertScore,
                                        key: uniqid.default(),
                                        details: item.scores && item.scores.map((score: any) => {
                                            score.key = uniqid.default();
                                            return score
                                        })
                                    }]
                                }
                                return item.row !== 1 ? <div key={uniqid.default()}
                                    className="table-row-div"
                                    onClick={() => { showModal(itemText) }}
                                    style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', color: item.expertScore ? '#00A968' : '', height: '39px', textDecoration: item.expertScore ? "underline" : '', cursor: "pointer", fontWeight: 'bold' }}
                                >
                                    {item.expertScore ? item.expertScore + '分' : '—'}
                                </div>
                                    :
                                    <div key={uniqid.default()}
                                        className="table-row-div"
                                        style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', color: item.expertScore ? '#00A968' : '', height: '39px', cursor: "pointer", fontWeight: 'bold' }}
                                    >
                                        共{item.expertScore ? item.expertScore + '分' : '—'}
                                    </div>


                                /*

                                 return item.row !== 1
                                     ? <div key={uniqid.default()} className="table-row-div" style={{borderBottom: cIndex === i.children.length-1 ? '':'1px solid #F0F0F0',height:'39px'}}>{item.expertScore?item.expertScore+'分':'—'}</div>
                                     :
                                     <div key={uniqid.default()} className="table-row-div table-bold" style={{borderBottom: cIndex === i.children.length-1 ? '':'1px solid #F0F0F0',height:'39px',color:item.expertScore?'#00A968':''}}>
                                         {item.expertScore?'共'+item.expertScore+'分':'—'}


                                * */

                            })
                            : '—'
                    )
                    : '—'
            )
        }
    ]
    let role1 = [
        {
            title: '专家评分',
            dataIndex: 'details',
            key: 'details',
            width: 80,
            render: (text: any, record: any) => (
                text.length > 0 ?
                    text.map((i: any, index: number) =>
                        i.children?.length > 0 ?
                            i.children.map((item: any, cIndex: number) => {
                                // return <div key={uniqid.default()} className="table-row-div" style={{borderBottom: cIndex === i.children.length-1 ? '':'1px solid #F0F0F0',height:'39px'}}>{item.expertScore?item.expertScore+'分':'—'}</div>

                                return item.row !== 1
                                    ? <div key={uniqid.default()} className="table-row-div" style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px' }}>{item.expertScore ? item.expertScore + '分' : '—'}</div>
                                    :
                                    <div key={uniqid.default()} className="table-row-div table-bold" style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px', color: item.expertScore ? '#00A968' : '' }}>
                                        {item.expertScore ? '共' + item.expertScore + '分' : '—'}
                                    </div>
                            })
                            : '—'
                    )
                    : '—'
            )
        }
    ]
    let columns: any = [
        {
            title: '一级指标',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any) => (
                <span className="first-item">{text}</span>
            )
        }, {
            title: '二级指标',
            dataIndex: 'details',
            key: 'details',
            render: (text: any, record: any) => (
                text.length > 1 ?
                    text.map((i: any, index: number) => {
                        return <div key={uniqid.default()}
                            className="table-row-div"
                            style={{ borderBottom: index === text.length - 1 ? '' : '1px solid #F0F0F0', height: i.children?.length * 39 + 'px' }}>
                            {index + 1}.{i.name}
                        </div>
                    })
                    : '—'
            )
        }, {
            title: '指标要求',
            dataIndex: 'details',
            key: 'details',
            render: (text: any, record: any) => (
                text.length > 0 ?
                    text.map((i: any, index: number) =>
                        i.children?.length > 0 ?
                            i.children.map((item: any, cIndex: number) => {
                                return item.row !== 1 ? <div key={uniqid.default()}
                                    className="table-row-div"
                                    style={{ borderBottom: index === text.length - 1 && cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px' }}>
                                    {text.length > 1 ? index + 1 + '.' + Number(cIndex + 1) : cIndex + 1 + '.'}{item.name}
                                </div>
                                    :
                                    <div key={uniqid.default()}></div>

                            })
                            : '—'
                    )
                    : '—'
            )
        }, {
            title: '企业水平',
            dataIndex: 'details',
            key: 'details',
            width: 100,
            render: (text: any, record: any) => (
                text.length > 0 ?
                    text.map((i: any, index: number) =>
                        i.children?.length > 0 ?
                            i.children.map((item: any, cIndex: number) => {
                                const flagName = item.optionList && item.optionList.find((o: any) => o.flag === item.flag)?.name || item.level || '-'
                                return <div key={uniqid.default()} className="table-row-div" style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px' }}>
                                    {flagName}
                                </div>
                            })
                            : '—'
                    )
                    : '—'
            )
        }, {
            title: '企业自评估得分',
            dataIndex: 'details',
            key: 'details',
            width: 80,
            render: (text: any, record: any) => (
                text.length > 0 ?
                    text.map((i: any, index: number) =>
                        i.children?.length > 0 ?
                            i.children.map((item: any, cIndex: number) => {
                                return item.row !== 1
                                    ? <div key={uniqid.default()} className="table-row-div" style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px' }}>{item.score ? item.score + '分' : '—'}</div>
                                    :
                                    <div key={uniqid.default()} className="table-row-div table-bold" style={{ borderBottom: cIndex === i.children.length - 1 ? '' : '1px solid #F0F0F0', height: '39px' }}>
                                        {item.score ? '共' + item.score + '分' : '—'}
                                    </div>
                            })
                            : '—'
                    )
                    : '—'
            )
        },
    ]
    if (role === 0) {
        columns = [...columns, ...arr]
    }
    if (role === 2) {
        columns = [...columns, ...role1]
    }
    if (!visible) {
        columns = [...columns, ...expertColumns]
    } else {
        columns.filter((item: any, index: number) => {
            if (item.key == 'zj') {
                columns.splice(index, 1);
            }
        });
    }
    return columns
}

const rationColumns = (rationList: any) => {
    return [
        {
            title: '',
            key: 'typeEnum',
            name: 'typeEnum',
            render: (text: production, record: any) => {
                // chooseUnit
                // if(!rationList[0].folding && record.key === 1){
                //   return null;
                // }
                let v: productType = text.typeEnum || 'V1';
                if (record.key < 2) {
                    return <span>{typeEnum[v]}({rationList[0].unit})</span>;
                } else if (record.key === 2) {
                    return <span>{typeEnum[v]}(m3/{rationList[0].unit})</span>;
                } else {
                    return <span>{typeEnum[v]}</span>
                }
            }
        }, {
            title: '企业数据',
            key: 'data',
            name: 'data',
            render: (text: any, record: any) => {
                if (text.typeEnum === 'V4' || text.typeEnum === 'V13' || text.typeEnum === 'V12' || text.typeEnum === 'V6') {
                    if (text.score === false) {
                        return <b className="font-Red">{text.data || '—'}</b>
                    } else {
                        return <b>{text.data || '—'}</b>
                    }
                } else {
                    if (text.score === false) {
                        return <span className="font-Red">{text.data || '—'}</span>
                    } else {
                        return <span>{text.data || '—'}</span>
                    }
                }
            }
        }, {
            title: '基准值',
            key: 'datum',
            name: 'datum',
            render: (text: any, record: any) => {
                return <span>{text.datum || '—'}</span>
            }
        }]
}

const scoreColumns = [
    {
        title: '专家评分均值',
        dataIndex: 'expertScore',
        key: 'expertScore',
        render: (text: any, record: any) => (
            <span style={{ color: '#00A968', fontWeight: 'bold' }} className="first-item">{text}</span>
        )
    }, {
        title: '专家姓名',
        dataIndex: 'details',
        key: 'details',
        render: (text: any, record: any) => (
            text.length > 0 ?
                text.map((i: any, index: number) => {
                    return <div key={uniqid.default()}
                        className="table-row-div"
                        style={{ padding: '7px' }}
                    >
                        {i.creatorName}
                    </div>
                })
                : '—'
        )
    },
    {
        title: '专家分数',
        dataIndex: 'details',
        key: 'details',
        render: (text: any, record: any) => (

            text.length > 0 ?
                text.map((i: any, index: number) => {
                    return <div key={uniqid.default()}
                        className="table-row-div"
                        style={{ padding: '7px' }}
                    >
                        {i.score}
                    </div>
                })
                : '—'
        )
    }


]

export { getColumns, rationColumns, scoreColumns }


