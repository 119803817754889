import React from 'react';
import Http from '../../../../utils/http';
import {Row, Col, Select, Form, message, InputNumber} from 'antd';
import { FormInstance } from 'antd/lib/form';
import unitList from '../../../../utils/unit.json';
import { adminEditAndRead } from '../../../../utils/utils';

export class AddEnergyMeasurement extends React.Component<any,any>{
  unitList: Array<any>;
  state = {
    energyData:[],
    list:[],
    readAndEdit:true
  };
  constructor(props: any){
    super(props);
    this.unitList = unitList;
    props.bindRef(this);
    this.getData = this.getData.bind(this);
    this.energyList = this.energyList.bind(this);
  }

  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  energyList(){
    let ec = new Promise(function (resolve){
      resolve(Http.get('/energy/list',{type:'EC'}));
    });
    let wc = new Promise(function (resolve){
      resolve(Http.get('/energy/list',{type:'WC'}));
    });
    Promise.all([ec,wc]).then((res:any)=>{
      let list = [...res[0].data,...res[1].data];
      this.setState({
        list:list
      })
    })
  }

  getData(values:any){
    if(this.props.editData.id){
      Http.put('/diagnosis/emi/'+this.props.editData.id,values).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功!');
          this.props.closeDrawer(true);
        }
      })
    } else {
      let requestData = {...values,diagnosisId:this.props.id}
      Http.post('/diagnosis/emi',requestData).then((res:any)=>{
        if(res&&res.code===200){
        message.success('新建成功!');
        this.props.closeDrawer(true);
        }
      })
    }
  }
  componentDidMount(){
    this.energyList();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.editData){
      this.createForm.current?.setFieldsValue(this.props.editData);
    }
  }

  render(){
    const { Option } = Select;
    const {list,readAndEdit} = this.state;
    const optionList = list.map((i:{id:number,name:string})=>{
      return <Option key={i.id} value={i.id}>{i.name}</Option>;
    })
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="能源品种" name="energyId" rules={[{required: true}]}>
              <Select style={{ width: '100%' }} placeholder="请选择" disabled={!readAndEdit} >
                {optionList}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <h3>进出用能单位</h3>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="应装台数" name={['ioEmi','should']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} placeholder="数值" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="安装台数" name={['ioEmi','actual']} rules={[{required: true}]}>
            <InputNumber style={{width:'100%'}} min={0} placeholder="数值" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="配备率(%)" name={['ioEmi','equRate']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} max={100} placeholder="百分数" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="完好率(%)" name={['ioEmi','intactRate']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} max={100}  placeholder="百分数" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="使用率(%)" name={['ioEmi','useRate']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} max={100}  placeholder="百分数" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
        </Row>
        
        
        <h3>进出次级用能单位</h3>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="应装台数" name={['insEmi','should']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} placeholder="数值" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="安装台数" name={['insEmi','actual']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} placeholder="数值" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="配备率(%)" name={['insEmi','equRate']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} max={100}  placeholder="百分数" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="完好率(%)" name={['insEmi','intactRate']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} max={100}  placeholder="百分数" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="使用率(%)" name={['insEmi','useRate']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} max={100}  placeholder="百分数" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
        </Row>

        <h3>主要用能设备</h3>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="应装台数" name={['ecEmi','should']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} placeholder="数值" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="安装台数" name={['ecEmi','actual']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} placeholder="数值" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="配备率(%)" name={['ecEmi','equRate']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} max={100}  placeholder="百分数" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="完好率(%)" name={['ecEmi','intactRate']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} max={100}  placeholder="百分数" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="使用率(%)" name={['ecEmi','useRate']} rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} min={0} max={100}  placeholder="百分数" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default AddEnergyMeasurement;