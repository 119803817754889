import React, { useState, useEffect, useCallback } from 'react';
import './water-park-enterprise.scss';
import { Row, Col, Table, Pagination, Drawer, Button, Space, Radio, Modal, Checkbox, DatePicker, message } from 'antd';
import { waterStatusType, waterStatusEnum, page, Page } from '../../../utils/entity';
import http from '../../../utils/http';
import { dateFormat } from '../../../utils/utils';
import moment from 'moment';
import cookie from 'react-cookies';
import { navigate } from '@reach/router';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const WaterParkEnterprise = (props: any) => {
    const [dataList, setDataList] = useState<Array<any>>([]);
    const [total, setTotal] = useState<number>(0);
    const [searchPage, setSearchPage] = useState<page>(new Page());
    const [status, setStatus] = useState<string>('');
    const [statuList, setStatusList] = useState<Array<any>>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [period, setPeriod] = useState<Date>(new Date());


    const pageChange = (page: number) => {
        let pageData = searchPage;
        pageData.SkipCount = (page - 1) * 10;
        pageData.PageNum = page;
        setSearchPage(pageData);
        getTableList();
    }

    const showViewDrawer = () => {
        setVisible(true);
    }

    const handleOk = () => {
        http.post('/apiWaterParkUrl/assess', {
            cl: false,
            pll: false,
            el: false,
            gba: true,
            se: false,
            pl: true,
            period: dateFormat(new Date(period), 'YYYY')
        }).then((res: any) => {
            if (res && res.code === 200) {
                message.success('创建成功');
                setVisible(false);
                getTableList();
            }
        })
    }

    const handleCancel = () => {
        setVisible(false);
        setPeriod(new Date(moment(new Date()).subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss')))

    }

    const changePeriod = (date: any) => {
        setPeriod(date);
    }

    const disabledDate = (current: any) => {
        return current.valueOf() <= new Date('2000') || current.valueOf() >= Date.now();
    }


    const getStatusList = () => {
        http.get('/apiWaterParkUrl/assess/status/count').then((res: any) => {
            if (res && res.code === 200) {
                let statusArr: Array<{ count: number, status: waterStatusType, text: string }> = [];
                res.data.forEach((i: any, index: number) => {
                    i.key = index + 1;
                })
                res.data.forEach((item: { count: number, status: waterStatusType }) => {
                    statusArr.push({
                        count: item.count,
                        status: item.status,
                        text: waterStatusEnum[item.status]
                    })
                });

                setStatusList(statusArr);
            }
        })
    }

    const getTableList = useCallback(() => {
        http.get('/apiWaterParkUrl/assess/page/user', {
            pageNum: searchPage.PageNum,
            pageSize: 10,
            status: status
        }).then((res: any) => {
            if (res && res.code === 200) {
                res.data.dataList.forEach((i: any, index: number) => {
                    i.key = index + 1;
                });
                setTotal(res.data.count);
                setDataList(res.data.dataList);
            }
        })
    }, [searchPage.PageNum, status])

    const changeStatus = (status: string) => {
        setStatus(status);
    }

    const lookUp = (data: any) => {


        localStorage.clear();
        localStorage.setItem('waterEffectStatus', data.status);
        localStorage.setItem('period', data.period);
        http.get('/apiWaterParkUrl/assess/' + data.id + '/editable').then((res: any) => {
            if (res && res.code === 200) {

                cookie.save('waterEffectEdit', res.data, { path: '/' });
                sessionStorage.setItem('waterEffectEdit', JSON.stringify(res.data));
                navigate('/home/waterParkProcess/' + data.id);

            }
        })


    }

    const deleteWaterEffect = (id: any) => {
        http.DELETE('/apiWaterParkUrl/assess/' + id).then((res: any) => {
            if (res && res.code === 200) {
                message.success("删除成功");
                getTableList();
            }
        })
    }

    const showDelete = (data: any) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '数据删除后不可恢复，确定要删除？',
            onOk() {
                deleteWaterEffect(data.id);
            }
        })
    }


    const listBtnRender = (list: any) => {
        if (list.status === 'WRITE') {
            return <Space size="middle">
                <span className="green-table-a" onClick={() => lookUp(list)}>编辑</span>
                <span className="green-table-a" onClick={() => showDelete(list)}>删除</span>
            </Space>
        } else if (list.status === 'SUBMITTED') {
            return <Space size="middle">
                <span className="green-table-a" onClick={() => lookUp(list)}>编辑</span>
            </Space>
        } else {
            return <Space size="middle">
                <span className="green-table-a" onClick={() => lookUp(list)}>查看</span>
            </Space>
        }
    }


    const columns = [{
        title: '序号',
        dataIndex: 'key',
        width: 60
    }, {
        title: '统计期',
        dataIndex: 'period',
        key: 'period',
        width: 150
    }, {
        title: '填写时间',
        dataIndex: 'createdTs',
        key: 'createdTs',
        width: 200,
        render: (text: string) => (
            <span>{text ? dateFormat(new Date(text), 'YYYY-mm-dd hh:MM:ss') : ''}</span>
        )
    }, {
        title: '评审时间',
        dataIndex: 'reviewTs',
        key: 'reviewTs',
        width: 200,
        render: (text: string) => (
            <span>{text ? dateFormat(new Date(text), 'YYYY-mm-dd hh:MM:ss') : '——'}</span>
        )
    }, {
        title: '服务机构',
        dataIndex: 'organization',
        key: 'organization',
        width: 200,
        render: (text: string, record: any) => (
            <span>{record.status === 'ING' || record.status === 'END' ? '中纺联行业专家' : '——'}</span>
        )
    }, {
        title: '评审专家',
        dataIndex: 'expert',
        key: 'expert',
        width: 200
    }, {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: 150,
        render: (text: waterStatusType, record: any) => (
            <span>{waterStatusEnum[text]}</span>
        )
    }, {
        title: '操作',
        key: 'action',
        fixed: 'right' as any,
        render: (text: any, record: any) => (
            listBtnRender(record)
        )
    }]

    const boundary = [
        {
            label: '园区层次',
            value: 0
        }, {
            label: '企业层次',
            value: 1
        }, {
            label: '生产线层次',
            value: 2
        }, {
            label: '设备层次',
            value: 3
        }];

    const depth = [{
        label: '通用基础评估',
        value: 0
    }, {
        label: '专项评估',
        value: 1
    }];


    useEffect(() => {
        getTableList();
        getStatusList();
    }, [getTableList])

    let statusCol = statuList.map((item: { count: string, status: waterStatusType, text: string }) => {
        return <Col span={4} key={item.status}>
            <div onClick={e => changeStatus(item.status)} className={status === item.status ? 'effect-assess-list-status-item-active effect-assess-list-status-item' : 'effect-assess-list-status-item'}>{item.text}({item.count})</div>
        </Col>
    })

    return (
        <>
            <div className="water-effect-assess-container">
                <h1>评估列表</h1>
                <Row gutter={16} style={{ marginBottom: '12px' }}>
                    <Col span={12}>
                        <Row gutter={16}>
                            <Col span={4}>
                                <div onClick={e => changeStatus('')} className={status === '' ? 'effect-assess-list-status-item-active effect-assess-list-status-item' : 'effect-assess-list-status-item'}>全部状态({total})</div>
                            </Col>
                            {statusCol}
                        </Row>
                    </Col>
                    <Col offset={10} span={2}>
                        <Button type="primary" onClick={showViewDrawer} style={{ background: '#00A566', borderColor: '#00A566' }}>新增评估</Button>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={dataList}
                    pagination={false}
                    size="small"
                />
                <div className="pagination">
                    <div className="pagination-total">总共{total}条信息</div>
                    <Pagination
                        total={total}
                        current={searchPage.PageNum}
                        hideOnSinglePage
                        onChange={(page: number) => pageChange(page)}
                    />
                </div>

                {/* <Pagination
          defaultCurrent={1}
          total={total}
          hideOnSinglePage
          onChange={(page:number)=>pageChange(page)}
        /> */}
                <Modal
                    title="新增评估"
                    visible={visible}
                    width="560px"
                    cancelText="取消"
                    okText="确定"
                    onOk={handleOk}
                    onCancel={handleCancel}
                    bodyStyle={{ height: '300px' }}
                >
                    <div className="modal-container">
                        <div className="modal-effect-assess-item">
                            <p>评估边界</p>
                            <Checkbox.Group
                                options={boundary}
                                disabled
                                defaultValue={[0]}
                            />
                        </div>
                        <div className="modal-effect-assess-item">
                            <p>评估深度</p>
                            <Checkbox.Group
                                options={depth}
                                disabled
                                defaultValue={[0]}
                            />
                        </div>
                        <div className="modal-effect-assess-item">
                            <p><span style={{ color: 'red' }}>*</span>统计期</p>
                            <DatePicker
                                picker="year"
                                value={moment(period)}
                                onChange={changePeriod}
                                disabledDate={disabledDate}
                            />
                        </div>

                    </div>
                </Modal>
            </div>
        </>
    )
}

export default WaterParkEnterprise;