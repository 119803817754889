import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Select, Upload } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { OPENIMG } from '../../../../utils/actions';
import Http from '../../../../utils/http';
import store from '../../../../utils/store';
import { adminEditAndRead, verificationType } from '../../../../utils/utils';

interface equProps {
  dfProcessId: string,
  id: string,
  bindRef:any
}

export class AddWasteGas extends React.Component<any,any>{
  upLoad:any;
  constructor(props:equProps){
    super(props);
    props.bindRef(this);
    this.state = {
      fileList:[],
      readAndEdit:true
    }
    this.upLoad = {
      beforeUpload: (file: any) => {
        if(verificationType(file.type)){
          this.fileUpload(file);
        } else {
          message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
        }
        return false;
      },
      onRemove: (file: { uid: any; })=>{
        let {fileList} = this.state;
        fileList = fileList.filter((i:any)=> i['uid'] !== file.uid);
        this.setState({fileList});
        return false;
      },
      onPreview:(file:any)=>{
        // store.dispatch({type: OPENIMG,url:file.url})
        store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name});
        return false;
      }
    };
    this.vailForm = this.vailForm.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.getData = this.getData.bind(this);
  }

  fileUpload(file:any){
    const formData = new FormData();
    formData.append('file', file);
    Http.post('/system/file/upload/auth',formData).then((res:any)=>{
      if(res&&res.code===200){
        let img = {
          uid:file.uid,
          name:file.name,
          status:'done',
          id:res.data,
          url:''
        };
        this.setState({
          fileList:[...this.state.fileList,img]
        })
      }
    })
  }

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(value:any){
    let fileArr:any = [];
    this.state.fileList.forEach((i:any)=>{
      fileArr.push({fileId:i.id,showName:i.name});
    })
    value.files = fileArr;
    if(this.props.editData.id){
      Http.put('/diagnosis/dyeing/fixed/process/equ/'+this.props.editData.id,value).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改完成');
          this.props.closeDrawer(true);
        }
      })
    } else {
      let responData = {
        dfProcessId:this.props.dfProcessId,
        diagnosisId:this.props.id,
        ...value
      }
      Http.post('/diagnosis/dyeing/fixed/process/equ',responData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新增完成');
          this.props.closeDrawer(true);
        }
      })
    }
    
  }

  createForm = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    });
    if(this.props.editData.id){
      this.createForm.current?.setFieldsValue({
        factoryName:this.props.editData.factoryName,
        process:this.props.editData.process,
        hz:this.props.editData.hz
      });
      let arr:any = [];
      if(this.props.editData.files.length !== 0){
        this.props.editData.files.forEach((i:any)=>{
          let img = {
            uid:i.fileId,
            name:i.showName,
            status:'done',
            id:i.fileId,
            url:''
          };
          arr.push(img);
        });
        this.setState({
          fileList: arr
        })
      }
    }
  }
  render(){
    let { fileList,readAndEdit } = this.state;
    let {Option} = Select;
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="装备厂家名称" name="factoryName" rules={[{required: true}]}>
              <Input placeholder="请输入装备厂家名称" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="处理废气工艺" name="process" rules={[{required: true}]}>
              <Input placeholder="请输入处理废气工艺" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="处理工艺中高压静电吸附的频率" name="hz" rules={[{required: true}]}>
              <Select style={{ width: '100%' }} placeholder="请选择" disabled={!readAndEdit} >
                <Option value="工频">工频</Option>
                <Option value="高频">高频</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="厂家资质证书(支持.xls .xlsx .doc .docx .pdf .jpg格式)">
              <Upload disabled={!readAndEdit} {...this.upLoad} fileList={fileList} style={{width:'100%'}}>
                <Button style={{width:'100%'}}>
                  <UploadOutlined />点击上传
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default AddWasteGas;