import React from 'react';
import { Table, Space, Pagination, Drawer, Button, message } from 'antd';
import './energySaving.scss';
import AddProduct from '../addProduct/addProduct';
import Http from '../../../../utils/http';
import { adminEditAndRead } from '../../../../utils/utils';

export class EnergySaving extends React.Component<any,any>{
  temNumber:any;
  formRef: any;
  editData:any = [];
  constructor(props: any){
    super(props);
    this.state = {
      newDrawer: false,
      tabData:[],
      total:10,
      currentPage:1,
      readAndEdit:true
    };
    this.temNumber = 1;
    this.clickConsole = this.clickConsole.bind(this);
    this.onCloseDrawer = this.onCloseDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.ecPage = this.ecPage.bind(this);
    this.edit = this.edit.bind(this);
    this.changePage = this.changePage.bind(this);
    this.delete = this.delete.bind(this);
  };

  setFormRef = (el: any) => this.formRef = el;

  onSubmitDrawer(){
    this.getFormRef();
  }

  changePage(page:any) {
    this.setState({
      currentPage:page
    });
    this.ecPage();
  }

  ecPage(){
    let {currentPage} = this.state;
    Http.get('/diagnosis/product/page',{
      diagnosisId:this.props.id,
      pageNum:currentPage,
      pageSize:10
    }).then((res:any)=>{
      if(res&&res.code===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key=index + 1;
          let processStr = '';
          i.process.forEach((k:any)=>{
            switch(k){
              case 'SG':{
                processStr = processStr + '丝光,';
                break;
              }
              case 'YH':{
                processStr = processStr + '印花,';
                break;
              }
              case 'QY_YLR':{
                processStr = processStr + '起绒-摇粒绒,';
                break;
              }
              case 'QY_SHR':{
                processStr = processStr + '起绒-珊瑚绒,';
                break;
              }
              case 'QY_JXRR':{
                processStr = processStr + '起绒-机械柔软,';
                break;
              }
              default:{
                processStr = processStr + '其他,';
                break;
              }
            }
          });
          processStr = processStr.substring(0,processStr.length-1);
          i.processStr = processStr;
        });
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count
        })
      }
      
    })
  }

  delete(value:any){
    Http.DELETE('/diagnosis/product/'+value.id,).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功!');
        this.ecPage();
      }
    });
    
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.ecPage();
    };
    this.setState({
      newDrawer: false
    })
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  getFormRef(){
    this.formRef.vailForm();
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }
  onCloseDrawer(){
    this.getFormRef();
  }
  componentDidMount(){
    this.ecPage();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }
  render(){
    const columns = [
      {
        title:'序号',
        dataIndex:'key'
      },
      {
        title: '产品种类',
        render:(text:string,record:any)=>(
          record.categoryId?<span>{record.attr1} - {record.attr2} {'- ' + record.attr3}</span>:<span>其他</span>
        )
      },
      {
        title: '产品名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '后整理工序',
        dataIndex: 'processStr',
        key: 'processStr'
      },
      {
        title: '年产量',
        dataIndex: 'yield',
        key: 'yield',
      },
      {
        title: '单位',
        dataIndex: 'unit',
        key: 'unit',
      },
      // {
      //   title: '年产值(万元)',
      //   dataIndex: 'output',
      //   key: 'output',
      // },
      {
        title: '操作',
        key: 'action',
        dataIndex: 'action',
        render: (text:any,record:any) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.delete(record)}>删除</span>:''}
          </Space>
        ),
      },
    ];
    const {total,currentPage,readAndEdit} = this.state;
    const data = this.state.tabData;
    return(
      <div className="table-container">
        <div className="table-header">
          <div className="table-title">产品信息表</div>
          {
            readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''
          }
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
        />
        <div className="pagination">
          <div className="pagination-total">总共{data.length}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
          
        </div>
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
            <AddProduct
              closeDrawer={this.closeDrawer} 
              editData={this.editData} 
              id={this.props.id}
              bindRef={this.setFormRef}></AddProduct>
          </Drawer>
      </div>
    )
  }
}

export default EnergySaving;