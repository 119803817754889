import React, {useEffect, useState} from 'react';
import './dashboard.scss';
import Head from '../../common/head/head';
import {Layout, Card, message} from 'antd';
import {navigate} from "@reach/router"
import Meta from 'antd/lib/card/Meta';
import cookie from 'react-cookies';
import {divide} from "lodash";

const DashboardPage = (props: any) => {
    const {Content} = Layout;
    let maxHeight = document.body.clientHeight - 56;
    const [disabled, setDisabled] = useState<boolean>(false);

    function clickLogin(url: string, type?: boolean) {

        if (disabled && type) {
            message.info("没有权限访问！");
            return;
        }
        navigate(url);
    }

    useEffect(() => {
        let role = cookie.load('faisUserInfo');
        if (role?.roleIds[0] === 2) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [])

    let role = cookie.load('faisUserInfo');
    const imgSrc = () => {
        if (role?.roleIds[0] === 3) {
            return <Card
                bordered
                cover={<img alt="节能诊断" className='imhase'
                            src='/assets/images/img_jingsai (1).png'/>}
            >
                <Meta className="card-meta" title={
                    <div>
                        <div className="card-meta-item" style={{background: '#5269E3'}}>
                            <span className="card-meta-span">竞赛专区</span>
                            <img alt="" src="/assets/images/icon_triangle_white.png"/>
                        </div>

                    </div>
                }/>
            </Card>

        } else {
            return <Card
                bordered
                style={{
                    width: '20.125rem',
                    padding: '1.25rem',
                    paddingBottom: '0.5rem'
                }}
                cover={<img alt="节能诊断"
                            src='/assets/images/img_jingsai.png'/>}
            >
                <Meta className="card-meta" title={
                    <div>
                        <div className="card-meta-item" style={{background: '#5269E3'}}>
                            <span className="card-meta-span">竞赛专区</span>
                            <img alt="" src="/assets/images/icon_triangle_white.png"/>
                        </div>

                    </div>
                }/>
            </Card>
        }
    }
    const imagess = () => {
        let role = cookie.load('faisUserInfo');
        if (role?.roleIds[0] === 3) {
            return <img alt="节能诊断" className='imhase'
                        src='/assets/images/img_yqshuixiao.png'/>
        } else {
            return <img alt="节能诊断" className='imhase'
                        src='/assets/images/img_shuixiao.png'/>
        }
    }
    return (<>
        <Layout>
            <Head isDashboard={true} isInfo={false} roleId={2} unreadMsgNumber={0}/>
            <Content className="site-layout">
                {/* <div className="dashboard-background" style={{ minHeight: '500px',height:maxHeight,overflowY:'auto' }}>
          <div className="select-system-container">
          <div className="select-system-item" onClick={e=>clickLogin('/home/diagnosisList')}>
              <div>节能诊断</div>
              <img src="/assets/images/dashboard-icon-1.png" />
            </div>
            <div className="select-system-item" onClick={e=>clickLogin('/home/waterEffectAssess')}>
              <div>水效评估</div>
              <img src="/assets/images/dashboard-icon-1.png" />
            </div>
            <div className="select-system-item" onClick={e=>clickLogin('/declaration')}>
              <div>绿色工艺、技术和装备申报</div>
              <img style={{marginTop:'30px'}} src="/assets/images/dashboard-icon-2.png" />
              <div style={{textAlign:'center',fontSize:'14px',marginTop:'10px'}}>
                <span>截止日期：</span><br />
                <span>节水类——2021年5月20日</span><br />
                <p>环保、节能、减碳、资源综合利用——2021年6月30日</p>
              </div>
            </div>
          </div>
        </div> */}
                <div className="dashboard-background"
                     style={{minHeight: '500px', height: maxHeight, overflowY: 'auto'}}
                >
                    <div className="dashboard-container">

                        {
                            role?.roleIds[0] !== 3 ?
                                <>
                                    <div className="dashboard-container-item bg1">
                                        <div className="dashboard-container-box"
                                             onClick={e => clickLogin('/home/diagnosisList')}>
                                            <Card

                                                bordered
                                                style={{
                                                    width: '20.125rem',
                                                    padding: '1.25rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                cover={<img alt="节能诊断" src="/assets/images/img_jieneng.png"/>}
                                            >
                                                <Meta className="card-meta" title={
                                                    <div className="card-meta-item" style={{background: '#00A968'}}>
                                                        <span className="card-meta-span">工业节能诊断</span>
                                                        <img alt="" src="/assets/images/icon_triangle_white.png"/>
                                                    </div>
                                                }/>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="dashboard-container-item bg2">
                                        <div className="dashboard-container-box"
                                             onClick={e => clickLogin(role?.roleIds[0] !== 3 ? '/home/waterEffectAssess' : '/home/waterParkEnterprise')}
                                             style={{cursor: disabled ? 'not-allowed' : ''}}>
                                            <Card
                                                bordered
                                                style={{
                                                    width: '20.125rem',
                                                    padding: '1.25rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                cover={<img alt="节能诊断" src="/assets/images/img_shuixiao.png"/>}
                                            >
                                                <Meta className="card-meta" title={
                                                    <div className="card-meta-item" style={{background: '#0494D9'}}>
                                                        <span className="card-meta-span">工厂水效评估</span>
                                                        <img alt="" src="/assets/images/icon_triangle_white.png"/>
                                                    </div>
                                                }/>
                                            </Card>
                                        </div>
                                    </div>
                                    {disabled ?
                                        <>
                                        </>

                                        :
                                        <>
                                            <div className="dashboard-container-item bg3"
                                                 style={{cursor: disabled ? 'inherit' : 'pointer'}}>
                                                <div className="dashboard-container-box"
                                                     onClick={e => clickLogin('/declaration', true)}
                                                     style={{cursor: disabled ? 'not-allowed' : ''}}>
                                                    <Card
                                                        bordered
                                                        style={{
                                                            width: '20.125rem',
                                                            padding: '1.25rem',
                                                            paddingBottom: '0.5rem'
                                                        }}
                                                        cover={<img alt="节能诊断"
                                                                    src="/assets/images/img_shenbao.png"/>}
                                                    >
                                                        <Meta className="card-meta" title={
                                                            <div>
                                                                <div className="card-meta-item"
                                                                     style={{background: '#00919C'}}>
                                                                    <span
                                                                        className="card-meta-span">绿色工艺、技术和装备申报</span>
                                                                    <img alt=""
                                                                         src="/assets/images/icon_triangle_white.png"/>
                                                                </div>

                                                            </div>
                                                        }/>
                                                    </Card>
                                                </div>
                                            </div>

                                        </>
                                    }
                                </>
                                : <>
                                    <div className="dashboard-container-item bg5"
                                         style={{cursor: disabled ? 'inherit' : 'pointer'}}>
                                        <div className="dashboard-container-box"
                                             onClick={e => clickLogin('/home/waterParkEnterprise', true)}
                                             style={{cursor: disabled ? 'not-allowed' : ''}}>
                                            <Card
                                                bordered
                                                cover={imagess()}

                                            >
                                                <Meta className="card-meta" title={
                                                    <div>
                                                        <div className="card-meta-item" style={{background: '#0494D9'}}>
                                                            <span className="card-meta-span">园区节水评价</span>
                                                            <img alt="" src="/assets/images/icon_triangle_white.png"/>
                                                        </div>

                                                    </div>
                                                }/>
                                            </Card>
                                        </div>
                                    </div>

                                </>
                        }
                        <div className="dashboard-container-item bg4"
                             style={{cursor: disabled ? 'inherit' : 'pointer'}}>
                            <div className="dashboard-container-box"
                                 onClick={e => clickLogin('/competitionZone', true)}
                                 style={{cursor: disabled ? 'not-allowed' : ''}}>
                                {imgSrc()}
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    </>)
}

export default DashboardPage;