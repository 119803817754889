import React, {useState, useEffect} from 'react';
import {Link, Router} from '@reach/router';
import {Col, Row} from 'antd';

import './business-manage.scss';
import DiagnosisListComponent from '../../view/admin/diagnosis-list/diagnosis-list';
import AdminDeclarationPage from '../../view/admin-declaration/admin-declaration';
import AdminWaterEffectAssessComponent from '../../view/admin/admin-water-effect-assess/admin-water-effect-assess';
import {broadcaster} from 'web-tool-lib';
import {Subscription} from 'rxjs';
import {LoadingOutlined} from '@ant-design/icons';
import AdminWaterParkExpert from '../../view/water-park/water-park-expert/water-park-expert';
import AdminManagementTest from '../../view/management/management-test/management-test';

const NavLink = (props: any) => (
    <Link
        {...props}
        getProps={({isCurrent}) => {
            return {
                className: isCurrent ? 'active' : ''
            };
        }}
    />
);

const BusinessManageComponent = (props: any) => {
    const broadcast = broadcaster;
    const unsubscrip = new Subscription();
    const [visiable, setVisiable] = useState<boolean>(false);

    useEffect(() => {
        let temp1 = broadcast.on('onCloseDowloadMsg').subscribe((data: boolean) => {
            setVisiable(data);
        });
        unsubscrip.add(temp1);
        return () => {
            unsubscrip.unsubscribe();
        }
    }, [broadcast, unsubscrip, visiable])

    return (
        <>
            <div className="white-container">
                <div className="component-nav">
                    <Row>
                        <Col span={visiable ? 8 : 18}>
                            <ul className="nav-ul">
                                <NavLink to=''>
                                    <li>工业节能诊断管理</li>
                                </NavLink>
                                <NavLink to='adminWaterEffectAssess/'>
                                    <li>工厂水效评估管理</li>
                                </NavLink>
                                <NavLink to='adminWaterParkExpert/'>
                                    <li>园区节水评价管理</li>
                                </NavLink>
                                <NavLink to='adminDeclaration/'>
                                    <li>申报管理</li>
                                </NavLink>
                                <NavLink to='adminManagementTest/'>
                                    <li>竞赛管理</li>
                                </NavLink>
                            </ul>
                        </Col>
                        {visiable ?
                            <Col span={8} style={{textAlign: 'center'}}>
                                <div className="download-box"><LoadingOutlined/><span style={{paddingLeft: '8px'}}>正在下载中，请稍等 ...</span>
                                </div>
                            </Col> : ''}
                    </Row>
                </div>
                <div>
                    <Router>
                        <DiagnosisListComponent path="/*"/>
                        <AdminWaterEffectAssessComponent path="adminWaterEffectAssess/*"/>
                        <AdminWaterParkExpert path="adminWaterParkExpert/*"/>
                        <AdminDeclarationPage path="adminDeclaration/*"/>
                        <AdminManagementTest path="adminManagementTest/*"/>
                    </Router>
                </div>
            </div>
        </>
    )
}

export default BusinessManageComponent;