import React, { useState, useEffect, useCallback } from 'react';
import './water-effect-expert.scss';
import http from '../../utils/http';
import { Pagination, Table, Col, Row, Space, Popover, Input, Select } from 'antd';
import { waterStatusType, waterStatusEnum, page, Page } from '../../utils/entity';
import { dateFormat } from '../../utils/utils';
import { PhoneFilled } from '@ant-design/icons';
import cookie from 'react-cookies';
import { navigate } from '@reach/router';

const WaterEffectExpert = (props:any) => {
  const { Option } = Select;
  const { Search } = Input;
  const [status,setStatus] = useState<string>('');
  const [searchValue,setSearchValue] = useState<string>('');
  const [dataList,setDataList] = useState<Array<any>>([]);
  const [total,setTotal] = useState<number>(0);
  const [searchPage,setSearchPage] = useState<page>(new Page());
  const [statuList,setStatusList] = useState<Array<any>>([]);

  const getTableList = useCallback(() => {
    http.get('/apiWaterUrl/assess/expert/page',{
      keyword: searchValue,
      pageNum: searchPage.PageNum,
      pageSize: 10,
      statusEnum: status
    }).then((res:any) => {
      if(res && res.code === 200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key = index+1;
        });
        setTotal(res.data.count);
        setDataList(res.data.dataList);
      }
    })
  },[searchPage.PageNum, searchValue, status])

  const getStatusList = () => {
    http.get('/apiWaterUrl/assess/expert/count').then((res:any) => {
      if(res && res.code === 200){
        let statusArr:Array<{count:number,status:waterStatusType,text:string}> = [];
        res.data.forEach((i:any,index:number) => {
          i.key = index+1;
        })
        res.data.forEach((item:{count:number,status:waterStatusType}) => {
          statusArr.push({
            count:item.count,
            status:item.status,
            text:waterStatusEnum[item.status]
          })
        });
        setStatusList(statusArr);
      }
    })
  }

  const changeStatus = (status:string) => {
    setStatus(status);
  }

  const onChange = (value:any) => {
    let page = searchPage;
    page.KeyWord = value.target.value;
    setSearchPage(page);
    setSearchValue(value.target.value);
  }

  const onSearch = (value:string) => {
    setSearchPage({
      KeyWord:value,
      SkipCount:0,
      MaxResultCount:10
    });
    pageChange(1);
  }

  const pageChange = (page:number) => {
    let pageData = searchPage;
    pageData.SkipCount = (page - 1) * 10;
    pageData.PageNum = page;
    setSearchPage(pageData);
    getTableList();
  }

  const showViewDrawer = (data:any,bigWater:string) => {
    localStorage.clear();
    localStorage.setItem('waterEffectStatus',data.status);
    localStorage.setItem('period',data.period);
    localStorage.setItem('bigWater',bigWater);
    navigate('/home/waterEffectProcess/'+data.assessId);
  }

  useEffect(() => {
    cookie.remove('waterEffectEdit');
    getTableList();
    getStatusList();
  },[getTableList])


  const columns = [{
    title:'序号',
    dataIndex:'key',
    width:60
  },{
    title:'企业名称',
    dataIndex:'companyName',
    key:'companyName',
    width:200
  },{
    title:'填写人',
    dataIndex:'writer',
    key:'writer',
    width:150,
    render: (text:string,record:any) => (
      record.writer?
      <span>{text}<Popover 
      placement="topLeft" 
      content={<>电话:{record.tel}</>} 
      title="联系电话" 
      trigger="hover">
        <PhoneFilled style={{marginLeft:'5px',color:'#1C9A6A'}} /></Popover>
      </span>:''
    )
  },{
    title:'统计期',
    dataIndex:'period',
    key:'period',
    width:100
  },{
    title:'填写时间',
    dataIndex:'createdTs',
    key:'createdTs',
    width:150,
    render: (text:string,record:any) => (
      <span>{text?dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss'):''}</span>
    )
  },{
    title:'评审时间',
    dataIndex:'reviewTs',
    key:'reviewTs',
    width:150,
    render: (text:string,record:any) => (
      <span>{text?dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss'):''}</span>
    )
  },{
    title:'评审截止日期',
    dataIndex:'endTs',
    key:'endTs',
    width:150,
    render: (text:string,record:any) => (
      <span>{text?dateFormat(new Date(text),'YYYY-mm-dd hh:MM:ss'):''}</span>
    )
  },{
    title:'状态',
    dataIndex:'status',
    key:'status',
    width:100,
    render:(text:waterStatusType,record:any) => (
      <span>{waterStatusEnum[text]}</span>
    )
  },{
    title:'操作',
    key: 'action',
    width: 150,
    fixed: 'right' as any,
    render:(text:any, record:any) => (
      listBtnRender(record)
    )  
  }]

  const listBtnRender = (list:any) => {
    if(list.status === 'ING'){
      return <Space size="middle">
        <span className="green-table-a" onClick={() => showViewDrawer(list,'4')}>在线评审</span>
      </Space>
    } else if(list.status === 'EXPIRE'){
      return <Space size="middle">
        <span>在线评审</span>
      </Space>
    } else {
      if(list.view){
        return <Space size="middle">
          <span className="green-table-a" onClick={() => showViewDrawer(list,'0')}>查看</span>
        </Space>
      }else{
        return <Space size="middle">
          <span>查看</span>
        </Space>
      }
    }
  }
  
  let statusCol = statuList.map((item:{count:string,status:waterStatusType,text:string})=>{
    return <Col span={3} key={item.status}>
      <div onClick={e=>changeStatus(item.status)} className={status===item.status?'effect-assess-list-status-item-active effect-assess-list-status-item':'effect-assess-list-status-item'}>{item.text}({item.count})</div>
    </Col>
  })

  return (
    <>
      <div className="water-effect-assess-container">
        <h1>评估列表</h1>
        <Row gutter={16} style={{marginBottom:'12px'}}>
          <Col span={16}>
            <Row gutter={16}>
              <Col span={3}>
                <div onClick={e=>changeStatus('')} className={status===''?'effect-assess-list-status-item-active effect-assess-list-status-item':'effect-assess-list-status-item'}>全部状态({total})</div>
              </Col>
              {statusCol}
              <Search value={searchValue} onChange={onChange} 
              onSearch={onSearch} placeholder="搜索企业名称/填表人" 
              style={{ width: '300px' }}/>
            </Row>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={dataList}
          pagination={false}
          size="small"
        />
        <Pagination
          defaultCurrent={1}
          total={total}
          hideOnSinglePage
          onChange={(page:number)=>pageChange(page)}
        />
      </div>
    </>
  )
}

export default WaterEffectExpert;