import React from 'react';
import {Menu, Dropdown, Badge} from 'antd';
import {
    DownOutlined,
    BellFilled,
    FileTextFilled,
    PoweroffOutlined,
    UserOutlined,
    QuestionCircleFilled
} from '@ant-design/icons';
import './head.scss';
import cookie from 'react-cookies';
import {navigate, useLocation} from '@reach/router';

class Head extends React.Component {
    username = '';
    state = {
        current: sessionStorage.getItem('titleCurrent') || '',
    };

    constructor(props) {
        super(props);
        this.username = cookie.load('username');
        this.returnIndex = this.returnIndex.bind(this);
        this.returnDashboard = this.returnDashboard.bind(this);
        this.outLogin = this.outLogin.bind(this);
        this.userInfo = this.userInfo.bind(this);
        this.changeItem = this.changeItem.bind(this);
        this.headRender = this.headRender.bind(this);
        this.routeMsg = this.routeMsg.bind(this);
        this.routeQAndA = this.routeQAndA.bind(this);
        this.routeResource = this.routeResource.bind(this);
    }

    returnIndex() {
        this.setState({
            current: 'home'
        })
        navigate('/home');
    }

    returnDashboard() {
        navigate('/dashboard');
    }

    userInfo() {
        navigate('/home/user');
    }

    changeItem(e) {
        this.setState({
            current: e.key
        });
        this.props.isMsg();
        if (e.key === 'home') {
            this.returnIndex();
        } else if (e.key === 'dataStatistics') {
            navigate('/home/dataStatistics')
        } else if (e.key === 'adminDeclaration') {
            navigate('/home/adminDeclaration');
        } else if (e.key === 'diagnosisList') {
            navigate('/home/diagnosisList');
        } else if (e.key === 'waterEffectAssess') {
            navigate('/home/waterEffectAssess');
        } else if (e.key === 'waterParkEnterprise') {
            navigate('/home/waterParkEnterprise');
        } else if (e.key === 'businessManage') {
            navigate('/home/businessManage');
        } else {
            navigate('/home/adminDiagnosis');
            // navigate('/home');
        }
        sessionStorage.setItem('titleCurrent', e.key);
    }

    outLogin() {
        cookie.remove('waterEffectEdit', {path: '/'});
        sessionStorage.removeItem('waterEffectEdit');
        cookie.remove('token');
        cookie.remove('username');
        navigate('/');
    }

    componentDidMount() {
        if (this.props.isInfo) {
            this.setState({
                current: 'user'
            });
        } else {
            this.setState({
                current: 'home'
            })
        }
        // console.log(this.props);
        let herf = window.location.href;
        if (herf.indexOf('adminDiagnosis') !== -1) {
            this.setState({
                current: 'adminDiagnosis'
            })
        }
        if (herf.indexOf('adminDeclaration') !== -1) {
            this.setState({
                current: 'adminDeclaration'
            })
        }
        if (herf.indexOf('businessManage') !== -1) {
            this.setState({
                current: 'businessManage'
            })
        }

        if (herf.indexOf('waterEffectAssess') !== -1 || herf.indexOf('waterEffectProcess') !== -1) {
            this.setState({
                current: 'waterEffectAssess'
            })
        }

        if (herf.indexOf('waterParkEnterprise') !== -1 || herf.indexOf('waterParkProcess') !== -1) {
            this.setState({
                current: 'waterParkEnterprise'
            })
        }
        if (herf.indexOf('diagnosisList') !== -1 || herf.indexOf('diagnosisProcess') !== -1) {
            this.setState({
                current: 'diagnosisList'
            })
        }

    }

    headRender() {
        if (this.props.isInfo) {
            return <Menu.Item key="home">用户管理</Menu.Item>;
        } else if (this.props.roleId === 2) {
            return <Menu.Item key="diagnosisList">诊断管理</Menu.Item>;
        } else {
            return <Menu.Item key="diagnosisList">工业节能诊断</Menu.Item>;
        }
    }

    routeMsg() {
        navigate('/home/msg');
        this.setState({
            current: ''
        });
    }

    routeResource() {
        navigate('/home/resource');
        this.setState({
            current: ''
        });
    }

    routeQAndA() {
        navigate('/home/question');
        this.setState({
            current: ''
        });
    }

    render() {
        const menu = (
            <Menu></Menu>
        );
        const userMenu = (
            <Menu>
                <Menu.Item onClick={this.userInfo} key="1" icon={<UserOutlined/>}>
                    个人信息
                </Menu.Item>
                <Menu.Item onClick={this.outLogin} key="2" icon={<PoweroffOutlined/>}>
                    退出登录
                </Menu.Item>
            </Menu>
        );
        const {current} = this.state;
        return (
            <div className="head">
                <div className="nav">
                    <div className="logo">
                        <img src="/assets/images/logotop.jpg" onClick={this.returnDashboard} title="返回首页"
                             alt="返回首页"/>
                    </div>
                    {/* <div>
            {this.props.isDashboard?<></>:<Dropdown disabled overlay={menu}>
              <span style={{color:'#26B57A',fontWeight:'bold'}}>
                工业节能诊断  <DownOutlined />
              </span>
            </Dropdown>}
          </div> */}
                    {this.props.isDashboard ? <></> : <div style={{marginLeft: '16px', marginRight: '40px'}}>|</div>}
                    {this.props.isDashboard ? <></> :
                        <Menu mode="horizontal" selectedKeys={[current]} onClick={this.changeItem}>
                            {/*{this.headRender()}*/}
                            {this.props.isInfo ?
                                <>
                                    <Menu.Item key="home">用户管理</Menu.Item>
                                    <Menu.Item key="businessManage">业务管理</Menu.Item>
                                    <Menu.Item key="dataStatistics">数据统计</Menu.Item>
                                </>
                                :
                                <></>
                            }

                            {this.props.roleId === 1 || this.props.roleId === 2 ?
                                <>
                                    <Menu.Item
                                        key="diagnosisList">{this.props.roleId === 1 ? '工业节能诊断' : '诊断管理'}</Menu.Item>
                                    <Menu.Item key="waterEffectAssess">水效评估</Menu.Item>

                                </>

                                : <></>}
                            {this.props.roleId === 3 ?
                                <>

                                    <Menu.Item key="waterParkEnterprise">园区节水评价</Menu.Item>

                                </>

                                : <></>}

                        </Menu>}


                </div>
                <div className="head-right">
                    <div className="head-right-item" onClick={this.routeMsg}>

                        <Badge dot={this.props.unreadMsgNumber !== 0 ? true : false}>
                            <BellFilled/>
                        </Badge>
                        <span>消息</span>
                    </div>
                    <div className="head-right-item" onClick={this.routeResource}>
                        <FileTextFilled/><span>资料查询</span>
                    </div>
                    {/* <div className="head-right-item" style={{color:'#ccc'}}>
            <FileTextFilled /><span>操作指南</span>
          </div> */}
                    <div className="head-right-item" onClick={this.routeQAndA}>
                        <QuestionCircleFilled/><span>问题与建议</span>
                    </div>
                    <div className="head-right-item">
                        <Dropdown overlay={userMenu} trigger={['hover']}>
                            <span className="ant-dropdown-link" style={{color: '#4D4D4D'}}>
                                {this.username}<DownOutlined/>
                            </span>
                        </Dropdown>
                    </div>
                </div>
            </div>
        )
    }

}

export default Head;