import React, { useState, useEffect, useCallback } from 'react';
import './water-effect-process.scss';
import { Steps, Divider, Button } from 'antd';
import { url } from 'inspector';
import { CheckCircleOutlined } from '@ant-design/icons';
import { navigate, useParams } from '@reach/router';
import WaterEffectBaiscInfo from '../water-effect/basic-infomation/water-effect-baisc-info/water-effect-baisc-info';
import EnterpriseBaiscInfo from '../water-effect/basic-infomation/enterprise-baisc-info/enterprise-baisc-info';
import ProdBaiscInfo from '../water-effect/basic-infomation/prod-baisc-info/prod-baisc-info';
import BasicRequirements from '../water-effect/qualitative-evaluation/basic-requirements/basic-requirements';
import WaterEffectManage from '../water-effect/qualitative-evaluation/water-effect-manage/water-effect-manage';
import ReducePollution from '../water-effect/qualitative-evaluation/reduce-pollution/reduce-pollution';
import WaterSavingTechnology from '../water-effect/qualitative-evaluation/water-saving-technology/water-saving-technology';
import StandardCoeditAwards from '../water-effect/qualitative-evaluation/standard-coedit-awards/standard-coedit-awards';
import ManagementSystem from '../water-effect/qualitative-evaluation/management-system/management-system';
import http from '../../utils/http';
import cookie from 'react-cookies';
import ProductInformation from '../water-effect/quantitative-evaluation/product-information/product-information';
import AssessmentContainer from '../water-effect/assessment-result/assessment-container/assessment-container';
import ExpertContainer from '../water-effect/expert-review/expert-review-container/expert-container';

const { Step } = Steps;

const WaterEffectProcessComponent = (props: any) => {
    const [bigCurrent, setBigCurrent] = useState<number>(Number(localStorage.getItem('bigWater')) ? Number(localStorage.getItem('bigWater')) : 0);
    const [current, setCurrent] = useState<number>(Number(localStorage.getItem('currentWater')) ? Number(localStorage.getItem('currentWater')) : 0);
    const [name, setName] = useState<string>('');
    const routeParams = useParams();
    const id = routeParams.id;
    const selectImg = '/assets/images/icon_pen_selected.png';
    const normalImg = '/assets/images/icon_pen_normal.png';
    const resSelectImg = '/assets/images/icon_result_selected.png';
    const resNormalImg = '/assets/images/icon_result_normal.png';
    const reviewSelectImg = '/assets/images/icon_expert_selected.png';
    const reviewNormalImg = '/assets/images/icon_expert_normal.png';

    const onChange = (current: any) => {
        setCurrent(current);
        localStorage.setItem('currentWater', current);
    }

    const goBack = () => {
        let roleId = cookie.load('faisUserInfo');
        if (roleId.roleIds.includes(0)) {
            navigate('/home/businessManage/adminWaterEffectAssess');
        } else if (roleId.roleIds.includes(1) || roleId.roleIds.includes(2)) {
            navigate('/home/waterEffectAssess');
        }
    }

    const onChangeBig = (bigCurrent: any) => {
        localStorage.setItem('bigWater', bigCurrent);
        localStorage.removeItem('currentWater');
        setBigCurrent(bigCurrent);
        setCurrent(0);
    };



    const nextCurrent = () => {
        let curr = current + 1;
        onChange(curr);
    }

    useEffect(() => {
        cookie.remove('waterEffectEdit', { path: '/' });
        editable();
    })

    const editable = () => {
        http.get('/apiWaterUrl/assess/' + id + '/editable').then((res: any) => {
            if (res && res.code === 200) {
                cookie.save('waterEffectEdit', res.data, { path: '/' });
                sessionStorage.setItem('waterEffectEdit', JSON.stringify(res.data));
            }
        })
    }

    const stepView = () => {
        switch (bigCurrent) {
            case 0:
                return <Steps size="small" style={{ maxWidth: '100%' }} current={current} onChange={onChange} className="site-navigation-steps-value" type="navigation" >
                    <Step title="水效评估基本信息" />
                    <Step title="企业基本信息" />
                    <Step title="生产基本信息" />
                </Steps>;
            case 1:
                return <Steps size="small" style={{ maxWidth: '100%' }} current={current} onChange={onChange} className="site-navigation-steps" type="navigation">
                    <Step title="基本要求" />
                    <Step title="水效管理制度" />
                    <Step title="减污治污与污水资源化" />
                    <Step title="节水技术应用" />
                    <Step title="标准参编与获奖" />
                    <Step title="管理体系实施" />
                </Steps>;
            case 2:
                return <div>
                    <span className="contanier-title">定量评估信息</span>
                    <span className="contanier-msg">（请填写企业最主要的1~2种产品）</span>
                </div>
            case 3:
                return <div>
                    <span className="contanier-title">水效评估结果</span>
                </div>
            case 4:
                return <div>
                    <span className="contanier-title">专家评审</span>
                </div>

        };
    }

    const currentDOMView = () => {
        switch (bigCurrent) {
            case 0:
                switch (current) {
                    case 0:
                        return <WaterEffectBaiscInfo id={id} nextCurrent={nextCurrent} />;
                    case 1:
                        return <EnterpriseBaiscInfo id={id} nextCurrent={nextCurrent} />;
                    case 2:
                        return <ProdBaiscInfo id={id} />;
                }
                break;
            case 1:
                switch (current) {
                    case 0:
                        return <BasicRequirements id={id} nextCurrent={nextCurrent} />;
                    case 1:
                        return <WaterEffectManage id={id} nextCurrent={nextCurrent} />;
                    case 2:
                        return <ReducePollution id={id} nextCurrent={nextCurrent} />;
                    case 3:
                        return <WaterSavingTechnology id={id} nextCurrent={nextCurrent} />;
                    case 4:
                        return <StandardCoeditAwards id={id} nextCurrent={nextCurrent} />;
                    case 5:
                        return <ManagementSystem id={id} />;
                }
                break;
            case 2:
                switch (current) {
                    case 0:
                        return <ProductInformation id={id} />;
                }
                break;
            case 3:
                switch (current) {
                    case 0:
                        return <AssessmentContainer handleBigCurrent={setBigCurrent} id={id} />;
                }
                break;
            case 4:
                switch (current) {
                    case 0:
                        return <ExpertContainer handleBigCurrent={setBigCurrent} id={id} />;
                }
                break;
        }
    }

    return (
        <>
            <div className="water-effect-process-container">
                <div className="left-nav">
                    <Steps current={bigCurrent} onChange={onChangeBig} className="step-value-width" direction="vertical">
                        <Step title="基本信息" className={bigCurrent === 0 ? 'step-active' : ''} icon={<img src={bigCurrent >= 0 ? selectImg : normalImg} alt=""></img>} />
                        <Step title="定性评估" className={bigCurrent === 1 ? 'step-active' : ''} icon={<img src={bigCurrent >= 1 ? selectImg : normalImg} alt=""></img>} />
                        <Step title="定量评估" className={bigCurrent === 2 ? 'step-active' : ''} icon={<img src={bigCurrent >= 2 ? selectImg : normalImg} alt=""></img>} />
                        <Step title="评估结果" className={bigCurrent === 3 ? 'step-active' : ''} icon={<img src={bigCurrent >= 3 ? resSelectImg : resNormalImg} alt=""></img>} />
                        <Step title="专家评审" className={bigCurrent === 4 ? 'step-active' : ''} icon={<img src={bigCurrent >= 4 ? reviewSelectImg : reviewNormalImg} alt=""></img>} />
                    </Steps>
                </div>
                <div className="right-container">
                    <div className="head-nav" style={{ backgroundColor: bigCurrent == 2 || bigCurrent == 3 || bigCurrent == 4 ? '' : '#fff' }}>
                        <div className="step">
                            <div className="step-container" style={{ height: bigCurrent == 2 || bigCurrent == 3 || bigCurrent == 4 ? '' : '52px' }}>
                                {stepView()}
                            </div>

                        </div>
                        <div className="head-right">
                            <img src="/assets/images/icon_return_normal.png" alt=""></img>
                            <Button className="head-right-button" type="text" onClick={goBack}>返回列表</Button>
                        </div>
                    </div>
                    <div className="body-container" style={{ height: props.maxHeight - 70 }}>
                        {currentDOMView()}
                        {/* <div className="show-echants-rect" onClick={this.showEchart}>
              <div></div>
              <div></div>
              <div></div>
            </div> */}
                    </div>
                    {/* <ModalEchants getdata={this.setShowChart.bind(this)} isShow={isShowChart} data={chartData.data} modelTitle={chartData.modelTitle} type={echartIndex} /> */}
                </div>
            </div>
        </>
    )
}

export default WaterEffectProcessComponent;