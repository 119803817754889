import React from 'react';
import { Form, Input, Row, Col, Select, message, InputNumber} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Http from '../../../../utils/http';
import { adminEditAndRead } from '../../../../utils/utils';

export class AddDyeingEnergy extends React.Component<any,any>{
  constructor(props: any){
    super(props);
    this.state = {
      energyData:[],
      readAndEdit:true
    };
    props.bindRef(this);
    this.selectChange = this.selectChange.bind(this);
    this.getData = this.getData.bind(this);
  }

  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(values:any){
    if(this.props.editData.id){
      Http.put('/diagnosis/dyeing-fixed/'+this.props.editData.id,values).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功!');
          this.props.closeDrawer(true);
        }
      })
    } else {
      Http.post('/diagnosis/dyeing-fixed?fixedProcessId='+this.props.id,values).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新建成功!');
          this.props.closeDrawer(true);
        }
      })
    }
  }

  selectChange(e:any){
    let data = this.state.energyData;
    let unit = data.filter((i:any)=> i.id===e)[0].unit;
    this.createForm.current?.setFieldsValue({
      unit: unit
    })
  }

  energyList(){
    Http.get('/diagnosis/dyeing-fixed/'+this.props.id+'/energy/type',{type:'EC'}).then((res:any)=>{
      if(res&&res.code===200){
        this.setState({
          energyData:res.data
        })
      }
    })
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    });
    this.energyList();
    if(this.props.editData){
      this.createForm.current?.setFieldsValue({
        energyId:this.props.editData.energyId,
        num:this.props.editData.num,
        unit:this.props.editData.unit,
      })
    } else {
      this.createForm.current?.setFieldsValue({
        energyId:'',
        num:'',
        unit:''
      })
    }
  }
  render(){
    const { Option } = Select;
    const optionList = this.state.energyData.map((i:any)=>{
      return <Option disabled={i.used==='T'?true:false} key={i.id} value={i.id}>{i.name}</Option>;
    });
    let { readAndEdit } = this.state;
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Form.Item label="能源类型" name="energyId" rules={[{required: true}]}>
          <Select disabled={!readAndEdit} style={{ width: '100%' }} onChange={this.selectChange}>
            {optionList}
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="消费量" name="num" rules={[{required: true}]}>
              <InputNumber disabled={!readAndEdit} style={{width:'100%'}} placeholder='数值' />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="单位" name="unit">
              <Input disabled/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default AddDyeingEnergy;