import cookie from "react-cookies";
import {ExpertScore} from "./expert-score";
import http from "../../../../utils/http";
import React from "react";
import './expert-score.scss'


const getScore = (item:any,id:number) =>{
    const userInfo = cookie.load('faisUserInfo')
    const role =  userInfo?.roleIds[0]
    if (item && role === 2){
        return <div className="expert-right"> <ExpertScore id={id} item={item} ></ExpertScore></div>
    }
}

const getRulesScore = async (additional:string = 'CB',maxScore:number = 5,id:any) =>{
    const userInfo = cookie.load('faisUserInfo')
    const role =  userInfo?.roleIds[0]
    if(role === 2){
        let data = await http.get('/apiWaterUrl/assess/rules/score/score',{
            assessId:id,
            additional:additional,
        })
        const item = {
            maxScore:maxScore,
            expertScore:data?.data || 0,
            assessId:id,
            additional:additional
        }
        return item
    }

}

export { getScore,getRulesScore }

