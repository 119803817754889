import React from 'react';
import './previewImg.scss';
import store from '../../utils/store';
import {CLOSEIMG} from '../../utils/actions';
import Http from '../../utils/http';

export class PreviewImg extends React.Component<any,any>{
  cancelSub = () => {};
  constructor(props:any){
    super(props);
    this.state = {
      imgState:false,
      imgUrl:'',
      imgName:''
    }
    this.hideImg = this.hideImg.bind(this);
    this.stop = this.stop.bind(this);
  }

  hideImg(e:any){
    this.setState({
      imgState:false
    });
    store.dispatch({type: CLOSEIMG})
  }
  stop(e:any){
    e.stopPropagation();
  }
  componentDidMount(){
    this.cancelSub = store.subscribe(()=>{
      let storeState = store.getState();
      if(storeState.imgId&&storeState.imgName){
        Http.get('/system/file/download',{fileId:storeState.imgId},{
          responseType:'blob'
        }).then((res:any)=>{
          let imgUrl = URL.createObjectURL(res.data);
          if(storeState.imgName.indexOf('xls')!==-1 || storeState.imgName.indexOf('xlsx')!==-1 || storeState.imgName.indexOf('docx')!==-1 || storeState.imgName.indexOf('doc')!==-1){
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display:none');
            a.setAttribute('href', imgUrl);
            a.setAttribute('download', storeState.imgName);
            a.click();
            URL.revokeObjectURL(imgUrl);
            this.setState({
              imgState:false
            });
            store.dispatch({type: CLOSEIMG});
          } else if(storeState.imgName.indexOf('pdf')!==-1){
            let blob = new Blob([res.data],{type:'application/pdf'});
            let url = URL.createObjectURL(blob);
            window.open(url);
            this.setState({
              imgState:false
            });
            store.dispatch({type: CLOSEIMG});
          } else {
            this.setState({
              imgState: storeState.imgState,
              imgUrl: imgUrl,
              imgName:storeState.imgName
            })
          }
        })
        // if(storeState.imgName.indexOf('pdf')!==-1 || storeState.imgName.indexOf('xls')!==-1 || storeState.imgName.indexOf('xlsx')!==-1 || storeState.imgName.indexOf('docx')!==-1 || storeState.imgName.indexOf('doc')!==-1){
        //   window.open(storeState.imgUrl);
        //   this.setState({
        //     imgState:false
        //   });
        //   store.dispatch({type: CLOSEIMG});
        // } else {
        //   this.setState({
        //     imgState: storeState.imgState,
        //     imgUrl: url + '/system/file/downLoad?fileId=' + storeState.imgId,
        //   })
        // }
      }
    })
  }
  componentWillUnmount() {
    this.cancelSub();
  }

  render(){
    const {imgState,imgUrl,imgName} = this.state;
    return(
      <>
      {imgState?<div className="img-bg" onClick={(e)=>this.hideImg(e)}>
        <div className="img-container" onClick={this.stop}>
          <img src={imgUrl} alt={imgName} />
        </div>
      </div>:''}
      </>
    )
  }
}

export default PreviewImg;