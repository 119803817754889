import { ArrowRightOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import { Input, message } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import React from 'react';
import { processRecordSheet } from '../../utils/entity';
import Http from '../../utils/http';
import { dateFormat,isAdmin } from '../../utils/utils';

export class MessageComponent extends React.Component<any,any>{
  constructor(props:{
    msgId:number,
  }){
    super(props);
    this.state = {
      infoData:{},
      visible:false
    };
    this.processRecordSheet = this.processRecordSheet.bind(this);
    this.buttonRender = this.buttonRender.bind(this);
    this.refuse = this.refuse.bind(this);
    this.getrefuse = this.getrefuse.bind(this);
    this.submit = this.submit.bind(this);
    this.accept = this.accept.bind(this);
    this.close = this.close.bind(this);
    this.herfDiagnosis = this.herfDiagnosis.bind(this);
    this.msgIsRead = this.msgIsRead.bind(this);
  }
  refuseInfo = React.createRef<FormInstance>();

  refuse(isTrueFasle:boolean){
    this.setState({
      visible:isTrueFasle
    })
  }

  herfDiagnosis(){
    if(isAdmin()){
      navigate('/home/adminDiagnosis');
    } else {
      navigate('/home');
    }
  }

  submit(){
    this.refuseInfo.current?.submit();
  }

  close(){
    this.props.refClose();
  }

  getrefuse(values:any){
    Http.put(`/diagnosis/expert/handle/refuseDiagnosed/${this.props.msgId}`,{
      refuseReason:values.refuseReason
    }).then((res:any)=>{
      if(res&&res.code===200){
        message.success('提交成功');
        this.props.refClose();
      }
    })
  }

  accept(){
    Http.put(`/diagnosis/expert/handle/acceptDiagnosed/${this.props.msgId}`).then((res:any)=>{
      if(res&&res.code===200){
        message.success('接受成功,请到诊断管理开始诊断');
        this.props.refClose();
      }
    })
  }

  buttonRender(){
    let {infoData} = this.state;
    switch(infoData.operationSteps){
      case 'DIAGNOSIS_REFUSE':
        return <>
          <div className="msg-btn-herf" onClick={this.herfDiagnosis}>前往诊断管理<ArrowRightOutlined style={{marginLeft:'12px'}} /></div>
          <div className="msg-btn-close" onClick={this.close}>关闭</div>
        </>;
      case 'DIAGNOSIS_END':
        return <>
          <div className="msg-btn-herf" onClick={this.herfDiagnosis}>前往诊断管理<ArrowRightOutlined style={{marginLeft:'12px'}} /></div>
          <div className="msg-btn-close" onClick={this.close}>关闭</div>
        </>;
      case 'DIAGNOSIS_APPOINT':
        return infoData.isRead==='T'?<></>:<>
          <div onClick={this.accept} className="msg-btn-herf">接受</div>
          <div onClick={e=>this.refuse(true)} className="msg-btn-herf refuse">拒绝</div>
        </>;
      default:
        return <><div className="msg-btn-close" onClick={this.close}>关闭</div></>;
    }
  }

  processRecordSheet(){
    Http.get(`/diagnosis/processRecordSheet/${this.props.msgId}`).then((res:any)=>{
      if(res&&res.code===200){
        this.setState({
          infoData:new processRecordSheet(res.data)
        });
        this.msgIsRead();
      }
    })
  }

  msgIsRead(){
    Http.put(`/diagnosis/processRecordSheet/isRead/${this.props.msgId}`).then((res:any)=>{
      
    })
  }

  componentDidMount(){
    this.processRecordSheet();
  }

  render(){
    let {infoData,visible} = this.state;
    const { TextArea } = Input;
    return (
      <div className="msg-container">
        {
          visible?<>
            <div className="refuse-title">
              <div>拒绝</div>
              <div onClick={e=>this.refuse(false)} className="msg-btn-close">返回</div>
            </div>
            <Form
              ref={this.refuseInfo}
              name="enter"
              layout="vertical"
              onFinish={this.getrefuse}
            >
              <Form.Item
                className="login-container-item"
                label="请输入拒绝原因"
                name="refuseReason"
                rules={[{ required: true, message: '请输入拒绝原因' }]}
              >
                <TextArea placeholder="请输入" />
              </Form.Item>
              <div className="msg-container-btn">
                <div onClick={this.submit} className="msg-btn-herf">提交</div>
                <div className="msg-btn-close">关闭</div>
              </div>
            </Form>
          </>:<>
            <div className="msg-congainer-title">{infoData.title}</div>
            <div className="msg-congainer-time">{dateFormat(new Date(infoData.sendTs),'YYYY-mm-dd hh:MM:ss')}</div>
            <div className="msg-congainer-content">{infoData.operationDescribe}</div>
            {infoData.table==='T'?<div className="msg-container-body">
              {infoData.tableItemList.map((i:{title:string,value:string},index:number)=>{
                return <div key={index} className="msg-container-body-container">
                  <div className="msg-container-body-container-left">{i.title}</div>
                  <div className="msg-container-body-container-right">{i.value}</div>
                </div>
              })}
            </div>:<></>}
            <div className="msg-container-btn">
              {this.buttonRender()}
            </div>
          </>
        }
      </div>
    )
  }
}

export default MessageComponent;