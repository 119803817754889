import './expert-container.scss';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import http from '../../../../utils/http';
import cookie from 'react-cookies';
import { Tabs, Input, Space, Button, message, Form, Modal } from 'antd';
import * as uniqid from "uniqid";

const ExpertContainer = (props:any) => {
  const { TabPane } = Tabs;
  const {TextArea} = Input;
  const [form] = Form.useForm();
  const [roleId,setRoleId] = useState<number>();
  const [httpUrl,setHttpUrl] = useState<string>('');
  const [expertData,setExpertData] = useState<any>();
  const [btnLoading1,setBtnLoading1] = useState<boolean>(false);
  const [btnLoading2,setBtnLoading2] = useState<boolean>(false);
  const [expertList,setExpertList] = useState<Array<any>>([]);
  const [modalVisible,setModalVisible]  = useState<boolean>()
  const [modalList,setModalList] = useState<Array<any>>([]);

  useEffect(() => {
    let role = cookie.load('faisUserInfo');
    setRoleId(role.roleIds[0]);
    let url = '';
    if(role.roleIds.includes(0)){
      url = '/apiWaterUrl/assess/expert/admin/' + props.id;
      getExpertData(url,'0');
    }else if(role.roleIds.includes(1)){
      url = '/apiWaterUrl/assess/expert/user/' + props.id;
      getExpertData(url,'1');
    }else{
      url = '/apiWaterUrl/assess/expert/' + props.id;
      getExpertData(url,'2');
    }
    setHttpUrl(url);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])




  const handleOk = () => {}

  const handleCancel = () => {}

  const getExpertData = (url?:string,role?:string) => {
    let id = role ? role : roleId?.toString();

    http.get(url?url:httpUrl).then((res:any) => {
      if(res && res.code === 200){
        if(id == '0' || id == '1'){
          res.data.forEach((i:any,index:number) => {
            i.index = index;
          })
          setExpertList(res.data);
        }else{
          http.get(`/apiWaterUrl/assess/rules/item/list/expert/check/${props.id}`).then((res) => {

            if(res.data.length){
              setModalList(res.data)
              setModalVisible(true);
            }
          })
          setExpertData(res.data);
          form.setFieldsValue({
            comment: res.data.comment,
          })    
          localStorage.setItem('waterEffectStatus',res.data.status);
        }
      }
    })
  }

  const save = () => {
    form.validateFields().then(values=>{
      if(!values.comment){
        message.info("请填写评审意见");
        return;
      }
      
      setBtnLoading1(true);
      http.put('/apiWaterUrl/assess/expert/'+props.id,{
        comment: values.comment,
      }).then((res:any) => {
        if(res && res.code === 200){
          message.success("保存成功");
          getExpertData();
        }
        setBtnLoading1(false);
      })  
    })
  }

  const finishInfo = () => {
    form.validateFields().then(values=>{
      if(!values.comment){
        message.info("请填写评审意见");
        return;
      }

      Modal.confirm({
        title:'',
        content:'点击“完成评审”后，填写的专家意见不可更改，请确认是否继续？',
        okText:'确认完成',
        onOk(){
          finishExpert(values.comment);
        }
      })
  
    })

  }

  const finishExpert = (value:string) => {
    setBtnLoading2(true);
    http.put('/apiWaterUrl/assess/expert/end/'+props.id,{
      comment: value,
    }).then((res:any) => {
      if(res && res.code === 200){
        message.success("评审成功");
        getExpertData();
      }
      setBtnLoading2(false);
    })
  }

  return (
    <>
      {
        roleId === 0 || roleId === 1 ? 
          expertList.length === 0 ?
          <div className="add-product-contanier">
            <div>
              <img alt="" src="/assets/images/img_default_shuixiao.png" />
            </div>
            <div className="contanier-padding">
              <p style={{paddingBottom: '8px'}}>暂时没有专家评审意见</p>
              <p style={{paddingBottom: '8px'}}>请在评估结果页面点击“提交评审”后，等待专家评审</p>
            </div>            
          </div> :
          <Tabs type="card" tabBarStyle={{margin:0}}>
            {
              expertList.map((item:any) => 
              <TabPane tab={<span className="pane-title">专家意见-{item.expertName}</span>} key={item.index}>
                <div className="expert-container">
                  <p className="expert-title">评审意见</p>
                  <div className="expert-content">
                      <TextArea placeholder='请填写专家意见，500字以内，支持分段。' value={item.comment} autoSize={{minRows: 6,maxRows:12}} style={{border: 'none'}}/>
                  </div>
                </div>
              </TabPane>
              )
            }
          </Tabs>
        :
        <div className="contanier">
          <div className="expert-info">
            <img src="/assets/images/icon_prompt_normal.png" alt=""/>
            <span className="expert-info-text">评审截止日期：{expertData?.endTs} [请先查看企业填写的资料与数据，再填写评审意见]</span>
          </div>
          <Tabs type="card" tabBarStyle={{margin:0}}>
            <TabPane tab={<span className="pane-title">专家意见-专家姓名</span>} key="1">
              <div className="expert-container">
                <p className="expert-title">填写评审意见</p>
                <div className="expert-content">
                <Form layout="vertical" form={form} name="add-product">
                  <Form.Item label={null} name="comment" >
                    <TextArea placeholder='请填写专家意见，500字以内，支持分段。' maxLength={500} autoSize={{minRows: 6,maxRows:12}} style={{border: 'none'}}/>
                  </Form.Item>
                </Form>
                </div>
                <div style={{textAlign: 'right',paddingTop: '22px'}}>
                  <Space>
                    <Button type="default" disabled={expertData?.status !== 'ING'} onClick={save} loading={btnLoading1}>保存</Button>
                    <Button type="primary" disabled={expertData?.status !== 'ING'} onClick={finishInfo} loading={btnLoading2} className="green-button">完成评审</Button>
                  </Space>
                </div>
              </div>
            </TabPane>
          </Tabs>

          <Modal
              title="定性评估中以下指标未评分"
              visible={modalVisible}
              onOk={handleOk}
              closable={false}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={()=>{ setModalVisible(false)}}>
                  取消
                </Button>,
                <Button key="submit" type="primary"  onClick={()=>{
                  setModalVisible(false)
                  props.handleBigCurrent(1)
                }}>
                  去补充
                </Button>,
              ]}
          >

            <div>
              {
                modalList.length> 0 &&modalList.map((list, index) =>{
                    return <div className="expert-testing-bold " key={index}>
                              { index+1 + ' ' + list.name + '>' }
                              {
                                  list.children?.length &&

                                  list.children.map((child:any, sIndex:number) =>{
                                    return <div className="expert-testing-bold" key={sIndex +100}>
                                      {index+1 + '.' + (sIndex+1) + ' ' + child.name + '>'}
                                      {
                                          child.children?.length &&

                                          child.children.map((item:any, cIndex:number) =>{
                                            return <div className="expert-testing" key={cIndex +100}>
                                              { index+1 + '.' + (sIndex+1) + ' ' +  (cIndex+1)  }  {item.name}
                                            </div>
                                          })
                                      }

                                    </div>
                                  })
                              }
                          </div>

                  })

              }
            </div>

          </Modal>


        </div>
      }

    </>
  )
}

export default ExpertContainer;