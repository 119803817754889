import './management-system.scss';
import React, { useState, useCallback, useEffect } from 'react';
import { Table, Pagination, Drawer, Button, Space, Popover, Row, Col, Modal, message } from 'antd';
import { page, Page, managementType, managementEnum } from '../../../../utils/entity';
import http from '../../../../utils/http';
import { waterEditAndRead, dateFormat } from '../../../../utils/utils';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';
import AddManagementSystem from '../add-management-system/add-management-system';
import {getRulesScore, getScore} from "../common/expert-additional";


const ManagementSystem = (props:any) => {
  const [readAndEdit,setReadAndEdit] = useState<boolean>(true);
  const [dataList,setDataList] = useState<Array<any>>([]);
  const [total,setTotal] = useState<number>(0);
  const [searchPage,setSearchPage] = useState<page>(new Page());
  const [visiable,setVisiable] = useState<boolean>(false);
  const [editData,setEditData] = useState<any>();
  const [deleteId,setDeleteId] = useState<number>(0);
  const [deleteVisible,setDeleteVisible] = useState<boolean>(false);
  const [selectedOption,setSelectOption] = useState<Array<string>>([]);
  const [expertData,setExpertData] = useState<any>()
  const onSubmitDrawer = () => {
    onClose();
    getTableList();
  }

  const pageChange = (page:number) => {
    let pageData = searchPage;
    pageData.SkipCount = (page - 1) * 10;
    pageData.PageNum = page;
    setSearchPage(pageData);
    getTableList();
  }

  const getTableList = useCallback(() => {
    http.get('/apiWaterUrl/assess/management/page',{
      assessId:props.id,
      pageNum:searchPage.PageNum,
      pageSize:10
    }).then((res:any) => {
      if(res && res.code === 200){
        let arr:string[] = [];
        res.data.dataList.forEach((i:any,index:number) => {
          i.key = index+1;
          arr.push(i.managementEnum);
        });
        setSelectOption(arr);
        setDataList(res.data.dataList);
        setTotal(res.data.count);
      }
    })
  },[props.id, searchPage.PageNum])

  const showDrawer = (data?:any) => {
    setEditData(data);
    setVisiable(true);
  }

  const onClose = () => {
    setVisiable(false);
  }

  const imgClick = (i:any) => {
    store.dispatch({type: OPENIMG,imgId:i.fileId,imgName:i.showName});
  }

  const deleteItem = (id:any) => {
    setDeleteId(id);
    setDeleteVisible(true);
  }

  const handleOk = () => {
    http.DELETE('/apiWaterUrl/assess/management/' + deleteId).then((res:any) => {
      if(res && res.code === 200){
        message.success("删除成功");
        handleCancel();
        getTableList();
      }
    })
  }

  const handleCancel = () => {
    setDeleteVisible(false);
  }

  useEffect(() => {
    setReadAndEdit(waterEditAndRead());
  }, [])

  useEffect(() => {
    getTableList();
    getRulesScore('GLTX',3,props.id).then((res:any)=>{
      setExpertData(res)
    })
  },[getTableList])

  const columns = [{
    title:'序号',
    dataIndex:'key',
    width:60
  },{
    title:'企业建立、实施并保持满足相关标准要求的体系',
    dataIndex:'managementEnum',
    key:'managementEnum',
    render:(text:managementType,record:any) => (
      <span>{managementEnum[text]}</span>
    )
  },{
    title:'实施时间',
    dataIndex:'produceTs',
    key:'produceTs',
    render: (text:string) => (
      <span>{text?dateFormat(new Date(text),'YYYY年mm月dd日'):''}</span>
    )
  },{
    title:'证明材料',
    dataIndex:'files',
    key:'files',
    render:(text:any, record:any) => (
      <Space size="middle">
        <Popover content={
          record?.files && record?.files.length > 0 ? record.files.map((i:any,index:number) => 
            <div key={index} onClick={() => imgClick(i)} className="div-a">{i.showName}</div>
          ) : null
        } title={null} trigger="hover">
          <span>共 <span style={{color:'#00A968'}}>{record?.files.length | 0}</span> 个</span>
        </Popover>
      </Space>
    )  
  },{
    title:'备注',
    dataIndex:'remark',
    key:'remark',
  },{
    title:'操作',
    key: 'action',
    fixed: 'right' as any,
    render:(text:any, record:any) => (
      readAndEdit?
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>编辑</span>
        <span className="green-table-a" onClick={() => deleteItem(record.id)}>删除</span>
      </Space>:
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>查看</span>
      </Space>
    )  
  }]

  const showButtonView = () => {
    if(readAndEdit){
      return (
        <div className="button-margin-left">
          <Button className="green-button" onClick={showDrawer}>新增</Button>
        </div>
      )
    }else{
      return '';
    }
  }

  return (
    <>
      <div className="table-container">
        <Row gutter={16} style={{paddingBottom: '8px'}}>
          <Col span={18}>
            <div className="table-title">管理体系实施列表</div>
            <div className="table-header-content">
              <img src="/assets/images/icon_prompt_normal.png" alt=""/>
              <span style={{paddingLeft: '8px'}}>管理体系的建立应充分考虑纺织企业自身特点，充分融合企业现有的管理体系，从而保证企业管理体系的集成与统一。</span>
            </div>
            <div className="table-header-content">
              <img src="/assets/images/icon_prompt_normal.png" alt=""/>
              <span style={{paddingLeft: '8px'}}>查阅有关质量管理体系环境管理体系、职业健康安全管理体系和能源管理体系有关文件。</span>
            </div>
          </Col>
          <Col span={6}>
            {getScore(expertData,props.id)}
            {showButtonView()}
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={dataList}
          pagination={false}
          size="small"
        />
        <div className="pagination">
          <div className="pagination-total">总共{total}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={searchPage.PageNum}
            hideOnSinglePage
            onChange={pageChange}
          />
          
        </div>
        <Drawer 
          title={editData?.id?'编辑':'新增'}
          width={550}
          onClose={onClose}
          destroyOnClose={true}
          visible={visiable}
          bodyStyle={{ paddingBottom: 10 }}
        >
            <AddManagementSystem
              onClose={onClose} 
              editData={editData} 
              id={props.id}
              selectedOption={selectedOption}
              onSubmitDrawer={onSubmitDrawer}></AddManagementSystem>
        </Drawer>
        <Modal
          title="删除"
          visible={deleteVisible}
          width="400px"
          cancelText="取消"
          okText="确定"
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose
          footer={[
            <Button key="cancel" type="default" onClick={handleCancel}>取消</Button>,
            <Button key="handleOk" type="primary" className="green-button" onClick={handleOk}>确认</Button>
          ]}
        >
          <p>确认要删除该项数据吗？</p>
        </Modal>
      </div>
    </>
  )
}

export default ManagementSystem;