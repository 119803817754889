import './product-information.scss';
import React, { useEffect, useState, useCallback, createContext, useReducer, useRef } from 'react';
import { Tabs, Button, Modal, message } from 'antd';
import http from '../../../../utils/http';
import ProductInfoDetail from '../product-info-detail/product-info-detail';
import * as uniqid from 'uniqid';
import { waterEditAndRead } from '../../../../utils/utils';

const ProductInformation = (props:any) => {
  const { TabPane } = Tabs;
  const [readAndEdit,setReadAndEdit] = useState<boolean>(false);
  const [isAdd,setIsAdd] = useState<boolean>(true);
  const [stepStatus,SetStepStatus] = useState<number>(0);
  const [tabsList,setTabsList] = useState<Array<any>>([]);
  const [period,setPeriod] = useState<number>(0);
  const [productName,setProductName] = useState<string>('');
  const [activeKey,setAcitveKey] = useState<string>('');
  const [hideAdd,setHideAdd] = useState<boolean>(false);
  const [visible,setVisible] = useState<boolean>(false);
  const [removeTagKey,setRemoveTagKey] = useState<string>('');
  // const [status,setStatus] = useState<boolean>(true);
  const status = useRef<boolean>(true);
  const [defalutKey,setDefaultKey] = useState<string>('');

  const childRef = useRef<any>();

  const handleNavigate = (data:string) => {
    if(childRef.current){
      childRef.current?.childFun(data);
    }
    // if(childRef.current){
    //   childRef.current.func();
    // }
  }



  const refreshPane = useCallback((targetKey:string) => {
    let lastIndex:number = 0;
    tabsList.forEach((pane:any,i:number) => {
      if(pane.key === targetKey){
        lastIndex = i-1;
      }
    })
    let newPanes = tabsList.filter(pane => pane.key !== targetKey);
    if(newPanes.length && activeKey === targetKey){
      if(lastIndex >= 0){
        setAcitveKey(newPanes[lastIndex].key);
      }else{
        setAcitveKey(newPanes[0].key);
      }
    }
    setTabsList(newPanes);
    if(newPanes.length===2){
      setHideAdd(true);
    }else{
      setHideAdd(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activeKey, tabsList])

  const removeTag = useCallback((targetKey:string) => {
    if(targetKey && targetKey !== defalutKey){
      setRemoveTagKey(targetKey);
      setVisible(true);
    }else{
      refreshPane(targetKey);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[refreshPane])

  const initProductName = (key:string,name:string) => {
    let panes = tabsList;
    panes.forEach((item:any) => {
      if(item.key === key){
        item.title = name;
      }
    })
    setTabsList(panes);
    removeTag('');
  }

  const refCount = useRef<number>(0);

  useEffect(() => {
    setAcitveKey(activeKey.toString());
    let arr = [...tabsList];
    setTabsList(arr);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activeKey])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProductList = (editKey?:string) => {
    http.get('/apiWaterUrl/assess/product/list/'+props.id).then((res:any) => {
      if(res && res.data){
        if(res.data.products.length > 0){
          setIsAdd(false);
          let arr:any = [];
          res.data.products.forEach((i:any) => {
            i.key = i.id;
            arr.push({
              title:i.productName,
              content:
              <ProductInfoDetail ref={childRef} step={3} period={res.data.period} tabKey={i.id} id={props.id} isEdit={true} refList={refList}/>,
              key:i.id.toString()
            });
          })
          if(res.data.products.length===2){
            setHideAdd(true);
          }
          setTabsList(arr);
          refCount.current = refCount.current+1;
          if(editKey){
            setAcitveKey(editKey.toString());
            handleNavigate(editKey.toString())
          }else{
            setAcitveKey(arr[0].key);
            handleNavigate(arr[0].key)
          }
          // removeTag('')
        }else{
          setIsAdd(true);
        }
        setPeriod(Number(res.data.period));
      }
    })
  }

  useEffect(() => {
    setReadAndEdit(waterEditAndRead());
  }, [])

  useEffect(() => {
    // if(status.current){
      getProductList();
    // }

    // return () => {
    //   status.current = false;
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const addProduct = () => {
    SetStepStatus(1);
    setHideAdd(false);
    addTag();
  }


  const addTag = () => {
    if(!readAndEdit){
      return;
    }
    let newPanes = tabsList; 
    let nextKey = uniqid.default();
    setDefaultKey(nextKey);
    newPanes.push({
      title:'',
      content:
      <ProductInfoDetail step={1} period={period} tabKey={nextKey} id={props.id} refList={refList} initProductName={initProductName}/>,
      key:nextKey
    });
    setTabsList(newPanes);
    setAcitveKey(nextKey);
    if(newPanes.length===2){
      setHideAdd(true);
    }else{
      setHideAdd(false);
    }
  }



  const onEdit = (targetKey:any,action:any) => {
    if(action === 'add'){
      addTag();
    }else{
      removeTag(targetKey);
    }
  }

  const onChange = (activeKey:any) => {
    setAcitveKey(activeKey);
    handleNavigate(activeKey);
  }

  const refList = (editKey:string) => {
    getProductList(editKey);
    handleNavigate(editKey);
  }

  const handleOk = () => {
    if(!isAdd){
      http.DELETE('/apiWaterUrl/assess/product/'+removeTagKey).then((res:any) => {
        if(res && res.code == 200){
          message.success('删除成功');
          getProductList();
        }
      })
    }
    refreshPane(removeTagKey);
    handleCancel();
  }

  const handleCancel = () => {
    setVisible(false);
  }

  const createDomView = () => {
    if(isAdd){
      if(stepStatus>0){
        return (
          <Tabs type="editable-card" activeKey={activeKey} 
          onEdit={onEdit} 
          onChange={onChange} 
          hideAdd={hideAdd}
          tabBarStyle={
            {margin:0}
          }>
          {
            tabsList.map((pane:any) => 
              <TabPane tab={<span className="pane-title">{pane.title}</span>} key={pane.key}>
                {pane.content}
              </TabPane>
            )
          }
          </Tabs>
          )
      }else{
        return (
          <AddProductInfo addProduct={addProduct} readAndEdit={readAndEdit}/>
        )
      }
    }else{
      return (
        <Tabs type="editable-card" activeKey={activeKey} 
        onEdit={onEdit} 
        onChange={onChange} 
        hideAdd={hideAdd}
        defaultActiveKey={activeKey}
        tabBarStyle={
          {margin:0}
        }>
        {
            tabsList.map((pane:any) => 
            <TabPane tab={<span className="pane-title">{pane.title}</span>} key={pane.key}>
              {pane.content}
            </TabPane>
          )
        }
        </Tabs>
      )
    }
  }

  return (
    <>
      {createDomView()}
      <Modal
          title={
            <div style={{textAlign: "center"}}>
              <img src="/assets/images/popup_icon_delete_normal.png" alt="" style={{cursor:'pointer'}}/>
            </div>
          }
          visible={visible}
          width="400px"
          cancelText="取消"
          okText="清空"
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose
          footer={[
            <Button key="cancel" type="default" onClick={handleCancel}>取消</Button>,
            <Button key="handleOk" type="primary" className="green-button" onClick={handleOk}>确认</Button>
          ]}
        >
          <p>点击将删除本产品（{tabsList.map((pane:any) => {
            if(pane.key === removeTagKey){
              return pane.title;
            }
          })}）的全部信息，请确认是否删除？ </p>
        </Modal>

    </>
  )
}

const AddProductInfo = (props:any) => {
  return(
    <>
      <div className="add-product-contanier">
        <div>
          <img alt="" src="/assets/images/img_default_shuixiao.png" />
        </div>
        <div className="contanier-padding">
          <p>还没有定量评估的产品信息，点击新增</p>
          <p style={{paddingBottom: '8px'}}>(请填写企业最主要的1~2种产品)</p>
          <Button className={props.readAndEdit?'green-button':''} disabled={!props.readAndEdit} onClick={props.addProduct}>新增</Button>
        </div>            
      </div>
    </>
  )
}

export default ProductInformation;