import React from 'react';
import './energyManagement.scss';
import { FormInstance } from 'antd/lib/form';
import {Radio, DatePicker, Upload, Button, Divider, message} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Http from '../../../../utils/http';
import moment from 'moment';
import { Anchor } from 'antd';
import { dateFormat,verificationType,adminEditAndRead } from '../../../../utils/utils';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';

interface SystemSubject{
  name:string,
  id:string,
  ruleId:string
  children:Array<SystemTopics>
}

interface SystemTopics {
  id:string
  name:string               // 题目标题
  has:string             // 是否选中
  implTs:string              // 实施时间
  situation:string           // 执行情况
  files: never[]          // 上传文件
  trueLabel:string
  falseLabel:string
  ruleId:string
}
export class EnergyManagement extends React.Component<any,any>{
  upLoad: any;
  constructor(props:any){
    super(props);
    this.state = {
      value:0,
      dataList:[],
      fileList:[],
      fileIndex:0,
      fileChildrenIndex:0,
      readAndEdit:true
    }
    this.upLoad = {
      beforeUpload:(file:any)=>{
        if(verificationType(file.type)){
          this.fileUpload(file);
        } else {
          message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
        }
        return false;
      },
      onRemove: (file:any)=>{
        let {dataList,fileIndex,fileChildrenIndex} = this.state;
        dataList[fileIndex].children[fileChildrenIndex].files = dataList[fileIndex].children[fileChildrenIndex].files.filter((i:any)=> i['uid'] !== file.uid);
        this.setState({dataList});
        return false;
      },
      onPreview:(file:any)=>{
        // store.dispatch({type: OPENIMG,url:file.url})
        store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name});
        return false;
      }
    };
    this.isTrueChange = this.isTrueChange.bind(this);
    this.getRulesList = this.getRulesList.bind(this);
    this.clickUpload = this.clickUpload.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.saveData = this.saveData.bind(this);
    this.changeTs = this.changeTs.bind(this);
    this.changeSituation = this.changeSituation.bind(this);
  }

  fileUpload(file:any){
    const formData = new FormData();
    formData.append('file', file);
    Http.post('/system/file/upload/auth',formData).then((res:any)=>{
      if(res&&res.code===200){
        let img = {
          uid:file.uid,
          name:file.name,
          status:'done',
          id:res.data,
          url:''
        };
        let {dataList,fileIndex,fileChildrenIndex} = this.state;
        dataList[fileIndex].children[fileChildrenIndex].files = [...dataList[fileIndex].children[fileChildrenIndex].files,img];
        this.setState({
          dataList:dataList
        })
      }
    })
  }

  clickUpload(index:number,childrenIndex:number){
    this.setState({
      fileIndex:index,
      fileChildrenIndex:childrenIndex
    })
  }

  changeSituation(e:any,index:number,childrenIndex:number){
    let {dataList} = this.state;
    dataList[index].children[childrenIndex].situation = e.target.value;
    this.setState({
      dataList:dataList
    })
  }

  changeTs(e:any,index:number,childrenIndex:number){
    let {dataList} = this.state;
    dataList[index].children[childrenIndex].implTs = dateFormat(new Date(e),'YYYY-mm');
    this.setState({
      dataList:dataList
    })
  }

  getRulesList(){
    Http.get('/diagnosis/rules/item/list/'+this.props.id).then((res:any)=>{
      if(res&&res.code===200){
        res.data.forEach((i:any)=>{
          i.children.forEach((k:any)=>{
            if(k.files&&k.files.length>0){
              let imgArr:any = [];
              k.files.forEach((t:any,index:number)=>{
                let img = {
                  uid:index,
                  name:t.showName,
                  status:'done',
                  id:t.fileId,
                  url:''
                };
                imgArr.push(img);
              })
              k.files = imgArr;
            } else {
              k.files = [];
            }
            if(k.implTs){
              k.implTs = new Date(k.implTs);
            }
          })
        })
        this.setState({
          dataList:res.data
        })
      }
    })
  }

  isTrueChange(e:any,bigindex:any,index:any){
    let {dataList} = this.state;
    dataList[bigindex].children[index].has = e.target.value;
    this.setState({
      dataList:dataList
    })
  }

  saveData(){
    let arr:any[] = [];
    let {dataList} = this.state;
    let isModify = false;
    dataList.forEach((i:SystemSubject)=>{
      i.children.forEach((element:SystemTopics)=>{
        let files:any[] = [];
        let data:any = {};
        if(element.ruleId){
          isModify = true;
        }
        if(element.files.length>0){
          element.files.forEach((k:any)=>{
            files.push({
              fileId:k.id,
              showName:k.name
            });
          })
        }
        if(element.ruleId){
          data = {
            itemId:element.id,
            has:element.has,
            implTs:element.implTs?new Date(element.implTs).getTime():'',
            files:files,
            id:element.ruleId,
            situation:element.situation?element.situation:''
          };
        } else {
          data = {
            itemId:element.id,
            has:element.has,
            implTs:element.implTs?new Date(element.implTs).getTime():'',
            files:files,
            situation:element.situation?element.situation:''
          };
        }
        if(!data.situation){
          delete data.situation;
        }
        if(!data.has){
          delete data.has;
        }
        arr.push(data);
      })
    });
    if(isModify){
      Http.put('/diagnosis/rules?diagnosisId='+this.props.id,arr).then((res:any)=>{
        if(res.code === 200){
          message.success('修改成功！');
          this.getRulesList();
        }
      })
    } else {
      Http.post('/diagnosis/rules?diagnosisId='+this.props.id,arr).then((res:any)=>{
        if(res.code === 200){
          message.success('保存成功！');
          this.getRulesList();
        }
      })
    }
    
  }

  componentDidMount(){
    this.getRulesList();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }

  enter = React.createRef<FormInstance>();
  
  render(){
    const { dataList,readAndEdit } = this.state;
    const { Link } = Anchor;
    const dataDom = dataList.map((i:SystemSubject,index:any)=>
      <div className="energy-management-container-left-item" id={index + 1} key={i.id}>
        <p className="energy-management-container-left-item-title">{index+1}、{i.name}</p>
        { i.children.map((element:SystemTopics,childrenIndex:any)=>
          <div key={element.id}>
            <div className="energy-management-container-left-default" >{index+1}.{childrenIndex+1} {element.name}</div>
            <div className="energy-management-container-left-small">
              <Radio.Group disabled={!readAndEdit} value={element.has} onChange={e => this.isTrueChange(e,index,childrenIndex)}>
                <Radio value='T'>{element.trueLabel}</Radio>
                <Radio value='F'>{element.falseLabel}</Radio>
              </Radio.Group>
            {
              element.has==="T"?<>
                <div className="small-title">实施时间</div>
                <DatePicker disabled={!readAndEdit} picker="month" value={element.implTs?moment(element.implTs,'YYYY-MM'):null} onChange={e=>this.changeTs(e,index,childrenIndex)} placeholder="年/月" />

                <div className="small-title">执行情况</div>
                <Radio.Group disabled={!readAndEdit} value={element.situation} onChange={e=>this.changeSituation(e,index,childrenIndex)}>
                  <Radio value='A'>良好</Radio>
                  <Radio value='B'>一般</Radio>
                  <Radio value='C'>较差</Radio>
                </Radio.Group>

                <div className="small-title">相关文件</div>
                <Upload disabled={!readAndEdit} {...this.upLoad} fileList={element.files} >
                  <Button onClick={e=>this.clickUpload(index,childrenIndex)}>
                    <UploadOutlined />上传文件
                  </Button>支持.xls .xlsx .doc .docx .pdf .jpg格式，单个文件大小不超过20M
                </Upload>
              </>:''
            }
            </div>
            <Divider></Divider>
          </div>
        )}
        
      </div>
    );
    return(
      <div className="energy-management-container">
        <div className="energy-management-container-left" id="center">
          {dataDom}
          {readAndEdit?<Button className="green-button" onClick={this.saveData}>保存</Button>:''}
          
        </div>
        <div className="energy-management-container-right">
          <div style={{position:'fixed'}}>
            <Anchor>
              <Link href="#1" title="组织构建与责任划分" />
              <Link href="#2" title="管理文件与企业标准" />
              <Link href="#3" title="计量统计与信息化建设" />
              <Link href="#4" title="宣传教育与岗位培训" />
              <Link href="#5" title="其他绿色评价与管理工作" />
            </Anchor>
          </div>
          
        </div>
      </div>
    )
  }
}

export default EnergyManagement;