import React from 'react';
import {Button, Table,Drawer, Space, message, Divider} from 'antd';
import AddDyeingEnergy from '../addDyeingEnergy/addDyeingEnergy';
import Http from '../../../../utils/http';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import WorkingFluidConsumption from '../../allPlantInformation/workingFluidConsumption/workingFluidConsumption';
import WasteHeat from '../../allPlantInformation/wasteHeat/wasteHeat';
import { adminEditAndRead } from '../../../../utils/utils';

export class DyeingEnergy extends React.Component<any,any>{
  temNumber:any;
  formRef: any;
  editData:any = [];
  constructor(props: any){
    super(props);
    this.state = {
      newDrawer: false,
      tabData:[],
      isOpen: true,
      readAndEdit:true
    };
    this.temNumber = 1;
    this.clickConsole = this.clickConsole.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.deleteTable = this.deleteTable.bind(this);
    this.changeOpen = this.changeOpen.bind(this);
  };

  setFormRef = (el: any) => this.formRef = el;

  changeOpen(){
    let status = this.state.isOpen;
    this.setState({
      isOpen: !status
    })
  };
  onSubmitDrawer(){
    this.getFormRef();
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.props.refresh(this.props.id);
    }
    this.setState({
      newDrawer: false
    })
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  getFormRef(){
    this.formRef.vailForm();
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }
  deleteTable(data:any){
    Http.DELETE('/diagnosis/dyeing-fixed/'+data.id).then((res:any)=>{
      if(res&&res.code===200){
        message.success('删除成功!');
        this.props.refresh(this.props.id);
      }
    })
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    });
    let tabData = this.props.data.ecs;
    if(tabData){
      tabData.forEach((i:any,index:number)=>{
        i.key = index;
      });
    }
    this.setState({
      tabData:tabData
    });
  }
  render(){
    const columns = [
      {
        title: '能源类别',
        dataIndex: 'energyName',
        key: 'energyName',
        width: '25%',
      },
      {
        title: '消费量',
        dataIndex: 'num',
        key: 'num',
        width: '30%',
      },
      {
        title: '单位',
        dataIndex: 'unit',
        key: 'unit',
        width: '25%',
      },
      {
        title: '操作',
        key: 'action',
        dataIndex: 'action',
        render: (text:any,record:any) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.deleteTable(record)}>删除</span>:''}
            
          </Space>
        ),
      },
    ];

    const {tabData,isOpen,readAndEdit} = this.state;
    return(
      <div>
      <div className="item-title-container">
        <div className="item-title">
          <img src="/assets/images/icon1.png" alt=''></img>
          <span>能耗信息</span>
        </div>
        <div className="change-open" onClick={this.changeOpen}>
          {isOpen?<><span>收起</span><UpOutlined /></>:<><span>展开</span><DownOutlined /></>}
          </div>
      </div>
      {isOpen?<><div className="table-container">
        <div className="table-header">
          <div className="table-title">能源消费</div>
          {readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''}
          
        </div>
        <Table
          columns={columns}
          dataSource={tabData}
          pagination={false}
          size="small"
        />
        <WorkingFluidConsumption closeDrawer={this.closeDrawer} tableData={this.props.data.wcs} id={this.props.id} diagnosisId={this.props.diagnosisId} type={1}></WorkingFluidConsumption>
        <WasteHeat closeDrawer={this.closeDrawer} herRate={this.props.data.herRate} tableData={this.props.data.hers} id={this.props.diagnosisId} type={1} dfProcessId={this.props.id}></WasteHeat>
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddDyeingEnergy
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id}
            diagnosisId={this.props.diagnosisId}
            bindRef={this.setFormRef}></AddDyeingEnergy>
        </Drawer>
      </div>
      </>:''}
      <Divider />
    </div>
    )
  }
}

export default DyeingEnergy;