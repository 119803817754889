import React from 'react';
import {Table, Button, Space, Drawer, Pagination, message} from 'antd';
import './energyMeasurement.scss';
import Http from  '../../../../utils/http';
import AddEnergyMeasurement from '../addEnergyMeasurement/addEnergyMeasurement';
import { adminEditAndRead } from '../../../../utils/utils';


export class EnergyMeasurement extends React.Component<any,any>{
  editData:any = [];
  formRef: any;
  constructor(props:any){
    super(props);
    this.state = {
      newDrawer: false,
      tabData:[],
      total:10,
      currentPage:1,
      modalVisible: false,
      readAndEdit:true
    };
    this.ecPage = this.ecPage.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.onSubmitDrawer = this.onSubmitDrawer.bind(this);
    this.clickConsole = this.clickConsole.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.edit = this.edit.bind(this);
    this.changePage = this.changePage.bind(this);
    this.delete = this.delete.bind(this);
    this.modalHandleCancel = this.modalHandleCancel.bind(this);
    this.modalShow = this.modalShow.bind(this);
    this.openPdf = this.openPdf.bind(this);
  }
  setFormRef = (el: any) => this.formRef = el;

  getFormRef(){
    this.formRef.vailForm();
  }

  openPdf(){
    window.open('/assets/GB 17167-2006.pdf');
  }

  onSubmitDrawer(){
    this.getFormRef();
  }

  edit(data:any){
    this.editData = data;
    this.setState({
      newDrawer: true
    })
  }

  changePage(page:any) {
    this.setState({
      currentPage:page
    });
    this.ecPage();
  }

  ecPage(){
    let {currentPage} = this.state;
    Http.get('/diagnosis/emi/page',{
      diagnosisId:this.props.id,
      pageNum:currentPage,
      pageSize:10,
    }).then((res:any)=>{
      if(res&&res.code ===200){
        res.data.dataList.forEach((i:any,index:number)=>{
          i.key=index + 1;
        });
        this.setState({
          tabData:res.data.dataList,
          total:res.data.count
        })
      }
      
    })
  }

  delete(value:any){
    Http.DELETE('/diagnosis/emi/'+value.id,).then((res:any)=>{
      message.success('删除成功!');
      this.ecPage();
    });
    
  }

  clickConsole(){
    this.editData = [];
    this.setState({
      newDrawer: true
    })
  }

  closeDrawer(isRefresh?:boolean){
    if(isRefresh){
      this.ecPage();
    };
    this.setState({
      newDrawer: false
    })
  }

  modalHandleCancel(){
    this.setState({
      modalVisible: false
    })
  }

  modalShow(){
    this.setState({
      modalVisible: true
    })
  }

  componentDidMount(){
    this.ecPage();
    this.setState({
      readAndEdit:adminEditAndRead()
    })
  }
  render(){
    const columns= [
      {
        title:'序号',
        dataIndex:'key',
        key:'key',
        width:70,
        fixed: true,
      },
      {
        title: '能源品种',
        dataIndex: 'energy',
        key: 'energy',
        width:100,
        fixed:true
      },{
        title: '进出用能单位',
        width:750,
        children:[
          {
            title:'应装台数',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ioEmi.should}</span>
            )
          },
          {
            title:'安装台数',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ioEmi.actual}</span>
            )
          },
          {
            title:'配备率',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ioEmi.equRate}%</span>
            )
          },
          {
            title:'完好率',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ioEmi.intactRate}%</span>
            )
          },
          {
            title:'使用率',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ioEmi.useRate}%</span>
            )
          },
        ]
      },{
        title: '进出次级用能单位',
        width:750,
        children:[
          {
            title:'应装台数',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.insEmi.should}</span>
            )
          },
          {
            title:'安装台数',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.insEmi.actual}</span>
            )
          },
          {
            title:'配备率',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.insEmi.actual}%</span>
            )
          },
          {
            title:'完好率',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.insEmi.intactRate}%</span>
            )
          },
          {
            title:'使用率',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.insEmi.useRate}%</span>
            )
          },
        ]
      },{
        title: '主要用能设备',
        width:750,
        children:[
          {
            title:'应装台数',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ecEmi.should}</span>
            )
          },
          {
            title:'安装台数',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ecEmi.actual}</span>
            )
          },
          {
            title:'配备率',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ecEmi.equRate}%</span>
            )
          },
          {
            title:'完好率',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ecEmi.intactRate}%</span>
            )
          },
          {
            title:'使用率',
            align:'center' as any,
            render:(text:string,record:any)=>(
              <span>{record.ecEmi.useRate}%</span>
            )
          },
        ]
      },{
        title: '操作',
        key: 'action',
        render: (text:any, record:any) => (
          <Space size="middle">
            <span className="green-table-a" onClick={e=>this.edit(record)}>{this.state.readAndEdit?'编辑':'查看'}</span>
            {this.state.readAndEdit?<span className="green-table-a" onClick={e=>this.delete(record)}>删除</span>:''}
            
          </Space>
        ),
        width:150,
        fixed:'right' as any
      }
    ];
    const data = this.state.tabData;
    const {readAndEdit} = this.state;
    const {total,currentPage} = this.state;
    return(
      <div className="application-energy">
        <div className="alert-info" onClick={this.openPdf}>按《GB 17167-2006 用能单位能源计量器具配备和管理通则》标准要求填写（点击查看标准原文）</div>
        <div className="application-energy-head">
          <div className="modular-title">能源计量器具信息</div>
          {readAndEdit?<Button className="green-button" onClick={this.clickConsole}>新增</Button>:''}
          
        </div>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{ x: 2000 }} 
          pagination={false}
          size="small"
        />
        <div className="pagination">
          <div className="pagination-total">总共{data.length}条信息</div>
          <Pagination
            total={total}
            defaultCurrent={currentPage}
            hideOnSinglePage
            onChange={this.changePage}
          />
          
        </div>
        <Drawer 
          title={this.editData.id?'编辑':'新增'}
          width={550}
          onClose={e=>this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.newDrawer}
          bodyStyle={{ paddingBottom: 10 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={e=>this.closeDrawer()} style={{ marginRight: 8 }}>
                取消
              </Button>
              {readAndEdit?<Button onClick={this.onSubmitDrawer} type="primary">
                确定
              </Button>:''}
              
            </div>
          }
        >
          <AddEnergyMeasurement 
            closeDrawer={this.closeDrawer} 
            editData={this.editData} 
            id={this.props.id} 
            bindRef={this.setFormRef}
          ></AddEnergyMeasurement>
        </Drawer>
        
      </div>
    )
  }
}

export default EnergyMeasurement;