import React, {useState} from 'react';
import '../index/index.scss';
import {Form, Input, Button, message, Cascader, Row, Col} from 'antd';
import Http from './../../utils/http';
import cookie from 'react-cookies';
import {useNavigate, navigate} from "@reach/router"
import {FormInstance} from 'antd/lib/form';
import Province from '../../utils/ssq.json';
import UserAgreement from '../../common/userAgreement/userAgreement';
import store from '../../utils/store'
import {SETROLEIDS} from "../../utils/actions";


export class LoginComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.returnIndex = this.returnIndex.bind(this);
    }

    returnIndex() {
        navigate('/');
    }

    render() {
        return (
            <div style={{
                height: document.body.clientHeight,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }} className="lca-propaganda">
                <div className="fais-introduce-title" style={{marginBottom: '15px'}}>
                    <span>纺织行业绿色发展服务平台</span>
                    <img src="/assets/images/fais.png" title="返回首页" onClick={this.returnIndex} alt="fais"
                         style={{cursor: 'pointer'}}/>
                </div>
                <HomePageBody></HomePageBody>
            </div>
        )
    }
}

function HomePageBody() {
    const [login] = Form.useForm();
    const [isRegister, changeState] = useState(false);
    const [isShow, setShow] = useState(false);
    const [idSi, setidSi] = useState<number>(Number(sessionStorage.getItem('roleId')));
    const navigate = useNavigate();

    function changeRegister() {
        changeState(!isRegister);
    }

    const returnSet = () => {
        setShow(false);
    }

    const userAgree = () => {
        if (idSi === 3) {
            navigate('/home/waterParkEnterprise');
        } else {

            navigate('/home');
        }
        setShow(false);

    }

    function next() {
        login.validateFields().then(values => {
            Http.post('/admin/login', values).then((res: any) => {
                if (res && res.code === 200) {
                    sessionStorage.setItem('roleId', res.data.roleIds[0])
                    setidSi(res.data.roleIds[0])
                    store.dispatch({type: SETROLEIDS, roleIds: res.roleIds})
                    cookie.save('token', res.data.token, {path: '/'});
                    cookie.save('username', res.data.username, {path: '/'});
                    cookie.save('faisUserInfo', JSON.stringify(res.data), {path: '/'});
                    let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
                    message.success('登录成功！');
                    if (!reg.test(values.password)) {
                        cookie.save('isPass', 0, {path: '/'});
                    } else {
                        cookie.remove('isPass');
                    }


                    Http.get('/admin/info/agree/userAgreement').then((resp: any) => {
                        if (resp.data) {

                            if (res.data.roleIds[0] === 0) {
                                navigate('/home');
                            } else {
                                navigate('/dashboard');
                            }
                        } else {
                            setShow(true);
                        }
                    })
                }
            })
        })
    }

    const loginDom = <div className="login-container">
        <div className="login-title">邮箱登录</div>
        <Form
            form={login}
            name="login"
            layout="vertical"
        >
            <div className="login-item-container">
                <div className="login-item">
                    <img className="login-item-icon" src="/assets/images/login-email.png" width="48px" height="33px"/>
                    <div className="login-item-divider"></div>
                    <div className="login-item-input">
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                                    message: '邮箱格式不正确'
                                },
                                {required: true, message: '邮箱格式不正确'}]}
                        >
                            <Input placeholder="邮箱账号"/>
                        </Form.Item>
                    </div>
                </div>
                <div className="login-item">
                    <img className="login-item-icon" src="/assets/images/login_password.png" width="45px"
                         height="46px"/>
                    <div className="login-item-divider"></div>
                    <div className="login-item-input">
                        <Form.Item
                            name="password"
                            rules={[
                                {required: true, message: '请输入密码'}]}
                        >
                            <Input.Password placeholder="密码"/>
                        </Form.Item>
                    </div>
                </div>
            </div>
        </Form>
        <div className="login-button" onClick={next}>登&nbsp;&nbsp;入</div>
        {/* <div className="forget-password">忘记密码</div> */}
        <div className="login-register">还没有账号？<span onClick={changeRegister}>企业注册</span></div>
    </div>;

    return (
        <>
            {isRegister ? <RegisterT changeRegister={changeRegister}></RegisterT> : loginDom}
            <UserAgreement isShow={isShow} setShow={returnSet} agree={userAgree}/>
            {/* <Register changeRegister={changeRegister}></Register> */}
        </>
    )
}

export class RegisterT extends React.Component<any, any> {
    province: any;
    createForm = React.createRef<FormInstance>();
    basic = React.createRef<FormInstance>();

    constructor(props: any) {
        super(props);
        let arr: any = [];
        this.state = {
            step: 0,
            bigStep: 0,
            isSend: false,
            isTime: 60,
            obj: {}
        };
        Province.forEach(i => {
            let option = {
                value: i.name,
                label: i.name,
                children: this.provicePush(i.children)
            }
            arr.push(option);
        })
        this.province = arr;

        this.provicePush = this.provicePush.bind(this);
        this.next = this.next.bind(this);
        this.clickRegister = this.clickRegister.bind(this);
        this.back = this.back.bind(this);
        this.infoNext = this.infoNext.bind(this);
        this.count = this.count.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    provicePush(arr: any) {
        let returnArr: any = [];
        let option: any;
        arr.forEach((element: any) => {
            if (element.children) {
                option = {
                    value: element.name,
                    label: element.name,
                    children: this.provicePush(element.children)
                };
                returnArr.push(option);
            } else {
                option = {
                    value: element.name,
                    label: element.name
                };
                returnArr.push(option);
            }
        });
        return returnArr;
    }

    next() {
        this.createForm.current?.validateFields().then(values => {
            Http.get('/admin', {username: values.username}).then((res: any) => {
                if (res && res.code === 200) {
                    if (!res.data) {
                        message.success('请填写其他信息');
                        this.setState({
                            bigStep: 1,
                            step: 0
                        })
                        // setCount(current + 1);
                    } else {
                        message.error('账号已存在！');
                    }
                }
            })
        })
    }

    clickRegister() {
        this.createForm.current?.validateFields().then(values => {
            let obj = {...this.state.obj, ...values};
            // let resData = {...obj,...values,status:'NORMAL',roleId:1};
            let resData = {
                city: obj.region[1],
                district: obj.region[2],
                province: obj.region[0],
                company: obj.company,
                email: obj.email,
                code: obj.code,
                idno: obj.idno,
                name: obj.name,
                phone: obj.phone,
                roleId: 1,
                tax: obj.tax,
                tel: obj.tel,
                password: obj.password,
                username: obj.username,
                industry: ['棉纺织及印染精加工'],
                status: 'NORMAL'
            };
            Http.post('/admin/info/reg', resData).then((res: any) => {
                if (res && res.code === 200) {
                    message.success('注册成功！请登录');
                    this.props.changeRegister();
                }
            })
        })

    }

    back() {
        if (this.state.bigStep === 0) {

        } else {
            if (this.state.step === 0) {
                this.setState({
                    bigStep: 0
                });
            } else if (this.state.step === 1) {
                this.setState({
                    step: 0
                })
            } else {
                this.setState({
                    step: 1
                })
            }
        }
    }

    infoNext() {
        this.createForm.current?.validateFields().then(values => {
            let obj = this.state.obj;
            this.setState({
                obj: {...obj, ...values}
            });
            if (this.state.bigStep === 0) {
                this.next();
            } else {
                if (this.state.step === 0) {
                    this.setState({
                        step: 1
                    })
                } else if (this.state.step === 1) {
                    this.setState({
                        step: 2
                    })
                }
            }
        })
    }

    cancel() {
        this.props.changeRegister();
    }

    count() {
        let siv = setInterval(() => {
            let {isTime} = this.state;
            isTime = isTime - 1;
            this.setState({isTime: isTime}, () => {
                if (isTime <= 0) {
                    clearInterval(siv);
                    this.setState({isSend: false, isTime: 60})
                }
            });
        }, 1000);
    }

    sendEmail() {
        if (this.createForm.current?.getFieldValue('email')) {
            if (!(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(this.createForm.current?.getFieldValue('email')))) {
                message.info('请输入正确的邮箱');
                return false;
            } else {
                Http.post('/system/send/mail/code', {account: this.createForm.current?.getFieldValue('email')}).then((res: any) => {
                    if (res && res.code === 200) {
                        message.success('发送成功');
                        this.setState({
                            isSend: true
                        });
                        this.count();
                    }
                })
            }
        } else {
            message.info('请填写邮箱');
            return false;
        }
    }

    render() {
        let {step, bigStep, isSend, isTime} = this.state;
        var stepOne = <div className="login-item-container">
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/13.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="name"
                        rules={[{required: true, message: '请输入用户姓名'}]}
                    >
                        <Input placeholder="用户姓名"/>
                    </Form.Item>
                </div>
            </div>
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/3.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                                message: '邮箱格式不正确'
                            },
                            {required: true, message: '请填写邮箱'}]}
                    >
                        <Input placeholder="邮箱账号"/>
                    </Form.Item>
                </div>
            </div>
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/15.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="password"
                        rules={[{
                            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
                            message: '密码必须为6-12位数字和字母组合'
                        },
                            {required: true, message: '请输入密码'}]}
                    >
                        <Input placeholder="密码"/>
                    </Form.Item>
                </div>
            </div>
        </div>;

        var stepTwo = <div className="login-item-container">
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/9.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="company"
                        rules={[{required: true, message: '请输入机构名称'}]}
                    >
                        <Input placeholder="请输入机构名称"/>
                    </Form.Item>
                </div>
            </div>
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/10.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="tax"
                        rules={[
                            {
                                pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
                                message: '社会信用代码格式不正确'
                            },
                            {required: true, message: '请输入18位社会信用代码'}]}
                    >
                        <Input placeholder="请输入社会信用代码(18位)"/>
                    </Form.Item>
                </div>
            </div>
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/11.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="region"
                        rules={[{required: true, message: '请选择所在地区'}]}
                    >
                        <Cascader options={this.province} placeholder="请选择区域"/>
                    </Form.Item>
                </div>
            </div>
        </div>;

        var stepthr = <div className="login-item-container">
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/13.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="name"
                    >
                        <Input placeholder="联系人姓名"/>
                    </Form.Item>
                </div>
            </div>
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/3.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                                message: '邮箱格式不正确'
                            },
                            {required: true, message: '邮箱格式不正确'}]}
                    >
                        <Input placeholder="联系邮箱"/>
                    </Form.Item>
                </div>
            </div>
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/3.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        rules={[{required: true}]}
                    >
                        <Row gutter={16}>
                            <Col span={10}>
                                <Form.Item
                                    name="code"
                                    rules={[{required: true, message: '请输入邮箱验证码'}]}
                                >
                                    <Input placeholder="邮箱验证码"/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Button disabled={isSend}
                                        onClick={this.sendEmail}>{isSend ? isTime + 's后可再次发送' : '发送邮件'}</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </div>
            </div>
        </div>;

        var stepfour = <div className="login-item-container">
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/7.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="idno"
                    >
                        <Input placeholder="请输入身份证号"/>
                    </Form.Item>
                </div>
            </div>
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/4.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="phone"
                    >
                        <Input placeholder="请输入手机"/>
                    </Form.Item>
                </div>
            </div>
            <div className="login-item">
                <img className="login-item-icon" src="/assets/images/5.png" width="50px"/>
                <div className="login-item-divider"></div>
                <div className="login-item-input">
                    <Form.Item
                        name="tel"
                    >
                        <Input placeholder="请输入电话"/>
                    </Form.Item>
                </div>
            </div>
        </div>;
        return (
            <div className="login-container">
                <div className="login-title" style={{color: '#000'}}>企业注册</div>
                <div className="login-steps">
                    <div className={bigStep === 0 ? 'login-step-item steps-active' : 'login-step-item'}>
                        <div className="login-step-index">1</div>
                        <span>账号信息</span>
                    </div>
                    <div className="login-divider"></div>
                    <div className={bigStep === 1 ? 'login-step-item steps-active' : 'login-step-item'}>
                        <div className="login-step-index">2</div>
                        <span>其他信息</span>
                    </div>
                </div>
                <Form
                    ref={this.createForm}
                    layout="vertical"
                >
                    {
                        bigStep === 0 ? stepOne : bigStep === 1 && step === 0 ? stepTwo : bigStep === 1 && step === 1 ? stepthr : stepfour
                    }

                </Form>
                {/* <div className="login-button">登&nbsp;&nbsp;入</div> */}
                <div className="register-button-container">
                    <div className="register-button" onClick={this.back}>上一步</div>
                    {bigStep === 1 && step === 2 ?
                        <div className="register-button" onClick={this.clickRegister}>完成注册</div> :
                        <div className="register-button" onClick={this.infoNext}>下一步</div>}
                    <div className="register-button" onClick={this.cancel}>取消</div>
                </div>
                <div className="login-register">已有账号&nbsp;<span onClick={this.cancel}>返回登入</span></div>
            </div>
        )
    }
}

export default LoginComponent;