import Form, { FormInstance } from 'antd/lib/form';
import './conclusion.scss'
import React from 'react';
import { AppstoreFilled, FileTextFilled,UpOutlined,UserOutlined,PlusOutlined, CloseCircleOutlined, DownOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Input, InputNumber, message, Modal, Row,Select,Space } from 'antd';
import Http from '../../../utils/http';
import {isChangeTab} from '../../../utils/utils';

export class ConclusionComponent extends React.Component<any,any>{
  _isMounted = false;
  constructor(props:{
    diagnosisId:number,
    diagnosisStatus:string
  }){
    super(props);

    this.state = {
      conclusionTabList:[],
      id:0,
    }
    this.conclusionList = this.conclusionList.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  changeTab(e:any,item:{id:number,name:string,key:number,disable:boolean}){
    if(!item.disable){
      this.setState({
        id:item.id
      });
    }
  }
  

  conclusionList(){
    this._isMounted = true;
    Http.get(`/diagnosis/handle/diagnosisConclusion/${this.props.diagnosisId}`).then((res:any)=>{
      if(res&&res.code===200){
        let arr:any = [];
        res.data.forEach((item:{id:number,name:string,key:number},index:number)=>{
          arr.push({
            key:index,
            id:item.id,
            name:item.name,
            disable:!isChangeTab(this.props.diagnosisStatus,index)
          });
        });
        if(this._isMounted){
          this.setState({
            conclusionTabList: arr,
            id:arr[0].disable?arr[1].id:arr[0].id
          });
          this.changeTab('',arr[0].disable?arr[1]:arr[0])
        }
        
      }
    })
  }

  componentDidMount(){
    this.conclusionList();
  }
  componentWillUnmount(){
    this._isMounted = false;
  }

  render(){
    let {conclusionTabList,id} = this.state;
    return(
      <div className="conclusion-container">
        <div className="conclusion-tab">
          {
            conclusionTabList.map((item:{key:number,id:number,name:string,disable:boolean},index:number)=>{
              return <div key={index} className={`conclusion-tab-item ${id===item.id?'active':''} ${item.disable?'disable-tab':''}`} onClick={e=>this.changeTab(e,item)} >
                {index===0?<><FileTextFilled style={{marginRight:'6px'}} />{item.name}</>:<><UserOutlined style={{marginRight:'6px'}} />{item.name}</>}
              </div>
            })
          }
        </div>
        <Alert message="查看企业填写情况，完成诊断意见的填写" type="warning" />
        <ConclusionFormComponent id={id} diagnosisId={this.props.diagnosisId} conclusionList={this.conclusionList} />
      </div>
    )
  }
}

export class ConclusionFormComponent extends React.Component<any,any>{
  _isMounted = false;
  constructor(props:{id:number,diagnosisId:number}){
    super(props);
    this.state = {
      isDisable:true,
      id:0
    }
    this.conclusionDetail = this.conclusionDetail.bind(this);
    this.sumbit = this.sumbit.bind(this);
    this.open = this.open.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
  }
  createForm = React.createRef<FormInstance>();

  open(index:any){
    let item = this.createForm.current?.getFieldValue('item');
    item[index].checked = !item[index].checked;
    this.createForm.current?.setFieldsValue({
      item:item
    })
  }

  componentWillReceiveProps(nextProps:any) {
    this.conclusionDetail(nextProps.id)
  }

  showConfirm(type:boolean) {
    if(type){
      let _that = this;
      Modal.confirm({
        title:'提交',
        icon: <ExclamationCircleOutlined />,
        content:'提交后不可编辑诊断结论与建议，是否提交？',
        onOk(){
          _that.sumbit(type);
        }
      })
    } else {
      this.sumbit(type);
    }
    
  }

  conclusionDetail(id?:number){
    this._isMounted = true;
    Http.get(`/diagnosis/handle/diagnosisConclusionDetail/${id}`).then((res:any)=>{
      if(res && res.code===200){
        if(this._isMounted){
          if(res.data.item&&res.data.item.length>0){
            res.data.item[0].checked = true;
          }
          this.createForm.current?.setFieldsValue({
            energyConsumption:res.data.energyConsumption,
            devicesUpgrade:res.data.factoryPotential?.devicesUpgrade,
            dyeEfficiencyUpgrade:res.data.factoryPotential?.dyeEfficiencyUpgrade,
            energyLostControl:res.data.factoryPotential?.energyLostControl,
            energyStructureUpgrade:res.data.factoryPotential?.energyStructureUpgrade,
            moldingEfficiencyUpgrade:res.data.factoryPotential?.moldingEfficiencyUpgrade,
            other:res.data.factoryPotential?.other,
            processUpgrade:res.data.factoryPotential?.processUpgrade,
            remark:res.data.remark,
            item:res.data.item || [{
              checked:true,
              content:'',
              effectTime:'',
              name:'',
              prospectEffect:0,
              prospectProfit:0,
              sum:0,
              type:'ENERGY_LOST_CONTROL'
            }]
          });
          this.setState({
            isDisable:res.data.canEdit==='T'?true:false
          })
        }
      }
    })
  }

  sumbit(type:boolean){
    this.createForm.current?.validateFields().then(values=>{
      let responseData = {
        diagnosisId:this.props.diagnosisId,
        energyConsumption:values.energyConsumption,
        factoryPotential:{
          devicesUpgrade:values.devicesUpgrade,
          dyeEfficiencyUpgrade:values.dyeEfficiencyUpgrade,
          energyLostControl:values.energyLostControl,
          energyStructureUpgrade:values.energyStructureUpgrade,
          moldingEfficiencyUpgrade:values.moldingEfficiencyUpgrade,
          other:values.other,
          processUpgrade:values.processUpgrade
        },
        item:values.item,
        remark:values.remark,
        submit:type?'T':'F'
      };
      Http.put('/diagnosis/expert/handle/onlineDiagnosis',responseData).then((res:any)=>{
        if(res&&res.code===200){
          message.success(type?'提交成功!':'保存成功!');
          // this.conclusionDetail(this.props.id);
          this.props.conclusionList();
        }
      })
    })
  }

  // componentDidMount(){
  //   this.conclusionDetail();
  // }

  componentWillUnmount(){
    this._isMounted = false;
  }

  render(){
    let {TextArea} = Input
    const { Option } = Select;
    let { isDisable } = this.state;
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product">
        <p className="form-title">企业概述</p>
        <div className="form-content">
          <Form.Item label="1）企业能源消费概况" name="energyConsumption">
            <TextArea disabled={!isDisable} placeholder="请输入" />
          </Form.Item>
        </div>
        <p className="form-title">1 全厂角度，全面分析企业能效提升和节能降耗的潜力。</p>
        <div className="form-content">
          <Form.Item label="1）能源损失控制、余热余能利用的节能潜力。" name="energyLostControl">
            <TextArea disabled={!isDisable} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="2）用能设备及变配电系统升级或运行优化控制的节能潜力。" name="devicesUpgrade">
            <TextArea disabled={!isDisable} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="3）能源结构调整、能源系统优化的节能潜力。" name="energyStructureUpgrade">
            <TextArea disabled={!isDisable} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="4）工艺流程优化、生产组织改进的节能潜力。" name="processUpgrade">
            <TextArea disabled={!isDisable} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="5）染色机能效提升潜力。" name="dyeEfficiencyUpgrade">
            <TextArea disabled={!isDisable} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="6）定型机热效率提升潜力。" name="moldingEfficiencyUpgrade">
            <TextArea disabled={!isDisable} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="7）其他节能潜力。" name="other">
            <TextArea disabled={!isDisable} placeholder="请输入" />
          </Form.Item>
        </div>
        <p className="form-title">2 从生产工艺、技术装备、系统优化、运行管理等方面提出节能改造建议，对各项改造措施的预期节能效果、经济效益和社会效益进行综合评</p>
        <div className="form-content">
          <div className="form-project-container">
          <Form.List name='item'>
            {(filelds,{add,remove})=>(
              <>
              <>
              {filelds.map((field,index) => (
                <div key={field.key} className="form-project-container-item">
                  <div className="form-project-container-item-head">
                    <div className="form-peoject-container-item-head-name"><AppstoreFilled style={{marginRight:'8px',color:'#00A566'}} />项目{index + 1} {isDisable?filelds.length>1?<DeleteOutlined disabled={!isDisable} onClick={()=>remove(field.name)} />:<></>:<></>}</div>
                    <div className="form-peoject-container-item-head-translate" onClick={e=>this.open(index)}>
                     {this.createForm.current?.getFieldValue('item')[index].checked?<>收起 <UpOutlined style={{color:'#00A566'}} /></>:<>展开 <DownOutlined style={{color:'#00A566'}} /></>} </div>
                  </div>
                  {this.createForm.current?.getFieldValue('item')[index].checked?
                  <div className="form-project-container-item-body">
                    <Row>
                      <Col span={11}>
                        <Form.Item label="项目名称" name={[field.name,'name']}>
                          <Input disabled={!isDisable} placeholder="请输入" />
                        </Form.Item>
                      </Col>
                      <Col offset={2} span={11}>
                        <Form.Item label="建议类型" name={[field.name,'type']}>
                          <Select disabled={!isDisable} style={{ width: '100%' }}>
                            <Option value="ENERGY_LOST_CONTROL">能源损失控制与余热余能利用</Option>
                            <Option value="DEVICES_UPGRADE">用能设备升级及运行优化控制</Option>
                            <Option value="ENERGY_MANAGER_UPGRADE">能源管理体系完善及措施改进</Option>
                            <Option value="PROCESS_UPGRADE">工艺流程优化与生产组织改进</Option>
                            <Option value="ENERGY_STRUCTURE_UPGRADE">能源结构调整与能源系统优化</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="主要内容" name={[field.name,'content']}>
                          <TextArea disabled={!isDisable} placeholder="请输入" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={11}>
                        <Form.Item label="预计总投资（单位：万元）" name={[field.name,'sum']}>
                          <InputNumber style={{width:'100%'}} disabled={!isDisable} placeholder="请输入" />
                        </Form.Item>
                      </Col>
                      <Col offset={2} span={11}>
                        <Form.Item label="预期节能效果（单位：万吨标准煤/年）" name={[field.name,'prospectEffect']}>
                          <InputNumber style={{width:'100%'}} disabled={!isDisable} placeholder="请输入" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={11}>
                        <Form.Item label="预期经济效益（单位：万元/年）" name={[field.name,'prospectProfit']} >
                          <InputNumber style={{width:'100%'}} disabled={!isDisable} placeholder="请输入" />
                        </Form.Item>
                      </Col>
                      <Col offset={2} span={11}>
                        <Form.Item label="建议实施时间" name={[field.name,'effectTime']}>
                          <Select disabled={!isDisable} style={{ width: '100%' }}>
                            <Option value="一年内">一年内</Option>
                            <Option value="两年内">两年内</Option>
                            <Option value="三年内">三年内</Option>
                            <Option value="其它">其它</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>:<></>}
                </div>
              ))}
              </>
              {isDisable?<Button size="middle" disabled={!isDisable} onClick={()=>add({
                checked:false,
                content:'',
                effectTime:'',
                name:'',
                prospectEffect:0,
                prospectProfit:0,
                sum:0,
                type:'ENERGY_LOST_CONTROL'
              })} className="form-project-add-btn" icon={<PlusOutlined />} > 添加项目</Button>:<></>}
              
              </>
            )}
          </Form.List>
          </div>
        </div>
        <p className="form-title">3 补充说明（着重提出的建议）</p>
        <Form.Item name="remark">
          <TextArea disabled={!isDisable} placeholder="请输入" />
        </Form.Item>
        {isDisable?<div className="form-btn-container">
          <Space size='middle'>
            <Button onClick={e=>this.showConfirm(false)} className="form-btn" type="primary">保存</Button>
            <Button onClick={e=>this.showConfirm(true)} className="form-btn" type="primary">提交</Button>
          </Space>
        </div>:''}
        {/* <div className="form-btn-container">
          <Space size='middle'>
            <Button disabled={!isDisable} onClick={e=>this.sumbit(false)} className="form-btn" type="primary">保存</Button>
            <Button disabled={!isDisable} onClick={e=>this.sumbit(true)} className="form-btn" type="primary">提交</Button>
          </Space>
        </div> */}
      </Form>
    )
  }
}