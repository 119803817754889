import './basic-requirements.scss';
import React, { useState, useEffect, useCallback, Component } from 'react';
import { Button, Anchor, Divider, Radio, Upload, message, Input } from 'antd';
import { waterEditAndRead, verificationType } from '../../../../utils/utils';
import http from '../../../../utils/http';
import { UploadOutlined } from '@ant-design/icons';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';
import * as uniqid from 'uniqid';
import cookie from "react-cookies";
import { ExpertScore } from './../common/expert-score'
import { forEachChildren } from './../common/forEachChildren'

interface BasicRequireInfo {
    key: number
    ruleId: number//条目id
    itemId: number
    name: string//标题
    declare: string//填报说明
    gist: string//考核依据
    flag: number//选择的标签
    optionList: Array<SubjectOption>//选项
    children: Array<BasicRequireInfo>
    files: Array<any>
    flags: Array<any>
    maxScore?: number
}

interface Fileitems {
    fileId: number
    showName: string
}

interface SubjectOption {
    flag: number//标识
    name: string//显示名称
    value: number
}

interface SelectItem {
    files: Array<any>
    flag: number
    itemId: number
}

const BasicRequirements = (props: any) => {

    const { Link } = Anchor;
    const [readAndEdit, setReadAndEdit] = useState<boolean>(true);
    const [listData, setListData] = useState<Array<BasicRequireInfo>>([]);
    const [selectData, setSelectData] = useState<Array<SelectItem>>([]);


    const getListData = useCallback(() => {
        if (props.proType === 'yuanqu') {
            http.get('/apiWaterParkUrl/assess/rules/item/list/' + props.id, {
                type: 'BASIC'
            }).then((res: any) => {
                if (res && res.code === 200) {

                    res.data.forEach((i: BasicRequireInfo, index: number) => {
                        i.key = index;
                        i.children.forEach((k: BasicRequireInfo) => {
                            if (k.flags != null) {
                                k.flag = k.flags[0]
                            }
                            if (k.files && k.files.length > 0) {
                                let imgArr: any = [];
                                k.files.forEach((t: any, index: number) => {
                                    let img = {
                                        uid: index,
                                        name: t.showName,
                                        status: 'done',
                                        id: t.fileId,
                                        url: ''
                                    };
                                    imgArr.push(img);
                                })
                                k.files = imgArr;
                            } else {
                                k.files = [];
                            }
                        })
                    });
                    setListData(res.data);
                }
            })
        } else {
            http.get('/apiWaterUrl/assess/rules/item/list/' + props.id, {
                type: 'BASIC'
            }).then((res: any) => {
                if (res && res.code === 200) {

                    res.data.forEach((i: BasicRequireInfo, index: number) => {
                        i.key = index;
                        i.children.forEach((k: BasicRequireInfo) => {
                            if (k.files && k.files.length > 0) {
                                let imgArr: any = [];
                                k.files.forEach((t: any, index: number) => {
                                    let img = {
                                        uid: index,
                                        name: t.showName,
                                        status: 'done',
                                        id: t.fileId,
                                        url: ''
                                    };
                                    imgArr.push(img);
                                })
                                k.files = imgArr;
                            } else {
                                k.files = [];
                            }
                        })
                    });
                    setListData(res.data);
                }
            })
        }
    }, [props])

    useEffect(() => {
        setReadAndEdit(waterEditAndRead());
        getListData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveData = () => {
        let arr: any[] = [];
        let isModify = true;
        let isAllow = false;
        listData.forEach((i: BasicRequireInfo) => {
            i.children.forEach((element: BasicRequireInfo) => {
                let files: any[] = [];
                let flags: any[] = [];
                let data: any = {};
                // if(element.ruleId){
                //   isModify = true;
                // }
                if (element.files.length > 0) {
                    element.files.forEach((k: any) => {
                        files.push({
                            fileId: k.id,
                            showName: k.name
                        });
                    })
                }
                if (props.proType === 'yuanqu') {
                    element.optionList.forEach((k: any) => {
                        if (element.flag === k.flag) {
                            flags.push(k.flag);
                        }

                    })
                    data = {
                        ruleId: element.ruleId,
                        flags: flags,
                        itemId: element.itemId,
                        files: files,
                        flag: element.flag,
                    };
                } else {
                    data = {
                        ruleId: element.ruleId,
                        flag: element.flag,
                        itemId: element.itemId,
                        files: files
                    };
                }
                if (data.flag === 1 && !data.files.length) {

                    isAllow = true;
                }


                arr.push(data);

            })
        });
        if (isAllow) {
            message.error('选择符合时，请上传文件');
            return;
        }
        if (isModify) {
            if (props.proType === 'yuanqu') {
                http.put('/apiWaterParkUrl/assess/rules?assessId=' + props.id, arr).then((res: any) => {
                    if (res.code === 200) {
                        message.success('修改成功！');
                        getListData();
                    } else {
                        message.error(res.message);
                    }
                })
            } else {
                http.put('/apiWaterUrl/assess/rules?assessId=' + props.id, arr).then((res: any) => {
                    if (res.code === 200) {
                        message.success('修改成功！');
                        getListData();
                    } else {
                        message.error(res.message);
                    }
                })
            }
        }
    }

    // const forEachChildren = (items:Array<BasicRequireInfo>,pIndex:number) => {
    //   const userInfo = cookie.load('faisUserInfo')
    //   const role =  userInfo.roleIds[0]
    //   return items.map((item:BasicRequireInfo,index:any) =>
    //     <div key={uniqid.default()}>
    //       <div className="expert-score-group" >
    //         {index+1}. {item.name}
    //         { role === 2 &&
    //           <ExpertScore id={props.id} item={item} ></ExpertScore>
    //         }
    //
    //       </div>
    //       <div className="basic-requirement-container-left-small">
    //         <img src="/assets/images/icon_prompt_normal.png" alt=""/><span className="small-span">{item.declare}</span>
    //       </div>
    //       <div className="basic-requirement-container-left-small">
    //         <img src="/assets/images/icon_prompt_normal.png" alt=""/><span className="small-span">{item.gist}</span>
    //       </div>
    //       <div className="basic-requirement-container-left-small" style={{margin: '8px 0px'}}>
    //         <OptionsComponent listData={listData} index={pIndex} childrenIndex={index} item={item} readAndEdit={readAndEdit}></OptionsComponent>
    //         <Divider type="vertical" />
    //         <UploadComponent listData={listData} readAndEdit={readAndEdit} fileIndex={pIndex} fileChildrenIndex={index} item={item}></UploadComponent>
    //       </div>
    //       <Divider></Divider>
    //     </div>
    //   )
    // }

    const dataDom = () => {
        if (listData.length > 0) {
            return listData.map((item: BasicRequireInfo, index: any) =>
                listData.length === 1 ?
                    <div key={uniqid.default()}>
                        {forEachChildren(item.children, index, props.id, props.proType, listData, readAndEdit, false)}
                    </div>
                    :
                    <div className="energy-management-container-left-item" id={index + 1} key={uniqid.default()}>
                        <p className="energy-management-container-left-item-title">{index + 1}、{item.name}</p>
                    </div>
            )
        } else {
            return <Divider></Divider>
        }
    }

    return (
        <>
            <div className="basic-requirement-container">
                <div className="basic-requirement-container-left" id="center">
                    {dataDom()}
                    {readAndEdit ?
                        <div className="basic-requirement-foot">
                            <Button className="green-button" onClick={saveData}>保存</Button>
                            {props.proType === 'yuanqu' ? "" : <Button type="default" onClick={props.nextCurrent}>下一步</Button>}
                        </div>
                        : ''}

                </div>
            </div>
        </>
    )
}

export class OptionsComponent extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            listData: this.props.listData,
            index: this.props.index,
            childrenIndex: this.props.childrenIndex,
            item: this.props.item,
            readAndEdit: this.props.readAndEdit
        }
        this.onChange = this.onChange.bind(this);
    }
    onChange(e: any, pIndex: any, index: any) {

        let { listData } = this.state;
        listData[pIndex].children[index].flag = e.target.value;
        this.setState({
            listData: listData
        })
    }

    render() {
        const { readAndEdit, index, childrenIndex, item } = this.state;
        return (
            <Radio.Group disabled={!readAndEdit} value={item.flag} onChange={(e: any) => this.onChange(e, index, childrenIndex)}>
                {
                    item.optionList.map((i: any, num: any) => <Radio value={i.flag} key={uniqid.default()}>{i.name}</Radio>)
                }
            </Radio.Group>
        )
    }
}

// export class ExpertScore extends React.Component<any,any>{
//   constructor(props:any) {
//     super(props);
//     this.state = {
//       item:this.props.item,
//       score:this.props.item.expertScore,
//       isDisable: this.props.item.score
//     }
//     this.onBlur = this.onBlur.bind(this)
//   }
//
//   onBlur(){
//     const data = {
//       score: Number(this.state.score),
//       assessId:this.props.id,
//       itemId:this.state.item.itemId
//     }
//
//     if( data.score > this.state.item.maxScore || data.score < 0) {
//       message.info('请输入正确的评分')
//       this.setState({score:null})
//       return
//     }
//
//     http.put(`/apiWaterUrl/assess/rules/score`,data).then((res:any)=>{
//       if(res.code === 200){
//         this.setState({isDisable:true})
//         message.success('保存成功！');
//       }
//     })
//   }
//
//   render(){
//     const { item , score} = this.state;
//     return(
//         <Input  placeholder="请输入您的评分"
//                 type={"number"}
//                 max={item.maxScore}
//                 min={0}
//                 value={score}
//                 addonBefore={'共'+item.maxScore + '分'}
//                 addonAfter={'分'}
//                 onChange={(e)=>{this.setState({ score: e.target.value })}}
//                 onBlur={this.onBlur}
//         ></Input>
//     )
//   }
// }



export class UploadComponent extends React.Component<any, any>{
    upLoad: any;
    constructor(props: any) {
        super(props);
        this.state = {
            value: 0,
            listData: this.props.listData,
            fileList: [],
            fileIndex: this.props.fileIndex,
            fileChildrenIndex: this.props.fileChildrenIndex,
            readAndEdit: this.props.readAndEdit,
            item: this.props.item
        }
        this.upLoad = {
            beforeUpload: (file: any) => {
                if (verificationType(file.type)) {
                    this.fileUpload(file);
                } else {
                    message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
                }
                return false;
            },
            onRemove: (file: any) => {
                let { listData, fileIndex, fileChildrenIndex } = this.state;
                listData[fileIndex].children[fileChildrenIndex].files = listData[fileIndex].children[fileChildrenIndex].files.filter((i: any) => i['uid'] !== file.uid);
                this.setState({ listData });
                return false;
            },
            onPreview: (file: any) => {
                store.dispatch({ type: OPENIMG, imgId: file.id, imgName: file.name });
                return false;
            }
        };
        this.clickUpload = this.clickUpload.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
    }

    fileUpload(file: any) {
        const formData = new FormData();
        formData.append('file', file);
        http.post('/system/file/upload/auth', formData).then((res: any) => {
            if (res && res.code === 200) {
                let img = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    id: res.data,
                    url: ''
                };
                let { listData, fileIndex, fileChildrenIndex } = this.state;
                listData[fileIndex].children[fileChildrenIndex].files = [...listData[fileIndex].children[fileChildrenIndex].files, img];
                this.setState({
                    listData: listData
                })
            }
        })
    }

    clickUpload(index: number, childrenIndex: number) {
        this.setState({
            fileIndex: index,
            fileChildrenIndex: childrenIndex
        })
    }

    componentDidMount() {
        // this.setState({
        //   readAndEdit:waterEditAndRead()
        // })
    }

    render() {
        const { readAndEdit, fileIndex, fileChildrenIndex, item } = this.state;
        // @ts-ignore
        return (
            <Upload disabled={!readAndEdit} {...this.upLoad} fileList={item.files} >
                <Button style={{ marginLeft: '20px' }} size="small" onClick={e => this.clickUpload(fileIndex, fileChildrenIndex)}>
                    <UploadOutlined />上传文件
                </Button>（单个文件大小不超过20M）
            </Upload>
        )
    }
}

export default BasicRequirements;