import React from 'react';
import {Button, Table, Select, Alert } from 'antd';
import Http from '../../../../utils/http';
import './resultsDiagnosis.scss';

export class ResultsDiagnosis extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    this.state = {
      dataList:[],
      dyeingList:[],
      fixedList:[],
      tips:[]
    }
    this.downFile = this.downFile.bind(this);
    this.downZip = this.downZip.bind(this);
    this.viewFile = this.viewFile.bind(this);
    this.calculation = this.calculation.bind(this);
    this.calculationList = this.calculationList.bind(this);
    this.calculationFixedList = this.calculationFixedList.bind(this);
    this.calculationTips = this.calculationTips.bind(this);
  }
  viewFile(){
    Http.get('/document/'+this.props.id+'/view/code').then((res:any)=>{
      let url = Http.getUrl();
      window.open(url+'/document/'+res.data+'/view');
    })
  }
  calculation(){
    Http.post('/diagnosis/energy/calculation/'+this.props.id+'?cateId=1').then((res:any)=>{
      if(res&&res.code===200){
        this.calculationTips();
        this.calculationList();
      }
    })
  }
  calculationTips(){
    Http.get('/diagnosis/energy/calculation/tips/'+this.props.id).then((res:any)=>{
      if(res&&res.code===200){
        this.setState({
          tips:res.data.tips
        })
      }
    })
  }
  downFile(){
    Http.downFile('/document/'+this.props.id+'/download');
  }
  downZip(){
    Http.downZip('/system/allFile/download?diagnosisId='+this.props.id,'节能诊断附件');
  }
  calculationList(){
    Http.get('/diagnosis/energy/calculation/list/'+this.props.id).then((res:any)=>{
      if(res&&res.code===200){
        res.data.forEach((i:any,index:number)=>{
          i.key = index + 1;
        })
        this.setState({
          dataList:res.data
        })
      }
    })
  }
  calculationFixedList(){
    Http.get('/diagnosis/dyeing/fixed/calculation/list',{diagnosisId:this.props.id}).then((res:any)=>{
      if(res&&res.code===200){
        if(res.data){
          res.data['DYEING'].forEach((i:any,index:number)=>{
            i.key=index + 1;
          });
          res.data['FIXED'].forEach((i:any,index:number)=>{
            i.key=index + 1;
          });
          this.setState({
            dyeingList:res.data['DYEING'],
            fixedList:res.data['FIXED']
          })
        }
      }
    })
  }
  componentDidMount(){
    this.calculationList();
    this.calculationTips();
    this.calculationFixedList();
  }
  render(){
    const {Option} = Select;
    const {dataList,dyeingList,fixedList,tips} = this.state;
    const table1Columns = [
      {
        title: '项目名称',
        dataIndex: 'itemName',
        key: 'itemName',
      },
      {
        title: '数量',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        key: 'unitName',
      },{
        title: '对标结果',
        dataIndex: 'result',
        key: 'result',
        render:(text:string,record:any)=>(
        <span>{record.datumsName}{text==='UTS'?'达标':text==='ETS'?'超标':'暂无结果'}</span>
        )
      }
    ];
    const table2Columns = [
      {
        title: '批次',
        dataIndex: 'key',
        key: 'key',
        render:(text:string)=>(
          <span>批次{text}</span>
        )
      },
      {
        title: '单位产品综合能耗',
        dataIndex: 'uec',
        key: 'uec',
      },
      {
        title: '单位',
        dataIndex: 'unit',
        key: 'unit',
        render:(text:string)=>(
          <span>kgce/t</span>
        )
      },{
        title: '对标结果',
        dataIndex: 'result',
        key: 'result',
      }
    ];
    let tipsList = tips.map((i:string,index:number)=>{
      return <Alert key={index} message={i} style={{marginBottom:'10px'}} type="error" />
    })
    return(
      <div className="result-container">
        <Alert message="提交诊断前可修改企业及能耗信息，修改后请重新计算，并可导出新的报告" style={{marginBottom:'10px'}} type="info" />
        <div className="result-calculation">
          <div>
            选择对标基准:<Select value="1" style={{width:'150px'}}>
              <Option value="1">行业平均值</Option>
            </Select>
          </div>
          <div>
            <Button className="green-button" onClick={this.calculation}>计算</Button>
          </div>
        </div>
        <div className="table-container" style={{padding:'0',paddingTop:'10px'}}>
          {tipsList}
          <div className="table-header">
            <div className="table-title">一、全厂能耗计算结果</div>
          </div>
          <Table
            columns={table1Columns}
            dataSource={dataList}
            pagination={false}
            size="small"
            rowClassName={(record)=>{
              let className='';
              if(record.result==='UTS'){
                className='row-uts';
              } else if(record.result==='ETS') {
                className='row-ets';
              } else {
                className='';
              }
              return className;
            }}
          />
        </div>
        <div className="table-container" style={{padding:'0'}}>
          <div className="table-header">
            <div className="table-title">二、专项诊断能耗计算结果</div>
          </div>
          <p>1、染色工序</p>
          <Table
            columns={table2Columns}
            dataSource={dyeingList}
            pagination={false}
            size="small"
            style={{marginBottom:'24px'}}
            rowClassName={(record)=>{
              let className='';
              if(record.result==='UTS'){
                className='row-uts';
              } else if(record.result==='ETS') {
                className='row-ets';
              } else {
                className='';
              }
              return className;
            }}
          />
          <p>2、定形工序</p>
          <Table
            columns={table2Columns}
            dataSource={fixedList}
            pagination={false}
            size="small"
          />
        </div>
        <div className="button-container">
          <Button className="green-button" onClick={this.viewFile} style={{marginRight:'20px'}}>预览报告</Button>
          <Button className="green-button" onClick={this.downFile} style={{marginRight:'20px'}}>导出报告</Button>
          <Button className="green-button" onClick={this.downZip}>下载节能诊断附件</Button>
        </div>
      </div>
    )
  }
}

export default ResultsDiagnosis;