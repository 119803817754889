import React from 'react';
import {Input, Select, Form, Row, Col, Button, Upload, message, InputNumber, Divider } from 'antd';
import Http from '../../../../utils/http';
import { FormInstance } from 'antd/lib/form';
import unitList from '../../../../utils/unit.json';
import { DownOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';
import { adminEditAndRead, verificationType } from '../../../../utils/utils';

export class DyeingProduct extends React.Component<any,any>{
  upLoad: any;
  fileupLoad: any;
  unitList: Array<any>;
  constructor(props:any){
    super(props);
    this.state={
      fileList:[],
      isShow:false,
      isOpen:true,
      readAndEdit:true
    }
    this.upLoad = {
      beforeUpload: (file: any) => {
        if(verificationType(file.type)){
          this.fileUpload(file);
        } else {
          message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
        }
        return false;
      },
      onRemove: (file: { uid: any; })=>{
        let {fileList} = this.state;
        fileList = fileList.filter((i: { [x: string]: any; })=> i['uid'] !== file.uid);
        this.setState({fileList});
        return false;
      },
      onPreview:(file:any)=>{
        store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name})
        return false;
      }
    };
    this.unitList = unitList;
    this.changeWay = this.changeWay.bind(this);
    this.getData = this.getData.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.changeOpen = this.changeOpen.bind(this);
  }
  createForm = React.createRef<FormInstance>();
  changeOpen(){
    let status = this.state.isOpen;
    this.setState({
      isOpen: !status
    })
  };
  fileUpload(file:any){
    const formData = new FormData();
    formData.append('file', file);
    Http.post('/system/file/upload/auth',formData).then((res:any)=>{
      if(res&&res.code===200){
        let img = {
          uid:file.uid,
          name:file.name,
          status:'done',
          id:res.data,
          url:''
        };
        this.setState({
          fileList:[...this.state.fileList,img]
        })
      }
    })
  }
  changeWay(e:any){
    if(e==='纱线染色'){
      this.setState({
        isShow:true
      })
    } else {
      this.setState({
        isShow:false
      })
    }
  }
  getData(value:any){
    let fileArr:any[] = [];
    this.state.fileList.forEach((i:any)=>{
      fileArr.push({
        fileId:i.id,
        showName:i.name
      });
    })
    value.files = fileArr;
    Http.put('/diagnosis/dyeing/fixed/'+this.props.id,value).then((res:any)=>{
      if(res&&res.code===200){
        message.success('修改完成');
        this.props.refresh(this.props.id);
      }
    })
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    });
    this.createForm.current?.setFieldsValue(this.props.product);
    let fileList:any[] = [];
    this.props.product.files?.forEach((i:any,index:number)=>{
      let img = {
        uid:index,
        name:i.showName,
        status:'done',
        id:i.fileId,
        url:''
      };
      fileList.push(img);
    });
    if(fileList.length>0){
      this.setState({
        fileList:fileList
      });
    }
    if(this.props.product.way){
      this.changeWay(this.props.product.way);
    }
  }
  render(){
    const {Option} = Select;
    const {fileList,isShow,isOpen,readAndEdit} = this.state;
    const standardYield = this.createForm.current?.getFieldValue('standardYield');
    const standardUnit = this.createForm.current?.getFieldValue('standardUnit');
    const unit = this.unitList.map(i=>{
      return <Option disabled={i.key==='t'||i.key==='kg'||i.key==='g'?false:true} key={i.key} value={i.key}>{i.name}</Option>;
    });
    return(
      <div style={{marginBottom:'20px'}}>
      <div className="item-title-container">
        <div className="item-title">
          <img src="/assets/images/icon1.png" alt='' ></img>
          <span>产品信息</span>
        </div>
        <div className="change-open" onClick={this.changeOpen}>
          {isOpen?<><span>收起</span><UpOutlined /></>:<><span>展开</span><DownOutlined /></>}
          </div>
      </div>
      {isOpen?<><Form style={{paddingLeft:'26px',marginTop:'32px'}} layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="产品名称" name="productName" rules={[{required: true}]}>
              <Input placeholder="请输入产品名称" disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="产品产量" rules={[{required: true}]}>
              <Input.Group>
                <Row style={{display:'flex',alignItems:'center'}}>
                  <Col span={9}>
                    <Form.Item name="yield" style={{marginBottom:0}} rules={[{required: true}]}>
                      <InputNumber style={{width:'100%'}} placeholder="数值" disabled={!readAndEdit} />
                    </Form.Item>
                  </Col>
                  <Col span={1} style={{textAlign:'center'}} > - </Col>
                  <Col span={14}>
                    <Form.Item name="unit" style={{marginBottom:0}} rules={[{required: true}]}>
                      <Select style={{ width: '100%' }} placeholder="单位" disabled={!readAndEdit} >
                        {unit}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          
          <Col span={12}>
            <Form.Item label="纤维组分" name="attr1" rules={[{required: true}]}>
              <Select disabled={!readAndEdit} style={{ width: '100%' }} placeholder="纤维组分" >
                <Option value="棉型">棉型</Option>
                <Option value="合成纤维">合成纤维</Option>
                <Option value="混纺">混纺</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="印染方式" name="way" rules={[{required: true}]}>
              <Select disabled={!readAndEdit} style={{ width: '100%' }} placeholder="印染方式" onChange={this.changeWay} >
                <Option value='纱线染色'>纱线染色</Option>
                <Option value='针织布染色'>针织布染色</Option>
              </Select>
            </Form.Item>
          </Col>
          {
            this.props.type===0?<>
            <Col span={12}>
              <Form.Item label="染色深浅" name="attr3" rules={[{required: true}]}>
                <Select disabled={!readAndEdit} style={{ width: '100%' }} placeholder="染色深浅" >
                  <Option value='深色'>深色（染料分量＞2.0%）</Option>
                  <Option value='中浅'>中浅（0.5%＜染料分量≤2.0%）</Option>
                  <Option value='漂白'>漂白（染料分量≤0.5%）</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="染料类型" name="dyeType" rules={[{required: true}]}>
                <Select disabled={!readAndEdit} style={{ width: '100%' }} placeholder="染料类型" >
                  <Option value='活性染料'>活性染料</Option>
                  <Option value='分散染料'>分散染料</Option>
                  <Option value='双性染料'>双性染料</Option>
                  <Option value='接染料'>接染料</Option>
                  <Option value='还原染料'>还原染料</Option>
                  <Option value='硫化染料'>硫化染料</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="染料重量（kg）" name="dyeWeight" rules={[{required: true}]}>
                <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="纱线支数（S）" name="yarnCount" rules={[{required: true}]}>
                <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="织物克重（g/m2）" name="gramWeight" rules={[{required: true}]}>
                <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
              </Form.Item>
            </Col></>:<><Col span={12}>
              <Form.Item label="克重大小" name="gramWeightItem" rules={[{required: true}]}>
                <Select disabled={!readAndEdit} style={{ width: '100%' }} placeholder="克重大小" >
                  <Option value='HUGE'>特大（克重＞200g/m2）</Option>
                  <Option value='BIG'>大（175g/m2＜克重≤200g/m2）</Option>
                  <Option value='MEDIUM'>中（150g/m2＜克重≤175g/m2）</Option>
                  <Option value='SMALL'>小（克重≤150g/m2）</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="织物克重（g/m2）" name="gramWeight" rules={[{required: true}]}>
                <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="织物含水率（%）" name="waterContent" rules={[{required: true}]}>
                <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="产品品质要求" name="req" rules={[{required: true}]}>
                <Input disabled={!readAndEdit} />
              </Form.Item>
            </Col></>
          }
            {isShow?<Col span={12}>
              <Form.Item label="标准品产量(单位)" rules={[{required: true}]}>
                <Input.Group>
                  <Row style={{display:'flex',alignItems:'center'}}>
                    <Col span={9}>
                      <Form.Item name="standardYield" style={{marginBottom:0}} rules={[{required: true}]}>
                        <InputNumber style={{width:'100%'}} placeholder="数值" disabled={!readAndEdit} />
                      </Form.Item>
                    </Col>
                    <Col span={1} style={{textAlign:'center'}}> - </Col>
                    <Col span={14}>
                      <Form.Item name="standardUnit" style={{marginBottom:0}} rules={[{required: true}]}>
                        <Select style={{ width: '100%' }} placeholder="单位" disabled={!readAndEdit} >
                          {unit}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
                {standardYield&&standardUnit?'':<p style={{fontSize:'12px',color:'#72986F'}}>请按《FZ/T 01002- 2010印染企业综合能耗计算办法及基本定额》填写合格 品和标准品的产量。</p>}
              </Form.Item>
            </Col>:''}
            <Col span={12}>
              <Form.Item label="上传附件(支持.xls .xlsx .doc .docx .pdf .jpg格式)">
                <Upload disabled={!readAndEdit} {...this.upLoad} fileList={fileList} style={{width:'100%'}}>
                  <Button style={{width:'100%'}}>
                    <UploadOutlined />点击上传
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={3}>
              {readAndEdit?<Button className="green-button" htmlType="submit">保存</Button>:''}
              
            </Col>
          </Row>
      </Form></>:''}
      <Divider />
    </div>
    )
  }
}
export default DyeingProduct;