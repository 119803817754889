import './dataStatistics.scss';
import '../../../../utils/util-class.scss';
import React,{ useState, useEffect  } from 'react';
import http from '../../../../utils/http';
import { Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import { dateFormat } from '../../../../utils/utils';


const DataStatistics = (props:any)=>{
  const [checkedList, setCheckedList] = React.useState(['H']);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const [dates,setDates] = React.useState(new Date('2020'));

  const options = [
    { label: '能源管理制度', value: 'A' },
    { label: '节能技术应用与标准参编', value: 'B' },
    { label: '能源计量器具配备', value: 'C' },
    { label: '落后工艺设备', value: 'D' },
    { label: '机电设备信息', value: 'E' },
    { label: '染色工序专项诊断', value: 'F' },
    { label: '定形工序专项诊断', value: 'G' },
    { label: '全厂能耗结果', value: 'H' },
  ];

  const onChange = (list:any) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e:any) => {
    let arr = options.map(i=> i.value);
    setCheckedList(e.target.checked ? arr : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const changeData = (e:any) =>{
    setDates(e);
  }

  const exportClick = () => {
    http.downZip(`/system/data/file/download?period=${dateFormat(new Date(dates),'YYYY')}&types=${checkedList}`,'FAIS系统统计数据');
  }
  
  return(<>
    <div className="diagnosis-list">
      <h1>数据统计</h1>
      <div className="margin-top-31">
        <div className="margin-bottom-12">请选择日期</div>
        <DatePicker picker='year' value={moment(dates)} onChange={changeData} />
      </div>
      <div className="margin-top-28">
        请选择报表<span className="padding-left-20"><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>全选</Checkbox></span>
      </div>
      <div className="width-500">
        <Checkbox.Group options={options} value={checkedList} onChange={onChange} />
      </div>
      <div className="div-flex-500 justify-content-flex-end margin-top-42">
        <div className="export-buttom" onClick={exportClick}>导出</div>
      </div>

    </div>
  </>)
}

export default DataStatistics;