import React from 'react';
import { Steps, Divider, message } from 'antd';
import { FormOutlined, EditOutlined, CheckCircleOutlined, ContainerOutlined } from '@ant-design/icons';
import './diagnosis-process.scss';
import {EnergySaving} from '../../common/diagnosis/information/energySaving/energySaving';
import {EnergySavingInformation} from '../../common/diagnosis/information/energySavingInformation/energySavingInformation';
import {EnterpriseInformation} from '../../common/diagnosis/information/enterpriseInformation/enterpriseInformation';
// import {SystemBoundary} from '../../common/diagnosis/information/systemBoundary/systemBoundary';
import {EnergyManagement} from '../../common/diagnosis/indicators/energyManagement/energyManagement';
import {ApplicationEnergy} from '../../common/diagnosis/indicators/applicationEnergy/applicationEnergy';
import {Standard} from '../../common/diagnosis/indicators/standard/standard';
import {EnergyMeasurement} from '../../common/diagnosis/indicators/energyMeasurement/energyMeasurement';
import {Backwardness} from '../../common/diagnosis/indicators/backwardness/backwardness';
import {HighConsumptionBackwardness} from '../../common/diagnosis/indicators/highConsumptionBackwardness/highConsumptionBackwardness';
import {EnergyConsumption} from '../../common/diagnosis/allPlantInformation/energyConsumption/energyConsumption';
import {WorkingFluidConsumption} from '../../common/diagnosis/allPlantInformation/workingFluidConsumption/workingFluidConsumption';
import {WasteHeat} from '../../common/diagnosis/allPlantInformation/wasteHeat/wasteHeat';
import {Dyeing} from '../../common/diagnosis/specialDiagnosis/dyeing/dyeing';
import {Fixed} from '../../common/diagnosis/specialDiagnosis/fixed/fixed';
import {ResultsDiagnosis} from '../../common/diagnosis/resultsDiagnosis/resultsDiagnosis/resultsDiagnosis';
import {ResultsEnergyConsumption} from '../../common/diagnosis/resultsDiagnosis/resultsEnergyConsumption/resultsEnergyConsumption';
// import {UnitOutPut} from '../../common/diagnosis/itemConsumption/unitOutput/unitOutPut';
import {AnnualProcess} from '../../common/diagnosis/itemConsumption/annualProcess/annualProcess';
import {EnterpriseTechnology} from '../../common/diagnosis/itemConsumption/enterpriseTechnology/enterpriseTechnology';
import {ConclusionComponent} from '../../common/diagnosis/conclusion/conclusion';
import http from '../../utils/http';
import cookie from 'react-cookies';
import {isExport,conclusionDisble,handleStatisticsData,statistics,statosticsUrlName} from '../../utils/utils';
import ModalEchants from '../../common/modalEcharts/modal-echarts';
import EnterPriseLevelDisplay from '../../common/diagnosis/resultsDiagnosis/enterpriseLevelDisplay/enterpriseLevelDisplay';


const { Step } = Steps;

export class DiagnosisProcess extends React.Component<any,any>{
  
  constructor(props:any){
    super(props);
    this.state = {
      bigCurrent: Number(localStorage.getItem('big'))?Number(localStorage.getItem('big')):0,
      current:Number(localStorage.getItem('current'))?Number(localStorage.getItem('current')):0,
      name:localStorage.getItem('name')?localStorage.getItem('name'):'基本信息',
      exports:false,
      editableDisable:false,
      diagnosisStatu:'',
      conDisable:false,
      isShowChart:false,
      chartData:[],
      echartIndex:0 // 0表示只有单张图 1表示多张图 2表示多个层次的多张或单张图
    };
    this.nextCurrent = this.nextCurrent.bind(this);
    this.editable = this.editable.bind(this);
    this.conclusion = this.conclusion.bind(this);
    this.showEchart = this.showEchart.bind(this);
    this.getStaticData = this.getStaticData.bind(this);
  }

  editable(){
    http.get('/diagnosis/'+this.props.id+'/editable').then((res:any)=>{
      if(res&&res.code===200){
        this.setState({
          editableDisable:res.data==='T'?true:false
        })
        cookie.save('editAndRead',res.data,{path:'/'});
      }
    })
  }
  
  nextCurrent(){
    let current = this.state.current+1;
    this.onChange(current);
  }

  onChangeBig = (bigCurrent: any) => {
    let name = ''
    switch(bigCurrent){
      case 0:
        name='基本信息';
        break;
      case 1:
        name="基础指标";
        break;
      case 2:
        name="全厂能耗信息";
        break;
      case 3:
        name="分项能耗信息";
        break;
      case 4:
        name="专项诊断";
        break;
      case 5:
        name="诊断结果";
        break;
      case 6:
        name="诊断结论";
        break;
      default:
        name="诊断结果";
        break;
    };
    localStorage.setItem('big',bigCurrent);
    localStorage.setItem('name',name);
    localStorage.removeItem('current');
    this.setState({ bigCurrent,current:0,name:name});
  };
  onChange = (current: any) => {
    this.setState({current});
    localStorage.setItem('current',current);
  }
  componentDidMount(){
    this.editable();
    let isexport = isExport();
    let diagnosisState = localStorage.getItem('diagnosisStatus');
    
    this.setState({
      exports:isexport,
      diagnosisStatu:diagnosisState
    },()=>{
      this.conclusion();
    });
  }

  conclusion(){
    let {diagnosisStatu} = this.state;
    let roleId = cookie.load('faisUserInfo');
    let isTrue = conclusionDisble(diagnosisStatu,roleId.roleIds[0]);
    this.setState({
      conDisable:isTrue
    })
  }

  showEchart(){
    let {bigCurrent,current} = this.state;
    switch(bigCurrent){
      case 0:{
        if(current===1){
          this.setState({
            echartIndex:0
          })
          this.getStaticData('diagnosis12',this.props.id,'企业基本信息');
          break;
        } else {
          message.warning({content:'此页面暂无统计数据！',style: {
            marginTop: '20vh',
          },});
          break;
        }
      }
      case 1:{
        if(current===1){
          this.setState({
            echartIndex:0
          })
          this.getStaticData('diagnosis22',this.props.id,'节能技术应用');
          break;
        } else if(current === 2){
          this.setState({
            echartIndex:1
          })
          this.getStaticData('diagnosis23',this.props.id,'标准参编');
          break;
        } else if(current === 3){
          this.setState({
            echartIndex:2
          })
          this.getStaticData('diagnosis24',this.props.id,'能源计量器具配备率');
          break;
        } else {
          message.warning({content:'此页面暂无统计数据！',style: {
            marginTop: '20vh',
          },});
          break;
        }
      }
      case 2:{
        message.warning({content:'此页面暂无统计数据！',style: {
          marginTop: '20vh',
        },});
        break;
      }
      case 3:{
        message.warning({content:'此页面暂无统计数据！',style: {
          marginTop: '20vh',
        },});
        break;
      }
      case 4:{
        if(current === 0){
          this.getDyeFixed('DYEING',0,this.props.id,'染色工序专项诊断');
          break; 
        } else {
          this.getDyeFixed('FIXED',1,this.props.id,'定形工序-单位产品综合能耗');
          break;
        }
      }
      case 5:{
        if(current === 0){
          this.getStaticData('diagnosis61',this.props.id,'全厂-单位产品综合能耗');
          break;
        } else {
          message.warning({content:'此页面暂无统计数据！',style: {
            marginTop: '20vh',
          },});
          break;
        }
      }
      case 6:{
        message.warning({content:'此页面暂无统计数据！',style: {
          marginTop: '20vh',
        },});
        break;
      }
    }
  }

  getDyeFixed(urlType:string,type:0 | 1,id:any,modelTitle:string){
    http.get(`/statistics/df/all/uec/${id}?type=${urlType}`).then((res:any)=>{
      if(res&&res.code==200){
        let data = handleStatisticsData(res.data,modelTitle);
        if(type===0){
          http.get(`/statistics/success/rate/${id}`).then((ress:any)=>{
            if(ress&&ress.code==200){
              let rateData = handleStatisticsData(ress.data,'');
              data.data = [...rateData.data,...data.data];
              this.setState({
                chartData:data,
                isShowChart:true
              });
            }
          })
        } else {
          this.setState({
            chartData:data,
            isShowChart:true
          });
        }
      }
    });
  }

  getStaticData(type:statosticsUrlName,id:any,modelTitle:string){
    let url = statistics[type] + '/' + id;
    http.get(url).then((res:any)=>{
      if(res&&res.code===200){
        let data = handleStatisticsData(res.data,modelTitle);
        this.setState({
          chartData:data,
          isShowChart:true
        });
      } else {
        message.error('服务端报错！请稍后重试');
      }
    })
  }

  setShowChart(){
    this.setState({
      isShowChart:false
    });
  }

  render(){
    const { bigCurrent,current,name,exports,editableDisable,diagnosisStatu,isShowChart,chartData,echartIndex} = this.state;
    let currentDOM;
    let step;
    switch(bigCurrent){
      case 0:
        step = <Steps size="small" current={current} onChange={this.onChange} className="site-navigation-steps-value" type="navigation" >
          <Step title="节能诊断基本信息" />
          <Step disabled={exports} title="企业基本信息" />
          <Step title="产品信息" />
          {/* <Step title="系统边界" /> */}
        </Steps>;
        switch(current){
          case 0:
            currentDOM = <EnergySavingInformation id={this.props.id} nextCurrent={this.nextCurrent} />;
            break;
          case 1:
            currentDOM = <EnterpriseInformation id={this.props.id} nextCurrent={this.nextCurrent} />;
            break;
          case 2:
            currentDOM = <EnergySaving id={this.props.id} />;
            break;
          // case 3:
          //   currentDOM = <SystemBoundary id={this.props.id} />
          //   break;
        }
        break;
      case 1:
        step = <Steps size="small" current={current} onChange={this.onChange} className="site-navigation-steps" type="navigation">
          <Step title="能源管理制度" />
          <Step title="节能技术应用" />
          <Step title="标准参编" />
          <Step title="能源计量器具信息" />
          <Step title="淘汰落后工艺或设备信息" />
          <Step title="机电设备信息" />
        </Steps>;
        switch(current){
          case 0:
            currentDOM = <EnergyManagement id={this.props.id} />;
            break;
          case 1:
            currentDOM = <ApplicationEnergy id={this.props.id} />;
            break;
          case 2:
            currentDOM = <Standard id={this.props.id} />;
            break;
          case 3:
            currentDOM = <EnergyMeasurement id={this.props.id} />;
            break;
          case 4:
            currentDOM = <Backwardness id={this.props.id} />;
            break;
          case 5:
            currentDOM = <HighConsumptionBackwardness id={this.props.id} />;
            break;
        }
        break;
      case 2:
        step = <Steps size="small" current={current} onChange={this.onChange} className="site-navigation-steps-value" type="navigation" >
          <Step title="能源消费" />
          <Step title="耗能工质消费" />
          <Step title="余热余能回收" />
        </Steps>;
        switch(current){
          case 0:
            currentDOM = <EnergyConsumption id={this.props.id}/>;
            break;
          case 1:
            currentDOM = <WorkingFluidConsumption id={this.props.id} type={0} />;
            break;
          case 2:
            currentDOM = <WasteHeat id={this.props.id} type={0} />;
            break;
        }
        break;
      case 3:
        step = <Steps size="small" current={current} onChange={this.onChange} className="site-navigation-steps-value" type="navigation" >
          {/* <Step title="单位产量能耗明细表" /> */}
          <Step title="年度辅助生产系统能耗" />
          <Step title="企业工艺设备统计" />
        </Steps>;
        switch(current){
          // case 0:
          //   currentDOM = <UnitOutPut id={this.props.id} />;
          //   break;
          case 0:
            currentDOM = <AnnualProcess id={this.props.id} />
            break;
          case 1:
            currentDOM = <EnterpriseTechnology id={this.props.id} />
            break;
        }
        break;
      case 4:
        step = <Steps size="small" current={current} onChange={this.onChange} className="site-navigation-steps-value" type="navigation" >
          <Step title="染色工序专项诊断" />
          <Step title="定形工序专项诊断" />
        </Steps>;
        switch(current){
          case 0:
            currentDOM = <Dyeing id={this.props.id} />
            break;
          case 1:
            currentDOM = <Fixed id={this.props.id} />
            break;
        }
        break;
      case 5:
        step = <Steps size="small" current={current} onChange={this.onChange} className="site-navigation-steps-value" type="navigation" >
          <Step title="诊断结果" />
          <Step title="能源消费明细" />
          <Step title="企业水平展示" />
        </Steps>;
        switch(current){
          case 0:
            currentDOM = <ResultsDiagnosis id={this.props.id} />
            break;
          case 1:
            currentDOM = <ResultsEnergyConsumption id={this.props.id} />;
            break;
          case 2:
            currentDOM = <EnterPriseLevelDisplay id={this.props.id} data={[{isOwn:true,label:'111',value:50},{isOwn:false,label:'131',value:20}]} titleName='1111' />;
            break;
        }
        break;
      case 6:
        currentDOM = <ConclusionComponent diagnosisStatus={diagnosisStatu} diagnosisId={this.props.id} />
    };
    
    return(
      <div className="diagnosis-process-container">
        <div className="left-nav">
          <Steps current={bigCurrent} onChange={this.onChangeBig} className="step-value-width" direction="vertical">
            <Step title="1.基本信息" icon={<FormOutlined />} />
            <Step title="2.基础指标" icon={<EditOutlined />} />
            <Step title="3.全厂能耗信息" icon={<EditOutlined />}/>
            <Step title="4.分项能耗信息" icon={<EditOutlined />}/>
            <Step title="5.专项诊断" icon={<EditOutlined />} />
            <Step title="诊断结果" icon={<CheckCircleOutlined />}/>
            <Step title="诊断结论与建议" disabled={this.state.conDisable} icon={<ContainerOutlined />}/>
          </Steps>
        </div>
        <div className="right-container">
          <div className="head-nav">
            <div className="step">
              <div className="step-name">{name}</div>
              <Divider type="vertical" />
              <div className="step-container">
                {step}
              </div>
              
            </div>
            {/* <div className="step-change"></div> */}
          </div>
          <div className="body-container" style={{height:this.props.maxHeight-70}}>
            {currentDOM}
            <div className="show-echants-rect" onClick={this.showEchart}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <ModalEchants getdata={this.setShowChart.bind(this)} isShow={isShowChart} data={chartData.data} modelTitle={chartData.modelTitle} type={echartIndex} />
        </div>
      </div>
    )
  }
}

export default DiagnosisProcess;