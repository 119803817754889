import './standard-coedit-awards.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Table, Pagination, Space, Button, Popover, message, Tabs, Drawer, Modal } from 'antd';
import { page, Page, standardType, standardEnum, awardsType, awardsEnum } from '../../../../utils/entity';
import { dateFormat, waterEditAndRead } from '../../../../utils/utils';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';
import http from '../../../../utils/http';
import AddAwards from '../add-awards/add-awards';
import AddStandard from '../add-standard/add-standard';
import {getScore,getRulesScore} from "../common/expert-additional";

import cookie from "react-cookies";
import {ExpertScore} from "../common/expert-score";

const StandardCoeditAwards = (props:any) => {
  const { TabPane } = Tabs;
  const [readAndEdit,setReadAndEdit] = useState<boolean>(true);
  const [dataList,setDataList] = useState<Array<any>>([]);
  const [total,setTotal] = useState<number>(0);
  const [searchPage,setSearchPage] = useState<page>(new Page());
  const [visiable,setVisiable] = useState<boolean>(false);
  const [editData,setEditData] = useState<any>();
  const [deleteId,setDeleteId] = useState<number>(0);
  const [deleteVisible,setDeleteVisible] = useState<boolean>(false);
  const [tabsKey,setTabsKey] = useState<string>('standard')
  const [expertData,setExpertData] = useState<any>()
  const [expertHJData,setExpertHJData] = useState<any>()

  const onSubmitDrawer = () => {
    onClose();
    getTableList();
  }

  const pageChange = (page:number) => {
    let pageData = searchPage;
    pageData.SkipCount = (page - 1) * 10;
    pageData.PageNum = page;
    setSearchPage(pageData);
    getTableList();
  }

  const getTableList = useCallback(() => {
    let url = '';
    if(tabsKey == 'standard'){
      url = '/apiWaterUrl/assess/standard/page';
    }else{
      url = '/apiWaterUrl/assess/prize/page';
    }
    http.get(url,{
      assessId:props.id,
      pageNum:searchPage.PageNum,
      pageSize:10
    }).then((res:any) => {
      if(res && res.code === 200){
        res.data.dataList.forEach((i:any,index:number) => {
          i.key = index+1;
        });
        setDataList(res.data.dataList);
        setTotal(res.data.count);
      }
    })
  },[props.id, searchPage.PageNum, tabsKey])


  const showDrawer = (data?:any) => {
    setEditData(data);
    setVisiable(true);
  }

  const onClose = () => {
    setVisiable(false);
  }
  
  const deleteItem = (id:any) => {
    setDeleteId(id);
    setDeleteVisible(true);
  }

  const handleOk = () => {
    let url = '';
    if(tabsKey == 'standard'){
      url = '/apiWaterUrl/assess/standard/';
    }else{
      url = '/apiWaterUrl/assess/prize/';
    }
    http.DELETE(url + deleteId).then((res:any) => {
      if(res && res.code === 200){
        message.success("删除成功");
        handleCancel();
        getTableList();
      }
    })
  }

  const handleCancel = () => {
    setDeleteVisible(false);
  }

  useEffect(() => {
    setReadAndEdit(waterEditAndRead());
  }, [])

  useEffect(() => {
    getTableList();
    getRulesScore('CB',5,props.id).then((res:any)=>{
      setExpertData(res)
    })
    getRulesScore('HJ',5,props.id).then((res:any)=>{
      setExpertHJData(res)
    })
  },[getTableList])

  const imgClick = (i:any) => {
    store.dispatch({type: OPENIMG,imgId:i.fileId,imgName:i.showName});
  }

  const columns = [{
    title:'序号',
    dataIndex:'key',
    width:60
  },{
    title:'标准类型',
    dataIndex:'standardEnum',
    key:'standardEnum',
    render:(text:standardType,record:any) => (
      <span>{standardEnum[text]}</span>
    )
  },{
    title:'标准名称',
    dataIndex:'name',
    key:'name'
  },{
    title:'标准号',
    dataIndex:'no',
    key:'no'
  },{
    title:'企业排名',
    dataIndex:'rank',
    key:'rank'
  },{
    title:'证明材料',
    dataIndex:'files',
    key:'files',
    render:(text:any, record:any) => (
      <Space size="middle">
        <Popover content={
          record?.files && record?.files.length > 0 ? record.files.map((i:any,index:number) => 
            <div key={index} onClick={() => imgClick(i)} className="div-a">{i.showName}</div>
          ) : null
        } title={null} trigger="hover">
          <span>共 <span style={{color:'#00A968'}}>{record?.files.length | 0}</span> 个</span>
        </Popover>
      </Space>
    )  
  },{
    title:'备注',
    dataIndex:'remark',
    key:'remark',
  },{
    title:'操作',
    key: 'action',
    fixed: 'right' as any,
    render:(text:any, record:any) => (
      readAndEdit?
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>编辑</span>
        <span className="green-table-a" onClick={() => deleteItem(record.id)}>删除</span>
      </Space>:
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>查看</span>
      </Space>
    )  
  }]

  const awardsColumns = [{
    title:'序号',
    dataIndex:'key',
    width:60
  },{
    title:'奖项类型',
    dataIndex:'prizeEnum',
    key:'prizeEnum',
    render:(text:awardsType,record:any) => (
      <span>{awardsEnum[text]}</span>
    )
  },{
    title:'奖项名称',
    dataIndex:'name',
    key:'name'
  },{
    title:'获奖时间',
    dataIndex:'produceTs',
    key:'produceTs',
    render: (text:string) => (
      <span>{text?dateFormat(new Date(text),'YYYY年mm月dd日'):''}</span>
    )
  },{
    title:'证明材料',
    dataIndex:'files',
    key:'files',
    render:(text:any, record:any) => (
      <Space size="middle">
        <Popover content={
          record?.files && record?.files.length > 0 ? record.files.map((i:any,index:number) => 
            <div key={index} onClick={() => imgClick(i)} className="div-a">{i.showName}</div>
          ) : null
        } title={null} trigger="hover">
          <span>共 <span style={{color:'#00A968'}}>{record?.files.length | 0}</span> 个</span>
        </Popover>
      </Space>
    )  
  },{
    title:'备注',
    dataIndex:'remark',
    key:'remark',
  },{
    title:'操作',
    key: 'action',
    fixed: 'right' as any,
    render:(text:any, record:any) => (
      readAndEdit?
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>编辑</span>
        <span className="green-table-a" onClick={() => deleteItem(record.id)}>删除</span>
      </Space>:
      <Space size="middle">
        <span className="green-table-a" onClick={() => showDrawer(record)}>查看</span>
      </Space>
    )  
  }]


  const showButtonView = () => {
    if(readAndEdit){
      return (
        <div className="button-margin-left">
          <Button className="green-button" onClick={showDrawer}>新增</Button>
        </div>
      )
    }else{
      return '';
    }
  }

  const tabOnChange = (key:any) => {
    setTabsKey(key);
    let pageData = searchPage;
    pageData.SkipCount = 0;
    pageData.PageNum = 1;
    setSearchPage(pageData);
  }

  const addDrawerView = () => {
    return (
      tabsKey == 'standard' ? 
        <AddStandard
          onClose={onClose} 
          editData={editData} 
          id={props.id}
          onSubmitDrawer={onSubmitDrawer}></AddStandard> : 
        <AddAwards
          onClose={onClose} 
          editData={editData} 
          id={props.id}
          onSubmitDrawer={onSubmitDrawer}></AddAwards>
    )
  }

  return (
    <>
      <div className="table-container">
        <Tabs type="card" activeKey={tabsKey} onChange={tabOnChange} size="small">
          <TabPane tab="标准参编列表" key="standard">
            <Row gutter={16} style={{paddingBottom: '8px'}}>
              <Col span={18}>
                <div className="table-title">标准参编：参编节水、节能和绿色环保等方面的标准</div>
                <div className="table-header-content">
                  <img src="/assets/images/icon_prompt_normal.png" alt=""/>
                  <span style={{paddingLeft: '8px'}}>标准参编是指企业参与相关标准的制定、修订，单位和单位人员在标准中署名。需要提供标准参编证明材料。</span>
                </div>
                <div className="table-header-content">
                  <img src="/assets/images/icon_prompt_normal.png" alt=""/>
                  <span style={{paddingLeft: '8px'}}>查阅参编标准。</span>
                </div>

              </Col>
              <Col span={6}>
                {getScore(expertData,props.id)}
                {showButtonView()}
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={dataList}
              pagination={false}
              size="small"
            />
            <div className="pagination">
              <div className="pagination-total">总共{total}条信息</div>
              <Pagination
                total={total}
                defaultCurrent={searchPage.PageNum}
                hideOnSinglePage
                onChange={pageChange}
              />
              
            </div>
          </TabPane>
          <TabPane tab="获奖信息列表" key="awards">
            <Row gutter={16} style={{paddingBottom: '8px'}}>
              <Col span={18}>
                <div className="table-title">获奖信息：获节水、节能、绿色环保相关的奖励</div>
                <div className="table-header-content">
                  <img src="/assets/images/icon_prompt_normal.png" alt=""/>
                  <span style={{paddingLeft: '8px'}}>获奖是指企业近三年内获得地方、行业、国家等科技奖励，例如技术发明奖、科技进步奖、节水型企业等。需要提供获奖证明材料。</span>
                </div>
                <div className="table-header-content">
                  <img src="/assets/images/icon_prompt_normal.png" alt=""/>
                  <span style={{paddingLeft: '8px'}}>查阅获奖证书。</span>
                </div>

              </Col>
              <Col span={6}>
                {getScore(expertHJData,props.id)}
                {showButtonView()}
              </Col>
            </Row>
            <Table
              columns={awardsColumns}
              dataSource={dataList}
              pagination={false}
              size="small"
            />
            <div className="pagination">
              <div className="pagination-total">总共{total}条信息</div>
              <Pagination
                total={total}
                current={searchPage.PageNum}
                hideOnSinglePage
                onChange={pageChange}
              />
              
            </div>
          </TabPane>
        </Tabs>
        <Drawer 
          title={editData?.id?'编辑':'新增'}
          width={550}
          onClose={onClose}
          destroyOnClose={true}
          visible={visiable}
          bodyStyle={{ paddingBottom: 10 }}
        >
          {addDrawerView()}
        </Drawer>
        <Modal
          title="删除"
          visible={deleteVisible}
          width="400px"
          cancelText="取消"
          okText="确定"
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose
          footer={[
            <Button key="cancel" type="default" onClick={handleCancel}>取消</Button>,
            <Button key="handleOk" type="primary" className="green-button" onClick={handleOk}>确认</Button>
          ]}
        >
          <p>确认要删除该项数据吗？</p>
        </Modal>
      </div>
    </>
  )
}

export default StandardCoeditAwards;