import React from 'react';
import './fiveStep.scss';
import { message, Radio } from 'antd';
import http from './../../utils/http';
import { SendOutlined } from '@ant-design/icons';

class FiveStepRule extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    this.state = {
      subjectIndex:0,
      subjectBgImg:'url(/assets/images/fiveStep_0.png)',
      subjectList:[],
      textArr:[
        ['湿地是物种的基因库、天然的蓄水池，气候的调节器，','为人类提供粮食、淡水、纤维和燃料，为人类抵御洪水、缓解干旱。','然而，自1970 年来，全球35%的湿地已经消失。'],
        ['有时，它是“秋水共长天一色”的浩渺水域；','有时，它是“碧野无垠接天云”的广阔草洲。','“珍禽王国”，“ 候鸟天堂”。它是鄱阳湖。'],
        ['这里是长江、黄河、澜沧江的发源地，这里河网密集、水草丰美，','这里是藏原羚、牦牛、黑颈鹤、狼和雪豹的乐土。','然而，气候变暖、湿地缩小、冰山萎缩、荒漠化','正侵蚀着三江源这座“中华水塔”。'],
        ['至少有 5.25 万亿个持久性微塑料漂浮在世界海洋中，','对海洋生物和海洋生态系统产生了巨大影响。','甚至在海底的最深处，没有栖居生物，','光也无法穿透，却仍然可以检测到微塑料。'],
        ['全球正面临着极端气候和生物多样性衰退的严峻态势。','应对这一挑战的根本之举是加快绿色转型，','使经济增长与生态环境之间紧张以至对抗的关系转变为和谐共生的关系。']
      ],
      isTrue:false
    };
    this.nextStep = this.nextStep.bind(this);
    this.categoryList = this.categoryList.bind(this);
    this.changeRadio = this.changeRadio.bind(this);
    this.submit = this.submit.bind(this);
  }

  nextStep(){
    let {subjectIndex,isTrue} = this.state;
    if(isTrue){
      this.setState({
        subjectIndex:subjectIndex+1,
        subjectBgImg:'url(/assets/images/fiveStep_'+(subjectIndex+1)+'.png)',
        isTrue:false
      })
    }
  }

  submit(){
    let {subjectList,isTrue,subjectIndex} = this.state;
    if(isTrue&&subjectIndex===4){
      let response:any = [];
      subjectList.forEach((i:any) => {
        i.questList.forEach((item:any)=>{
          response.push({
            questId:item.id,
            value:item.value
          })
        })
      });
      http.post('/fsem/quest/answer',response).then((res:any)=>{
        if(res&&res.code===200){
          message.success('提交完成');
          this.props.refresh();
        }
      })
    }
  }

  categoryList(){
    http.get('/fsem/quest/category/list').then((res:any)=>{
      if(res&&res.code===200){
        this.setState({
          subjectList:res.data
        })
      }
    })
  }

  changeRadio(index:number,e:any){
    let {subjectList,subjectIndex,isTrue} = this.state;
    let num = 0;
    subjectList[subjectIndex].questList[index].value = e.target.value;

    subjectList[subjectIndex].questList.forEach((i:any)=>{
      if(i.value){
        num++;
      }
    });

    if(num===subjectList[subjectIndex].questList.length){
      isTrue = true;
    } else {
      isTrue = false;
    }

    this.setState({
      subjectList,
      isTrue
    });
  }

  componentDidMount(){
    this.categoryList();
  }

  render(){
    let {subjectIndex,subjectList,subjectBgImg,textArr,isTrue} = this.state;
    let currentJect = <>
      <div className="five-step-container-item" style={{height:document.body.clientHeight - 110}}>
        <div className="five-step-container-item-left">
          <div className="five-step-container-item-left-title">
            <img src="/assets/images/fiveStep_icon.png" />让我们更好地了解您
          </div>
          <div className="five-step-container-item-left-introduction">在以下说法中，请根据您所在企业的实际情况，与实际情况最不相符为1，最相符为6，请做出最接 近实际情况从1到6中选择一个数字。</div>
          <div className="five-step-container-item-left-lvl">LEVEL {subjectIndex + 1} - {subjectList[subjectIndex]?.name}</div>
          <div className="five-step-container-item-left-option">
            {
            subjectList[subjectIndex]?.questList.map((i:any,index:number) => {
              return <div key={i.id} className="five-step-container-item-left-option-item">
                <div className="five-step-container-item-left-option-item-title">
                  {i.quest}
                </div>
                <div className="five-step-container-item-left-option-item-container">
                  <Radio.Group onChange={e=>this.changeRadio(index,e)} defaultValue={0}>
                    {
                      i.scores.map((j:number,t:number)=>{
                        return <Radio key={t} value={j}>{j}</Radio>;
                      })
                    }
                  </Radio.Group>
                </div>
              </div>;
            })}
          </div>
          <div className="division"></div>
          <div className="five-step-container-item-left-text">
            {
              textArr[subjectIndex].map((item:any,index:number) => {
                return <div key={index}>{item}</div>;
              })
            }
          </div>
          {
            subjectIndex!==4?<div className={isTrue?'five-step-container-item-left-next active':'five-step-container-item-left-next'} onClick={this.nextStep}>
              下一步<img src='/assets/images/fiveStep_next.png' />
            </div>:<div className={isTrue?'five-step-container-item-left-next-submit active':'five-step-container-item-left-next-submit'} onClick={this.submit}>提交并前往诊断<SendOutlined style={{marginLeft:'0.5rem'}} /></div>
          }
          
        </div>
        <div className="five-step-container-item-right">
          <img src={'/assets/images/fiveStepBg_' + (subjectIndex+1) + '.jpg'} />
        </div>
      </div>
    </>
    return(
      <>
        <div style={{height:document.body.clientHeight,backgroundImage:subjectBgImg}} className="five-step-container">
          <div className="fais-introduce-title" style={{padding:'0'}}>
            <span style={{fontSize:'0.6rem !important'}}>纺织行业绿色发展服务平台</span>
            <img src="/assets/images/fais.png" alt="fais" style={{width:'6rem'}} />
          </div>
          {currentJect}
        </div>
      </>
    )
  }
}

export default FiveStepRule;