import React from 'react';
import { Button, Form, Input, message,Select, Upload} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Http from '../../../../utils/http';
import { adminEditAndRead, verificationType } from '../../../../utils/utils';
import { UploadOutlined } from '@ant-design/icons';
import store from '../../../../utils/store';
import { OPENIMG } from '../../../../utils/actions';

export class AddStandard extends React.Component<any,any>{
  upLoad:any;
  constructor(props: any){
    super(props);
    this.state = {
      readAndEdit:true,
      fileList:[],
      preview:false,
      previewUrl:'',
    };
    this.upLoad = {
      beforeUpload: (file:any) => {
        if(verificationType(file.type)){
          this.fileUpload(file);
        } else {
          message.error('文件上传仅支持png,jpg,doc,docx,xls,xlsx格式后缀的文件');
        }
        return false;
      },
      onRemove: (file:any)=>{
        let {fileList} = this.state;
        fileList = fileList.filter((i:any)=> i['uid'] !== file.uid);
        this.setState({fileList});
        return false;
      },
      onPreview:(file:any)=>{
        store.dispatch({type: OPENIMG,imgId:file.id,imgName:file.name})
        return false;
      }
    };
    props.bindRef(this);
    this.getData = this.getData.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }
  createForm = React.createRef<FormInstance>();

  fileUpload(file:any){
    const formData = new FormData();
    formData.append('file', file);
    Http.post('/system/file/upload/auth',formData).then((res:any)=>{
      if(res&&res.code===200){
        let img = {
          uid:file.uid,
          name:file.name,
          status:'done',
          id:res.data,
          url:''
        };
        this.setState({
          fileList:[...this.state.fileList,img]
        })
      }
    })
  }

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(values:any){
    let {fileList} = this.state;
    values.files = [];
    fileList.forEach((i:any)=>{
      values.files.push({
        fileId:i.id,
        showName:i.name
      });
    })
    if(this.props.editData.id){
      let requestData = {...values}
      Http.put('/diagnosis/standard/'+this.props.editData.id,requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功');
          this.props.closeDrawer(true);
        }
      })
    } else {
      let requestData = {...values,diagnosisId:this.props.id}
      Http.post('/diagnosis/standard',requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新增成功');
          this.props.closeDrawer(true);
        }
      })
    }
    
  }

  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.editData){
      this.createForm.current?.setFieldsValue({
        industryStandard:this.props.editData.industryStandard,
        name:this.props.editData.name,
        no:this.props.editData.no,
        rank:this.props.editData.rank
      });
      if(this.props.editData.files&&this.props.editData.files.length>0){
        let arr:any = [];
        this.props.editData.files.forEach((i:any,index:number)=>{
          let img = {
            uid:index,
            name:i.showName,
            status:'done',
            id:i.fileId,
            url:''
          };
          arr.push(img);
        });
        this.setState({
          fileList:arr
        })
      }
    }
    
  }
  render(){
    const { Option } = Select;
    let {readAndEdit,fileList} = this.state;
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Form.Item label="标准名称" name="name" rules={[{required: true}]}>
          <Input disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="国家/行业标准" name="industryStandard" rules={[{required: true}]}>
          <Select style={{ width: '100%' }} placeholder="请选择" disabled={!readAndEdit} >
            <Option value="国家标准">国家标准</Option>
            <Option value="行业标准">行业标准</Option>
            <Option value="团体标准">团体标准</Option>
          </Select>
        </Form.Item>
        <Form.Item label="标准号" name="no" rules={[{required: true}]}>
          <Input disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="企业排名" name="rank">
          <Input disabled={!readAndEdit} />
        </Form.Item>
        <Form.Item label="上传附件">
          <Upload {...this.upLoad} fileList={fileList} disabled={!readAndEdit} >
            <Button>
              <UploadOutlined />点击上传
            </Button>支持.xls .xlsx .doc .docx .pdf .jpg格式，单个文件大小不超过20M
          </Upload>
        </Form.Item>
      </Form>
    )
  }
}

export default AddStandard;