import React from 'react';
import {Select, Form, message, Row, Col, InputNumber, Input} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Http from '../../../../utils/http';
import { adminEditAndRead } from '../../../../utils/utils';

export class AddWorkTable extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    props.bindRef(this);
    this.state={
      list:[],
      energylist:[],
      readAndEdit:true
    };
    this.getData = this.getData.bind(this);
    this.ecList = this.ecList.bind(this);
    this.changeProduct = this.changeProduct.bind(this);
  };
  
  createForm = React.createRef<FormInstance>();

  vailForm(){
    this.createForm.current?.submit();
  }

  getData(values:any){
    if(this.props.editData.id){
      Http.put('/diagnosis/process/ec/'+this.props.editData.id,{...values}).then((res:any)=>{
        if(res&&res.code===200){
          message.success('修改成功');
          this.props.closeDrawer(true);
        }
      })
    } else {
      values.diagnosisId = this.props.id;
      values.diagnosisProcessId = this.props.workId;
      let requestData = {...values,diagnosisId:this.props.id}
      Http.post('/diagnosis/process/ec',requestData).then((res:any)=>{
        if(res&&res.code===200){
          message.success('新增成功');
          this.props.closeDrawer(true);
        }
      })
    }
  }

  energyList(){
    const id = this.props.id;
    let ec = new Promise(function (resolve){
      resolve(Http.get('diagnosis/ec/'+id+'/energy/type?type=EC'));
    });
    let wc = new Promise(function (resolve){
      resolve(Http.get('diagnosis/ec/'+id+'/energy/type?type=WC'));
    });
    Promise.all([ec,wc]).then((res:any)=>{
      let energylist = [...res[0].data,...res[1].data];
      this.setState({
        energylist:energylist
      })
    })
  }

  ecList(){
    Http.get('/diagnosis/process/device/page',{
      diagnosisProcessId:this.props.workId,
      diagnosisId:this.props.id,
      energyCosType:this.createForm.current?.getFieldValue('energyCosType'),
      processType:this.props.processType
    }).then((res:any)=>{
      this.setState({
        list:[...res.data]
      });
      if(this.props.editData.id){
        this.createForm.current?.setFieldsValue(this.props.editData);
      }
    })
  }
  componentDidMount(){
    this.setState({
      readAndEdit:adminEditAndRead()
    })
    if(this.props.editData.id){
      this.createForm.current?.setFieldsValue(this.props.editData);
    } else {
      this.createForm.current?.setFieldsValue({
        energyCosType:'MAIN'
      });
    }
    this.ecList();
    this.energyList();
  }

  changeProduct(e:any){
    let {energylist} = this.state;
    let selectList = energylist.filter((i:any)=> i.id === e)[0];
    this.createForm.current?.setFieldsValue({
      unit:selectList.unit
    });
  }
  
  render(){
    const { Option } = Select;
    const {list,energylist,readAndEdit} = this.state;
    const product = list.map((i:any)=>{
      return <Option disabled={i.used==='T'?true:false} key={i.id} value={i.id}>{i.name}</Option>;
    });
    const energyProduct = energylist.map((i:any)=>{
      return <Option disabled={i.used==='T'?true:false} key={i.id} value={i.id}>{i.name}</Option>;
    });
    return(
      <Form layout="vertical" ref={this.createForm} name="add-product" onFinish={this.getData}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="重点用能系统" name="energyCosType" rules={[{required: true}]}>
              <Select disabled={!readAndEdit} style={{ width: '100%' }} placeholder="请选择" onChange={this.ecList}>
                  <Option value="MAIN">主要生产系统</Option>
                  <Option value="AID">辅助生产系统</Option>
                </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="主要用能设备" name="processDeviceId" rules={[{required: true}]}>
              <Select style={{ width: '100%' }} placeholder="请选择" disabled={!readAndEdit} >
                {product}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="台数" name="deviceNum" rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="能源种类" name="energyId" rules={[{required: true}]}>
              <Select disabled={!readAndEdit} style={{ width: '100%' }} placeholder="请选择" onChange={this.changeProduct} >
                {energyProduct}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="年消耗量" name="cosNum" rules={[{required: true}]}>
              <InputNumber style={{width:'100%'}} disabled={!readAndEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="计量单位" name="unit" >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="备注" name="comment" >
              <Input disabled={!readAndEdit} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default AddWorkTable;